/* eslint-disable import/no-cycle */

import {Api} from '../services/api';
import {RootStore} from './root-store';
import {BaseStore} from './base-store';
import {TravelInsuranceRate, TravelInsuranceRateType} from '../models/travel-insurance-rate';
import {TermOfInsurance} from '../models/term-of-insurance';

export class TravelInsuranceRateStore extends BaseStore<TravelInsuranceRate, number> {
  constructor(rootStore: RootStore) {
    super(rootStore, 'id', Api.getTravelInsuranceRates);

    this.loadAll();
  }

  findAll(
    type: TravelInsuranceRateType,
    termOfInsurance: TermOfInsurance
  ): TravelInsuranceRate[] {
    return this.models.filter(
      (rate) =>
        rate.type === type
        && rate.termOfInsurance?.id === termOfInsurance.id
    );
  }
}
