import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import {Person} from '../../models/person';
import {AddonInsurance, AddonInsuranceType, AddonInsuranceTypes, PatientCareType} from '../../models/addon-insurance';
import {useStore} from '../../hooks/useStore';
import {ADDON_INSURANCE_PREMIUM_REDUCTION_FACTOR} from '../../stores/calculator-store';
import Card from '../shared/Card';
import {Col, Row} from '../layout/Grid';
import Icon from '../shared/Icon';
import Price from '../shared/Price';
import Checklist from '../shared/Checklist';
import Divider from '../shared/Divider';
import Button from '../shared/Button';
import {TrashBinIcon} from '../shared/icons';
import Text from '../shared/Text';

interface AddonInsuranceCardProps {
  person: Person;
  addonInsurance: AddonInsurance;
  editable: boolean;
}

const AddonInsuranceCardOverview: FC<AddonInsuranceCardProps> = ({person, addonInsurance, editable}) => {
  const {t} = useTranslation();
  const store = useStore();
  const hasReduction = store.calculatorStore.getPremiumReductionAddonInsurances(person);
  const factor = hasReduction && addonInsurance.type !== AddonInsuranceType.ACCIDENT_DEATH_DISABILITY
    ? ADDON_INSURANCE_PREMIUM_REDUCTION_FACTOR
    : 1;

  const removeAddonInsurance = (type: AddonInsuranceType) => {
    person.removeAddonInsurance(type);
  };

  return (
    <div className={'addon-insurance'} key={addonInsurance.type}>
      <Card>
        <Row wrap={false}>
          <Col flex={'auto'}>
            <Text header={2}>
              {t(`${AddonInsuranceTypes[addonInsurance.type].translationPrefix}.title`)}
            </Text>
            <Text size={'extra-small'} color={'turquoise'} strong opacity={0.6}>
              {t('addonInsurance.one')}
            </Text>
          </Col>
          <Col flex={'50px'} className={'product-icon'}>
            <Icon component={AddonInsuranceTypes[addonInsurance.type].icon} size={40}/>
          </Col>
        </Row>
        <br/>
        {addonInsurance.type === AddonInsuranceType.PATIENT_CARE && (
          <Text size={'small'} color={'gray-blue'}>
            {`${t('insurance.model')}: `}
            {t(`patientCare.${addonInsurance.rate?.variant}`)}
            <br/>
            {addonInsurance.rate?.deductible ? (
              <>
                {`${t('insurance.deductible')}: `}
                {'CHF '}
                <Price noZeroDecimals value={addonInsurance.rate.deductible}/>
              </>
            ) : addonInsurance.rate?.variant !== PatientCareType.NORMAL && t('insurance.noDeductible')}
          </Text>
        )}
        {addonInsurance.type === AddonInsuranceType.ACCIDENT_DEATH_DISABILITY && (
          <Text size={'small'} color={'gray-blue'}>
            {`${t('addonInsurance.death')}: `}
            {'CHF '}
            {addonInsurance.rate?.death && (<Price noZeroDecimals value={addonInsurance.rate.death}/>)}
            <br/>
            {`${t('addonInsurance.invalidity')}: `}
            {'CHF '}
            {addonInsurance.rate?.invalidity
              && (<Price noZeroDecimals value={addonInsurance.rate.invalidity}/>)}
          </Text>
        )}
        <div className={'print-show'}>
          <br/>
          <Checklist text={t(`${AddonInsuranceTypes[addonInsurance.type].translationPrefix}.description`)}/>
        </div>
        <Divider primary/>
        <Row align={'bottom'} className={'price-row'}>
          <Col flex={'auto'}>
            <Text size={'small'} opacity={0.4}>{t('insurance.monthlyRate')}</Text>
            <br/>
            <Text size={'small'} opacity={0.4}>CHF </Text>
            <Text strong color={'gray-blue'}><Price value={addonInsurance.price * factor}/></Text>
          </Col>
          {editable && (
            <Col flex={'50px'} className={'delete'}>
              <Button type={'link'} onClick={() => removeAddonInsurance(addonInsurance.type)}>
                <Icon component={TrashBinIcon}/>
              </Button>
            </Col>
          )}
        </Row>
      </Card>
    </div>
  );
};

export default observer(AddonInsuranceCardOverview);
