import {useTranslation} from 'react-i18next';
import {FC, ReactNode} from 'react';
import {observer} from 'mobx-react-lite';
import {useNavigate} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import PaymentCard from './cards/PaymentCard';
import ContactCard from './cards/ContactCard';
import {useStore} from '../hooks/useStore';
import {Person} from '../models/person';
import {CalculatorPaths} from '../pages/paths';
import {Col, Row} from './layout/Grid';
import {Config, GUTTER_SIZE} from '../config';
import Space from './shared/Space';
import InfoBox from './shared/InfoBox';
import PersonBlock from './PersonBlock';
import Button from './shared/Button';
import TotalCard from './cards/TotalCard';

interface PeopleOverviewProps {
  children?: ReactNode;
  fullDetails?: boolean;
}

const PeopleOverview: FC<PeopleOverviewProps> = ({fullDetails = false, children}) => {
  const {t} = useTranslation();
  const store = useStore();
  const navigate = useNavigate();

  const addPerson = () => {
    const person = Person.create();
    store.calculatorStore.add(person);
    store.calculatorStore.setCurrent(person);
    store.setEditMode(false);
    navigate(CalculatorPaths.index());
  };

  return (
    <Row gutter={[GUTTER_SIZE, GUTTER_SIZE]}>
      <Col xs={24} sm={24} md={24} lg={16} xl={16}>
        <Space fullWidth direction={'vertical'} size={'large'}>
          {store.calculatorStore.current.isNewBorn && (
            <InfoBox title={t('person.infoNewBorn.title')}>
              <ReactMarkdown className={'aquilana-text'} linkTarget={'_blank'}>
                {t('person.infoNewBorn.text', {url: Config.aquilanaContactUrl})}
              </ReactMarkdown>
            </InfoBox>
          )}
          {store.calculatorStore.models.map((person, index) => (
            <PersonBlock
              key={person.uuid}
              person={person}
              index={index}
              fullDetails={fullDetails}
            />
          ))}
          {!fullDetails && !store.calculatorStore.current.isNewBorn && (
            <Button skin={'secondary'} onClick={addPerson}>{t('person.add')}</Button>
          )}
          {fullDetails && !store.calculatorStore.current.isNewBorn && (
            <Row gutter={[GUTTER_SIZE, GUTTER_SIZE]} style={{justifyContent: 'space-between'}}>
              <Col>
                <Button skin={'secondary'} onClick={() => navigate(CalculatorPaths.overview())}>
                  {t('action.backToOverview')}
                </Button>
              </Col>
              <Col>
                {children}
              </Col>
            </Row>
          )}
        </Space>
      </Col>
      <Col xs={24} sm={24} md={24} lg={8} xl={8}>
        <Space fullWidth direction={'vertical'} size={'large'}>
          <TotalCard/>
          {fullDetails && <ContactCard/>}
          {fullDetails && <PaymentCard/>}
        </Space>
      </Col>
    </Row>
  );
};

export default observer(PeopleOverview);
