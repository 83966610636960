import {FC} from 'react';
import {formatNumber} from '../../utils/numbers';

interface PriceProps {
  value: number;
  noZeroDecimals?: boolean;
  noDecimals?: boolean;
}

const Price: FC<PriceProps> = ({value, noZeroDecimals, noDecimals}) => {
  if (noDecimals || (noZeroDecimals && value % 1 === 0)) {
    return (
      <>
        {formatNumber(value, 0)}
        {!noDecimals && '.-'}
      </>
    );
  }

  return (
    <>{formatNumber(value)}</>
  );
};

export default Price;
