/* eslint-disable import/no-cycle */
import {action, makeObservable, observable} from 'mobx';
import {AquilanaFunctionStore} from './aquilana-function-store';
import {RootStore} from './root-store';
import {ICancellationUploadRequest} from '../models/document-request';
import {Sex} from '../models/person';

export class CancellationUploadStore extends AquilanaFunctionStore {
  firstName?: string = undefined;
  lastName?: string = undefined;
  sex?: Sex = undefined;
  file?: string = undefined;
  insuranceNumber?: string = undefined;
  customerEmail?: string = undefined;
  issueType?: string = undefined;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      firstName: observable,
      lastName: observable,
      sex: observable,
      file: observable,
      insuranceNumber: observable,
      customerEmail: observable,
      issueType: observable,

      setFirstName: action,
      setLastName: action,
      setSex: action,
      setFile: action,
      setInsuranceNumber: action,
      setCustomerEmail: action,
      setIssueType: action,
    });
  }

  setFile(file: string) {
    this.file = file;
  }

  setInsuranceNumber(insuranceNumber: string) {
    this.insuranceNumber = insuranceNumber;
  }

  setFirstName(firstName: string) {
    this.firstName = firstName;
  }

  setLastName(lastName: string) {
    this.lastName = lastName;
  }

  setSex(sex: Sex) {
    this.sex = sex;
  }

  setCustomerEmail(email: string) {
    this.customerEmail = email;
  }

  setIssueType(issueType: string) {
    this.issueType = issueType;
  }

  reset() {
    this.file = undefined;
    this.insuranceNumber = undefined;
    this.firstName = undefined;
    this.lastName = undefined;
    this.customerEmail = undefined;
    this.sex = undefined;
  }

  getIssueRequest(): ICancellationUploadRequest {
    return {
      issueType: this.issueType ?? '',
      firstName: this.firstName ?? '',
      lastName: this.lastName ?? '',
      sex: this.sex || Sex.MALE,
      email: this.email ?? '',
      phone: this.phone ?? '',
      insuranceNo: this.insuranceNumber ?? '',
      file: this.file ?? '',
      formData: {
        email: this.customerEmail,
      },
    };
  }
}
