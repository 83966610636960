import {computed, makeObservable, observable} from 'mobx';
import {IBaseModel} from './base-model';

export interface IEnvironment extends IBaseModel {
  name: string;
}

export class Environment implements IEnvironment {
  name = '';

  constructor() {
    makeObservable(this, {
      name: observable,
      humanName: computed,
      isEnvironmentInfoVisible: computed,
    });
  }

  public get humanName() {
    return this.name.charAt(0).toUpperCase() + this.name.slice(1);
  }

  public get isEnvironmentInfoVisible() {
    return this.name !== 'prod';
  }

  static fromJSON(data: IEnvironment): Environment {
    const environment = new Environment();
    environment.name = data.name;

    return environment;
  }
}
