import {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '../../../../hooks/useStore';
import {Col, Row} from '../../../../components/layout/Grid';
import Space from '../../../../components/shared/Space';
import {GUTTER_SIZE} from '../../../../config';
import PersonBlockFranchiseChange from './PersonBlockFranchiseChange';
import TotalCard from '../../../../components/cards/TotalCard';

const PeopleOverviewFranchiseChange: FC = () => {
  const store = useStore();

  return (
    <Row gutter={[GUTTER_SIZE, GUTTER_SIZE]}>
      <Col xs={24} sm={24} md={24} lg={16} xl={16}>
        <Space fullWidth direction={'vertical'} size={'large'}>
          {store.franchiseChangeStore.models.map((person) => (
            <PersonBlockFranchiseChange
              key={person.uuid}
              person={person}
            />
          ))}
        </Space>
      </Col>
      <Col xs={24} sm={24} md={24} lg={8} xl={8}>
        <Space fullWidth direction={'vertical'} size={'large'}>
          <TotalCard/>
        </Space>
      </Col>
    </Row>
  );
};

export default observer(PeopleOverviewFranchiseChange);
