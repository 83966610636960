import {useContext} from 'react';
import {DomainContext} from '../contexts/DomainContext';
import {CALCULATOR_CONTEXT_NAME} from '../pages/calculator/Context';
import {FRANCHISE_CHANGE_CONTEXT_NAME} from '../pages/franchise-change/Context';
import {TRAVEL_INSURANCE_CONTEXT_NAME} from '../pages/travel-insurance/Context';
import {useStore} from './useStore';
import {CANCELLATION_UPLOAD_CONTEXT_NAME} from '../pages/cancellation-upload/Context';
import {CANCELLATION_WIZARD_CONTEXT_NAME} from '../pages/cancellation-wizard/Context';
import {CANCELLATION_DEATH_CONTEXT_NAME} from '../pages/cancellation-death/Context';
import {CANCELLATION_LEAVE_CONTEXT_NAME} from '../pages/cancellation-leave/Context';

const useCurrentDomainInformation = () => {
  const context = useContext(DomainContext);
  const store = useStore();

  if (!context) {
    throw new Error('useCurrentDomainInformation must be used within a DomainContextProvider');
  }

  const isFranchiseChange = context.name === FRANCHISE_CHANGE_CONTEXT_NAME;
  const isTravelInsurance = context.name === TRAVEL_INSURANCE_CONTEXT_NAME;
  const isCalculator = context.name === CALCULATOR_CONTEXT_NAME;
  const isCancellationUpload = context.name === CANCELLATION_UPLOAD_CONTEXT_NAME;
  const isCancellationWizard = context.name === CANCELLATION_WIZARD_CONTEXT_NAME;
  const isCancellationDeath = context.name === CANCELLATION_DEATH_CONTEXT_NAME;
  const isCancellationLeave = context.name === CANCELLATION_LEAVE_CONTEXT_NAME;

  const currentStore = context.store || store.calculatorStore;

  return {
    isCalculator,
    isFranchiseChange,
    isTravelInsurance,
    isCancellationUpload,
    isCancellationWizard,
    isCancellationDeath,
    isCancellationLeave,
    currentStore,
  };
};

export default useCurrentDomainInformation;
