import {FC} from 'react';
import {Col, Row} from 'antd';
import {useTranslation} from 'react-i18next';
import Button from './shared/Button';
import {GUTTER_SIZE} from '../config';
import './StepsNavigationButtons.scss';

interface StepsNavigationButtonsProps {
  back: () => void;
  backDisabled?: boolean;
  backLabel?: string;
  backHidden?: boolean;
  next: () => void;
  nextDisabled?: boolean;
  nextLabel?: string;
  loading?: boolean;
}

const StepsNavigationButtons: FC<StepsNavigationButtonsProps> = ({
  back,
  backDisabled,
  backLabel,
  backHidden = false,
  next,
  nextDisabled,
  nextLabel,
  loading,
}) => {
  const {t} = useTranslation();

  return (
    <Row gutter={[GUTTER_SIZE, GUTTER_SIZE]} className={'navigation-buttons'}>
      <Col span={12}>
        {!backHidden && (
          <Button skin={'secondary'} onClick={() => back()} disabled={backDisabled || loading}>
            {backLabel || t('action.back')}
          </Button>
        )}
      </Col>
      <Col span={12} style={{textAlign: 'right'}}>
        <Button skin={'primary-gray-blue'} onClick={() => next()} disabled={nextDisabled || loading} loading={loading}>
          {nextLabel || t('action.next')}
        </Button>
      </Col>
    </Row>
  );
};

export default StepsNavigationButtons;
