import React, {FC} from 'react';
import {useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {useStore} from '../../../hooks/useStore';
import {TravelInsurancePaths} from '../../paths';
import Container from '../../../components/layout/Container';
import Text from '../../../components/shared/Text';
import {TravelInsuranceRateType} from '../../../models/travel-insurance-rate';
import {Col, Row} from '../../../components/layout/Grid';
import {GUTTER_SIZE} from '../../../config';
import TravelInsuranceContactCard from './_components/TravelInsuranceContactCard';
import Space from '../../../components/shared/Space';
import Button from '../../../components/shared/Button';
import {Person, TravelInsurancePersonType} from '../../../models/person';
import PersonBlock from '../../../components/PersonBlock';
import SubmitApplicationForm from '../../../components/SubmitApplicationForm';

const TravelInsuranceOverview: FC = () => {
  const store = useStore();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const addPerson = (isChild = true) => {
    const person = Person.create();
    person.setTravelInsurancePersonType(isChild ? TravelInsurancePersonType.CHILD : TravelInsurancePersonType.PARTNER);
    store.travelInsuranceStore.add(person);
    store.travelInsuranceStore.setCurrent(person);
    store.setEditMode(false);
    navigate(TravelInsurancePaths.family());
  };

  const onChangeInsurance = () => {
    navigate(TravelInsurancePaths.index());
  };

  return (
    <>
      <Container background={'white'}>
        <Text header={1}>{t('travelInsurance.overview.title')}</Text>
        <Text size={'intro'}>{t('travelInsurance.overview.header')}</Text>
      </Container>
      <Container background={'light'} flex={1}>
        <Row gutter={[GUTTER_SIZE, GUTTER_SIZE]}>
          <Col xs={24} sm={24} md={24} lg={16} xl={16}>
            <Space fullWidth direction={'vertical'} size={'large'}>
              <PersonBlock person={store.travelInsuranceStore.policyHolder} fullDetails index={0}/>
              {store.travelInsuranceStore.partner && (
                <PersonBlock person={store.travelInsuranceStore.partner} fullDetails index={1}/>
              )}
              {store.travelInsuranceStore.children.map((child, index) => (
                <PersonBlock key={child.uuid} person={child} fullDetails index={index + 1}/>
              ))}
              <Space fullWidth direction={'horizontal'} size={'large'}>
                <Button
                  onClick={onChangeInsurance}
                  skin={'secondary'}
                >
                  {t('travelInsurance.overview.changeInsurance')}
                </Button>
                {store.travelInsuranceStore.type === TravelInsuranceRateType.FAMILY
                  && !store.travelInsuranceStore.partner && (
                    <Button
                      onClick={() => addPerson(false)}
                      skin={'secondary'}
                    >
                      {t('travelInsurance.family.addPartner')}
                    </Button>
                )}
                {store.travelInsuranceStore.type === TravelInsuranceRateType.FAMILY && (
                  <Button
                    onClick={() => addPerson()}
                    skin={'secondary'}
                  >
                    {t('travelInsurance.family.addChildren')}
                  </Button>
                )}
              </Space>
            </Space>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <Space fullWidth direction={'vertical'} size={'large'}>
              <TravelInsuranceContactCard/>
            </Space>
          </Col>
        </Row>
      </Container>
      <Container background={'light'} flex={1}>
        <SubmitApplicationForm/>
      </Container>
    </>
  );
};

export default observer(TravelInsuranceOverview);
