import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import {useNavigate} from 'react-router-dom';
import Container from '../../../components/layout/Container';
import Text from '../../../components/shared/Text';
import { useStore } from '../../../hooks/useStore';
import {FranchiseChangePaths} from '../../paths';
import FranchiseChangePersonDetailsForm from './_components/FranchiseChangePersonDetailsForm';
import {Col, Row} from '../../../components/layout/Grid';

const PersonDetails: FC = () => {
  const {t} = useTranslation();
  const store = useStore();
  const navigate = useNavigate();

  const nextStep = () => {
    store.setEditMode(false);
    navigate(FranchiseChangePaths.overview());
  };
  return (
    <>
      <Container background={'white'}>
        <Text header={1}>{t('stepPersonDetails.title')}</Text>
        <Text size={'intro'}>{t('stepPersonDetails.headerInfo')}</Text>
      </Container>
      <Container background={'light'} flex={1}>
        <Row>
          <Col xs={24} sm={24} md={24} lg={14} xl={14}>
            <FranchiseChangePersonDetailsForm afterSubmit={nextStep}/>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default observer(PersonDetails);
