import {makeObservable, observable} from 'mobx';
import {IBaseModel} from './base-model';
import {PremiumVersion} from './premium-version';

export interface IAddonInsuranceRate extends IBaseModel {
  id: number;
  premiumVersionId: number;
  addonInsuranceTypeId: number;
  fromAge: number;
  toAge: number;
  value: number;
  deductible: number;
  variant: string;
  death: number;
  invalidity: number;
}

export class AddonInsuranceRate implements IAddonInsuranceRate {
  id = 0;
  premiumVersionId = 0;
  premiumVersion?: PremiumVersion = undefined;
  addonInsuranceTypeId = 0;
  fromAge = 0;
  toAge = 0;
  value = 0;
  deductible = 0;
  variant = '';
  death = 0;
  invalidity = 0;

  constructor() {
    makeObservable(this, {
      id: observable,
      premiumVersionId: observable,
      premiumVersion: observable,
      addonInsuranceTypeId: observable,
      fromAge: observable,
      toAge: observable,
      value: observable,
      deductible: observable,
      variant: observable,
      death: observable,
      invalidity: observable,
    });
  }

  public setPremiumVersion(premiumVersion?: PremiumVersion) {
    this.premiumVersion = premiumVersion;
  }

  static fromObject(data: IAddonInsuranceRate): AddonInsuranceRate {
    const addonInsuranceRate = new AddonInsuranceRate();
    addonInsuranceRate.id = data.id;
    addonInsuranceRate.premiumVersionId = data.premiumVersionId;
    addonInsuranceRate.addonInsuranceTypeId = data.addonInsuranceTypeId;
    addonInsuranceRate.fromAge = data.fromAge;
    addonInsuranceRate.toAge = data.toAge;
    addonInsuranceRate.value = data.value;
    addonInsuranceRate.deductible = data.deductible ?? 0;
    addonInsuranceRate.variant = data.variant;
    addonInsuranceRate.death = data.death ?? 0;
    addonInsuranceRate.invalidity = data.invalidity ?? 0;

    return addonInsuranceRate;
  }
}
