/* eslint-disable import/no-cycle */
import {Api} from '../services/api';
import {RootStore} from './root-store';
import {BaseStore} from './base-store';
import {PreviousInsurer} from '../models/previous-insurer';

export class PreviousInsurerStore extends BaseStore<PreviousInsurer, number> {
  constructor(rootStore: RootStore) {
    super(rootStore, 'id', Api.getPreviousInsurers);
  }
}
