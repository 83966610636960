import React, {FC, useState} from 'react';
import {DatePicker, Form, Input, Radio} from 'antd';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import dayjs, {Dayjs} from 'dayjs';
import {useNavigate} from 'react-router-dom';
import {Col, Row} from '../../../../components/layout/Grid';
import {Config, GUTTER_SIZE} from '../../../../config';
import {Sex} from '../../../../models/person';
import {Validators} from '../../../../utils/validators';
import InfoBox from '../../../../components/shared/InfoBox';
import Text from '../../../../components/shared/Text';
import OptionalLabel from '../../../../components/shared/OptionalLabel';
import {formatInsuranceNumber} from '../../../../utils/formatter';
import {useStore} from '../../../../hooks/useStore';
import {City} from '../../../../models/city';
import Select from '../../../../components/shared/Select';
import useCitySelection from '../../../../hooks/useCitySelection';
import InfoWithLabel from '../../../../components/shared/InfoWithLabel';
import StepsNavigationButtons from '../../../../components/StepsNavigationButtons';
import {CancellationWizardPaths} from '../../../paths';
import CancellationDatesInfo from './CancellationDatesInfo';

interface FormValues {
  sex: Sex;
  firstName: string;
  lastName: string;
  address: string;
  city: City['id'];
  email?: string;
  phone?: string;
  birthDate: Dayjs;
  insuranceNumber: string;
}

const PersonForm: FC = () => {
  const {t} = useTranslation();
  const store = useStore();
  const navigate = useNavigate();
  const [form] = Form.useForm<FormValues>();
  const [loading, setLoading] = useState(false);
  const {options} = useCitySelection();
  const person = store.cancellationWizardStore.current;
  const {isPolicyHolder} = person;

  const onBack = () => {
    if (store.editMode) {
      store.setEditMode(false);

      navigate(CancellationWizardPaths.overview());
    } else {
      store.cancellationWizardStore.remove(person);
      navigate(CancellationWizardPaths.overview());
    }
  };

  const onNext = () => {
    navigate(CancellationWizardPaths.overview());
  };

  const initialValues = {
    sex: person.sex,
    firstName: person.firstName,
    lastName: person.lastName,
    address: store.cancellationWizardStore.address,
    city: store.cancellationWizardStore.city?.id,
    email: store.cancellationWizardStore.email,
    phone: store.cancellationWizardStore.phone,
    birthDate: person.birthday ?? undefined,
    insuranceNumber: person.insuranceNo,
  };

  const handleSubmit = (values: FormValues) => {
    setLoading(true);
    person.setSex(values.sex);
    person.setFirstName(values.firstName);
    person.setLastName(values.lastName);
    store.cancellationWizardStore.setAddress(values.address);
    store.cancellationWizardStore.setCity(store.cityStore.getById(values.city));

    if (values.email && isPolicyHolder) {
      store.cancellationWizardStore.setEmail(values.email);
    }

    if (values.phone && isPolicyHolder) {
      store.cancellationWizardStore.setPhone(values.phone);
    } else if (isPolicyHolder) {
      store.cancellationWizardStore.setPhone(undefined);
    }

    person.setBirthday(values.birthDate);
    person.setInsuranceNo(values.insuranceNumber);

    onNext();
  };

  return (
    <Row gutter={[GUTTER_SIZE, GUTTER_SIZE]}>
      <Col xs={24} sm={24} md={24} lg={16} xl={16}>
        <Form
          form={form}
          initialValues={initialValues}
          layout={'vertical'}
          colon={false}
          labelAlign={'left'}
          requiredMark={false}
          onFinish={handleSubmit}
        >
          <Form.Item>
            <InfoBox title={t('cancellation.wizard.person.info.title')}>
              {t('cancellation.wizard.person.info.text')}
            </InfoBox>
          </Form.Item>
          <Form.Item
            name={'sex'}
            label={t('label.sex')}
            rules={[Validators.required(t('validator.salutationRequired'))]}
          >
            <Radio.Group>
              {Object.values(Sex).map((sex) => (
                <Radio key={sex} value={sex}>{t(`sex.${sex}`)}</Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name={'firstName'}
            label={t('label.firstName')}
            rules={[Validators.required(t('validator.firstNameRequired')), Validators.noSpecialCharacters]}
          >
            <Input placeholder={t('label.firstName')} maxLength={50}/>
          </Form.Item>
          <Form.Item
            name={'lastName'}
            label={t('label.name')}
            rules={[Validators.required(t('validator.nameRequired')), Validators.noSpecialCharacters]}
          >
            <Input placeholder={t('label.name')} maxLength={50}/>
          </Form.Item>
          <Form.Item
            name={'address'}
            label={t('label.address')}
            rules={[Validators.required(t('validator.addressRequired')), Validators.noSpecialCharacters]}
          >
            <Input disabled={!isPolicyHolder} placeholder={t('label.address')} maxLength={50}/>
          </Form.Item>
          <Form.Item
            name={'city'}
            label={t('label.postcodeCity')}
            rules={[Validators.required(t('validator.postcodeCityRequired'))]}
          >
            <Select
              disabled={!isPolicyHolder}
              showSearch
              loading={store.cityStore.isLoading}
              options={options}
              placeholder={t('label.postcodeCityPlaceholder')}
            />
          </Form.Item>
          {isPolicyHolder && (
            <>
              <Form.Item
                name={'email'}
                label={t('label.email')}
                rules={[Validators.requiredIf(isPolicyHolder, t('validator.emailRequired')), Validators.email]}
              >
                <Input placeholder={t('label.email')} maxLength={255}/>
              </Form.Item>
              <Form.Item
                name={'phone'}
                label={<OptionalLabel label={t('label.phone')}/>}
                rules={[Validators.phone]}
              >
                <Input placeholder={t('label.phone')} maxLength={50}/>
              </Form.Item>
            </>
          )}
          <Form.Item
            name={'birthDate'}
            label={t('label.birthday')}
            rules={[Validators.required(t('validator.birthdayRequired')), {
              validator: (_, value) => {
                if (isPolicyHolder && value && dayjs().diff(dayjs(value), 'years') < 18) {
                  return Promise.reject(t('cancellation.wizard.person.policyHolderHasToBeAdult'));
                }
                return Promise.resolve();
              },
            }]}
          >
            <DatePicker
              format={Config.dateFormat}
              placeholder={t('label.dateFormat')}
              disabledDate={(d) => d.isAfter(dayjs().endOf('day'))
                || d.isBefore(dayjs().startOf('day').subtract(150, 'years'))}
            />
          </Form.Item>
          <Form.Item
            name={'insuranceNumber'}
            label={t('label.insuranceNumber')}
            rules={[Validators.insuranceNumber, Validators.required(t('validator.insuranceNumberRequired'))]}
          >
            <Input
              placeholder={t('label.insuranceNumberPlaceholder')}
              onInput={formatInsuranceNumber}
            />
          </Form.Item>
          <Form.Item style={{marginTop: '2rem'}}>
            <InfoWithLabel
              label={(
                <Text header={4}>
                  {t(
                    'cancellation.wizard.person.cancellationDate.label',
                    {date: store.cancellationWizardStore.cancellationDate.format(Config.dateFormat)}
                  )}
                </Text>
              )}
              width={1100}
            >
              <CancellationDatesInfo/>
            </InfoWithLabel>
          </Form.Item>

          <StepsNavigationButtons
            backHidden={isPolicyHolder && !store.editMode}
            back={onBack}
            next={form.submit}
            loading={loading}
          />
        </Form>
      </Col>
    </Row>
  );
};

export default observer(PersonForm);
