import {FC} from 'react';
import {Checkbox as AntCheckbox, CheckboxProps as AntCheckboxProps} from 'antd';
import './Checkbox.scss';

interface CheckboxProps extends AntCheckboxProps {
}

const Checkbox: FC<CheckboxProps> = ({children, ...props}) => (
  <AntCheckbox {...props}>{children}</AntCheckbox>
);

export default Checkbox;
