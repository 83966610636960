import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import {Descriptions} from 'antd';
import {useNavigate} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import dayjs from 'dayjs';
import {Person} from '../../../../models/person';
import Button from '../../../../components/shared/Button';
import {useStore} from '../../../../hooks/useStore';
import Space from '../../../../components/shared/Space';
import {ArrowLeftIcon, AvatarIcon, PenIcon, PriceOverviewIcon} from '../../../../components/shared/icons';
import {Config, GUTTER_SIZE} from '../../../../config';
import {Col, Row} from '../../../../components/layout/Grid';
import Icon from '../../../../components/shared/Icon';
import {Box} from '../../../../components/shared/Box';
import useBreakpoint from '../../../../hooks/useBreakpoint';
import {FranchiseChangePaths} from '../../../paths';
import InfoBox from '../../../../components/shared/InfoBox';
import {formatNumber} from '../../../../utils/numbers';
import {BASE_INSURANCE_PREMIUM_REDUCTION_FACTOR} from '../../../../stores/calculator-store';
import BaseInsuranceCard from '../../../../components/cards/BaseInsuranceCardOverview';
import '../../../../components/PersonBlock.scss';

interface PersonBlockFranchiseChangeProps {
  person: Person;
}

const PersonBlockFranchiseChange: FC<PersonBlockFranchiseChangeProps> = ({person}) => {
  const {t} = useTranslation();
  const store = useStore();
  const {isMobile} = useBreakpoint();
  const navigate = useNavigate();
  const name = person.fullName;
  const oldFranchise = person.isChild
    ? formatNumber(person.baseInsurance?.oldFranchiseGroup?.valueChild ?? 0)
    : formatNumber(person.baseInsurance?.oldFranchiseGroup?.valueAdult ?? 0);
  const newFranchise = person.isChild
    ? formatNumber(person.baseInsurance?.franchiseGroup?.valueChild ?? 0)
    : formatNumber(person.baseInsurance?.franchiseGroup?.valueAdult ?? 0);
  const hasReduced = store.franchiseChangeStore.getPremiumReductionBaseInsurance(person);
  const factor = hasReduced ? BASE_INSURANCE_PREMIUM_REDUCTION_FACTOR : 1;
  const oldPremium = person.baseInsurance?.getOldNetPrice(factor) || 0;
  const newPremium = person.baseInsurance?.getNetPrice(factor) || 0;
  const change = newPremium - oldPremium;
  const changeInPercent = formatNumber((change / oldPremium) * 100);

  const handleChangeSelection = () => {
    store.franchiseChangeStore.setCurrent(person);
    store.editMode = true;
    navigate(FranchiseChangePaths.new());
  };

  const editPerson = () => {
    store.franchiseChangeStore.setCurrent(person);
    store.setEditMode(true);
    navigate(FranchiseChangePaths.index());
  };

  return (
    <Space
      direction={'vertical'}
      fullWidth
      className={'person-block'}
      size={GUTTER_SIZE}
    >
      <div>
        <div className={'block-header'}>
          <div className={'person-header'}>
            <Box flex={'0 1 50px'}>
              <Icon component={person.isNewBorn ? PriceOverviewIcon : AvatarIcon} size={50}/>
            </Box>
            <Box flex={'auto'}>
              {person.isNewBorn ? (
                <>{t('personCard.priceOverview')}</>
              ) : (
                <div>
                  <div className={'name'}>
                    {name}
                  </div>
                  <div className={'details'}>
                    {person.birthday ? dayjs(person.birthday).format(Config.dateFormat) : ''}
                    <br/>
                    {store.franchiseChangeStore.city?.label}
                  </div>
                </div>
              )}
            </Box>
            <Box flex={'0 1 50px'} className={'edit'}>
              <Space direction={'vertical'}>
                {!person.isNewBorn && (
                  <Button type={'link'} onClick={editPerson}>
                    <Icon component={PenIcon} size={22}/>
                  </Button>
                )}
              </Space>
            </Box>
          </div>
          <div className={'person-body'}>
            <Descriptions size={'small'} layout={'vertical'} bordered column={isMobile ? 1 : 2}>
              <Descriptions.Item label={`${t('label.sex')} / ${t('label.name')}`}>
                {t(`sex.${person.sex}`)}
                {' '}
                {person.fullName}
              </Descriptions.Item>
              <Descriptions.Item label={t('label.address')}>
                {store.franchiseChangeStore.address}
              </Descriptions.Item>
              <Descriptions.Item label={t('label.insuranceNumber')}>
                {person.insuranceNo || '-'}
              </Descriptions.Item>
            </Descriptions>
          </div>
        </div>
        <InfoBox title={t('stepOverviewFranchiseChange.changeInfo.title')}>
          <ReactMarkdown>
            {t('stepOverviewFranchiseChange.changeInfo.text', {
              year: dayjs().add(1, 'y').year(),
              oldFranchise,
              newFranchise,
              oldPremium: formatNumber(oldPremium),
              newPremium: formatNumber(newPremium),
              change: formatNumber(change),
              changeInPercent,
            })}
          </ReactMarkdown>
        </InfoBox>
        <Row style={{marginTop: 20}} gutter={[GUTTER_SIZE, 0]} className={'person-insurances'}>
          <Col xs={24} lg={12} className={'base-insurance'}>
            <BaseInsuranceCard person={person} fullDetails/>
          </Col>
        </Row>
      </div>
      <Button type={'link'} style={{textDecoration: 'none'}} onClick={handleChangeSelection}>
        <Icon style={{marginRight: 15}} component={ArrowLeftIcon} size={20}/>
        {t('stepOverviewFranchiseChange.changeSelection')}
      </Button>
    </Space>
  );
};

export default observer(PersonBlockFranchiseChange);
