import React, {FC, useState} from 'react';
import {Form, Input, Radio} from 'antd';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import {useNavigate} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import {Col, Row} from '../../../../components/layout/Grid';
import {GUTTER_SIZE} from '../../../../config';
import InfoBox from '../../../../components/shared/InfoBox';
import StepsNavigationButtons from '../../../../components/StepsNavigationButtons';
import {CancellationLeavePaths} from '../../../paths';
import Upload, {UploadChangeParam, UploadFile} from '../../../../components/shared/Upload';
import Text from '../../../../components/shared/Text';
import {Validators} from '../../../../utils/validators';
import Space from '../../../../components/shared/Space';
import Button from '../../../../components/shared/Button';
import {useStore} from '../../../../hooks/useStore';

interface FormValues {
  hasNewDomicile: boolean;
  newDomicile?: string;
  hasTemporaryResidenceAbroad: boolean;
  temporaryResidenceAbroadReason?: string;
  noMoreLegalObligationOfInsurance: boolean;
  legalObligationFile?: UploadFile[];
  crossBorderCommuter: boolean;
  swissEmployer: boolean;
  ahvFile?: UploadFile[];
  hasBenefitsFromSwitzerland: boolean;
  benefitsFromSwitzerland?: string;
  willBeWorkingAbroad: boolean;
  workingAbroad?: string;
  hasBenefitsFromAbroad: boolean;
  benefitsFromAbroad?: string;
  isFamilyJoining: boolean;
  familyJoining?: string;
}

const QuestionsForm: FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const store = useStore();
  const [form] = Form.useForm<FormValues>();
  const [loading, setLoading] = useState(false);

  const onBack = () => {
    navigate(CancellationLeavePaths.index());
  };

  const onNext = () => {
    navigate(CancellationLeavePaths.cancellation());
  };

  const initialValues = {
    hasNewDomicile: store.cancellationLeaveStore.newDomicile !== undefined,
    newDomicile: store.cancellationLeaveStore.newDomicile,
    hasTemporaryResidenceAbroad: store.cancellationLeaveStore.temporaryResidenceAbroadReason !== undefined,
    temporaryResidenceAbroadReason: store.cancellationLeaveStore.temporaryResidenceAbroadReason,
    noMoreLegalObligationOfInsurance: store.cancellationLeaveStore.noMoreLegalObligationOfInsurance,
    legalObligationFile: store.cancellationLeaveStore.legalObligationFileObject,
    crossBorderCommuter: store.cancellationLeaveStore.crossBorderCommuter,
    swissEmployer: store.cancellationLeaveStore.swissEmployer,
    ahvFile: store.cancellationLeaveStore.ahvFileObject,
    hasBenefitsFromSwitzerland: store.cancellationLeaveStore.benefitsFromSwitzerland !== undefined,
    benefitsFromSwitzerland: store.cancellationLeaveStore.benefitsFromSwitzerland,
    willBeWorkingAbroad: store.cancellationLeaveStore.workingAbroad !== undefined,
    workingAbroad: store.cancellationLeaveStore.workingAbroad,
    hasBenefitsFromAbroad: store.cancellationLeaveStore.benefitsFromAbroad !== undefined,
    benefitsFromAbroad: store.cancellationLeaveStore.benefitsFromAbroad,
    isFamilyJoining: store.cancellationLeaveStore.familyJoining !== undefined,
    familyJoining: store.cancellationLeaveStore.familyJoining,
  };

  const handleSubmit = (values: FormValues) => {
    setLoading(true);

    if (values.noMoreLegalObligationOfInsurance && values.legalObligationFile) {
      const readerLegal = new FileReader();
      readerLegal.readAsDataURL(values.legalObligationFile[0].originFileObj as Blob);
      readerLegal.onload = () => {
        store.cancellationLeaveStore.setLegalObligationFile((readerLegal.result as string).split(',').pop() || '');
        store.cancellationLeaveStore.setLegalObligationFileObject(values.legalObligationFile);
      };
    }

    if (values.swissEmployer && values.ahvFile) {
      const readerAhv = new FileReader();
      readerAhv.readAsDataURL(values.ahvFile[0].originFileObj as Blob);
      readerAhv.onload = () => {
        store.cancellationLeaveStore.setAhvFile((readerAhv.result as string).split(',').pop() || '');
        store.cancellationLeaveStore.setAhvFileObject(values.ahvFile);
      };
    }

    store.cancellationLeaveStore.setNewDomicile(values.hasNewDomicile ? values.newDomicile : undefined);
    store.cancellationLeaveStore.setTemporaryResidenceAbroadReason(values.hasTemporaryResidenceAbroad
      ? values.temporaryResidenceAbroadReason : undefined);
    store.cancellationLeaveStore.setNoMoreLegalObligationOfInsurance(values.noMoreLegalObligationOfInsurance);
    store.cancellationLeaveStore.setCrossBorderCommuter(values.crossBorderCommuter);
    store.cancellationLeaveStore.setSwissEmployer(values.swissEmployer);
    store.cancellationLeaveStore.setBenefitsFromSwitzerland(values.hasBenefitsFromSwitzerland
      ? values.benefitsFromSwitzerland : undefined);
    store.cancellationLeaveStore.setWorkingAbroad(values.willBeWorkingAbroad ? values.workingAbroad : undefined);
    store.cancellationLeaveStore.setBenefitsFromAbroad(values.hasBenefitsFromAbroad
      ? values.benefitsFromAbroad : undefined);
    store.cancellationLeaveStore.setFamilyJoining(values.isFamilyJoining ? values.familyJoining : undefined);

    onNext();
  };

  const onValuesChange = (changedValues: Partial<FormValues>) => {
    if (changedValues.hasNewDomicile === false) {
      form.setFieldsValue({newDomicile: undefined});
    } else if (changedValues.hasTemporaryResidenceAbroad === false) {
      form.setFieldsValue({temporaryResidenceAbroadReason: undefined});
    } else if (changedValues.noMoreLegalObligationOfInsurance === false) {
      form.setFieldsValue({legalObligationFile: undefined});
    } else if (changedValues.swissEmployer === false) {
      form.setFieldsValue({ahvFile: undefined});
    } else if (changedValues.hasBenefitsFromSwitzerland === false) {
      form.setFieldsValue({benefitsFromSwitzerland: undefined});
    } else if (changedValues.willBeWorkingAbroad === false) {
      form.setFieldsValue({workingAbroad: undefined});
    } else if (changedValues.hasBenefitsFromAbroad === false) {
      form.setFieldsValue({benefitsFromAbroad: undefined});
    } else if (changedValues.isFamilyJoining === false) {
      form.setFieldsValue({familyJoining: undefined});
    }
  };

  const normFile = (e: UploadChangeParam) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e.fileList;
  };

  return (
    <Row gutter={[GUTTER_SIZE, GUTTER_SIZE]}>
      <Col xs={24} sm={24} md={24} lg={16} xl={16}>
        <Form
          form={form}
          initialValues={initialValues}
          layout={'vertical'}
          colon={false}
          labelAlign={'left'}
          requiredMark={false}
          onFinish={handleSubmit}
          onValuesChange={onValuesChange}
        >
          <Form.Item>
            <InfoBox title={t('cancellation.leave.questions.info.title')}>
              <ReactMarkdown>
                {t('cancellation.leave.questions.info.text')}
              </ReactMarkdown>
            </InfoBox>
          </Form.Item>
          <Form.Item style={{marginBottom: 0}}>
            <Text header={4}>{t('cancellation.leave.questions.residence')}</Text>
          </Form.Item>
          <Form.Item
            name={'hasNewDomicile'}
            label={t('label.hasNewDomicile')}
            rules={[Validators.required(t('validator.hasNewDomicileRequired'))]}
            style={{marginBottom: 0}}
          >
            <Radio.Group>
              <Radio value>{t('label.yes')}</Radio>
              <Radio value={false}>{t('label.no')}</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.hasNewDomicile !== currentValues.hasNewDomicile}
          >
            {(f) => {
              const hasNewDomicile = f.getFieldValue('hasNewDomicile');

              if (!hasNewDomicile) {
                return null;
              }

              return (
                <Space direction={'vertical'} fullWidth>
                  <Text>
                    {t('label.newDomicile')}
                  </Text>
                  <Form.Item
                    name={'newDomicile'}
                    rules={[Validators.required(t('validator.newDomicileRequired'))]}
                  >
                    <Input placeholder={t('label.newDomicilePlaceholder')} maxLength={50}/>
                  </Form.Item>
                </Space>
              );
            }}
          </Form.Item>

          <Form.Item
            name={'hasTemporaryResidenceAbroad'}
            label={t('label.hasTemporaryResidenceAbroad')}
            rules={[Validators.required(t('validator.hasTemporaryResidenceAbroadRequired'))]}
            style={{marginBottom: 0, marginTop: 24}}
          >
            <Radio.Group>
              <Radio value>{t('label.yes')}</Radio>
              <Radio value={false}>{t('label.no')}</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.hasTemporaryResidenceAbroad !== currentValues.hasTemporaryResidenceAbroad}
          >
            {(f) => {
              const hasTemporaryResidenceAbroad = f.getFieldValue('hasTemporaryResidenceAbroad');

              if (!hasTemporaryResidenceAbroad) {
                return null;
              }

              return (
                <Space direction={'vertical'} fullWidth>
                  <Text>
                    {t('label.temporaryResidenceAbroadReason')}
                  </Text>
                  <Form.Item
                    name={'temporaryResidenceAbroadReason'}
                    rules={[Validators.required(t('validator.temporaryResidenceAbroadReasonRequired'))]}
                  >
                    <Input placeholder={t('label.temporaryResidenceAbroadReasonPlaceholder')} maxLength={50}/>
                  </Form.Item>
                </Space>
              );
            }}
          </Form.Item>

          <Form.Item
            name={'noMoreLegalObligationOfInsurance'}
            label={t('label.noMoreLegalObligationOfInsurance')}
            rules={[Validators.required(t('validator.noMoreLegalObligationOfInsuranceRequired'))]}
            style={{marginBottom: 0, marginTop: 24}}
          >
            <Radio.Group>
              <Radio value>{t('label.yes')}</Radio>
              <Radio value={false}>{t('label.no')}</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.noMoreLegalObligationOfInsurance !== currentValues.noMoreLegalObligationOfInsurance}
          >
            {(f) => {
              const noMoreLegalObligationOfInsurance = f.getFieldValue('noMoreLegalObligationOfInsurance');

              if (!noMoreLegalObligationOfInsurance) {
                return null;
              }

              return (
                <Space direction={'vertical'} fullWidth>
                  <Text>
                    {t('label.legalObligationFile')}
                  </Text>
                  <Form.Item
                    name={'legalObligationFile'}
                    getValueFromEvent={normFile}
                    valuePropName={'fileList'}
                    rules={[
                      {
                        validator: (_, arr) => {
                          if (!arr) {
                            return Promise.reject(t('validator.legalObligationFileRequired'));
                          }
                          const [file] = arr;

                          if (!file) {
                            return Promise.reject(t('validator.legalObligationFileRequired'));
                          }

                          if (file.type !== 'application/pdf') {
                            return Promise.reject(t('validator.cancellationFileInvalid'));
                          }

                          const maxFileSize = 20 * 1024 * 1024;
                          if (file.size > maxFileSize) {
                            return Promise.reject(t('validator.cancellationFileTooLarge'));
                          }

                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Upload maxCount={1} beforeUpload={() => false} accept={'.pdf'}>
                      <Space direction={'vertical'} fullWidth size={'large'}>
                        <Button skin={'primary-gray-blue'}>{t('label.upload')}</Button>
                        <Text size={'small'}>{t('cancellation.leave.questions.legalObligationFile.info')}</Text>
                      </Space>
                    </Upload>
                  </Form.Item>
                </Space>
              );
            }}
          </Form.Item>

          <Form.Item style={{marginBottom: 0, marginTop: 24}}>
            <Text header={4}>{t('cancellation.leave.questions.finance')}</Text>
          </Form.Item>

          <Form.Item
            name={'crossBorderCommuter'}
            label={t('label.crossBorderCommuter')}
            rules={[Validators.required(t('validator.crossBorderCommuterRequired'))]}
          >
            <Radio.Group>
              <Radio value>{t('label.yes')}</Radio>
              <Radio value={false}>{t('label.no')}</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name={'swissEmployer'}
            label={t('label.swissEmployer')}
            rules={[Validators.required(t('validator.swissEmployerRequired'))]}
            style={{marginBottom: 0, marginTop: 24}}
          >
            <Radio.Group>
              <Radio value>{t('label.yes')}</Radio>
              <Radio value={false}>{t('label.no')}</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.swissEmployer !== currentValues.swissEmployer}
          >
            {(f) => {
              const swissEmployer = f.getFieldValue('swissEmployer');

              if (!swissEmployer) {
                return null;
              }

              return (
                <Space direction={'vertical'} fullWidth>
                  <Text>
                    {t('label.ahvFile')}
                  </Text>
                  <Form.Item
                    name={'ahvFile'}
                    getValueFromEvent={normFile}
                    valuePropName={'fileList'}
                    rules={[
                      {
                        validator: (_, arr) => {
                          if (!arr) {
                            return Promise.reject(t('validator.ahvFileRequired'));
                          }
                          const [file] = arr;

                          if (!file) {
                            return Promise.reject(t('validator.ahvFileRequired'));
                          }

                          if (file.type !== 'application/pdf') {
                            return Promise.reject(t('validator.cancellationFileInvalid'));
                          }

                          const maxFileSize = 20 * 1024 * 1024;
                          if (file.size > maxFileSize) {
                            return Promise.reject(t('validator.cancellationFileTooLarge'));
                          }

                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Upload maxCount={1} beforeUpload={() => false} accept={'.pdf'}>
                      <Space direction={'vertical'} fullWidth size={'large'}>
                        <Button skin={'primary-gray-blue'}>{t('label.upload')}</Button>
                        <Text size={'small'}>{t('cancellation.leave.questions.ahvFile.info')}</Text>
                      </Space>
                    </Upload>
                  </Form.Item>
                </Space>
              );
            }}
          </Form.Item>

          <Form.Item
            name={'hasBenefitsFromSwitzerland'}
            label={t('label.hasBenefitsFromSwitzerland')}
            rules={[Validators.required(t('validator.hasBenefitsFromSwitzerlandRequired'))]}
            style={{marginBottom: 0, marginTop: 24}}
          >
            <Radio.Group>
              <Radio value>{t('label.yes')}</Radio>
              <Radio value={false}>{t('label.no')}</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.hasBenefitsFromSwitzerland !== currentValues.hasBenefitsFromSwitzerland}
          >
            {(f) => {
              const hasBenefitsFromSwitzerland = f.getFieldValue('hasBenefitsFromSwitzerland');

              if (!hasBenefitsFromSwitzerland) {
                return null;
              }

              return (
                <Space direction={'vertical'} fullWidth>
                  <Text>
                    {t('label.benefitsFromSwitzerland')}
                  </Text>
                  <Form.Item
                    name={'benefitsFromSwitzerland'}
                    rules={[Validators.required(t('validator.benefitsFromSwitzerlandRequired'))]}
                  >
                    <Input placeholder={t('label.benefitsFromSwitzerlandPlaceholder')} maxLength={50}/>
                  </Form.Item>
                </Space>
              );
            }}
          </Form.Item>

          <Form.Item
            name={'willBeWorkingAbroad'}
            label={t('label.willBeWorkingAbroad')}
            rules={[Validators.required(t('validator.willBeWorkingAbroadRequired'))]}
            style={{marginBottom: 0, marginTop: 24}}
          >
            <Radio.Group>
              <Radio value>{t('label.yes')}</Radio>
              <Radio value={false}>{t('label.no')}</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.willBeWorkingAbroad !== currentValues.willBeWorkingAbroad}
          >
            {(f) => {
              const willBeWorkingAbroad = f.getFieldValue('willBeWorkingAbroad');

              if (!willBeWorkingAbroad) {
                return null;
              }

              return (
                <Space direction={'vertical'} fullWidth>
                  <Text>
                    {t('label.workingAbroad')}
                  </Text>
                  <Form.Item
                    name={'workingAbroad'}
                    rules={[Validators.required(t('validator.workingAbroadRequired'))]}
                  >
                    <Input placeholder={t('label.workingAbroadPlaceholder')} maxLength={50}/>
                  </Form.Item>
                </Space>
              );
            }}
          </Form.Item>

          <Form.Item
            name={'hasBenefitsFromAbroad'}
            label={t('label.hasBenefitsFromAbroad')}
            rules={[Validators.required(t('validator.hasBenefitsFromAbroadRequired'))]}
            style={{marginBottom: 0, marginTop: 24}}
          >
            <Radio.Group>
              <Radio value>{t('label.yes')}</Radio>
              <Radio value={false}>{t('label.no')}</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.hasBenefitsFromAbroad !== currentValues.hasBenefitsFromAbroad}
          >
            {(f) => {
              const hasBenefitsFromAbroad = f.getFieldValue('hasBenefitsFromAbroad');

              if (!hasBenefitsFromAbroad) {
                return null;
              }

              return (
                <Space direction={'vertical'} fullWidth>
                  <Text>
                    {t('label.benefitsFromAbroad')}
                  </Text>
                  <Form.Item
                    name={'benefitsFromAbroad'}
                    rules={[Validators.required(t('validator.benefitsFromAbroadRequired'))]}
                  >
                    <Input placeholder={t('label.benefitsFromAbroadPlaceholder')} maxLength={50}/>
                  </Form.Item>
                </Space>
              );
            }}
          </Form.Item>

          <Form.Item style={{marginBottom: 0, marginTop: 24}}>
            <Text header={4}>{t('cancellation.leave.questions.family')}</Text>
          </Form.Item>

          <Form.Item
            name={'isFamilyJoining'}
            label={t('label.isFamilyJoining')}
            rules={[Validators.required(t('validator.isFamilyJoiningRequired'))]}
            style={{marginBottom: 0}}
          >
            <Radio.Group>
              <Radio value>{t('label.yes')}</Radio>
              <Radio value={false}>{t('label.no')}</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.isFamilyJoining !== currentValues.isFamilyJoining}
          >
            {(f) => {
              const isFamilyJoining = f.getFieldValue('isFamilyJoining');

              if (!isFamilyJoining) {
                return null;
              }

              return (
                <Space direction={'vertical'} fullWidth>
                  <Text>
                    {t('label.familyJoining')}
                  </Text>
                  <Form.Item
                    name={'familyJoining'}
                    rules={[Validators.required(t('validator.familyJoiningRequired'))]}
                  >
                    <Input placeholder={t('label.familyJoiningPlaceholder')} maxLength={50}/>
                  </Form.Item>
                </Space>
              );
            }}
          </Form.Item>

          <StepsNavigationButtons
            back={onBack}
            next={form.submit}
            loading={loading}
          />
        </Form>
      </Col>
    </Row>
  );
};

export default observer(QuestionsForm);
