import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import dayjs from 'dayjs';
import ReactMarkdown from 'react-markdown';
import Card from '../../../../components/shared/Card';
import Price from '../../../../components/shared/Price';
import Divider from '../../../../components/shared/Divider';
import {useStore} from '../../../../hooks/useStore';
import Text from '../../../../components/shared/Text';
import {BaseInsuranceIcon} from '../../../../components/shared/icons';
import Icon from '../../../../components/shared/Icon';
import {Col, Row} from '../../../../components/layout/Grid';
import {TravelInsuranceRateType} from '../../../../models/travel-insurance-rate';
import Info from '../../../../components/shared/Info';
import ExternalLink from '../../../../components/shared/ExternalLink';
import {Config} from '../../../../config';
import {TravelInsuranceTypes} from '../../../../models/travel-insurance-type';

const TravelInsuranceOverviewCard: FC = () => {
  const {t} = useTranslation();
  const store = useStore();
  const rate = store.travelInsuranceStore.travelInsuranceRate;

  if (!rate?.travelInsuranceType) {
    return null;
  }

  return (
    <Card>
      <Row>
        <Col flex={'auto'}>
          <Text header={2}>
            {rate.travelInsuranceType.label}
          </Text>
          <Text size={'extra-small'} color={'turquoise'} strong opacity={0.6}>
            {t('travelInsurance.one')}
          </Text>
        </Col>
        <Col flex={'50px'} className={'product-icon'}>
          <Icon component={BaseInsuranceIcon} size={40}/>
        </Col>
      </Row>
      <br/>
      <Text size={'small'}>
        {t(`travelInsuranceRate.type.${store.travelInsuranceStore.type ?? TravelInsuranceRateType.FAMILY}`)}
      </Text>
      <Divider type={'vertical'} orientation={'center'} primary style={{margin: '0.25rem'}}/>
      <Text size={'small'}>
        <Text>CHF </Text>
        <Price value={store.travelInsuranceStore.travelInsuranceRate?.insuranceAmount ?? 0}/>
      </Text>
      <Divider type={'vertical'} orientation={'center'} primary style={{margin: '0.25rem'}}/>
      <Text size={'small'}>
        {store.travelInsuranceStore.travelInsuranceDuration?.days}
        {' '}
        {t('label.days')}
      </Text>
      <Divider type={'vertical'} orientation={'center'} primary style={{margin: '0.25rem'}}/>
      <Text size={'small'}>
        {t('person.insuranceStart')}
      </Text>
      &nbsp;
      <Text size={'small'}>
        {dayjs(store.travelInsuranceStore.travelInsuranceStartDate).format('DD.MM.YYYY')}
      </Text>
      <Divider primary/>
      <Row className={'price-row'} align={'bottom'}>
        <Col flex={'auto'}>
          <Text size={'small'} opacity={0.4}>
            {t('travelInsurance.priceText', {days: rate.termOfInsurance?.days})}
          </Text>
          <br/>
          <Text size={'small'} opacity={0.4}>CHF </Text>
          <Text strong color={'gray-blue'}><Price value={rate.value}/></Text>
        </Col>
        <Col>
          <Info>
            <ReactMarkdown>
              {t('travelInsuranceType.general.info')}
            </ReactMarkdown>
            <ReactMarkdown>
              {t(`${TravelInsuranceTypes[rate.travelInsuranceType.identifier].translationPrefix}.info`)}
            </ReactMarkdown>
            <br/>
            <ExternalLink href={Config.travelInsuranceInformationUrl}>{t('label.moreInformation')}</ExternalLink>
          </Info>
        </Col>
      </Row>
    </Card>
  );
};

export default observer(TravelInsuranceOverviewCard);
