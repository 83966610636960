const defaultSortOptions = {ignoreCase: true, order: 'asc'};

export const sortAlphabetically = (a: string, b: string, options = defaultSortOptions) => {
  if (!b && !a) return 0;
  if (a && !b) return 1;
  if (b && !a) return -1;

  if (options.ignoreCase) a = a.toLowerCase();
  if (options.ignoreCase) b = b.toLowerCase();

  const sortOrder = options.order === 'asc' ? 1 : -1;

  if (a < b) return -1 * sortOrder;
  if (a > b) return sortOrder;
  return 0;
};

export const sortNumerically = (a: number, b: number) => {
  let parsedA = a;
  let parsedB = b;

  if (!Number.isFinite(parsedA) && !Number.isFinite(parsedB)) {
    parsedA = 0;
    parsedB = 0;
  }

  if (!Number.isFinite(parsedA) && Number.isFinite(parsedB)) {
    parsedA = parsedB + 1;
  }

  if (Number.isFinite(parsedA) && !Number.isFinite(parsedB)) {
    parsedB = parsedA + 1;
  }

  return parsedA - parsedB;
};
