/* eslint-disable import/no-cycle */
import {action, computed, makeObservable, observable} from 'mobx';
import {CasamedIcon, SmartmedIcon, StandardIcon} from '../components/shared/icons';
import {BaseInsuranceRate} from './base-insurance-rate';
import {FranchiseGroup} from './franchise-group';
import {IApiBaseInsurance} from './document-request';

export enum BaseInsuranceType {
  STANDARD = 'standard',
  CASAMED = 'casamed',
  SMARTMED = 'smartmed'
}

export const BaseInsuranceTypes = {
  [BaseInsuranceType.STANDARD]: {
    icon: StandardIcon,
    // t('baseInsurance.standard.title')
    // t('baseInsurance.standard.description')
    // t('baseInsurance.standard.info')
    translationPrefix: 'baseInsurance.standard',
    productLink: 'https://www.aquilana.ch/okp',
  },
  [BaseInsuranceType.CASAMED]: {
    icon: CasamedIcon,
    // t('baseInsurance.casamed.title')
    // t('baseInsurance.casamed.description')
    // t('baseInsurance.casamed.info')
    translationPrefix: 'baseInsurance.casamed',
    productLink: 'https://www.aquilana.ch/casamed',
  },
  [BaseInsuranceType.SMARTMED]: {
    icon: SmartmedIcon,
    // t('baseInsurance.smartmed.title')
    // t('baseInsurance.smartmed.description')
    // t('baseInsurance.smartmed.info')
    translationPrefix: 'baseInsurance.smartmed',
    productLink: 'https://www.aquilana.ch/smartmed',
  },
};

export interface IBaseInsurance {
  withAccident: boolean;
  type: BaseInsuranceType;
  franchiseGroup?: FranchiseGroup;
  oldFranchiseGroup?: FranchiseGroup;
  rate?: BaseInsuranceRate;
  oldRate?: BaseInsuranceRate;
}

export class BaseInsurance implements IBaseInsurance {
  withAccident = false;
  type = BaseInsuranceType.STANDARD;
  franchiseGroup?: FranchiseGroup = undefined;
  oldFranchiseGroup?: FranchiseGroup = undefined;
  rate?: BaseInsuranceRate = undefined;
  oldRate?: BaseInsuranceRate = undefined;

  constructor() {
    makeObservable(this, {
      withAccident: observable,
      type: observable,
      franchiseGroup: observable,
      oldFranchiseGroup: observable,
      rate: observable,
      oldRate: observable,

      grossPrice: computed,
      grossOldPrice: computed,
      setRate: action,
      setFranchiseGroup: action,
      setOldFranchiseGroup: action,
    });
  }

  get grossPrice(): number {
    if (!this.rate) {
      return 0;
    }
    return this.withAccident ? this.rate.valueWithAccident : this.rate.valueWithoutAccident;
  }

  get grossOldPrice(): number {
    if (!this.oldRate) {
      return 0;
    }
    return this.withAccident ? this.oldRate.valueWithAccident : this.oldRate.valueWithoutAccident;
  }

  getNetPrice(factor = 1): number {
    return (this.grossPrice * factor) - (this.rate?.premiumVersion?.environmentalTax || 0);
  }

  getOldNetPrice(factor = 1): number {
    return (this.grossOldPrice * factor) - (this.oldRate?.premiumVersion?.environmentalTax || 0);
  }

  static getTypeAsNumber(type: BaseInsuranceType): number {
    switch (type) {
      case BaseInsuranceType.CASAMED:
        return 2;
      case BaseInsuranceType.SMARTMED:
        return 3;
      default:
        return 1;
    }
  }

  static getNumberAsType(n: number): BaseInsuranceType {
    switch (n) {
      case 2:
        return BaseInsuranceType.CASAMED;
      case 3:
        return BaseInsuranceType.SMARTMED;
      default:
        return BaseInsuranceType.STANDARD;
    }
  }

  setRate(rate?: BaseInsuranceRate) {
    this.rate = rate;
  }

  setOldRate(rate?: BaseInsuranceRate) {
    this.oldRate = rate;
  }

  setFranchiseGroup(franchiseGroup?: FranchiseGroup) {
    this.franchiseGroup = franchiseGroup;
  }

  setOldFranchiseGroup(franchiseGroup?: FranchiseGroup) {
    this.oldFranchiseGroup = franchiseGroup;
  }

  static fromJSON(data?: Partial<IApiBaseInsurance>, rate?: BaseInsuranceRate, franchiseGroup?: FranchiseGroup) {
    const baseInsurance = new BaseInsurance();
    if (data?.accidentCoverage) {
      baseInsurance.withAccident = data.accidentCoverage;
    }
    if (rate?.baseInsuranceTypeId) {
      baseInsurance.rate = rate;
      baseInsurance.type = BaseInsurance.getNumberAsType(rate.baseInsuranceTypeId);
    }
    if (franchiseGroup) {
      baseInsurance.franchiseGroup = FranchiseGroup.fromJSON(franchiseGroup);
    }

    return baseInsurance;
  }
}
