import {FC} from 'react';
import {Radio} from 'antd';
import {useTranslation} from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import Info from '../shared/Info';
import ExternalLink from '../shared/ExternalLink';
import Checklist from '../shared/Checklist';
import InfoBox from '../shared/InfoBox';
import {Config} from '../../config';
import {BaseInsuranceType, BaseInsuranceTypes} from '../../models/base-insurance';
import InsuranceCard from './InsuranceCard';

interface BaseInsuranceCardProps {
  type: BaseInsuranceType;
  selected: boolean;
  onSelectionChange: () => void;
  price: number;
  reduced?: boolean;
}

const BaseInsuranceCardProduct: FC<BaseInsuranceCardProps> = ({
  type,
  selected,
  onSelectionChange,
  price,
  reduced = false,
}) => {
  const {t} = useTranslation();
  const baseInsurance = BaseInsuranceTypes[type];

  return (
    <InsuranceCard
      icon={baseInsurance.icon}
      title={t(`${baseInsurance.translationPrefix}.title`)}
      subTitle={t('baseInsurance.one')}
      onSelectionChange={onSelectionChange}
      price={price}
      info={(
        <Info>
          <ReactMarkdown>
            {t(`${baseInsurance.translationPrefix}.info`)}
          </ReactMarkdown>
          <br/>
          <ExternalLink href={baseInsurance.productLink}>{t('label.moreInformation')}</ExternalLink>
        </Info>
      )}
      selectInput={<Radio checked={selected} onChange={onSelectionChange}/>}
    >
      {!reduced && <Checklist text={t(`${baseInsurance.translationPrefix}.description`)}/>}
      {type === BaseInsuranceType.CASAMED && !reduced && (
        <InfoBox title={t('label.notice')}>
          <ReactMarkdown linkTarget={'_blank'}>
            {t('baseInsurance.casamed.doctorInfo', {url: Config.doctorListUrl})}
          </ReactMarkdown>
        </InfoBox>
      )}
    </InsuranceCard>
  );
};

export default BaseInsuranceCardProduct;
