/* eslint-disable import/no-cycle */
import {action, makeObservable, observable} from 'mobx';
import {Dayjs} from 'dayjs';
import {AquilanaFunctionStore} from './aquilana-function-store';
import {RootStore} from './root-store';
import {AdditionalFileIdentifier, ICancellationUploadRequest} from '../models/document-request';
import {Sex} from '../models/person';
import {Config} from '../config';

export class CancellationDeathStore extends AquilanaFunctionStore {
  personSex?: Sex = undefined;
  personFirstName?: string = undefined;
  personLastName?: string = undefined;
  personBirthday?: Dayjs = undefined;
  personDeathDate?: Dayjs = undefined;
  personInsuranceNumber?: string = undefined;
  personDeathCertificate?: string = undefined;
  personHeritageCertificate?: string = undefined;
  sex?: Sex = undefined;
  firstName?: string = undefined;
  lastName?: string = undefined;
  birthDate?: Dayjs = undefined;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      sex: observable,
      firstName: observable,
      lastName: observable,
      personBirthday: observable,
      personDeathDate: observable,
      personDeathCertificate: observable,
      personHeritageCertificate: observable,
      personSex: observable,
      personFirstName: observable,
      personLastName: observable,
      personInsuranceNumber: observable,

      setSex: action,
      setFirstName: action,
      setLastName: action,
      setPersonSex: action,
      setPersonFirstName: action,
      setPersonLastName: action,
      setPersonBirthday: action,
      setPersonInsuranceNumber: action,
      setPersonDeathDate: action,
      setPersonDeathCertificate: action,
      setPersonHeritageCertificate: action,
    });
  }

  setSex(sex: Sex) {
    this.sex = sex;
  }

  setFirstName(firstName: string) {
    this.firstName = firstName;
  }

  setLastName(lastName: string) {
    this.lastName = lastName;
  }

  setBirthDate(birthDate: Dayjs) {
    this.birthDate = birthDate;
  }

  setPersonDeathDate(deathDate: Dayjs) {
    this.personDeathDate = deathDate;
  }

  setPersonDeathCertificate(deathCertificate: string) {
    this.personDeathCertificate = deathCertificate;
  }

  setPersonHeritageCertificate(heritageCertificate: string) {
    this.personHeritageCertificate = heritageCertificate;
  }

  setPersonBirthday(birthday: Dayjs) {
    this.personBirthday = birthday;
  }

  setPersonSex(sex: Sex) {
    this.personSex = sex;
  }

  setPersonFirstName(firstName: string) {
    this.personFirstName = firstName;
  }

  setPersonLastName(lastName: string) {
    this.personLastName = lastName;
  }

  setPersonInsuranceNumber(insuranceNumber?: string) {
    this.personInsuranceNumber = insuranceNumber;
  }

  reset() {
    this.sex = undefined;
    this.firstName = undefined;
    this.lastName = undefined;
    this.birthDate = undefined;
    this.address = undefined;
    this.city = undefined;
    this.email = undefined;
    this.phone = undefined;
    this.personSex = undefined;
    this.personFirstName = undefined;
    this.personLastName = undefined;
    this.personDeathDate = undefined;
    this.personBirthday = undefined;
    this.personDeathCertificate = undefined;
    this.personHeritageCertificate = undefined;
    this.personInsuranceNumber = undefined;
  }

  getIssueRequest(): ICancellationUploadRequest {
    return {
      issueType: Config.issueTypeCancellationDeath,
      sex: this.sex || Sex.MALE,
      firstName: this.firstName ?? '',
      lastName: this.lastName ?? '',
      address: this.address ?? '',
      postcode: this.city?.postcode ?? 0,
      city: this.city?.city ?? '',
      phone: this.phone ?? '',
      email: this.email ?? '',
      insuranceNo: '',
      file: this.personDeathCertificate ?? '',
      additionalFiles: this.personHeritageCertificate ? [
        {
          type: AdditionalFileIdentifier.HERITAGE_FILE,
          file: this.personHeritageCertificate,
        },
      ] : undefined,
      formData: {
        deathDate: this.personDeathDate?.format(Config.apiDateFormat) ?? '',
        birthday: this.personBirthday?.format(Config.apiDateFormat) ?? '',
        sex: this.personSex || Sex.MALE,
        firstName: this.personFirstName ?? '',
        lastName: this.personLastName ?? '',
        insuranceNumber: this.personInsuranceNumber ?? '',
      },
    };
  }
}
