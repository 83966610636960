import {useTranslation} from 'react-i18next';
import {FC, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {Navigate, useNavigate} from 'react-router-dom';
import dayjs from 'dayjs';
import Container from '../../../components/layout/Container';
import {useStore} from '../../../hooks/useStore';
import Text from '../../../components/shared/Text';
import {Col, Row} from '../../../components/layout/Grid';
import {BaseInsuranceType} from '../../../models/base-insurance';
import {Config, GUTTER_SIZE} from '../../../config';
import {CalculatorPaths} from '../../paths';
import NewBornForm from './_components/NewBornForm';
import PersonDetailsForm from './_components/PersonDetailsForm';

const Details: FC = () => {
  const {t} = useTranslation();
  const store = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!store.previousInsurerStore.models.length && !store.previousInsurerStore.isLoading) {
      store.previousInsurerStore.loadAll();
    }
  }, []);

  if (!store.calculatorStore.current.baseInsurance && !store.calculatorStore.current.hasAddonInsurances) {
    return (
      <Navigate replace to={CalculatorPaths.index()}/>
    );
  }

  const afterSubmit = () => {
    if (store.editMode) {
      store.setEditMode(false);
      navigate(CalculatorPaths.finish());
    } else {
      if (store.calculatorStore.current.baseInsurance?.type === BaseInsuranceType.CASAMED) {
        navigate(CalculatorPaths.doctor());
        return;
      }

      if (store.calculatorStore.hasNext) {
        store.calculatorStore.setNextAsCurrent();
        window.scrollTo(0, 0);
      } else {
        navigate(CalculatorPaths.contact());
      }
    }
  };

  return (
    <>
      <Container background={'white'}>
        <Text header={1}>
          {
            store.calculatorStore.current.isNewBorn
              ? t('stepDetails.contactDataPolicyholder.title')
              : t('stepDetails.personData', {number: store.calculatorStore.currentIndex + 1})
          }
        </Text>
        {store.calculatorStore.current.isNewBorn ? (
          <Text>{t('stepDetails.contactDataPolicyholder.text')}</Text>
        ) : (
          <Text>
            {
              store.calculatorStore.current.birthday
                ? dayjs(store.calculatorStore.current.birthday).format(Config.dateFormat)
                : ''
            }
          </Text>
        )}
      </Container>
      <Container background={'light'} flex={1}>
        <Row gutter={[GUTTER_SIZE, GUTTER_SIZE]}>
          <Col xs={24} sm={24} md={24} lg={16} xl={16}>
            {store.calculatorStore.current.isNewBorn ? (
              <NewBornForm/>
            ) : (
              <PersonDetailsForm afterSubmit={afterSubmit}/>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default observer(Details);
