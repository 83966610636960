import {useTranslation} from 'react-i18next';
import {FC, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import Container from '../../../components/layout/Container';
import Text from '../../../components/shared/Text';
import TravelInsuranceFamilyForm from './_components/TravelInsuranceFamilyForm';
import {useStore} from '../../../hooks/useStore';
import {Col, Row} from '../../../components/layout/Grid';
import {GUTTER_SIZE} from '../../../config';
import {TravelInsurancePersonType} from '../../../models/person';

const TravelInsuranceFamily: FC = () => {
  const store = useStore();
  const person = store.travelInsuranceStore.current;
  const { t } = useTranslation();

  useEffect(() => {
    if (!store.previousInsurerStore.models.length && !store.previousInsurerStore.isLoading) {
      store.previousInsurerStore.loadAll();
    }
  }, []);

  return (
    <>
      <Container background={'white'}>
        <Text header={1}>
          {
            person.travelInsurancePersonType === TravelInsurancePersonType.CHILD
              ? t(
                'travelInsurance.family.children.title',
                {index: store.travelInsuranceStore.children.indexOf(person) + 1}
              )
              : t('travelInsurance.family.partner.title')
          }
        </Text>
        <Text size={'intro'}>{t('travelInsurance.family.header')}</Text>
      </Container>
      <Container background={'light'} flex={1}>
        <Row gutter={[GUTTER_SIZE, GUTTER_SIZE]}>
          <Col xs={24} sm={24} md={24} lg={16} xl={16}>
            <TravelInsuranceFamilyForm/>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default observer(TravelInsuranceFamily);
