/* eslint-disable import/no-cycle */

import {action, makeObservable, observable} from 'mobx';
import dayjs, {Dayjs} from 'dayjs';
import {RootStore} from './root-store';
import {ContactLanguage, Person, Sex, TravelInsurancePersonType} from '../models/person';
import {IApiPerson, ITravelInsuranceIssue, ITravelInsuranceLocalStorage, PersonType} from '../models/document-request';
import {TravelInsuranceRate, TravelInsuranceRateType} from '../models/travel-insurance-rate';
import {TermOfInsurance} from '../models/term-of-insurance';
import {Config} from '../config';
import {AquilanaFunctionStore} from './aquilana-function-store';

const STORAGE_KEY = 'travel-insurance';

export class TravelInsuranceStore extends AquilanaFunctionStore {
  travelInsuranceRate?: TravelInsuranceRate = undefined;
  travelInsuranceStartDate?: Dayjs = undefined;
  type?: TravelInsuranceRateType = undefined;
  travelInsuranceDuration?: TermOfInsurance = undefined;

  constructor(rootStore: RootStore) {
    super(rootStore);

    this.policyHolder.setTravelInsurancePersonType(TravelInsurancePersonType.POLICY_HOLDER);

    makeObservable(this, {
      travelInsuranceRate: observable,
      travelInsuranceStartDate: observable,
      type: observable,
      travelInsuranceDuration: observable,

      setTravelInsuranceRate: action,
      setTravelInsuranceStartDate: action,
      setType: action,
      setTravelInsuranceDuration: action,
      removePartner: action,
      removeChildren: action,
      loadFromSession: action,
      saveToSession: action,
      convertPersonToIPersonRequest: action,
    });
  }

  setTravelInsuranceRate(travelInsuranceRate: TravelInsuranceRate) {
    this.travelInsuranceRate = travelInsuranceRate;
  }

  setTravelInsuranceStartDate(travelInsuranceStartDate: Dayjs) {
    this.travelInsuranceStartDate = travelInsuranceStartDate;
  }

  setType(travelInsuranceType: TravelInsuranceRateType) {
    this.type = travelInsuranceType;
  }

  setTravelInsuranceDuration(travelInsuranceDuration: TermOfInsurance) {
    this.travelInsuranceDuration = travelInsuranceDuration;
  }

  removePartner() {
    const {partner} = this;
    if (partner) {
      this.remove(partner);
    }
  }

  removeChildren() {
    this.children.forEach((child) => this.remove(child));
  }

  saveToSession() {
    const data: ITravelInsuranceLocalStorage = {
      start: this.travelInsuranceStartDate?.toISOString(),
      travelInsuranceRateId: this.travelInsuranceRate?.id,
      type: this.type,
      termOfInsuranceId: this.travelInsuranceDuration?.id,
    };
    localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
  }

  loadFromSession(): boolean {
    const storedData = localStorage.getItem(STORAGE_KEY);

    if (storedData) {
      const data: ITravelInsuranceLocalStorage = JSON.parse(storedData);

      if (data.start) {
        this.setTravelInsuranceStartDate(dayjs(data.start));
      }

      if (data.travelInsuranceRateId) {
        const rate = this.rootStore.travelInsuranceRateStore.getById(data.travelInsuranceRateId);
        if (!rate) {
          return false;
        }
        this.setTravelInsuranceRate(rate);
      }

      if (data.type) {
        this.setType(data.type);
      }

      if (data.termOfInsuranceId) {
        const term = this.rootStore.termOfInsuranceStore.getById(data.termOfInsuranceId);
        if (!term) {
          return false;
        }
        this.setTravelInsuranceDuration(term);
      }

      return true;
    }

    return false;
  }

  reset(soft = false): void {
    this.models = [soft ? this.policyHolder : Person.create({type: PersonType.POLICY_HOLDER})];
    this.currentIndex = 0;
    this.policyHolder.setTravelInsurancePersonType(TravelInsurancePersonType.POLICY_HOLDER);
    if (!soft) {
      this.city = undefined;
      this.address = undefined;
      this.email = undefined;
      this.phone = undefined;
    }
    this.travelInsuranceRate = undefined;
    this.travelInsuranceStartDate = undefined;
    this.type = undefined;
    this.travelInsuranceDuration = undefined;
    localStorage.removeItem(STORAGE_KEY);
  }

  convertPersonToIPersonRequest(person: Person): IApiPerson {
    return {
      firstName: person.firstName || '',
      lastName: person.lastName || '',
      address1: this.address || '',
      address2: '',
      city: this.city ? `/api/cities/${this.city.id}` : '',
      email: this.email || '',
      phone: this.phone || '',
      mobile: '',
      country: 'CH',
      language: ContactLanguage.DE,
      birthday: person.birthday ? dayjs(person.birthday).format(Config.apiDateFormat) : '',
      type: person.type,
      dateOfArrival: null,
      childPremiumReduction: false,
      moveInFromAbroad: false,
      sex: person.sex || Sex.MALE,
      insurer: person.previousInsurer ? `/api/insurers/${person.previousInsurer?.id}` : Config.aquilanaInsurer,
    };
  }

  getIssueRequest(): ITravelInsuranceIssue {
    return {
      issueType: Config.issueTypeTravelInsurance,
      sex: this.policyHolder?.sex || null,
      firstName: this.policyHolder?.firstName || '',
      lastName: this.policyHolder?.lastName || '',
      email: this.email || '',
      travelInsurances: [{
        start: dayjs(this.travelInsuranceStartDate).format(Config.apiDateFormat),
        travelInsuranceRate: `/api/travel-insurance-rates/${this.travelInsuranceRate?.id}`,
        people: this.models.map((person) => this.convertPersonToIPersonRequest(person)),
      }],
    };
  }

  get children(): Person[] {
    if (this.type !== TravelInsuranceRateType.FAMILY) {
      return [];
    }
    return this.models.filter((p) => p.travelInsurancePersonType === TravelInsurancePersonType.CHILD);
  }

  get partner(): Person | undefined {
    if (this.type !== TravelInsuranceRateType.FAMILY) {
      return undefined;
    }
    return this.models.find((p) => p.travelInsurancePersonType === TravelInsurancePersonType.PARTNER);
  }
}
