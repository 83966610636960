/* eslint-disable import/no-cycle */
import {Api} from '../services/api';
import {RootStore} from './root-store';
import {BaseStore} from './base-store';
import {FranchiseGroup} from '../models/franchise-group';

export class FranchiseGroupStore extends BaseStore<FranchiseGroup, number> {
  constructor(rootStore: RootStore) {
    super(rootStore, 'id', Api.getFranchiseGroups);

    this.loadAll();
  }
}
