import React, {FC} from 'react';
import {Form} from 'antd';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {PaymentMethod, PaymentRhythm} from '../../../../models/person';
import {useStore} from '../../../../hooks/useStore';
import Select from '../../../../components/shared/Select';
import {GUTTER_SIZE} from '../../../../config';
import {Col, Row} from '../../../../components/layout/Grid';
import StepsNavigationButtons from '../../../../components/StepsNavigationButtons';
import {CancellationWizardPaths} from '../../../paths';
import {Validators} from '../../../../utils/validators';

interface FormValues {
  paymentMethod: PaymentMethod;
  paymentRhythm: PaymentRhythm;
}

const VerificationForm: FC = () => {
  const {t} = useTranslation();
  const store = useStore();
  const [form] = Form.useForm<FormValues>();
  const navigate = useNavigate();

  const onBack = () => {
    navigate(CancellationWizardPaths.overview());
  };

  const handleSubmit = (values: FormValues) => {
    store.cancellationWizardStore.setPaymentMethod(values.paymentMethod);
    store.cancellationWizardStore.setPaymentRhythm(values.paymentRhythm);

    navigate(CancellationWizardPaths.optIn());
  };

  const initialValues = {
    paymentMethod: store.cancellationWizardStore.paymentMethod,
    paymentRhythm: store.cancellationWizardStore.paymentRhythm,
  };

  return (
    <>
      <Row gutter={[GUTTER_SIZE, GUTTER_SIZE]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form<FormValues>
            form={form}
            initialValues={initialValues}
            layout={'vertical'}
            colon={false}
            labelAlign={'left'}
            requiredMark={false}
            onFinish={handleSubmit}
          >
            <Form.Item
              name={'paymentMethod'}
              label={t('cancellation.wizard.verification.selectPaymentMethod.label')}
              rules={[Validators.required(t('validator.paymentMethodRequired'))]}
            >
              <Select
                placeholder={t('cancellation.wizard.verification.selectPaymentMethod.placeholder')}
                options={Object.values(PaymentMethod).map((value) => ({value, label: t(`paymentMethod.${value}`)}))}
              />
            </Form.Item>
            <Form.Item
              name={'paymentRhythm'}
              label={t('cancellation.wizard.verification.selectPaymentRhythm.label')}
              rules={[Validators.required(t('validator.paymentRhythmRequired'))]}
            >
              <Select
                placeholder={t('cancellation.wizard.verification.selectPaymentRhythm.placeholder')}
                options={Object.values(PaymentRhythm).map((value) => ({value, label: t(`paymentRhythm.${value}`)}))}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <StepsNavigationButtons back={onBack} next={form.submit}/>
    </>
  );
};

export default observer(VerificationForm);
