/* eslint-disable import/no-cycle */
import {action, makeObservable, observable} from 'mobx';
import {Dayjs} from 'dayjs';
import {AquilanaFunctionStore} from './aquilana-function-store';
import {RootStore} from './root-store';
import {AdditionalFileIdentifier, ICancellationUploadRequest, IIssueAdditionalFile} from '../models/document-request';
import {Sex} from '../models/person';
import {City} from '../models/city';
import {Config} from '../config';
import {UploadFile} from '../components/shared/Upload';

export class CancellationLeaveStore extends AquilanaFunctionStore {
  sex?: Sex = undefined;
  firstName?: string = undefined;
  lastName?: string = undefined;
  birthDate: Dayjs | null = null;
  insuranceNumber?: string = undefined;
  dateOfLeaving?: Dayjs = undefined;
  documentOfLeaving?: string = undefined;
  documentOfLeavingObject?: UploadFile[] = undefined;
  newDomicile?: string = undefined;
  temporaryResidenceAbroadReason?: string = undefined;
  noMoreLegalObligationOfInsurance?: boolean = false;
  legalObligationFile?: string = undefined;
  legalObligationFileObject?: UploadFile[] = undefined;
  crossBorderCommuter?: boolean = false;
  swissEmployer?: boolean = false;
  ahvFile?: string = undefined;
  ahvFileObject?: UploadFile[] = undefined;
  benefitsFromSwitzerland?: string = undefined;
  workingAbroad?: string = undefined;
  benefitsFromAbroad?: string = undefined;
  familyJoining?: string = undefined;

  senderDifferentFromPolicyHolder?: boolean = false;

  personSex?: Sex = undefined;
  personFirstName?: string = undefined;
  personLastName?: string = undefined;
  personAddress?: string = undefined;
  personCity?: City = undefined;
  personEmail?: string = undefined;
  personPhone?: string = undefined;
  personBirthday?: Dayjs = undefined;
  personInsuranceNumber?: string = undefined;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      sex: observable,
      firstName: observable,
      lastName: observable,
      birthDate: observable,
      insuranceNumber: observable,
      dateOfLeaving: observable,
      documentOfLeaving: observable,
      documentOfLeavingObject: observable,
      newDomicile: observable,
      temporaryResidenceAbroadReason: observable,
      noMoreLegalObligationOfInsurance: observable,
      legalObligationFile: observable,
      legalObligationFileObject: observable,
      crossBorderCommuter: observable,
      swissEmployer: observable,
      ahvFile: observable,
      ahvFileObject: observable,
      benefitsFromSwitzerland: observable,
      workingAbroad: observable,
      benefitsFromAbroad: observable,
      familyJoining: observable,

      senderDifferentFromPolicyHolder: observable,

      personSex: observable,
      personFirstName: observable,
      personLastName: observable,
      personAddress: observable,
      personCity: observable,
      personEmail: observable,
      personPhone: observable,
      personBirthday: observable,
      personInsuranceNumber: observable,

      setSex: action,
      setFirstName: action,
      setLastName: action,
      setBirthDate: action,
      setInsuranceNumber: action,
      setDateOfLeaving: action,
      setDocumentOfLeaving: action,
      setDocumentOfLeavingObject: action,
      setNewDomicile: action,
      setTemporaryResidenceAbroadReason: action,
      setNoMoreLegalObligationOfInsurance: action,
      setLegalObligationFile: action,
      setLegalObligationFileObject: action,
      setCrossBorderCommuter: action,
      setSwissEmployer: action,
      setAhvFile: action,
      setAhvFileObject: action,
      setBenefitsFromSwitzerland: action,
      setWorkingAbroad: action,
      setBenefitsFromAbroad: action,
      setFamilyJoining: action,

      setSenderDifferentFromPolicyHolder: action,

      setPersonSex: action,
      setPersonFirstName: action,
      setPersonLastName: action,
      setPersonAddress: action,
      setPersonCity: action,
      setPersonEmail: action,
      setPersonPhone: action,
      setPersonBirthday: action,
      setPersonInsuranceNumber: action,
    });
  }

  setSex(sex: Sex) {
    this.sex = sex;
  }

  setFirstName(firstName: string) {
    this.firstName = firstName;
  }

  setLastName(lastName: string) {
    this.lastName = lastName;
  }

  setBirthDate(birthDate: Dayjs | null) {
    this.birthDate = birthDate;
  }

  setInsuranceNumber(insuranceNumber: string) {
    this.insuranceNumber = insuranceNumber;
  }

  setDateOfLeaving(dateOfLeaving: Dayjs) {
    this.dateOfLeaving = dateOfLeaving;
  }

  setDocumentOfLeaving(documentOfLeaving: string) {
    this.documentOfLeaving = documentOfLeaving;
  }

  setDocumentOfLeavingObject(documentOfLeavingObject: UploadFile[]) {
    this.documentOfLeavingObject = documentOfLeavingObject;
  }

  setNewDomicile(newDomicile?: string) {
    this.newDomicile = newDomicile;
  }

  setTemporaryResidenceAbroadReason(temporaryResidenceAbroadReason?: string) {
    this.temporaryResidenceAbroadReason = temporaryResidenceAbroadReason;
  }

  setNoMoreLegalObligationOfInsurance(noMoreLegalObligationOfInsurance?: boolean) {
    this.noMoreLegalObligationOfInsurance = noMoreLegalObligationOfInsurance;
  }

  setLegalObligationFile(legalObligationFile?: string) {
    this.legalObligationFile = legalObligationFile;
  }

  setLegalObligationFileObject(legalObligationFileObject?: UploadFile[]) {
    this.legalObligationFileObject = legalObligationFileObject;
  }

  setCrossBorderCommuter(crossBorderCommuter?: boolean) {
    this.crossBorderCommuter = crossBorderCommuter;
  }

  setSwissEmployer(swissEmployer?: boolean) {
    this.swissEmployer = swissEmployer;
  }

  setAhvFile(ahvFile?: string) {
    this.ahvFile = ahvFile;
  }

  setAhvFileObject(ahvFileObject?: UploadFile[]) {
    this.ahvFileObject = ahvFileObject;
  }

  setBenefitsFromSwitzerland(benefitsFromSwitzerland?: string) {
    this.benefitsFromSwitzerland = benefitsFromSwitzerland;
  }

  setWorkingAbroad(workingAbroad?: string) {
    this.workingAbroad = workingAbroad;
  }

  setBenefitsFromAbroad(benefitsFromAbroad?: string) {
    this.benefitsFromAbroad = benefitsFromAbroad;
  }

  setFamilyJoining(familyJoining?: string) {
    this.familyJoining = familyJoining;
  }

  setSenderDifferentFromPolicyHolder(senderDifferentFromPolicyHolder?: boolean) {
    this.senderDifferentFromPolicyHolder = senderDifferentFromPolicyHolder;
  }

  setPersonSex(sex?: Sex) {
    this.personSex = sex;
  }

  setPersonFirstName(firstName?: string) {
    this.personFirstName = firstName;
  }

  setPersonLastName(lastName?: string) {
    this.personLastName = lastName;
  }

  setPersonAddress(address?: string) {
    this.personAddress = address;
  }

  setPersonCity(city?: City) {
    this.personCity = city;
  }

  setPersonEmail(email?: string) {
    this.personEmail = email;
  }

  setPersonPhone(phone?: string) {
    this.personPhone = phone;
  }

  setPersonInsuranceNumber(insuranceNumber?: string) {
    this.personInsuranceNumber = insuranceNumber;
  }

  setPersonBirthday(birthday?: Dayjs) {
    this.personBirthday = birthday;
  }

  reset() {
    this.sex = undefined;
    this.firstName = undefined;
    this.lastName = undefined;
    this.birthDate = null;
    this.insuranceNumber = undefined;
    this.dateOfLeaving = undefined;
    this.documentOfLeaving = undefined;
    this.documentOfLeavingObject = undefined;
    this.newDomicile = undefined;
    this.temporaryResidenceAbroadReason = undefined;
    this.noMoreLegalObligationOfInsurance = false;
    this.legalObligationFile = undefined;
    this.legalObligationFileObject = undefined;
    this.crossBorderCommuter = false;
    this.swissEmployer = false;
    this.ahvFile = undefined;
    this.ahvFileObject = undefined;
    this.benefitsFromSwitzerland = undefined;
    this.workingAbroad = undefined;
    this.benefitsFromAbroad = undefined;
    this.familyJoining = undefined;

    this.senderDifferentFromPolicyHolder = false;

    this.personSex = undefined;
    this.personFirstName = undefined;
    this.personLastName = undefined;
    this.personAddress = undefined;
    this.personCity = undefined;
    this.personEmail = undefined;
    this.personPhone = undefined;
    this.personBirthday = undefined;
    this.personInsuranceNumber = undefined;
  }

  getAdditionalFiles(): IIssueAdditionalFile[] {
    const result = [];
    if (this.ahvFile) {
      result.push({
        file: this.ahvFile,
        type: AdditionalFileIdentifier.AHV_FILE,
      });
    }
    if (this.legalObligationFile) {
      result.push({
        file: this.legalObligationFile,
        type: AdditionalFileIdentifier.LEGAL_OBLIGATION_FILE,
      });
    }

    return result;
  }

  getIssueRequest(): ICancellationUploadRequest {
    return {
      issueType: Config.issueTypeCancellationLeave,
      sex: this.sex || Sex.MALE,
      firstName: this.firstName ?? '',
      lastName: this.lastName ?? '',
      birthday: this.birthDate ? this.birthDate.format(Config.apiDateFormat) : undefined,
      address: this.address ?? '',
      city: this.city?.name ?? '',
      postcode: this.city?.postcode ?? 0,
      email: this.email ?? '',
      insuranceNo: this.insuranceNumber ?? '',
      file: this.documentOfLeaving ?? '',
      phone: this.phone ?? '',
      formData: {
        newDomicile: this.newDomicile ?? 'Nein',
        temporaryResidenceAbroadReason: this.temporaryResidenceAbroadReason ?? 'Nein',
        noMoreLegalObligationOfInsurance: this.noMoreLegalObligationOfInsurance ?? false,
        crossBorderCommuter: this.crossBorderCommuter ?? false,
        swissEmployer: this.swissEmployer ?? false,
        benefitsFromSwitzerland: this.benefitsFromSwitzerland ?? 'Nein',
        workingAbroad: this.workingAbroad ?? 'Nein',
        benefitsFromAbroad: this.benefitsFromAbroad ?? 'Nein',
        familyJoining: this.familyJoining ?? 'Nein',
        dateOfLeaving: this.dateOfLeaving?.format(Config.apiDateFormat) ?? '',
        sex: this.personSex,
        firstName: this.personFirstName,
        lastName: this.personLastName,
        address: this.personAddress,
        postcode: this.personCity?.postcode,
        city: this.personCity?.name,
        phone: this.personPhone,
        birthday: this.personBirthday?.format(Config.apiDateFormat),
        email: this.personEmail,
        insuranceNumber: this.personInsuranceNumber,
      },
      additionalFiles: this.getAdditionalFiles(),
    };
  }
}
