import {FC, ReactNode} from 'react';
import './Card.scss';

export interface CardProps {
  children: ReactNode;
  className?: string;
  onClick?: () => void;
}

const Card: FC<CardProps> = ({children, className, onClick}) => (
  <div onClick={onClick} aria-hidden className={className ? `card ${className}` : 'card'}>
    {children}
  </div>
);

export default Card;
