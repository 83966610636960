import React, {FC, useState} from 'react';
import {DatePicker, Form, Input, Radio} from 'antd';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import dayjs, {Dayjs} from 'dayjs';
import {useNavigate} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import {Config} from '../../../../config';
import {Sex} from '../../../../models/person';
import {Validators} from '../../../../utils/validators';
import InfoBox from '../../../../components/shared/InfoBox';
import Text from '../../../../components/shared/Text';
import OptionalLabel from '../../../../components/shared/OptionalLabel';
import {formatInsuranceNumber} from '../../../../utils/formatter';
import {useStore} from '../../../../hooks/useStore';
import {City} from '../../../../models/city';
import Select from '../../../../components/shared/Select';
import useCitySelection from '../../../../hooks/useCitySelection';
import StepsNavigationButtons from '../../../../components/StepsNavigationButtons';
import {CancellationLeavePaths} from '../../../paths';
import Upload, {UploadChangeParam, UploadFile} from '../../../../components/shared/Upload';
import Space from '../../../../components/shared/Space';
import Button from '../../../../components/shared/Button';
import InfoModal from '../../../../components/shared/InfoModal';
import './PersonForm.scss';

interface FormValues {
  sex: Sex;
  firstName: string;
  lastName: string;
  birthDate: Dayjs;
  address: string;
  city: City['id'];
  email: string;
  phone?: string;
  insuranceNumber: string;
  dateOfLeaving: Dayjs;
  documentOfLeaving: UploadFile[];
}

const PersonForm: FC = () => {
  const {t} = useTranslation();
  const store = useStore();
  const navigate = useNavigate();
  const [form] = Form.useForm<FormValues>();
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const {options} = useCitySelection();

  const initialValues = {
    sex: store.cancellationLeaveStore.personSex,
    firstName: store.cancellationLeaveStore.personFirstName,
    lastName: store.cancellationLeaveStore.personLastName,
    birthDate: store.cancellationLeaveStore.personBirthday,
    address: store.cancellationLeaveStore.personAddress,
    city: store.cancellationLeaveStore.personCity?.id,
    email: store.cancellationLeaveStore.personEmail,
    phone: store.cancellationLeaveStore.personPhone,
    insuranceNumber: store.cancellationLeaveStore.personInsuranceNumber,
    dateOfLeaving: store.cancellationLeaveStore.dateOfLeaving,
    documentOfLeaving: store.cancellationLeaveStore.documentOfLeavingObject,
  };

  const onNext = () => {
    navigate(CancellationLeavePaths.questions());
  };

  const handleSubmit = (values: FormValues) => {
    setLoading(true);

    const reader = new FileReader();
    reader.readAsDataURL(values.documentOfLeaving[0].originFileObj as Blob);
    reader.onload = () => {
      store.cancellationLeaveStore.setSex(values.sex);
      store.cancellationLeaveStore.setFirstName(values.firstName);
      store.cancellationLeaveStore.setLastName(values.lastName);
      store.cancellationLeaveStore.setBirthDate(values.birthDate);
      store.cancellationLeaveStore.setAddress(values.address);
      store.cancellationLeaveStore.setCity(store.cityStore.getById(values.city));
      store.cancellationLeaveStore.setEmail(values.email);
      store.cancellationLeaveStore.setPhone(values.phone);
      store.cancellationLeaveStore.setInsuranceNumber(values.insuranceNumber);
      store.cancellationLeaveStore.setDateOfLeaving(values.dateOfLeaving);
      store.cancellationLeaveStore.setDocumentOfLeaving((reader.result as string).split(',').pop() || '');
      store.cancellationLeaveStore.setDocumentOfLeavingObject(values.documentOfLeaving);

      store.cancellationLeaveStore.setPersonSex(values.sex);
      store.cancellationLeaveStore.setPersonFirstName(values.firstName);
      store.cancellationLeaveStore.setPersonLastName(values.lastName);
      store.cancellationLeaveStore.setPersonAddress(values.address);
      store.cancellationLeaveStore.setPersonCity(store.cityStore.getById(values.city));
      store.cancellationLeaveStore.setPersonEmail(values.email);
      store.cancellationLeaveStore.setPersonPhone(values.phone);
      store.cancellationLeaveStore.setPersonBirthday(values.birthDate);
      store.cancellationLeaveStore.setPersonInsuranceNumber(values.insuranceNumber);
    };

    onNext();
  };

  const normFile = (e: UploadChangeParam) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e.fileList;
  };

  return (
    <Space fullWidth direction={'vertical'}>
      <Form
        form={form}
        layout={'vertical'}
        colon={false}
        labelAlign={'left'}
        requiredMark={false}
        onFinish={handleSubmit}
        initialValues={initialValues}
      >
        <Form.Item>
          <InfoBox title={t('cancellation.leave.person.info.title')}>
            <span>
              {t('cancellation.leave.person.info.text')}
              &nbsp;
              <Button type={'link'} onClick={() => setModalVisible((prev) => !prev)}>
                {t('cancellation.leave.person.info.link')}
              </Button>
            </span>
          </InfoBox>
        </Form.Item>
        <Form.Item>
          <Text header={2}>{t('cancellation.leave.person.formTitle')}</Text>
        </Form.Item>
        <Form.Item
          name={'sex'}
          label={t('label.sex')}
          rules={[Validators.required(t('validator.salutationRequired'))]}
        >
          <Radio.Group>
            {Object.values(Sex).map((sex) => (
              <Radio key={sex} value={sex}>{t(`sex.${sex}`)}</Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name={'firstName'}
          label={t('label.firstName')}
          rules={[Validators.required(t('validator.firstNameRequired')), Validators.noSpecialCharacters]}
        >
          <Input placeholder={t('label.firstName')} maxLength={50}/>
        </Form.Item>
        <Form.Item
          name={'lastName'}
          label={t('label.name')}
          rules={[Validators.required(t('validator.nameRequired')), Validators.noSpecialCharacters]}
        >
          <Input placeholder={t('label.name')} maxLength={50}/>
        </Form.Item>
        <Form.Item
          name={'birthDate'}
          label={t('label.birthday')}
          rules={[Validators.required(t('validator.birthdayRequired')), {
            validator: (_, value) => {
              if (value && form.getFieldValue('dateOfLeaving') && value.isAfter(form.getFieldValue('dateOfLeaving'))) {
                return Promise.reject(t('validator.birthDateAfterDateOfLeaving'));
              }
              return Promise.resolve();
            },
          }]}
        >
          <DatePicker
            format={Config.dateFormat}
            placeholder={t('label.dateFormat')}
            disabledDate={(d) => d.isAfter(dayjs().endOf('day').add(2, 'years'))
              || d.isBefore(dayjs().startOf('day').subtract(150, 'years'))}
          />
        </Form.Item>
        <Form.Item
          name={'address'}
          label={t('label.address')}
          rules={[Validators.required(t('validator.addressRequired')), Validators.noSpecialCharacters]}
        >
          <Input placeholder={t('label.address')} maxLength={50}/>
        </Form.Item>
        <Form.Item
          name={'city'}
          label={t('label.postcodeCity')}
          rules={[Validators.required(t('validator.postcodeCityRequired'))]}
        >
          <Select
            showSearch
            loading={store.cityStore.isLoading}
            options={options}
            placeholder={t('label.postcodeCityPlaceholder')}
          />
        </Form.Item>
        <Form.Item
          name={'email'}
          label={t('label.email')}
          rules={[Validators.required(t('validator.emailRequired')), Validators.email]}
        >
          <Input placeholder={t('label.email')} maxLength={255}/>
        </Form.Item>
        <Form.Item
          name={'phone'}
          label={<OptionalLabel label={t('label.phone')}/>}
          rules={[Validators.phone]}
        >
          <Input placeholder={t('label.phone')} maxLength={50}/>
        </Form.Item>
        <Form.Item
          name={'insuranceNumber'}
          label={t('label.insuranceNumber')}
          rules={[Validators.insuranceNumber, Validators.required(t('validator.insuranceNumberRequired'))]}
        >
          <Input
            placeholder={t('label.insuranceNumberPlaceholder')}
            onInput={formatInsuranceNumber}
          />
        </Form.Item>
        <Form.Item style={{marginTop: '2rem'}}>
          <Text header={2}>{t('cancellation.leave.person.leaveTitle')}</Text>
        </Form.Item>
        <Form.Item
          name={'dateOfLeaving'}
          label={t('label.dateOfLeaving')}
          rules={[Validators.required(t('validator.dateOfLeavingRequired')), {
            validator: (_, value) => {
              if (value && form.getFieldValue('birthDate') && value.isBefore(form.getFieldValue('birthDate'))) {
                return Promise.reject(t('validator.birthDateAfterDateOfLeaving'));
              }
              return Promise.resolve();
            },
          }]}
        >
          <DatePicker
            format={Config.dateFormat}
            placeholder={t('label.dateFormat')}
            disabledDate={(d) => d.isAfter(dayjs().endOf('day').add(1, 'year'))
              || d.isBefore(dayjs().startOf('day').subtract(1, 'year'))}
          />
        </Form.Item>
        <Form.Item
          name={'documentOfLeaving'}
          label={t('label.uploadDocumentOfLeaving')}
          getValueFromEvent={normFile}
          valuePropName={'fileList'}
          rules={[
            {
              validator: (_, arr) => {
                if (!arr) {
                  return Promise.reject(t('validator.documentOfLeavingFileRequired'));
                }
                const [file] = arr;

                if (!file) {
                  return Promise.reject(t('validator.documentOfLeavingFileRequired'));
                }

                if (file.type !== 'application/pdf') {
                  return Promise.reject(t('validator.cancellationFileInvalid'));
                }

                const maxFileSize = 20 * 1024 * 1024;
                if (file.size > maxFileSize) {
                  return Promise.reject(t('validator.cancellationFileTooLarge'));
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Upload maxCount={1} beforeUpload={() => false} accept={'.pdf'}>
            <Space direction={'vertical'} fullWidth size={'large'}>
              <Button skin={'primary-gray-blue'}>{t('action.selectDocumentOfLeavingFile')}</Button>
              <Text size={'small'}>{t('cancellation.leave.person.fileUpload.info')}</Text>
            </Space>
          </Upload>
        </Form.Item>

        <StepsNavigationButtons
          backHidden
          back={() => {}}
          next={form.submit}
          loading={loading}
        />
      </Form>
      <InfoModal modalVisible={modalVisible} setModalVisible={setModalVisible}>
        <Text size={'regular'}>
          <ReactMarkdown>
            {t('cancellation.leave.person.info.modal')}
          </ReactMarkdown>
        </Text>
      </InfoModal>
    </Space>
  );
};

export default observer(PersonForm);
