import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Layout, Modal} from 'antd';
import {useLocation, useNavigate} from 'react-router-dom';
import Container from './Container';
import Space from '../shared/Space';
import {useStore} from '../../hooks/useStore';
import Button from '../shared/Button';
import Text from '../shared/Text';
import useBreakpoint from '../../hooks/useBreakpoint';
import {
  CalculatorPaths, CancellationDeathPaths, CancellationLeavePaths, CancellationUploadPaths, CancellationWizardPaths,
  FranchiseChangePaths,
  TravelInsurancePaths
} from '../../pages/paths';
import useCurrentDomainInformation from '../../hooks/useCurrentDomainInformation';
import './Header.scss';

const Header: FC = () => {
  const {t} = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const store = useStore();
  const {isMobile} = useBreakpoint();
  const navigate = useNavigate();
  const {
    isCalculator,
    isFranchiseChange,
    isTravelInsurance,
    isCancellationUpload,
    isCancellationWizard,
    isCancellationDeath,
    isCancellationLeave,
  } = useCurrentDomainInformation();

  const calculatorSteps = [
    {key: `${CalculatorPaths.index()}/init`, label: t('step.calculator.person')},
    {key: `${CalculatorPaths.index()}/product`, label: t('step.calculator.products')},
    {key: `${CalculatorPaths.overview()}`, label: t('step.calculator.overview')},
    {key: `${CalculatorPaths.details()}`, label: t('step.calculator.details')},
    {key: `${CalculatorPaths.index()}/finish`, label: t('step.calculator.finish')},
  ];

  const franchiseSteps = [
    {key: `${FranchiseChangePaths.index()}/init`, label: t('step.franchiseChange.person')},
    {key: FranchiseChangePaths.current(), label: t('step.franchiseChange.currentFranchise')},
    {key: FranchiseChangePaths.new(), label: t('step.franchiseChange.newFranchise')},
    {key: FranchiseChangePaths.person(), label: t('step.franchiseChange.personalData')},
    {key: FranchiseChangePaths.overview(), label: t('step.franchiseChange.finish')},
  ];

  const travelInsuranceSteps = [
    {key: `${TravelInsurancePaths.index()}/init`, label: t('step.travelInsurance.products')},
    {key: TravelInsurancePaths.person(), label: t('step.travelInsurance.person')},
    {key: TravelInsurancePaths.overview(), label: t('step.travelInsurance.overview')},
    {key: `${TravelInsurancePaths.index()}/finish`, label: t('step.travelInsurance.finish')},
  ];

  const cancellationUploadSteps = [
    {key: `${CancellationUploadPaths.index()}/init`, label: t('step.cancellationUpload.cancellation')},
    {key: `${CancellationUploadPaths.index()}/success`, label: t('step.cancellationUpload.confirmation')},
  ];

  const cancellationWizardSteps = [
    {key: `${CancellationWizardPaths.index()}/init`, label: t('step.cancellationWizard.person')},
    {key: `${CancellationWizardPaths.index()}/overview`, label: t('step.cancellationWizard.overview')},
    {key: `${CancellationWizardPaths.index()}/verification`, label: t('step.cancellationWizard.verification')},
    {key: `${CancellationWizardPaths.index()}/opt-in`, label: t('step.cancellationWizard.optIn')},
    {key: `${CancellationWizardPaths.index()}/success`, label: t('step.cancellationWizard.success')},
  ];

  const cancellationDeathSteps = [
    {key: `${CancellationDeathPaths.index()}/init`, label: t('step.cancellationDeath.cancellation')},
    {key: `${CancellationDeathPaths.index()}/success`, label: t('step.cancellationDeath.confirmation')},
  ];

  const cancellationLeaveSteps = [
    {key: `${CancellationLeavePaths.index()}/init`, label: t('step.cancellationLeave.person')},
    {key: `${CancellationLeavePaths.index()}/questions`, label: t('step.cancellationLeave.questions')},
    {key: `${CancellationLeavePaths.index()}/cancellation`, label: t('step.cancellationLeave.cancellation')},
    {key: `${CancellationLeavePaths.index()}/success`, label: t('step.cancellationLeave.success')},
  ];

  const steps = (() => {
    if (isFranchiseChange) {
      return franchiseSteps;
    }

    if (isTravelInsurance) {
      return travelInsuranceSteps;
    }

    if (isCancellationUpload) {
      return cancellationUploadSteps;
    }

    if (isCancellationWizard) {
      return cancellationWizardSteps;
    }

    if (isCancellationDeath) {
      return cancellationDeathSteps;
    }

    if (isCancellationLeave) {
      return cancellationLeaveSteps;
    }

    if (isCalculator) {
      return calculatorSteps;
    }

    return [];
  })();

  useEffect(() => {
    const step = steps.findIndex(({key}) => location.pathname.startsWith(key));
    setCurrentStep(step !== -1 ? step : 0);
  }, [location]);

  const getClasses = (index: number) => {
    const className = 'step';

    if (index < currentStep) {
      return `${className} done`;
    }

    if (index === currentStep) {
      return `${className} active`;
    }

    return className;
  };

  const checkReset = () => {
    if (isFranchiseChange) {
      navigate(FranchiseChangePaths.index());
    } else if (store.calculatorStore.models.length > 1 || store.calculatorStore.city) {
      setShowModal(true);
    } else if (isTravelInsurance
      && (store.travelInsuranceStore.models.length > 1 || store.travelInsuranceStore.travelInsuranceDuration
        || store.travelInsuranceStore.type || store.travelInsuranceStore.travelInsuranceStartDate)) {
      setShowModal(true);
    } else if (isTravelInsurance) {
      navigate(TravelInsurancePaths.index());
    } else if (isCancellationUpload) {
      navigate(CancellationUploadPaths.index());
    } else if (isCancellationWizard) {
      navigate(CancellationWizardPaths.index());
    } else if (isCancellationDeath) {
      navigate(CancellationDeathPaths.index());
    } else if (isCancellationLeave) {
      navigate(CancellationLeavePaths.index());
    } else {
      navigate(CalculatorPaths.index());
    }
  };

  const navigateToHome = (reset: boolean) => {
    if (reset) {
      if (isTravelInsurance) {
        store.travelInsuranceStore.reset();
      } else {
        store.calculatorStore.reset();
      }
    }
    if (isTravelInsurance) {
      navigate(TravelInsurancePaths.index());
    } else {
      navigate(CalculatorPaths.index());
    }
    setShowModal(false);
  };

  return (
    <Layout.Header className={'header print-hidden'}>
      <Container noPadding>
        <Space fullWidth direction={'horizontal'} style={{justifyContent: 'space-between'}}>
          <Button type={'link'} onClick={() => checkReset()}>
            <img src={'/img/aquilana-logo-small.svg'} height={64} alt={t('logo')} className={'d-lg-none'}/>
            <img src={'/img/aquilana-logo.svg'} height={64} alt={t('logo')} className={'d-none d-lg-block'}/>
          </Button>
          <div className={'steps'}>
            {steps.map((step, index) => (
              <div key={step.key.replaceAll('/', '-')} className={getClasses(index)}>
                <span className={`${index === currentStep ? 'd-block' : 'd-none'} d-lg-block`}>{step.label}</span>
              </div>
            ))}
          </div>
        </Space>
      </Container>
      <Modal
        width={800}
        open={showModal}
        onCancel={() => setShowModal(false)}
        closable={false}
        footer={(
          <Space direction={isMobile ? 'vertical' : 'horizontal'}>
            <Button skin={'primary-turquoise'} onClick={() => navigateToHome(true)}>
              {isTravelInsurance
                ? t('restoredSessionModal.startNewTravelInsurance') : t('restoredSessionModal.startNewCalculation')}
            </Button>
            <Button skin={'primary-gray-blue'} onClick={() => navigateToHome(false)}>
              {t('restoredSessionModal.continueCalculation')}
            </Button>
          </Space>
        )}
      >
        <div className={'content-wrapper'}>
          <Text size={'small'}>
            {t('header.resetQuestion')}
          </Text>
        </div>
      </Modal>
    </Layout.Header>
  );
};

export default Header;
