/* eslint-disable import/no-cycle */
import {runInAction} from 'mobx';
import {Dayjs} from 'dayjs';
import {BaseStore} from './base-store';
import {RootStore} from './root-store';
import {BaseInsuranceRate} from '../models/base-insurance-rate';
import {Api} from '../services/api';
import {BaseInsurance, BaseInsuranceType} from '../models/base-insurance';

export class BaseInsuranceRateStore extends BaseStore<BaseInsuranceRate, number> {
  constructor(rootStore: RootStore) {
    super(rootStore, 'id', Api.getBaseInsuranceRates);

    this.loadAll();
  }

  loadAll(): Promise<void> {
    if (!this.apiEndpoint) {
      throw new Error('not supported');
    }

    this.isLoading = true;
    return this.apiEndpoint().then((models) => {
      runInAction(() => {
        this.models = models;
        this.isLoading = false;
      });
    });
  }

  findOne(
    type: BaseInsuranceType,
    franchiseGroup: number,
    age: number,
    regionCode: string,
    insuranceStart: Dayjs
  ): BaseInsuranceRate | undefined {
    return this.models.find(
      (rate) =>
        rate.baseInsuranceTypeId === BaseInsurance.getTypeAsNumber(type)
        && rate.fromAge <= age
        && age <= rate.toAge
        && rate.franchiseGroupId === franchiseGroup
        && rate.regionCode === regionCode
        && rate.premiumVersion?.isValidAt(insuranceStart)
    );
  }
}
