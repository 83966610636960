import { Person } from '../models/person';
import { BaseInsurance, BaseInsuranceType } from '../models/base-insurance';
import { RootStore } from '../stores/root-store';
import { AquilanaFunctionStore } from '../stores/aquilana-function-store';

export const updateInsuranceRates = (
  person: Person,
  store: RootStore,
  aquilanaFunctionStore: AquilanaFunctionStore
) => {
  if (person.baseInsurance) {
    const rate = store.baseInsuranceRateStore.findOne(
      person.baseInsurance.type,
      person.baseInsurance.franchiseGroup?.id || 1,
      person.age,
      aquilanaFunctionStore.city?.regionCode || '',
      person.insuranceStart
    );

    if (!rate) {
      const baseInsurance = new BaseInsurance();
      baseInsurance.setFranchiseGroup(person.baseInsurance.franchiseGroup);
      const baseInsuranceRate = store.baseInsuranceRateStore.findOne(
        BaseInsuranceType.STANDARD,
        person.baseInsurance.franchiseGroup?.id || 1,
        person.age,
        aquilanaFunctionStore.city?.regionCode || '',
        person.insuranceStart
      );
      baseInsurance.setOldRate(person.baseInsurance.oldRate);
      baseInsurance.setOldFranchiseGroup(person.baseInsurance.oldFranchiseGroup);
      baseInsurance.setRate(baseInsuranceRate);
      person.setBaseInsurance(baseInsurance);
    } else {
      person.baseInsurance.setRate(rate);
    }
  }

  if (person.hasAddonInsurances) {
    Object.values(person.addonInsurances || {}).forEach((addonInsurance) => {
      const currentRate = addonInsurance.rate;

      if (currentRate) {
        const rates = store.addonInsuranceRateStore.findBy(
          addonInsurance.type,
          person,
          currentRate.variant,
          currentRate.deductible,
          currentRate.death,
          currentRate.invalidity
        );

        if (rates.length) {
          addonInsurance.setRate(rates[0]);
        } else if (person.addonInsurances?.[addonInsurance.type]) {
          delete person.addonInsurances[addonInsurance.type];
        }
      }
    });
  }
};
