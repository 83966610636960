import React, {FC, ReactNode} from 'react';
import {Modal} from 'antd';
import {useTranslation} from 'react-i18next';
import Button from './Button';
import useBreakpoint from '../../hooks/useBreakpoint';
import Text from './Text';

export interface InfoModalProps {
  title?: string;
  children: ReactNode;
  width?: number;
  modalVisible: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const InfoModal: FC<InfoModalProps> = ({title, children, width = 800, modalVisible, setModalVisible}) => {
  const {t} = useTranslation();
  const {isMobile} = useBreakpoint();

  return (
    <Modal
      width={width}
      style={isMobile ? {top: 0, left: 0, bottom: 0, right: 0} : undefined}
      className={'aquilana-info-modal'}
      open={modalVisible}
      onCancel={() => setModalVisible(false)}
      closable={false}
      footer={(
        <Button skin={'primary-turquoise'} onClick={() => setModalVisible(false)}>{t('action.close')}</Button>
      )}
    >
      <div className={'content-wrapper'}>
        <Text header={2} className={'title'}>{title || t('label.information')}</Text>
        <br/>
        <Text size={'small'}>
          {children}
        </Text>
      </div>
    </Modal>
  );
};

export default InfoModal;
