/* eslint-disable import/no-cycle */
import {action, computed, makeObservable, observable} from 'mobx';
import {
  AccidentDeathDisabilityIcon, PatientCareIcon, PatientCarePlusIcon, PatientCareTopIcon, ToothCare1Icon, ToothCare2Icon
} from '../components/shared/icons';
import {AddonInsuranceRate} from './addon-insurance-rate';
import {IApiAddonInsurance} from './document-request';

export enum AddonInsuranceType {
  PATIENT_CARE_PLUS = 'PLUS',
  PATIENT_CARE_TOP = 'TOP',
  PATIENT_CARE = 'SV',
  TOOTH_CARE_1 = 'ZV1',
  TOOTH_CARE_2 = 'ZV2',
  ACCIDENT_DEATH_DISABILITY = 'UTI'
}

export enum PatientCareType {
  // t('patientCare.normal')
  NORMAL = 'normal',
  // t('patientCare.halfprivate')
  HALF_PRIVATE = 'halfprivate',
  // t('patientCare.private')
  PRIVATE = 'private'
}

export const AddonInsuranceTypes = {
  [AddonInsuranceType.PATIENT_CARE_PLUS]: {
    icon: PatientCarePlusIcon,
    // t('addonInsurance.patientCarePlus.title')
    // t('addonInsurance.patientCarePlus.description')
    // t('addonInsurance.patientCarePlus.info')
    translationPrefix: 'addonInsurance.patientCarePlus',
    productLink: 'https://www.aquilana.ch/krankenpflege-plus',
  },
  [AddonInsuranceType.PATIENT_CARE_TOP]: {
    icon: PatientCareTopIcon,
    // t('addonInsurance.patientCareTop.title')
    // t('addonInsurance.patientCareTop.description')
    // t('addonInsurance.patientCareTop.info')
    translationPrefix: 'addonInsurance.patientCareTop',
    productLink: 'https://www.aquilana.ch/krankenpflege-top',
  },
  [AddonInsuranceType.PATIENT_CARE]: {
    icon: PatientCareIcon,
    // t('addonInsurance.patientCare.title')
    // t('addonInsurance.patientCare.description')
    // t('addonInsurance.patientCare.info')
    translationPrefix: 'addonInsurance.patientCare',
    productLink: 'https://www.aquilana.ch/spitalpflege',
  },
  [AddonInsuranceType.TOOTH_CARE_1]: {
    icon: ToothCare1Icon,
    // t('addonInsurance.toothCare1.title')
    // t('addonInsurance.toothCare1.description')
    // t('addonInsurance.toothCare1.info')
    translationPrefix: 'addonInsurance.toothCare1',
    productLink: 'https://www.aquilana.ch/zahnpflege-1',
  },
  [AddonInsuranceType.TOOTH_CARE_2]: {
    icon: ToothCare2Icon,
    // t('addonInsurance.toothCare2.title')
    // t('addonInsurance.toothCare2.description')
    // t('addonInsurance.toothCare2.info')
    translationPrefix: 'addonInsurance.toothCare2',
    productLink: 'https://www.aquilana.ch/zahnpflege-2',
  },
  [AddonInsuranceType.ACCIDENT_DEATH_DISABILITY]: {
    icon: AccidentDeathDisabilityIcon,
    // t('addonInsurance.accidentDeathDisability.title')
    // t('addonInsurance.accidentDeathDisability.description')
    // t('addonInsurance.accidentDeathDisability.info')
    translationPrefix: 'addonInsurance.accidentDeathDisability',
    productLink: 'https://www.aquilana.ch/uti',
  },
};

export interface IAddonInsurance {
  type: AddonInsuranceType;
  rate?: AddonInsuranceRate;
}

export class AddonInsurance implements IAddonInsurance {
  type = AddonInsuranceType.PATIENT_CARE;
  rate?: AddonInsuranceRate = undefined;

  constructor() {
    makeObservable(this, {
      type: observable,
      rate: observable,

      price: computed,
      setRate: action,
    });
  }

  get price(): number {
    if (!this.rate) {
      return 0;
    }
    return this.rate.value;
  }

  static getTypeAsNumber(type: AddonInsuranceType): number {
    switch (type) {
      case AddonInsuranceType.PATIENT_CARE_TOP:
        return 2;
      case AddonInsuranceType.PATIENT_CARE:
        return 3;
      case AddonInsuranceType.TOOTH_CARE_1:
        return 4;
      case AddonInsuranceType.TOOTH_CARE_2:
        return 5;
      case AddonInsuranceType.ACCIDENT_DEATH_DISABILITY:
        return 6;
      default:
        return 1;
    }
  }

  static getNumberAsType(n: number): AddonInsuranceType {
    switch (n) {
      case 2:
        return AddonInsuranceType.PATIENT_CARE_TOP;
      case 3:
        return AddonInsuranceType.PATIENT_CARE;
      case 4:
        return AddonInsuranceType.TOOTH_CARE_1;
      case 5:
        return AddonInsuranceType.TOOTH_CARE_2;
      case 6:
        return AddonInsuranceType.ACCIDENT_DEATH_DISABILITY;
      default:
        return AddonInsuranceType.PATIENT_CARE_PLUS;
    }
  }

  setRate(rate?: AddonInsuranceRate) {
    this.rate = rate;
  }

  static fromJSON(data?: Partial<IApiAddonInsurance>, rate?: AddonInsuranceRate) {
    const addonInsurance = new AddonInsurance();

    if (rate?.addonInsuranceTypeId) {
      addonInsurance.type = AddonInsurance.getNumberAsType(rate.addonInsuranceTypeId);
    }

    return addonInsurance;
  }
}
