import React from 'react';
import {useTranslation} from 'react-i18next';
import {ColumnType} from 'antd/es/table';
import ReactMarkdown from 'react-markdown';
import Text from '../../../../components/shared/Text';
import {Table} from '../../../../components/shared/Table';
import Space from '../../../../components/shared/Space';
import './PatientCareInfoTable.scss';

interface DataType {
  key: React.Key;
  description: string;
  normal: string;
  halfPrivate: string;
  private: string;
}

const PatientCareInfoTable = () => {
  const {t} = useTranslation();

  const columns: ColumnType<DataType>[] = [
    {
      title: t('addonInsurance.patientCare.table.row0.description'),
      dataIndex: 'description',
      render: (description: string) => (
        <Text size={'regular'}>
          <ReactMarkdown>{description}</ReactMarkdown>
        </Text>
      ),
      width: '25%',
    },
    {
      title: t('addonInsurance.patientCare.table.row0.normal'),
      dataIndex: 'normal',
      render: (normalText: string) => (
        <Text size={'regular'}>
          <ReactMarkdown>{normalText}</ReactMarkdown>
        </Text>
      ),
      width: '25%',
    },
    {
      title: t('addonInsurance.patientCare.table.row0.halfPrivate'),
      dataIndex: 'halfPrivate',
      render: (halfPrivateText: string) => (
        <Text size={'regular'}>
          <ReactMarkdown>{halfPrivateText}</ReactMarkdown>
        </Text>
      ),
      width: '25%',
    },
    {
      title: t('addonInsurance.patientCare.table.row0.private'),
      dataIndex: 'private',
      render: (privateText: string) => (
        <Text size={'regular'}>
          <ReactMarkdown>{privateText}</ReactMarkdown>
        </Text>
      ),
      width: '25%',
    },
  ];

  const data: DataType[] = [
    {
      key: 'beds',
      description: t('addonInsurance.patientCare.table.row1.description'),
      normal: t('addonInsurance.patientCare.table.row1.normal'),
      halfPrivate: t('addonInsurance.patientCare.table.row1.halfPrivate'),
      private: t('addonInsurance.patientCare.table.row1.private'),
    },
    {
      key: 'visiting',
      description: t('addonInsurance.patientCare.table.row2.description'),
      normal: t('addonInsurance.patientCare.table.row2.normal'),
      halfPrivate: t('addonInsurance.patientCare.table.row2.halfPrivate'),
      private: t('addonInsurance.patientCare.table.row2.private'),
    },
    {
      key: 'treatment',
      description: t('addonInsurance.patientCare.table.row3.description'),
      normal: t('addonInsurance.patientCare.table.row3.normal'),
      halfPrivate: t('addonInsurance.patientCare.table.row3.halfPrivate'),
      private: t('addonInsurance.patientCare.table.row3.private'),
    },
    {
      key: 'choice_of_hospital',
      description: t('addonInsurance.patientCare.table.row4.description'),
      normal: t('addonInsurance.patientCare.table.row4.normal'),
      halfPrivate: t('addonInsurance.patientCare.table.row4.halfPrivate'),
      private: t('addonInsurance.patientCare.table.row4.private'),
    },
    {
      key: 'room_category',
      description: t('addonInsurance.patientCare.table.row5.description'),
      normal: t('addonInsurance.patientCare.table.row5.normal'),
      halfPrivate: t('addonInsurance.patientCare.table.row5.halfPrivate'),
      private: t('addonInsurance.patientCare.table.row5.private'),
    },
    {
      key: 'transport',
      description: t('addonInsurance.patientCare.table.row6.description'),
      normal: t('addonInsurance.patientCare.table.row6.normal'),
      halfPrivate: t('addonInsurance.patientCare.table.row6.halfPrivate'),
      private: t('addonInsurance.patientCare.table.row6.private'),
    },
    {
      key: 'better_doc',
      description: t('addonInsurance.patientCare.table.row7.description'),
      normal: t('addonInsurance.patientCare.table.row7.normal'),
      halfPrivate: t('addonInsurance.patientCare.table.row7.halfPrivate'),
      private: t('addonInsurance.patientCare.table.row7.private'),
    },
    {
      key: 'legal_protection',
      description: t('addonInsurance.patientCare.table.row8.description'),
      normal: t('addonInsurance.patientCare.table.row8.normal'),
      halfPrivate: t('addonInsurance.patientCare.table.row8.halfPrivate'),
      private: t('addonInsurance.patientCare.table.row8.private'),
    },
    {
      key: 'rooming_in',
      description: t('addonInsurance.patientCare.table.row9.description'),
      normal: t('addonInsurance.patientCare.table.row9.normal'),
      halfPrivate: t('addonInsurance.patientCare.table.row9.halfPrivate'),
      private: t('addonInsurance.patientCare.table.row9.private'),
    },
  ];

  return (
    <Space direction={'vertical'} fullWidth>
      <Table<DataType>
        size={'small'}
        className={'patient-care-info-table'}
        columns={columns}
        dataSource={data}
        rowKey={(record) => record.key}
        striped
        scroll={{x: 'fit-content'}}
      />
      <Text size={'extra-small'}>
        <ReactMarkdown>
          {t('addonInsurance.patientCare.table.footer')}
        </ReactMarkdown>
      </Text>
    </Space>
  );
};

export default PatientCareInfoTable;
