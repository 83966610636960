import * as React from 'react';
import {FC, HTMLAttributes} from 'react';
import './Box.scss';

interface BoxProps extends HTMLAttributes<HTMLDivElement> {
  flex?: string | number;
}

interface BoxWrapperProps extends HTMLAttributes<HTMLDivElement> {
}

export const Box: FC<BoxProps> = ({style, flex, children, ...props}) => (
  <div
    style={{...style, flex}}
    {...props}
  >
    {children}
  </div>
);

export const BoxWrapper: FC<BoxWrapperProps> = ({className, children, ...props}) => (
  <div className={className ? `aquilana-box-wrapper ${className}` : 'aquilana-box-wrapper'} {...props}>
    {children}
  </div>
);
