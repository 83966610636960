/* eslint-disable import/no-cycle */
import {action, computed, makeObservable, observable} from 'mobx';
import dayjs, {Dayjs} from 'dayjs';
import {RootStore} from './root-store';
import {AddonInsuranceCollection, ContactLanguage, PaymentMethod, PaymentRhythm, Person, Sex} from '../models/person';
import {IApiInsurance, IApiPerson, IIssue, INewBornRequest, PersonType} from '../models/document-request';
import {BaseInsurance} from '../models/base-insurance';
import {AddonInsurance} from '../models/addon-insurance';
import {Api} from '../services/api';
import i18n from '../i18n/i18n';
import {Config} from '../config';
import {sortNumerically} from '../utils/sorters';
import {AquilanaFunctionStore} from './aquilana-function-store';

const STORAGE_KEY = 'calculator';

export const BASE_INSURANCE_PREMIUM_REDUCTION_FACTOR = 0.5;
export const ADDON_INSURANCE_PREMIUM_REDUCTION_FACTOR = 0.5;

export class CalculatorStore extends AquilanaFunctionStore {
  contactLanguage?: ContactLanguage = undefined;
  paymentMethod?: PaymentMethod = undefined;
  usePaymentMethodForCostSharing?: boolean = undefined;
  paymentRhythm?: PaymentRhythm = undefined;
  iban?: string = undefined;
  insuranceNumber?: string = undefined;
  comparisId?: string = undefined;
  caseId?: string = undefined;
  caseCc?: string = undefined;
  newBornEstimatedBirthDate?: Dayjs = undefined;
  firstName?: string = undefined;
  lastName?: string = undefined;
  sex?: Sex = undefined;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      contactLanguage: observable,
      paymentMethod: observable,
      paymentRhythm: observable,
      usePaymentMethodForCostSharing: observable,
      iban: observable,
      insuranceNumber: observable,
      comparisId: observable,
      caseId: observable,
      caseCc: observable,
      firstName: observable,
      lastName: observable,
      newBornEstimatedBirthDate: observable,
      sex: observable,

      setContactLanguage: action,
      setPaymentMethod: action,
      setPaymentUseMethodForCostSharing: action,
      setPaymentRhythm: action,
      setIban: action,
      setInsuranceNumber: action,
      loadByReference: action,
      setNewbornEstimatedBirthDate: action,
      setFirstName: action,
      setLastName: action,
      setSex: action,
      loadFromSession: action,
      saveToSession: action,
      convertPersonToIPersonRequest: action,

      canSubmitApplication: computed,
      hasBaseInsurance: computed,
      parentHasAddonInsurances: computed,
    });
  }

  getIndex(person: Person): number {
    return this.models.findIndex(({uuid}) => uuid === person.uuid);
  }

  setContactLanguage(contactLanguage: ContactLanguage) {
    this.contactLanguage = contactLanguage;
  }

  setPaymentMethod(paymentMethod: PaymentMethod) {
    this.paymentMethod = paymentMethod;
  }

  setPaymentUseMethodForCostSharing(usePaymentMethodForCostSharing: boolean) {
    this.usePaymentMethodForCostSharing = usePaymentMethodForCostSharing;
  }

  setPaymentRhythm(paymentRhythm: PaymentRhythm) {
    this.paymentRhythm = paymentRhythm;
  }

  setIban(iban: string) {
    this.iban = iban;
  }

  setInsuranceNumber(insuranceNumber: string) {
    this.insuranceNumber = insuranceNumber;
  }

  setModels(models: Person[]) {
    this.models = models;
    this.setCurrentIndex(0);
  }

  saveToSession() {
    const data = this.getInsuranceData();
    localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
  }

  loadFromSession(): boolean {
    const storedData = localStorage.getItem(STORAGE_KEY);

    if (storedData) {
      const data: IApiInsurance = JSON.parse(storedData);
      const models = data.people.map((p) => {
        const person = Person.fromJSON(p);

        if (person.type === PersonType.POLICY_HOLDER) {
          this.setCity(this.rootStore.cityStore.getByUrn(p.city));
        }

        if (p.baseInsurance?.baseInsuranceRate) {
          const rate = this.rootStore.baseInsuranceRateStore.getByUrn(p.baseInsurance.baseInsuranceRate);
          const franchiseGroup = this.rootStore.franchiseGroupStore.getById(rate?.franchiseGroupId ?? 1);
          const baseInsurance = BaseInsurance.fromJSON(p.baseInsurance, rate, franchiseGroup);
          if (rate) {
            baseInsurance.setRate(rate);
            person.setBaseInsurance(baseInsurance);
          }
        }

        if (p.addonInsurances) {
          person.setAddonInsurances({});
          Object.values(p.addonInsurances).forEach((addonInsuranceData) => {
            const rate = this.rootStore.addonInsuranceRateStore.getByUrn(addonInsuranceData.addonInsuranceRate);
            const addonInsurance = AddonInsurance.fromJSON(addonInsuranceData, rate);

            if (rate) {
              addonInsurance.setRate(rate);
              person.addAddonInsurance(addonInsurance);
            }
          });
        }

        return person;
      });

      const validModels = models.filter((p) => p.baseInsurance || p.addonInsurances);

      if (validModels.length) {
        this.setModels(validModels);
      }

      return validModels.length > 0;
    }

    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  async loadByReference(gc: string, cc: string): Promise<void> {
    await this.rootStore.doctorStore.loadAll();
    await this.rootStore.previousInsurerStore.loadAll();

    try {
      const insuranceCase = await Api.getCase(gc, cc);

      if (insuranceCase?.comparisId && insuranceCase.insurances?.length) {
        this.comparisId = insuranceCase.comparisId || '';
        this.caseId = gc;
        this.caseCc = cc;
        const [insurance] = insuranceCase.insurances;
        this.setCity(this.rootStore.cityStore.getByUrn(insurance.people[0].city));
        this.setEmail(insurance.people[0].email);
        this.setPhone(insurance.people[0].phone);
        this.setAddress(insurance.people[0].address1);
        this.setInsuranceNumber(insurance.people[0].insuranceNo || '');
        // this.setContactLanguage(insuranceCase.contact.contactLanguage); // TODO: enable when language ready
        const models: Person[] = [];

        insurance.people.forEach((p: IApiPerson) => {
          const person = Person.fromJSON(p);

          if (p.baseInsurance) {
            const baseRate = this.rootStore.baseInsuranceRateStore.getByUrn(p.baseInsurance.baseInsuranceRate);
            const franchiseGroup = this.rootStore.franchiseGroupStore.getById(baseRate?.franchiseGroupId ?? 1);
            const baseInsurance = BaseInsurance.fromJSON(p.baseInsurance, baseRate, franchiseGroup);

            if (p.doctor) {
              person.setDoctor(this.rootStore.doctorStore.getByUrn(p.doctor));
            }

            if (p.baseInsurance.insurer) {
              person.setPreviousInsurer(this.rootStore.previousInsurerStore.getByUrn(p.baseInsurance.insurer));
            }

            person.setBaseInsurance(baseInsurance);
          }

          if (p.addonInsurances) {
            const addonInsurances: AddonInsuranceCollection = {};

            p.addonInsurances.forEach((apiAddonInsurance) => {
              const rate = this.rootStore.addonInsuranceRateStore.getByUrn(apiAddonInsurance.addonInsuranceRate);
              if (rate) {
                const addonInsurance = new AddonInsurance();
                addonInsurance.setRate(rate);
                const type = AddonInsurance.getNumberAsType(rate.addonInsuranceTypeId);
                addonInsurances[type] = addonInsurance;
              }
            });
            person.setAddonInsurances(addonInsurances);
          }

          models.push(person);
        });
        this.setModels(models);
        this.setPolicyholder(models[0].uuid);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('failed to load case', e);
    }
  }

  reset(): void {
    this.models = [Person.create({type: PersonType.POLICY_HOLDER})];
    this.currentIndex = 0;
    this.city = undefined;
    this.address = undefined;
    this.email = undefined;
    this.phone = undefined;
    this.contactLanguage = undefined;
    this.paymentMethod = undefined;
    this.usePaymentMethodForCostSharing = undefined;
    this.paymentRhythm = undefined;
    this.iban = undefined;
    this.insuranceNumber = undefined;
    localStorage.removeItem(STORAGE_KEY);
  }

  convertPersonToIPersonRequest(person: Person, index: number, includeUUID: boolean): IApiPerson {
    const firstName = person.firstName?.length
      ? person.firstName
      : i18n.t('personCard.namePlaceholder', {number: index + 1});
    return {
      uuid: includeUUID ? person.uuid : undefined,
      firstName,
      lastName: !includeUUID ? person.lastName || '' : '',
      address1: !includeUUID ? this.address || '' : '',
      address2: '',
      city: this.city ? `/api/cities/${this.city.id}` : '',
      phone: !includeUUID ? this.phone || '' : '',
      mobile: !includeUUID ? person.mobile || '' : '',
      email: !includeUUID ? (person.isEmailRequired ? person.email : this.email) || '' : '',
      birthday: person.birthday
        ? dayjs(person.birthday).format(Config.apiDateFormat)
        : dayjs(person.insuranceStart).endOf('year').format(Config.apiDateFormat),
      country: person.residenceInSwitzerland ? 'CH' : '',
      sex: person.sex || Sex.MALE,
      language: this.contactLanguage || ContactLanguage.DE,
      insuranceNo: '',
      type: person.type,
      doctor: person.doctor?.id ? `/api/doctors/${person.doctor?.id}` : undefined,
      premiumReductionBaseInsurance: this.getPremiumReductionBaseInsurance(person),
      premiumReductionAddonInsurances: this.getPremiumReductionAddonInsurances(person),
      baseInsurance: person.hasBaseInsurance ? {
        baseInsuranceRate: `/api/base-insurance-rates/${person.baseInsurance?.rate?.id}`,
        start: dayjs(person.insuranceStart).format(Config.apiDateFormat),
        accidentCoverage: person?.baseInsurance?.withAccident || false,
        insurer: person.previousInsurer ? `/api/insurers/${person.previousInsurer?.id}` : undefined,
        hasPreviousInsurer: person.previousInsurer ? true : undefined,
      } : undefined,
      addonInsurances: person.hasAddonInsurances
        ? (Object.values(person.addonInsurances ?? []) as AddonInsurance[]).map(
          (addonInsurance) =>
            ({
              addonInsuranceRate: `/api/addon-insurance-rates/${addonInsurance.rate?.id}`,
              start: dayjs(person.insuranceStart).format(Config.apiDateFormat),
              insurer: person.previousInsurer ? `/api/insurers/${person.previousInsurer?.id}` : undefined,
            })
        )
        : undefined,
      moveInFromAbroad: person.moveInFromAbroad,
      dateOfArrival: person.moveInFromAbroad ? dayjs(person.dateOfArrival).format(Config.apiDateFormat) : null,
      childPremiumReduction: false,
    };
  }

  getInsuranceData(): IApiInsurance {
    return {
      people: this.models.map((person, index) => this.convertPersonToIPersonRequest(person, index, true)),
    };
  }

  getIssueRequest(): IIssue {
    const contractType = this.comparisId ? Config.issueTypeComparisContract : Config.issueTypeContract;
    const requestType = this.comparisId ? Config.issueTypeComparisRequest : Config.issueTypeRequest;
    let start = this.policyHolder?.insuranceStart;
    this.models.forEach((person) => {
      if (person.insuranceStart.valueOf() < start?.valueOf()) {
        start = person.insuranceStart;
      }
    });
    return {
      issueType: this.canSubmitApplication ? contractType : requestType,
      comparisId: this.comparisId || undefined,
      caseId: this.caseId || undefined,
      caseCc: this.caseCc || undefined,
      sex: this.policyHolder?.sex || null,
      firstName: this.policyHolder?.firstName || '',
      lastName: this.policyHolder?.lastName || '',
      email: this.email || '',
      insurances: [{
        start: start ? dayjs(start).format(Config.apiDateFormat) : undefined,
        paymentRhythm: this.paymentRhythm,
        paymentMethodPremium: this.paymentMethod,
        paymentMethodCostSharing: this.usePaymentMethodForCostSharing ? this.paymentMethod : PaymentMethod.DEPOSIT,
        iban: this.iban || '',
        people: this.models.map((person, index) => this.convertPersonToIPersonRequest(person, index, false)),
      }],
    };
  }

  getNewbornRequest(): INewBornRequest {
    return {
      sex: this.sex || Sex.MALE,
      issueType: Config.issueTypeNewBorn,
      firstName: this.firstName || '',
      lastName: this.lastName || '',
      address: this.address || '',
      city: this.city ? this.city.name : '',
      postcode: this.city?.postcode || 0,
      phone: this.phone || '',
      email: this.email || '',
      insuranceNo: this.insuranceNumber || '',
      formData: {
        offer: true,
        estimatedDateOfBirth: dayjs(this.newBornEstimatedBirthDate).format('YYYY-MM-DD'),
        message: '',
      },
    };
  }

  setNewbornEstimatedBirthDate(date: Dayjs): void {
    this.newBornEstimatedBirthDate = date;
  }

  setFirstName(firstName: string): void {
    this.firstName = firstName;
  }

  setLastName(lastName: string): void {
    this.lastName = lastName;
  }

  setSex(sex: Sex): void {
    this.sex = sex;
  }

  get canSubmitApplication(): boolean {
    return !this.models.some((p) => p.hasAddonInsurances);
  }

  get hasBaseInsurance(): boolean {
    return this.models.some((p) => p.hasBaseInsurance);
  }

  get parentHasAddonInsurances(): boolean {
    return this.models.some((p) => p.hasAddonInsurances && !p.isChild);
  }

  get children(): Person[] {
    return this.models.slice().sort((a, b) => sortNumerically(a?.birthday?.valueOf() || 0, b?.birthday?.valueOf() || 0))
      .filter((p) => p.isChild);
  }

  getPremiumReductionBaseInsurance(person: Person): boolean {
    return this.children.indexOf(person) >= 2;
  }

  getPremiumReductionAddonInsurances(person: Person): boolean {
    return this.children.indexOf(person) >= 2 && this.parentHasAddonInsurances;
  }
}
