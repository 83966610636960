import React from 'react';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import Card from '../../../../components/shared/Card';
import {Col, Row} from '../../../../components/layout/Grid';
import Text from '../../../../components/shared/Text';
import Icon from '../../../../components/shared/Icon';
import {CasamedIcon} from '../../../../components/shared/icons';

const CancellationTypeOverviewCard = () => {
  const {t} = useTranslation();

  return (
    <Card>
      <Row wrap={false}>
        <Col flex={'auto'}>
          <Text header={2}>
            {t('cancellation.wizard.overview.productCard.title')}
          </Text>
          <Text size={'extra-small'} color={'turquoise'} strong opacity={0.6}>
            {t('cancellation.one')}
          </Text>
        </Col>
        <Col flex={'50px'} className={'product-icon'}>
          <Icon component={CasamedIcon} size={40}/>
        </Col>
      </Row>
    </Card>
  );
};

export default observer(CancellationTypeOverviewCard);
