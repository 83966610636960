import {useTranslation} from 'react-i18next';
import {FC} from 'react';
import Container from '../../../components/layout/Container';
import Text from '../../../components/shared/Text';
import {Col, Row} from '../../../components/layout/Grid';
import FranchisePersonForm from './_components/FranchisePersonForm';
import InfoBox from '../../../components/shared/InfoBox';
import {Config} from '../../../config';

export const FranchiseChange: FC = () => {
  const {t} = useTranslation();

  if (Config.franchiseChangeServiceDisabled) {
    return (
      <Container background={'white'} flex={1}>
        <Text header={1}>{t('franchiseChange.title')}</Text>
        <InfoBox title={t('franchiseChange.notAvailable.title')}>
          <Text size={'intro'}>{t('franchiseChange.notAvailable.text')}</Text>
        </InfoBox>
      </Container>
    );
  }

  return (
    <>
      <Container background={'white'}>
        <Text header={1}>{t('franchiseChange.title')}</Text>
        <Text size={'intro'}>{t('franchiseChange.text')}</Text>
      </Container>
      <Container background={'light'} flex={1}>
        <Row>
          <Col xs={24} sm={24} md={24} lg={12} xl={8}>
            <FranchisePersonForm/>
          </Col>
        </Row>
      </Container>
    </>
  );
};
