import React from 'react';
import {useTranslation} from 'react-i18next';
import Container from '../../../components/layout/Container';
import Text from '../../../components/shared/Text';
import {Col, Row} from '../../../components/layout/Grid';
import {GUTTER_SIZE} from '../../../config';
import SideCard from './_components/SideCard';
import PersonForm from './_components/PersonForm';

const Person = () => {
  const {t} = useTranslation();

  return (
    <>
      <Container background={'white'}>
        <Text header={1}>{t('cancellation.leave.person.title')}</Text>
        <Text size={'intro'}>{t('cancellation.leave.person.text')}</Text>
      </Container>
      <Container background={'light'} flex={1}>
        <Row gutter={[GUTTER_SIZE, GUTTER_SIZE]}>
          <Col xs={24} sm={24} md={24} lg={16} xl={16}>
            <PersonForm/>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <SideCard/>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Person;
