import {useTranslation} from 'react-i18next';
import {Layout} from 'antd';
import dayjs from 'dayjs';
import Container from './Container';
import Text from '../shared/Text';
import {Col, Row} from './Grid';
import {Config, GUTTER_SIZE} from '../../config';
import Space from '../shared/Space';
import {SqsGoodPrivacyIcon, SqsIso1991Icon, SsVdszIcon} from '../shared/icons';
import Icon from '../shared/Icon';
import useBreakpoint from '../../hooks/useBreakpoint';
import Button from '../shared/Button';
import {useStore} from '../../hooks/useStore';
import './Footer.scss';
import {APP_VERSION} from '../../version';

const Footer = () => {
  const {t} = useTranslation();
  const {isMobile} = useBreakpoint();
  const store = useStore();

  const sendMail = () => {
    const a = document.createElement('a');
    const debugData = {
      location: window.location.href,
      userAgent: navigator.userAgent,
      calculatorStore: store.calculatorStore.getIssueRequest(),
      franchiseChangeStore: store.franchiseChangeStore.getIssueRequest(),
    };
    const body = '(Platz für Text / Screenshots)\n\n\n\n'
      + '--------------------------------------------------\n'
      + '[Debug-Informationen bitte nicht entfernen]\n\n'
      + `Version: ${APP_VERSION}\n`
      + `URL: ${window.location.href}\n`
      + `${btoa(JSON.stringify(debugData, null, '  '))}`;
    a.href = `mailto:support@semabit.ch?body=${encodeURI(body)}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  // @ts-ignore
  const cookieSettings = () => klaro?.show();

  return (
    <Layout.Footer className={'footer print-hidden'}>
      <Container background={'dark'}>
        <Space fullWidth direction={'vertical'} size={'large'}>
          <Text color={'turquoise'} size={'large'}>
            {t('footer.needHelp')}
          </Text>
          <Row gutter={[GUTTER_SIZE, GUTTER_SIZE * 2]}>
            <Col xs={24} md={12}>
              <Space direction={'vertical'} size={'middle'}>
                <Text medium>{t('footer.contact')}</Text>
                <Text>
                  {t('footer.byPhone')}
                  {': '}
                  <a href={`tel:${Config.contact.phoneLink}`}>
                    {Config.contact.phone}
                  </a>
                </Text>
                <Text>
                  {Config.contact.openingHours}
                </Text>
                <Text>
                  <br/>
                  {t('footer.byMail')}
                  {': '}
                  <a href={`mail:${Config.contact.email}`}>
                    {Config.contact.email}
                  </a>
                </Text>
              </Space>
            </Col>
            <Col xs={24} md={12}>
              <Space direction={'vertical'} size={'large'}>
                <Space direction={'vertical'} size={'middle'}>
                  <Text medium>{t('footer.legal')}</Text>
                  <Space wrap size={'large'}>
                    <a href={'https://www.aquilana.ch/impressum'}>{t('footer.imprint')}</a>
                    <a href={'https://www.aquilana.ch/rechtliche-hinweise'}>{t('footer.legalNotes')}</a>
                    <a href={'https://www.aquilana.ch/datenschutz'}>{t('footer.privacy')}</a>
                    <Button type={'link'} onClick={cookieSettings}>{t('footer.cookieSettings')}</Button>
                  </Space>
                </Space>
                <Space wrap size={'large'}>
                  <Icon component={SqsGoodPrivacyIcon} size={isMobile ? 80 : 100}/>
                  <Icon component={SqsIso1991Icon} size={isMobile ? 80 : 100}/>
                  <Icon component={SsVdszIcon} size={isMobile ? 80 : 100}/>
                </Space>
              </Space>
            </Col>
          </Row>
          <div className={'copy'}>
            {t('footer.copyright', {year: dayjs().year()})}
            {Config.debug > 0 && (
              <Button
                type={'link'}
                style={{marginLeft: 10, opacity: Config.debug === 2 ? 1 : 0, color: 'red'}}
                onClick={sendMail}
              >
                Fehler / Hinweis
              </Button>
            )}
          </div>
        </Space>
      </Container>
    </Layout.Footer>
  );
};

export default Footer;
