/* eslint-disable import/no-cycle */
import {action, computed, makeObservable, observable} from 'mobx';
import {v4 as uuidv4} from 'uuid';
import dayjs, {Dayjs} from 'dayjs';
import {IBaseModel} from './base-model';
import {BaseInsurance, BaseInsuranceType} from './base-insurance';
import {AddonInsurance, AddonInsuranceType} from './addon-insurance';
import {PreviousInsurer} from './previous-insurer';
import {Doctor} from './doctor';
import {IApiPerson, PersonType} from './document-request';
import {
  ADDON_INSURANCE_PREMIUM_REDUCTION_FACTOR, BASE_INSURANCE_PREMIUM_REDUCTION_FACTOR
} from '../stores/calculator-store';
import {Config} from '../config';

export interface AddonInsuranceCollection {
  [type: string]: AddonInsurance;
}

// t('sex.f')
// t('sex.m')
export enum Sex {
  FEMALE = 'f',
  MALE = 'm'
}

// t('lang.de')
// t('lang.fr')
// t('lang.it')
// t('lang.en')
export enum ContactLanguage {
  DE = 'DE',
  FR = 'FR',
  IT = 'IT',
  EN = 'EN'
}

// t('paymentMethod.debit-direct')
// t('paymentMethod.debit-direct-post')
// t('paymentMethod.e-bill')
// t('paymentMethod.deposit')
export enum PaymentMethod {
  DIRECT_DEBIT = 'debit-direct',
  DIRECT_DEBIT_POST = 'debit-direct-post',
  E_BILL = 'e-bill',
  DEPOSIT = 'deposit'
}

// t('paymentRhythm.monthly')
// t('paymentRhythm.two-monthly')
// t('paymentRhythm.quarterly')
// t('paymentRhythm.semiannually')
// t('paymentRhythm.annually')
export enum PaymentRhythm {
  MONTHLY = 'monthly',
  TWO_MONTHLY = 'two-monthly',
  QUARTERLY = 'quarterly',
  SEMIANNUALLY = 'semiannually',
  ANNUALLY = 'annually'
}

export enum TravelInsurancePersonType {
  POLICY_HOLDER = 'policy-holder',
  PARTNER = 'partner',
  CHILD = 'child'
}

export interface IPerson extends IBaseModel {
  uuid: string;
  sex?: Sex;
  lastName?: string;
  firstName?: string;
  birthday?: Dayjs;
  baseInsurance?: BaseInsurance;
  addonInsurances?: AddonInsuranceCollection;
  residenceInSwitzerland: boolean;
  moveInFromAbroad: boolean;
  previousInsurer?: PreviousInsurer;
  dateOfArrival?: Dayjs;
  doctor?: Doctor;
  insuranceStart: Dayjs;
  email?: string;
  mobile?: string;
  type: PersonType;
  insuranceNo?: string;
}

const AGE_ADULT = 19;
const AGE_SENIOR = 65;

export class Person implements IPerson {
  uuid = '';
  sex?: Sex = undefined;
  lastName?: string = undefined;
  firstName?: string = undefined;
  birthday?: Dayjs = undefined;
  baseInsurance?: BaseInsurance = undefined;
  addonInsurances?: AddonInsuranceCollection = undefined;
  residenceInSwitzerland = true;
  moveInFromAbroad = false;
  previousInsurer?: PreviousInsurer = undefined;
  dateOfArrival?: Dayjs = undefined;
  doctor?: Doctor = undefined;
  insuranceStart: Dayjs = Person.defaultInsuranceStart();
  email?: string = undefined;
  mobile?: string = undefined;
  type: PersonType = PersonType.DEFAULT;
  insuranceNo?: string = undefined;
  travelInsurancePersonType?: TravelInsurancePersonType = undefined;

  constructor() {
    makeObservable(this, {
      uuid: observable,
      sex: observable,
      lastName: observable,
      firstName: observable,
      birthday: observable,
      baseInsurance: observable,
      addonInsurances: observable,
      residenceInSwitzerland: observable,
      moveInFromAbroad: observable,
      previousInsurer: observable,
      dateOfArrival: observable,
      doctor: observable,
      insuranceStart: observable,
      email: observable,
      mobile: observable,
      insuranceNo: observable,
      type: observable,
      travelInsurancePersonType: observable,

      createId: action,
      setBaseInsurance: action,
      setAddonInsurances: action,
      setSex: action,
      setFirstName: action,
      setLastName: action,
      setBirthday: action,
      setResidenceInSwitzerland: action,
      setMoveInFromAbroad: action,
      setPreviousInsurer: action,
      setDateOfArrival: action,
      addAddonInsurance: action,
      removeAddonInsurance: action,
      setDoctor: action,
      setInsuranceStart: action,
      setEmail: action,
      setMobile: action,
      setInsuranceNo: action,
      setTravelInsurancePersonType: action,

      fullName: computed,
      age: computed,
      isNewBorn: computed,
      isChild: computed,
      isSenior: computed,
      hasAddonInsurances: computed,
      hasBaseInsurance: computed,
      isAddonInsuranceSelectionValid: computed,
      isEmailRequired: computed,
      isMobileRequired: computed,
      isPolicyHolder: computed,
    });
  }

  createId() {
    this.uuid = uuidv4();
  }

  setBaseInsurance(insurance: BaseInsurance | undefined) {
    this.baseInsurance = insurance;
  }

  setAddonInsurances(insurance: AddonInsuranceCollection | undefined) {
    this.addonInsurances = insurance;
  }

  setSex(sex: Sex) {
    this.sex = sex;
  }

  setFirstName(firstName: string) {
    this.firstName = firstName;
  }

  setLastName(lastName: string) {
    this.lastName = lastName;
  }

  setBirthday(birthday: Dayjs | undefined) {
    this.birthday = birthday;
  }

  setResidenceInSwitzerland(residenceInSwitzerland: boolean) {
    this.residenceInSwitzerland = residenceInSwitzerland;
  }

  setMoveInFromAbroad(moveInFromAbroad: boolean) {
    this.moveInFromAbroad = moveInFromAbroad;
  }

  setPreviousInsurer(previousInsurer: PreviousInsurer | undefined) {
    this.previousInsurer = previousInsurer;
  }

  setDateOfArrival(dateOfArrival: Dayjs | undefined) {
    this.dateOfArrival = dateOfArrival;
  }

  setInsuranceStart(insuranceStart: Dayjs) {
    this.insuranceStart = insuranceStart;
  }

  setDoctor(doctor: Doctor | undefined) {
    this.doctor = doctor;
  }

  setEmail(email: string | undefined) {
    this.email = email;
  }

  setMobile(mobile: string | undefined) {
    this.mobile = mobile;
  }

  setInsuranceNo(insuranceNo: string | undefined) {
    this.insuranceNo = insuranceNo;
  }

  setTravelInsurancePersonType(travelInsurancePersonType: TravelInsurancePersonType | undefined) {
    this.travelInsurancePersonType = travelInsurancePersonType;
  }

  get fullName(): string | undefined {
    return this.firstName && this.lastName ? `${this.firstName} ${this.lastName}` : undefined;
  }

  get age(): number {
    if (!this.birthday) {
      return 0;
    }
    return Math.max(0, this.insuranceStart.year() - this.birthday.year());
  }

  get isPolicyHolder(): boolean {
    return this.type === PersonType.POLICY_HOLDER;
  }

  getAgeForAccidentDeathDisability(): number {
    const ageInMonths = dayjs(this.insuranceStart).diff(this.birthday, 'months', true);

    if (ageInMonths <= 30) {
      return 2;
    }

    if (this.age < 4) {
      return 4;
    }

    return this.age;
  }

  get isNewBorn(): boolean {
    return !this.birthday || dayjs().isBefore(dayjs(this.birthday));
  }

  get isChild(): boolean {
    return this.age < AGE_ADULT;
  }

  get isSenior(): boolean {
    return this.age > AGE_SENIOR;
  }

  get hasAddonInsurances(): boolean {
    return this.addonInsurances !== undefined && Object.keys(this.addonInsurances).length > 0;
  }

  get hasBaseInsurance(): boolean {
    return this.baseInsurance !== undefined;
  }

  get isAddonInsuranceSelectionValid(): boolean {
    const addons = this.addonInsurances ? Object.keys(this.addonInsurances) : [];
    const addonsWithoutTopPlus = addons.filter(
      (addon) => addon !== AddonInsuranceType.PATIENT_CARE_TOP && addon !== AddonInsuranceType.PATIENT_CARE_PLUS
    );
    return this.baseInsurance !== undefined || addonsWithoutTopPlus.length > 0 || addons.length === 0;
  }

  get isEmailRequired(): boolean {
    return this.baseInsurance?.type === BaseInsuranceType.SMARTMED;
  }

  get isMobileRequired(): boolean {
    return this.baseInsurance?.type === BaseInsuranceType.SMARTMED;
  }

  total(baseInsuranceReduced: boolean, addonInsuranceReduced: boolean): number {
    const baseFactor = baseInsuranceReduced ? BASE_INSURANCE_PREMIUM_REDUCTION_FACTOR : 1;
    let total = this.baseInsurance?.getNetPrice(baseFactor) || 0;

    if (this.addonInsurances) {
      Object.values(this.addonInsurances).forEach((addonInsurance) => {
        const addonFactor = addonInsuranceReduced && addonInsurance.type
        !== AddonInsuranceType.ACCIDENT_DEATH_DISABILITY ? ADDON_INSURANCE_PREMIUM_REDUCTION_FACTOR : 1;
        total += addonInsurance.price * addonFactor;
      });
    }

    return total;
  }

  getAddonInsurance(type: AddonInsuranceType): AddonInsurance | undefined {
    return this.addonInsurances?.[type];
  }

  addAddonInsurance(addonInsurance: AddonInsurance): void {
    if (!this.addonInsurances) {
      this.setAddonInsurances({
        [addonInsurance.type]: addonInsurance,
      });
    } else {
      this.addonInsurances[addonInsurance.type] = addonInsurance;
    }
  }

  removeAddonInsurance(type: AddonInsuranceType): void {
    if (this.addonInsurances?.[type] !== undefined) {
      delete this.addonInsurances[type];
    }
  }

  static defaultInsuranceStart(): Dayjs {
    return (
      dayjs().isBefore(dayjs(`${dayjs().year()}-09-01`))
      || (dayjs().isAfter(dayjs(`${dayjs().year()}-09-01`)) && dayjs().year() + 1 !== Config.currentPriceYear)
        ? dayjs().startOf('month').add(1, 'month')
        : dayjs(`${dayjs().year() + 1}-01-01`)
    ).startOf('day');
  }

  static create(data?: Partial<IApiPerson>): Person {
    return Person.fromJSON({
      uuid: uuidv4(),
      ...data,
    });
  }

  static fromJSON(data?: Partial<IApiPerson>) {
    const person = new Person();

    if (data?.uuid !== undefined) {
      person.uuid = data.uuid;
    } else {
      person.uuid = uuidv4();
    }
    if (data?.sex !== undefined) {
      person.sex = data.sex;
    }
    if (data?.lastName !== undefined) {
      person.lastName = data.lastName;
    }
    if (data?.firstName !== undefined) {
      person.firstName = data.firstName;
    }
    if (data?.birthday !== undefined) {
      person.birthday = dayjs(data.birthday);
      person.birthday.set('hours', 0).set('minutes', 0).set('seconds', 0).set('milliseconds', 0);
    }
    if (data?.baseInsurance?.start !== undefined) {
      person.insuranceStart = dayjs(data.baseInsurance.start);
      person.insuranceStart.set('hours', 0).set('minutes', 0).set('seconds', 0).set('milliseconds', 0);
    } else {
      person.insuranceStart = Person.defaultInsuranceStart();
    }
    if (data?.email !== undefined) {
      person.email = data.email;
    }
    if (data?.mobile !== undefined) {
      person.mobile = data.mobile;
    }
    if (data?.type !== undefined) {
      person.type = data.type;
    }

    return person;
  }
}
