import {Space as AntSpace, SpaceProps as AntSpaceProps} from 'antd';
import {FC} from 'react';

interface SpaceProps extends AntSpaceProps {
  fullWidth?: boolean;
}

const Space: FC<SpaceProps> = ({fullWidth, style, children, ...props}) => (
  <AntSpace style={fullWidth ? {...style, width: '100%'} : style} {...props}>{children}</AntSpace>
);

export default Space;
