import {RuleObject} from 'antd/lib/form';
import i18n from '../i18n/i18n';

const email: RuleObject = {type: 'email', message: i18n.t('messages.email.notValid')};

const url: RuleObject = {type: 'url', message: i18n.t('messages.url.notValid')};

const requiredIf = (required: boolean, message?: string): RuleObject => ({
  required,
  message: message || i18n.t('messages.input.required'),
});

const domain = (): RuleObject => ({
  validator: (rule: RuleObject, value: any) => {
    const regex = '^(http:\\/\\/|https:\\/\\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$';
    if (value === '' || value.match(regex)) {
      return Promise.resolve();
    }
    return Promise.reject(i18n.t('messages.domain.notValid'));
  },
});

const checkbox = (message?: string): RuleObject => ({
  validator: (rule: RuleObject, value: any) => {
    if (value) {
      return Promise.resolve();
    }
    return Promise.reject(message || i18n.t('messages.checkbox.required'));
  },
});

const requireYes = (message?: string): RuleObject => ({
  validator: (rule: RuleObject, value: any) => {
    if (value === 'yes') {
      return Promise.resolve();
    }
    return Promise.reject(message || i18n.t('messages.checkbox.required'));
  },
});

const maxSelectCount = (maxLength: number): RuleObject => ({
  type: 'array',
  validator: (rule: RuleObject, value: any) => {
    if (value.length <= maxLength) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(i18n.t('messages.select.maxLengthOrLessMustBeSelected', {maxLength})));
  },
});

const iban = (): RuleObject => ({
  validator: (rule: RuleObject, value: any = '') => {
    const ibanStripped = value.replace(/[^A-Z0-9]+/gi, '').toUpperCase();

    if (ibanStripped.length === 0) {
      return Promise.resolve();
    }

    const m = ibanStripped.match(/^(CH)([0-9]{2})([A-Z0-9]{9,30})$/);

    if (!m) {
      return Promise.reject(i18n.t('messages.iban.wrongFormat'));
    }

    const numeric = (m[3] + m[1] + m[2]).replace(/[A-Z]/g, (ch: string) => ch.charCodeAt(0) - 55);
    const mod97 = numeric.match(/\d{1,7}/g)
      .reduce((total: string, curr: string) => Number(total + curr) % 97, '');

    if (mod97 === 1) {
      return Promise.resolve();
    }

    return Promise.reject(i18n.t('messages.iban.notValid'));
  },
});

const insuranceNumber = (): RuleObject => ({
  validator: (rule: RuleObject, value: any) => {
    if (!value || value === '' || value.match(/^([0-9])([.])([0-9]{3})([.])([0-9]{3})$/)) {
      return Promise.resolve();
    }
    return Promise.reject(i18n.t('messages.insuranceNumber.notValid'));
  },
});

const noSpecialCharacters = (): RuleObject => ({
  validator: (rule: RuleObject, value: any) => {
    if (!value || value === '' || value.match(/^[^!#?"^+<>{}[\],./\\()]*$/)) {
      return Promise.resolve();
    }
    return Promise.reject(i18n.t('messages.string.noSpecialCharacters', {characters: '^!#?"^+<>{}[],./\\()'}));
  },
});

const phone = (): RuleObject => ({
  validator: (rule: RuleObject, value: any) => {
    if (!value || value === '' || value.match(/^[0-9+ ]*$/)) {
      return Promise.resolve();
    }
    return Promise.reject(i18n.t('messages.phone.notValid'));
  },
});

export const Validators = {
  email,
  url,
  domain,
  required: (message?: string) => requiredIf(true, message),
  requiredIf,
  requireYes,
  checkbox,
  maxSelectCount,
  iban,
  insuranceNumber,
  noSpecialCharacters,
  phone,
};
