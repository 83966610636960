import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import Container from '../../../components/layout/Container';
import Text from '../../../components/shared/Text';
import TravelInsurancePersonalDataForm from './_components/TravelInsurancePersonalDataForm';
import {useStore} from '../../../hooks/useStore';

const TravelInsurancePersonalData = () => {
  const store = useStore();
  const {t} = useTranslation();

  useEffect(() => {
    if (!store.previousInsurerStore.models.length && !store.previousInsurerStore.isLoading) {
      store.previousInsurerStore.loadAll();
    }
  }, []);

  return (
    <>
      <Container background={'white'}>
        <Text header={1}>{t('travelInsurance.personalData.title')}</Text>
        <Text size={'intro'}>{t('travelInsurance.personalData.header')}</Text>
      </Container>
      <TravelInsurancePersonalDataForm/>
    </>
  );
};

export default observer(TravelInsurancePersonalData);
