import React from 'react';
import {useTranslation} from 'react-i18next';
import Container from '../../../components/layout/Container';
import Text from '../../../components/shared/Text';
import PersonForm from './_components/PersonForm';

const Person = () => {
  const {t} = useTranslation();

  return (
    <>
      <Container background={'white'} flex={1}>
        <Text header={1}>{t('cancellation.wizard.person.title')}</Text>
        <Text size={'intro'}>{t('cancellation.wizard.person.text')}</Text>
      </Container>
      <Container background={'light'} flex={1}>
        <PersonForm/>
      </Container>
    </>
  );
};

export default Person;
