import {Table as AntTable, TableProps as AntTableProps} from 'antd';
import {PropsWithChildren} from 'react';

import './Table.scss';

export interface TableProps<RecordType> extends PropsWithChildren<AntTableProps<RecordType>> {
  striped?: boolean;
  activeClass?: string;
  activeIndex?: number;
  rowClassName?: (record: RecordType, index: number) => string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Table = <RecordType extends object = any>({
  rowKey,
  className,
  striped,
  activeClass = 'ant-table-row-selected',
  activeIndex,
  rowClassName,
  ...props
}: TableProps<RecordType>) => {
  const classNames = [];
  if (className) {
    classNames.push(className);
  }
  if (striped) {
    classNames.push('striped');
  }

  const rowClassNameValue = (record: RecordType, index: number) => {
    const evaluated = rowClassName ? rowClassName(record, index) : '';
    const isEven = index % 2 === 0;
    const isActiveRow = activeIndex === index;

    if (isEven && isActiveRow) {
      return `striped-even ${activeClass ?? ''} ${evaluated}`;
    } if (isEven && !isActiveRow) {
      return `striped-even ${evaluated}`;
    } if (!isEven && isActiveRow) {
      return `striped-odd ${activeClass ?? ''} ${evaluated}`;
    } if (!isEven && !isActiveRow) {
      return `striped-odd ${evaluated}`;
    }

    return '';
  };

  return (
    <AntTable<RecordType>
      scroll={{x: 'max-content'}}
      className={classNames.join(' ')}
      rowClassName={striped ? rowClassNameValue : ''}
      pagination={false}
      {...props}
    />
  );
};
