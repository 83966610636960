import {DatePicker, Form, Input, Radio} from 'antd';
import {useTranslation} from 'react-i18next';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import dayjs from 'dayjs';
import Container from '../../../../components/layout/Container';
import {Col, Row} from '../../../../components/layout/Grid';
import Text from '../../../../components/shared/Text';
import {PreviousInsurer} from '../../../../models/previous-insurer';
import {Sex} from '../../../../models/person';
import {City} from '../../../../models/city';
import Checkbox from '../../../../components/shared/Checkbox';
import {Validators} from '../../../../utils/validators';
import {useStore} from '../../../../hooks/useStore';
import useCitySelection from '../../../../hooks/useCitySelection';
import {Config, GUTTER_SIZE} from '../../../../config';
import StepsNavigationButtons from '../../../../components/StepsNavigationButtons';
import {TravelInsurancePaths} from '../../../paths';
import {sortAlphabetically} from '../../../../utils/sorters';
import Select from '../../../../components/shared/Select';
import InfoBox from '../../../../components/shared/InfoBox';

interface FormValues {
  isInsurerAquilana: boolean;
  insurer?: PreviousInsurer['id'];
  sex: Sex;
  firstName: string;
  lastName: string;
  address: string;
  city: City['id'];
  birthDate: Date;
  email: string;
  phone: string;
}

const TravelInsurancePersonalDataForm = () => {
  const {t} = useTranslation();
  const store = useStore();
  const navigate = useNavigate();
  const {options} = useCitySelection();
  const [form] = Form.useForm<FormValues>();
  const {policyHolder} = store.travelInsuranceStore;
  const [showInsurerSelection, setShowInsurerSelection] = useState<boolean>(
    policyHolder?.previousInsurer?.id
      ? (policyHolder?.previousInsurer?.id
        !== store.previousInsurerStore.getByUrn(Config.aquilanaInsurer)?.id)
      : false
  );
  const insurerOptions = store.previousInsurerStore.models.filter((insurer) => !insurer.deletedAt)
    .map((insurer) => ({label: insurer.name, value: insurer.id}))
    .sort((a, b) => sortAlphabetically(a.label, b.label));

  const onSubmit = (values: FormValues) => {
    if (!policyHolder) {
      return;
    }

    policyHolder.setSex(values.sex);
    policyHolder.setFirstName(values.firstName);
    policyHolder.setLastName(values.lastName);
    policyHolder.setPreviousInsurer(
      values.insurer
        ? store.previousInsurerStore.getById(values.insurer)
        : store.previousInsurerStore.getByUrn(Config.aquilanaInsurer)
    );
    policyHolder.setBirthday(dayjs(values.birthDate));

    store.travelInsuranceStore.setAddress(values.address);
    store.travelInsuranceStore.setCity(store.cityStore.getById(values.city));
    store.travelInsuranceStore.setEmail(values.email);
    store.travelInsuranceStore.setPhone(values.phone);

    store.setEditMode(false);
    navigate(TravelInsurancePaths.overview());
  };

  const onValuesChange = (changedValues: Partial<FormValues>) => {
    if (changedValues.isInsurerAquilana !== undefined) {
      setShowInsurerSelection(!changedValues.isInsurerAquilana);
      form.setFieldsValue({insurer: undefined});
    }
  };

  const initialValues = {
    isInsurerAquilana: !showInsurerSelection,
    insurer: policyHolder?.previousInsurer?.id,
    sex: policyHolder?.sex,
    firstName: policyHolder?.firstName,
    lastName: policyHolder?.lastName,
    address: store.travelInsuranceStore.address,
    city: store.travelInsuranceStore.city?.id,
    birthDate: policyHolder?.birthday
      ? dayjs(policyHolder?.birthday) : undefined,
    email: store.travelInsuranceStore.email,
    phone: store.travelInsuranceStore.phone,
  };

  const onBack = () => {
    if (store.editMode) {
      navigate(TravelInsurancePaths.overview());
    } else {
      navigate(TravelInsurancePaths.productSelection());
    }
  };

  const onContinue = () => {
    form.submit();
  };

  return (
    <Form
      form={form}
      layout={'vertical'}
      colon={false}
      labelAlign={'left'}
      onFinish={onSubmit}
      onValuesChange={onValuesChange}
      initialValues={initialValues}
      requiredMark={false}
    >
      <Container background={'white'} flex={1}>
        <Row gutter={[GUTTER_SIZE, GUTTER_SIZE]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item>
              <Text header={4}>
                {t('travelInsurance.personalData.currentBaseInsurance.title')}
              </Text>
            </Form.Item>
            <Form.Item name={'isInsurerAquilana'} valuePropName={'checked'}>
              <Checkbox>{t('stepPersonDetails.currentBaseInsurance.isInsurerAquilana')}</Checkbox>
            </Form.Item>
            <Form.Item
              hidden={!showInsurerSelection}
              name={'insurer'}
              label={t('stepPersonDetails.currentBaseInsurance.insurer')}
              rules={[Validators.requiredIf(showInsurerSelection, t('validator.insurerRequired'))]}
            >
              <Select
                showSearch
                loading={store.previousInsurerStore.isLoading}
                options={insurerOptions}
                placeholder={t('person.selectInsurer')}
              />
            </Form.Item>
          </Col>
        </Row>
      </Container>
      <Container background={'light'} flex={1}>
        <Row gutter={[GUTTER_SIZE, GUTTER_SIZE]}>
          <Col xs={24} sm={24} md={24} lg={16} xl={16}>
            <Form.Item>
              <InfoBox title={t('travelInsurance.personalData.detailsInfo.title')}>
                {t('travelInsurance.personalData.detailsInfo.text')}
              </InfoBox>
            </Form.Item>
            <Form.Item>
              <Text header={4}>{t('travelInsurance.personalData.policyHolderInfo')}</Text>
            </Form.Item>
            <Form.Item
              name={'sex'}
              label={t('label.sex')}
              rules={[Validators.required(t('validator.salutationRequired'))]}
            >
              <Radio.Group>
                {Object.values(Sex).map((sex) => (
                  <Radio key={sex} value={sex}>{t(`sex.${sex}`)}</Radio>
                ))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name={'firstName'}
              label={t('label.firstName')}
              rules={[Validators.required(t('validator.firstNameRequired')), Validators.noSpecialCharacters]}
            >
              <Input placeholder={t('label.firstName')} maxLength={50}/>
            </Form.Item>
            <Form.Item
              name={'lastName'}
              label={t('label.name')}
              rules={[Validators.required(t('validator.nameRequired')), Validators.noSpecialCharacters]}
            >
              <Input placeholder={t('label.name')} maxLength={50}/>
            </Form.Item>
            <Form.Item
              name={'address'}
              label={t('label.address')}
              rules={[Validators.required(t('validator.addressRequired')), Validators.noSpecialCharacters]}
            >
              <Input placeholder={t('label.address')} maxLength={50}/>
            </Form.Item>
            <Form.Item
              name={'city'}
              label={t('label.postcodeCity')}
              rules={[Validators.required(t('validator.postcodeCityRequired'))]}
            >
              <Select
                showSearch
                loading={store.cityStore.isLoading}
                options={options}
                placeholder={t('label.postcodeCityPlaceholder')}
              />
            </Form.Item>
            <Form.Item
              name={'birthDate'}
              label={t('label.birthday')}
              rules={[Validators.required(t('validator.birthdayRequired')), {
                validator: (_, value) => {
                  if (value && dayjs(store.travelInsuranceStore.travelInsuranceStartDate)
                    .diff(dayjs(value), 'years') < 18) {
                    return Promise.reject(t('travelInsurance.hasToBeAdultAtStartDate'));
                  }
                  return Promise.resolve();
                },
              }]}
            >
              <DatePicker
                format={Config.dateFormat}
                placeholder={t('label.dateFormat')}
                disabledDate={(d) => d.isAfter(dayjs().endOf('day'))
                  || d.isBefore(dayjs().startOf('day').subtract(150, 'years'))}
              />
            </Form.Item>
            <Form.Item
              name={'email'}
              label={t('label.email')}
              rules={[Validators.required(t('validator.emailRequired')), Validators.email]}
            >
              <Input placeholder={t('label.email')} maxLength={255}/>
            </Form.Item>
            <Form.Item
              name={'phone'}
              label={t('label.phone')}
              rules={[Validators.required(t('validator.phoneRequired')), Validators.phone]}
            >
              <Input placeholder={t('label.phone')} maxLength={50}/>
            </Form.Item>
            <StepsNavigationButtons back={onBack} next={onContinue}/>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

export default observer(TravelInsurancePersonalDataForm);
