import React from 'react';
import {useTranslation} from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import {useStore} from '../../../hooks/useStore';
import {Config, GUTTER_SIZE} from '../../../config';
import Container from '../../../components/layout/Container';
import Space from '../../../components/shared/Space';
import {Box, BoxWrapper} from '../../../components/shared/Box';
import Icon from '../../../components/shared/Icon';
import {PoliceIcon} from '../../../components/shared/icons';
import InfoBox from '../../../components/shared/InfoBox';
import Button from '../../../components/shared/Button';
import Text from '../../../components/shared/Text';
import {Col, Row} from '../../../components/layout/Grid';

const Success = () => {
  const {t} = useTranslation();
  const store = useStore();

  const toWebsite = () => {
    window.location.href = Config.websiteUrl;
  };

  return (
    <>
      <Container background={'white'}>
        <Text header={1}>{t('cancellation.leave.success.title')}</Text>
      </Container>
      <Container background={'light'} flex={1}>
        <Row gutter={[GUTTER_SIZE, GUTTER_SIZE]}>
          <Col xs={24} sm={24} md={24} lg={16} xl={16}>
            <Space direction={'vertical'} size={'large'}>
              <BoxWrapper>
                <Box flex={'0 0 70px'}>
                  <Icon component={PoliceIcon} size={50} color={'gray-blue'}/>
                </Box>
                <Box flex={'auto'}>
                  <Text medium color={'gray-blue'}>
                    <ReactMarkdown linkTarget={'_blank'}>
                      {t('cancellation.leave.success.text', {email: store.cancellationLeaveStore.email})}
                    </ReactMarkdown>
                  </Text>
                </Box>
              </BoxWrapper>
              <InfoBox title={t('cancellation.leave.success.info.title')}>
                <ReactMarkdown linkTarget={'_blank'}>
                  {t('cancellation.leave.success.info.text', {contactUrl: Config.aquilanaContactUrl})}
                </ReactMarkdown>
              </InfoBox>
            </Space>
          </Col>
        </Row>
      </Container>
      <Container background={'light'} flex={1}>
        <Space direction={'horizontal'} fullWidth style={{justifyContent: 'end'}}>
          <Button onClick={() => toWebsite()} skin={'primary-gray-blue'}>
            {t('action.toWebsite')}
          </Button>
        </Space>
      </Container>
    </>
  );
};

export default Success;
