import {Col as AntCol, ColProps as AntColProps, Row as AntRow, RowProps as AntRowProps} from 'antd';
import {FC} from 'react';

interface RowProps extends AntRowProps {
}

export const Row: FC<RowProps> = ({children, ...props}) => (
  <AntRow {...props}>{children}</AntRow>
);

interface ColProps extends AntColProps {
}

export const Col: FC<ColProps> = ({children, ...props}) => (
  <AntCol {...props}>{children}</AntCol>
);
