import {ThemeConfig} from 'antd';

const fontSizeBase = 20;
export const theme: ThemeConfig = {
  token: {
    colorPrimary: '#375064',
    colorTextHeading: '#375064',
    colorInfo: '#6180BC',
    colorWarning: '#F0BC47',
    colorSuccess: '#488B60',
    colorError: '#D24345',
    colorText: '#000000',
    // eslint-disable-next-line max-len
    fontFamily: '"gt-walsheim", system-ui, -apple-system, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    lineHeight: 1.2,
    fontSize: fontSizeBase,
    fontSizeHeading1: Math.ceil(fontSizeBase * 2.6),
    fontSizeHeading2: Math.ceil(fontSizeBase * 1.6),
    fontSizeHeading3: Math.ceil(fontSizeBase * 1.4),
    fontSizeHeading4: Math.ceil(fontSizeBase * 1.2),
    fontSizeHeading5: Math.ceil(fontSizeBase * 1.1),
  },
  components: {
    Radio: {
      radioSize: 20,
      dotSize: 8,
    },
    Checkbox: {
      controlInteractiveSize: 20,
    },
  },
};
