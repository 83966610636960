import React from 'react';
import ReactMarkdown from 'react-markdown';
import {useTranslation} from 'react-i18next';
import Card from '../../../../components/shared/Card';
import Text from '../../../../components/shared/Text';
import './SideCard.scss';
import Space from '../../../../components/shared/Space';
import {AbroadSecureIcon, SuspensionIcon} from '../../../../components/shared/icons';
import Icon from '../../../../components/shared/Icon';
import {Config} from '../../../../config';

const SideCard = () => {
  const {t} = useTranslation();

  return (
    <Card className={'side-card'}>
      <div className={'card-header'}>
        <Text size={'regular'}>
          {t('cancellation.leave.person.sideCard.title')}
        </Text>
      </div>
      <div className={'card-content'}>
        <Space direction={'vertical'} size={'large'} align={'center'}>
          <Icon component={AbroadSecureIcon} size={80}/>
          <Text size={'regular'}>
            <ReactMarkdown linkTarget={'_blank'}>
              {t('cancellation.leave.person.sideCard.firstText', {aquilanaContactUrl: Config.aquilanaContactUrl})}
            </ReactMarkdown>
          </Text>
          <Icon component={SuspensionIcon} size={80}/>
          <Text size={'regular'}>
            <ReactMarkdown>
              {t('cancellation.leave.person.sideCard.secondText')}
            </ReactMarkdown>
          </Text>
        </Space>
      </div>
    </Card>
  );
};

export default SideCard;
