import {FC} from 'react';
import {Radio} from 'antd';
import {useTranslation} from 'react-i18next';
import {Doctor} from '../../../../models/doctor';
import Card from '../../../../components/shared/Card';
import {Col, Row} from '../../../../components/layout/Grid';
import Icon from '../../../../components/shared/Icon';
import {PoliceIcon} from '../../../../components/shared/icons';
import Text from '../../../../components/shared/Text';

interface DoctorCardProps {
  doctor: Doctor;
  selected: boolean;
  onSelectionChange: () => void;
}

const DoctorCard: FC<DoctorCardProps> = ({
  doctor,
  selected,
  onSelectionChange,
}) => {
  const onChange = () => onSelectionChange();
  const {t} = useTranslation();

  return (
    <Card className={'doctor-card text-small'} onClick={() => onSelectionChange()}>
      <Row align={'top'}>
        <Col flex={'auto'}>
          <Text>
            {doctor.title}
            <br/>
          </Text>
          <Text strong>
            {doctor.name}
            <br/>
          </Text>
          <Text>
            {doctor.address}
            <br/>
            {doctor.postcode}
            {' '}
            {doctor.city}
            <br/>
          </Text>
          <Text color={doctor.patientAdmissionStop ? 'light-red' : 'turquoise'}>
            <Icon component={PoliceIcon}/>
            {' '}
            {
              doctor.patientAdmissionStop
                ? t('doctorSelection.patientAdmissionStop')
                : t('doctorSelection.patientAdmissionOpen')
            }
          </Text>
        </Col>
        <Col flex={'50'} className={'radio-wrapper'}>
          <Radio checked={selected} onChange={onChange}/>
        </Col>
      </Row>
    </Card>
  );
};

export default DoctorCard;
