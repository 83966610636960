/* eslint-disable import/no-cycle */
import {action, makeObservable, observable} from 'mobx';
import {DoctorStore} from './doctor-store';
import {FranchiseGroupStore} from './franchise-group-store';
import {PreviousInsurerStore} from './previous-insurer-store';
import {BaseInsuranceRateStore} from './base-insurance-rate-store';
import {CityStore} from './city-store';
import {CalculatorStore} from './calculator-store';
import {AddonInsuranceRateStore} from './addon-insurance-rate-store';
import {PremiumVersionStore} from './premium-version-store';
import {FranchiseChangeStore} from './franchise-change-store';
import {TravelInsuranceStore} from './travel-insurance-store';
import {TermOfInsuranceStore} from './term-of-insurance-store';
import {TravelInsuranceTypeStore} from './travel-insurance-type-store';
import {TravelInsuranceRateStore} from './travel-insurance-rate-store';
import {CancellationUploadStore} from './cancellation-upload-store';
import {CancellationWizardStore} from './cancellation-wizard-store';
import {ConfigStore} from './config-store';
import {CancellationDeathStore} from './cancellation-death-store';
import {CancellationLeaveStore} from './cancellation-leave-store';

export class RootStore {
  configStore: ConfigStore;
  cityStore: CityStore;
  doctorStore: DoctorStore;
  franchiseGroupStore: FranchiseGroupStore;
  baseInsuranceRateStore: BaseInsuranceRateStore;
  addonInsuranceRateStore: AddonInsuranceRateStore;
  previousInsurerStore: PreviousInsurerStore;
  calculatorStore: CalculatorStore;
  premiumVersionStore: PremiumVersionStore;
  franchiseChangeStore: FranchiseChangeStore;
  travelInsuranceStore: TravelInsuranceStore;
  termOfInsuranceStore: TermOfInsuranceStore;
  travelInsuranceTypeStore: TravelInsuranceTypeStore;
  travelInsuranceRateStore: TravelInsuranceRateStore;
  cancellationUploadStore: CancellationUploadStore;
  cancellationWizardStore: CancellationWizardStore;
  cancellationDeathStore: CancellationDeathStore;
  cancellationLeaveStore: CancellationLeaveStore;
  editMode: boolean = false;

  constructor() {
    this.configStore = new ConfigStore(this);
    this.cityStore = new CityStore(this);
    this.doctorStore = new DoctorStore(this);
    this.franchiseGroupStore = new FranchiseGroupStore(this);
    this.baseInsuranceRateStore = new BaseInsuranceRateStore(this);
    this.addonInsuranceRateStore = new AddonInsuranceRateStore(this);
    this.previousInsurerStore = new PreviousInsurerStore(this);
    this.calculatorStore = new CalculatorStore(this);
    this.premiumVersionStore = new PremiumVersionStore(this);
    this.franchiseChangeStore = new FranchiseChangeStore(this);
    this.travelInsuranceStore = new TravelInsuranceStore(this);
    this.termOfInsuranceStore = new TermOfInsuranceStore(this);
    this.travelInsuranceTypeStore = new TravelInsuranceTypeStore(this);
    this.travelInsuranceRateStore = new TravelInsuranceRateStore(this);
    this.cancellationUploadStore = new CancellationUploadStore(this);
    this.cancellationWizardStore = new CancellationWizardStore(this);
    this.cancellationDeathStore = new CancellationDeathStore(this);
    this.cancellationLeaveStore = new CancellationLeaveStore(this);

    makeObservable(this, {
      editMode: observable,

      setEditMode: action,
    });
  }

  setEditMode(editMode: boolean) {
    this.editMode = editMode;
  }
}
