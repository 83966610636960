import {useTranslation} from 'react-i18next';
import React, {FC, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Navigate, useNavigate} from 'react-router-dom';
import Container from '../../../components/layout/Container';
import {useStore} from '../../../hooks/useStore';
import Button from '../../../components/shared/Button';
import Text from '../../../components/shared/Text';
import Space from '../../../components/shared/Space';
import SplittedContainer from '../../../components/layout/SplittedContainer';
import {DocumentsIcon, PrintIcon} from '../../../components/shared/icons';
import Icon from '../../../components/shared/Icon';
import useBreakpoint from '../../../hooks/useBreakpoint';
import InfoBox from '../../../components/shared/InfoBox';
import {Api} from '../../../services/api';
import {CalculatorPaths} from '../../paths';
import PeopleOverview from '../../../components/PeopleOverview';

const Overview: FC = () => {
  const {t} = useTranslation();
  const store = useStore();
  const navigate = useNavigate();
  const {isMobile} = useBreakpoint();
  const [isLoading, setIsLoading] = useState(false);

  const print = () => {
    setIsLoading(true);
    Api.getOfferPdf(store.calculatorStore.getIssueRequest())
      .then(async (data) => {
        if (data.pdfdata) {
          const dataURI = `data:application/pdf;base64,${encodeURI(data.pdfdata)}`;
          const base64Response = await fetch(dataURI);
          const blob = await base64Response.blob();

          const a = document.createElement('a');
          a.href = window.URL.createObjectURL(blob);
          a.download = 'Offerte.pdf';
          a.target = '_blank';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          // TODO: add error handling
        }
        setIsLoading(false);
      })
      .catch(() => {
        // TODO: add error handling
        setIsLoading(false);
      });
  };

  const requestQuote = async () => {
    store.calculatorStore.setCurrentIndex(0);
    navigate(CalculatorPaths.details());
  };

  const goBack = async () => {
    store.calculatorStore.setCurrentIndex(0);
    navigate(CalculatorPaths.index());
  };

  if (!store.calculatorStore.city
    || !(store.calculatorStore.current.baseInsurance || store.calculatorStore.current.hasAddonInsurances)
  ) {
    return (
      <Navigate replace to={CalculatorPaths.index()}/>
    );
  }

  const isValid = !store.calculatorStore.models.some((person) => !person.isAddonInsuranceSelectionValid);
  const hasAdult = store.calculatorStore.models.some((person) => !person.isChild);

  return (
    <div className={'overview'}>
      <Container background={'white'}>
        <img src={'/img/aquilana-logo.svg'} height={64} alt={t('logo')} className={'logo-print print-show'}/>
        <Text header={1}>{t('stepOverview.title')}</Text>
        {store.calculatorStore.current.isNewBorn ? (
          <Text size={'intro'}>{t('stepOverview.newBornText')}</Text>
        ) : (
          <Text size={'intro'}>{t('stepOverview.text')}</Text>
        )}
      </Container>
      {!hasAdult && !store.calculatorStore.current.isNewBorn && (
        <Container background={'white'} style={{paddingTop: 0}}>
          <InfoBox title={t('label.attention')}>
            <Text>
              {t('stepOverview.adultIsMissing')}
            </Text>
          </InfoBox>
        </Container>
      )}
      {isValid && (hasAdult || store.calculatorStore.current.isNewBorn) && (
        <Container background={'white'} className={'print-hidden'}>
          <Space size={'large'} direction={isMobile ? 'vertical' : 'horizontal'}>
            <Button skin={'primary-turquoise'} onClick={print} loading={isLoading}>
              {t('stepOverview.printOverview')}
            </Button>
            <Button skin={'primary-gray-blue'} onClick={requestQuote}>
              {t('stepOverview.requestQuote')}
            </Button>
            {store.calculatorStore.current.isNewBorn && (
              <Button skin={'secondary'} onClick={goBack}>
                {t('action.back')}
              </Button>
            )}
          </Space>
        </Container>
      )}
      <Container background={'light'} flex={1}>
        <PeopleOverview/>
      </Container>
      {isValid && (hasAdult || store.calculatorStore.current.isNewBorn) && (
        <SplittedContainer
          left={(
            <Space direction={'vertical'} size={'large'}>
              <Icon component={PrintIcon} size={85}/>
              <Text header={2}>{t('stepOverview.print.title')}</Text>
              <Text>{t('stepOverview.print.text')}</Text>
              <Button skin={'primary-turquoise'} onClick={print}>
                {t('stepOverview.printOverview')}
              </Button>
            </Space>
          )}
          backgroundLeft={'gray-blue'}
          right={(
            <Space direction={'vertical'} size={'large'}>
              <Icon component={DocumentsIcon} size={85}/>
              <Text header={2}>{t('stepOverview.quote.title')}</Text>
              <Text>{t('stepOverview.quote.text')}</Text>
              <Button skin={'primary-gray-blue'} onClick={requestQuote}>{t('stepOverview.requestQuote')}</Button>
            </Space>
          )}
          backgroundRight={'smoke-blue'}
          className={'print-hidden'}
        />
      )}
    </div>
  );
};

export default observer(Overview);
