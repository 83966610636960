import * as React from 'react';
import {FC} from 'react';
import {Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import './Button.scss';

interface ButtonProps extends Omit<React.ButtonHTMLAttributes<any>, 'type'> {
  skin?: 'primary-gray-blue' | 'primary-turquoise' | 'secondary' | 'danger';
  type?: 'reset' | 'submit' | 'button' | 'link';
  loading?: boolean;
}

const Button: FC<ButtonProps> = ({skin, children, className, type = 'button', loading, disabled, ...props}) => {
  const classNames = ['button', 'print-hidden'];

  if (type) {
    classNames.push(`button-${type}`);
  }

  if (skin) {
    classNames.push(skin);
  }

  if (className) {
    classNames.push(className);
  }

  return (
    <button
      className={classNames.join(' ')}
      type={type === 'submit' ? 'submit' : 'button'}
      disabled={loading || disabled}
      {...props}
    >
      {loading && <Spin indicator={<LoadingOutlined style={{ marginRight: 16 }} spin/>}/>}
      {children}
    </button>
  );
};

export default Button;
