import {FC, HTMLAttributes, ReactNode} from 'react';
import './Text.scss';

interface TextProps extends HTMLAttributes<any> {
  header?: number;
  size?: 'extra-small' | 'small' | 'regular' | 'intro' | 'large';
  color?: 'turquoise' | 'gray-blue' | 'light-red';
  children: ReactNode;
  medium?: boolean;
  strong?: boolean;
  opacity?: number;
  block?: boolean;
  align?: 'left' | 'center' | 'right';
}

const Text: FC<TextProps> = ({
  header,
  children,
  size = 'regular',
  color,
  medium,
  strong,
  className,
  opacity,
  block,
  align = 'left',
  ...props
}) => {
  const classNames = [`align-${align}`];

  if (size) {
    classNames.push(`size-${size}`);
  }

  if (color) {
    classNames.push(`color-${color}`);
  }

  if (medium) {
    classNames.push('medium');
  }

  if (strong) {
    classNames.push('strong');
  }

  if (className) {
    classNames.push(className);
  }

  if (header) {
    switch (header) {
      case 1:
        return <h1 className={classNames.join(' ')}>{children}</h1>;
      case 2:
        return <h2 className={classNames.join(' ')}>{children}</h2>;
      case 3:
        return <h3 className={classNames.join(' ')}>{children}</h3>;
      case 4:
        return <h4 className={classNames.join(' ')}>{children}</h4>;
      case 5:
        return <h5 className={classNames.join(' ')}>{children}</h5>;
      default:
      // ignore, render as regular text
    }
  } else {
    classNames.splice(0, 0, 'aquilana-text');
  }

  if (block) {
    return <div className={classNames.join(' ')} style={opacity ? {opacity} : undefined} {...props}>{children}</div>;
  }

  return (
    <span className={classNames.join(' ')} style={opacity ? {opacity} : undefined} {...props}>{children}</span>
  );
};

export default Text;
