import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import dayjs, {Dayjs} from 'dayjs';
import {DatePicker, Form, Input, Radio} from 'antd';
import {observer} from 'mobx-react-lite';
import {useNavigate} from 'react-router-dom';
import {Col, Row} from '../../../../components/layout/Grid';
import {Config, GUTTER_SIZE} from '../../../../config';
import InfoBox from '../../../../components/shared/InfoBox';
import Text from '../../../../components/shared/Text';
import {Sex} from '../../../../models/person';
import Upload, {UploadChangeParam, UploadFile} from '../../../../components/shared/Upload';
import {Validators} from '../../../../utils/validators';
import {City} from '../../../../models/city';
import {formatInsuranceNumber} from '../../../../utils/formatter';
import Space from '../../../../components/shared/Space';
import Button from '../../../../components/shared/Button';
import OptionalLabel from '../../../../components/shared/OptionalLabel';
import Select from '../../../../components/shared/Select';
import {useStore} from '../../../../hooks/useStore';
import useCitySelection from '../../../../hooks/useCitySelection';
import Checkbox from '../../../../components/shared/Checkbox';
import StepsNavigationButtons from '../../../../components/StepsNavigationButtons';
import {Api} from '../../../../services/api';
import {CancellationDeathPaths} from '../../../paths';

interface FormValues {
  sex: Sex;
  firstName: string;
  lastName: string;
  insuranceNumber: string;
  deathCertificate: UploadFile[];
  heritageCertificate?: UploadFile[];
  personBirthday: Dayjs;
  personDeathDate: Dayjs;
  personSex: Sex;
  personFirstName: string;
  personLastName: string;
  personAddress: string;
  personInsuranceNumber: string;
  cityId: City['id'];
  email: string;
  phone?: string;
  optIn: boolean;
}

const CancellationDeathForm: FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const store = useStore();
  const {options} = useCitySelection();
  const [form] = Form.useForm<FormValues>();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (values: FormValues) => {
    setLoading(true);

    const reader = new FileReader();
    reader.readAsDataURL(values.deathCertificate[0].originFileObj as Blob);
    reader.onload = () => {
      store.cancellationDeathStore.setPersonSex(values.personSex);
      store.cancellationDeathStore.setPersonFirstName(values.personFirstName);
      store.cancellationDeathStore.setPersonLastName(values.personLastName);
      store.cancellationDeathStore.setPersonBirthday(values.personBirthday);
      store.cancellationDeathStore.setPersonDeathDate(values.personDeathDate);
      store.cancellationDeathStore.setPersonInsuranceNumber(values.personInsuranceNumber);
      store.cancellationDeathStore.setPersonDeathCertificate((reader.result as string).split(',').pop() || '');

      store.cancellationDeathStore.setSex(values.sex);
      store.cancellationDeathStore.setFirstName(values.firstName);
      store.cancellationDeathStore.setLastName(values.lastName);
      store.cancellationDeathStore.setAddress(values.personAddress);
      store.cancellationDeathStore.setCity(store.cityStore.getById(values.cityId));
      store.cancellationDeathStore.setEmail(values.email);
      store.cancellationDeathStore.setPhone(values.phone);

      if (values.heritageCertificate) {
        const readerHeritage = new FileReader();
        readerHeritage.readAsDataURL(values.heritageCertificate[0].originFileObj as Blob);
        readerHeritage.onload = () => {
          store.cancellationDeathStore.setPersonHeritageCertificate((readerHeritage.result as string).split(',')
            .pop() || '');

          Api.sendRequest(store.cancellationDeathStore.getIssueRequest())
            .then(() => {
              navigate(CancellationDeathPaths.successSubmission());
            })
            .finally(() => setLoading(false));
        };
      } else {
        Api.sendRequest(store.cancellationDeathStore.getIssueRequest())
          .then(() => {
            navigate(CancellationDeathPaths.successSubmission());
          })
          .finally(() => setLoading(false));
      }
    };
  };

  const normFile = (e: UploadChangeParam) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e.fileList;
  };

  return (
    <Row gutter={[GUTTER_SIZE, GUTTER_SIZE]}>
      <Col xs={24} sm={24} md={24} lg={16} xl={16}>
        <Form
          form={form}
          layout={'vertical'}
          colon={false}
          labelAlign={'left'}
          requiredMark={false}
          onFinish={handleSubmit}
        >
          <Form.Item>
            <InfoBox title={t('cancellation.death.initialPage.info.title')}>
              {t('cancellation.death.initialPage.info.text')}
            </InfoBox>
          </Form.Item>
          <Form.Item>
            <Text header={4}>{t('cancellation.death.initialPage.policyHolder.formTitle')}</Text>
          </Form.Item>
          <Form.Item
            name={'personSex'}
            label={t('label.sex')}
            rules={[Validators.required(t('validator.salutationRequired'))]}
          >
            <Radio.Group>
              {Object.values(Sex).map((sex) => (
                <Radio key={sex} value={sex}>{t(`sex.${sex}`)}</Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name={'personFirstName'}
            label={t('label.firstName')}
            rules={[Validators.required(t('validator.firstNameRequired')), Validators.noSpecialCharacters]}
          >
            <Input placeholder={t('label.firstName')} maxLength={50}/>
          </Form.Item>
          <Form.Item
            name={'personLastName'}
            label={t('label.name')}
            rules={[Validators.required(t('validator.nameRequired')), Validators.noSpecialCharacters]}
          >
            <Input placeholder={t('label.name')} maxLength={50}/>
          </Form.Item>
          <Form.Item
            name={'personBirthday'}
            label={t('label.birthday')}
            rules={[Validators.required(t('validator.birthdayRequired')), {
              validator: (_, value) => {
                if (value && form.getFieldValue('deathDate') && value.isAfter(form.getFieldValue('deathDate'))) {
                  return Promise.reject(t('validator.birthDateAfterDeathDate'));
                }
                return Promise.resolve();
              },
            }]}
          >
            <DatePicker
              format={Config.dateFormat}
              placeholder={t('label.dateFormat')}
              disabledDate={(d) => d.isAfter(dayjs().endOf('day').add(2, 'years'))
                || d.isBefore(dayjs().startOf('day').subtract(150, 'years'))}
            />
          </Form.Item>
          <Form.Item
            name={'personDeathDate'}
            label={t('label.deathDate')}
            rules={[Validators.required(t('validator.deathDateRequired')), {
              validator: (_, value) => {
                if (value
                  && form.getFieldValue('personBirthday')
                  && value.isBefore(form.getFieldValue('personBirthday'))
                ) {
                  return Promise.reject(t('validator.deathDateBeforeBirthDate'));
                }
                return Promise.resolve();
              },
            }]}
          >
            <DatePicker
              format={Config.dateFormat}
              placeholder={t('label.dateFormat')}
              disabledDate={(d) => d.isAfter(dayjs().endOf('day').add(2, 'years'))
                || d.isBefore(dayjs().startOf('day').subtract(150, 'years'))}
            />
          </Form.Item>
          <Form.Item
            name={'personInsuranceNumber'}
            label={t('label.insuranceNumber')}
            rules={[Validators.insuranceNumber, Validators.required(t('validator.insuranceNumberRequired'))]}
          >
            <Input
              placeholder={t('label.insuranceNumberPlaceholder')}
              onInput={formatInsuranceNumber}
            />
          </Form.Item>
          <Form.Item
            name={'deathCertificate'}
            label={t('label.uploadDeathCertificate')}
            getValueFromEvent={normFile}
            valuePropName={'fileList'}
            rules={[
              {
                validator: (_, [file]) => {
                  if (!file) {
                    return Promise.reject(t('validator.deathCertificateFileRequired'));
                  }

                  if (file.type !== 'application/pdf') {
                    return Promise.reject(t('validator.cancellationFileInvalid'));
                  }

                  const maxFileSize = 20 * 1024 * 1024;
                  if (file.size > maxFileSize) {
                    return Promise.reject(t('validator.cancellationFileTooLarge'));
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Upload maxCount={1} beforeUpload={() => false} accept={'.pdf'}>
              <Space direction={'vertical'} fullWidth size={'large'}>
                <Button skin={'primary-gray-blue'}>{t('action.selectDeathCertificateFile')}</Button>
                <Text size={'small'}>{t('cancellation.death.initialPage.deathCertificateUpload.info')}</Text>
              </Space>
            </Upload>
          </Form.Item>
          <Form.Item
            name={'heritageCertificate'}
            label={<OptionalLabel label={t('label.uploadHeritageCertificate')}/>}
            getValueFromEvent={normFile}
            valuePropName={'fileList'}
            rules={[
              {
                validator: (_, arr) => {
                  if (!arr) {
                    return Promise.resolve();
                  }

                  const [file] = arr;
                  if (!file) {
                    return Promise.resolve();
                  }

                  if (file.type !== 'application/pdf') {
                    return Promise.reject(t('validator.cancellationFileInvalid'));
                  }

                  const maxFileSize = 20 * 1024 * 1024;
                  if (file.size > maxFileSize) {
                    return Promise.reject(t('validator.cancellationFileTooLarge'));
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Upload maxCount={1} beforeUpload={() => false} accept={'.pdf'}>
              <Space direction={'vertical'} fullWidth size={'large'}>
                <Button skin={'primary-gray-blue'}>{t('action.selectHeritageFile')}</Button>
                <Text size={'small'}>{t('cancellation.death.initialPage.heritageUpload.info')}</Text>
              </Space>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Text header={4}>{t('cancellation.death.initialPage.sender.formTitle')}</Text>
          </Form.Item>
          <Form.Item
            name={'sex'}
            label={t('label.sex')}
            rules={[Validators.required(t('validator.salutationRequired'))]}
          >
            <Radio.Group>
              {Object.values(Sex).map((sex) => (
                <Radio key={sex} value={sex}>{t(`sex.${sex}`)}</Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name={'firstName'}
            label={t('label.firstName')}
            rules={[Validators.required(t('validator.firstNameRequired')), Validators.noSpecialCharacters]}
          >
            <Input placeholder={t('label.firstName')} maxLength={50}/>
          </Form.Item>
          <Form.Item
            name={'lastName'}
            label={t('label.name')}
            rules={[Validators.required(t('validator.nameRequired')), Validators.noSpecialCharacters]}
          >
            <Input placeholder={t('label.name')} maxLength={50}/>
          </Form.Item>
          <Form.Item
            name={'address'}
            label={t('label.address')}
            rules={[Validators.required(t('validator.addressRequired')), Validators.noSpecialCharacters]}
          >
            <Input placeholder={t('label.address')} maxLength={50}/>
          </Form.Item>
          <Form.Item
            name={'cityId'}
            label={t('label.postcodeCity')}
            rules={[Validators.required(t('validator.postcodeCityRequired'))]}
          >
            <Select
              showSearch
              loading={store.cityStore.isLoading}
              options={options}
              placeholder={t('label.postcodeCityPlaceholder')}
            />
          </Form.Item>
          <Form.Item
            name={'email'}
            label={t('label.email')}
            rules={[Validators.email, Validators.required(t('validator.emailRequired'))]}
          >
            <Input placeholder={t('label.email')} maxLength={255}/>
          </Form.Item>
          <Form.Item
            name={'phone'}
            label={<OptionalLabel label={t('label.phone')}/>}
            rules={[Validators.phone]}
          >
            <Input placeholder={t('label.phone')} maxLength={50}/>
          </Form.Item>
          <Form.Item
            style={{marginTop: '3rem'}}
            name={'optIn'}
            valuePropName={'checked'}
            label={t('cancellation.death.initialPage.optIn.title')}
            rules={[Validators.checkbox()]}
          >
            <Checkbox>{t('cancellation.death.initialPage.optIn.text')}</Checkbox>
          </Form.Item>

          <StepsNavigationButtons
            backHidden
            back={() => ''}
            next={() => form.submit()}
            nextLabel={t('cancellation.death.initialPage.submitCancellation')}
            loading={loading}
          />
        </Form>
      </Col>
    </Row>
  );
};

export default observer(CancellationDeathForm);
