/* eslint-disable import/no-cycle */
import {action, makeObservable, observable} from 'mobx';
import {Api} from '../services/api';
import {RootStore} from './root-store';
import {Environment} from '../models/environment';

export class ConfigStore {
  public rootStore: RootStore;
  public environment: Environment | null = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeObservable(this, {
      environment: observable,
      setEnvironment: action,
    });

    this.load();
  }

  public setEnvironment(environment: Environment) {
    this.environment = environment;
  }

  private load() {
    Api.getEnvironment().then((environment) => {
      this.setEnvironment(environment);
    });
  }
}
