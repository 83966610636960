import {useTranslation} from 'react-i18next';
import {FC, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useNavigate} from 'react-router-dom';
import {CheckboxChangeEvent} from 'antd/lib/checkbox/Checkbox';
import Container from '../../../components/layout/Container';
import {BaseInsurance, BaseInsuranceType, BaseInsuranceTypes} from '../../../models/base-insurance';
import {useStore} from '../../../hooks/useStore';
import StepsNavigationButtons from '../../../components/StepsNavigationButtons';
import Text from '../../../components/shared/Text';
import Space from '../../../components/shared/Space';
import {Col, Row} from '../../../components/layout/Grid';
import Checkbox from '../../../components/shared/Checkbox';
import Info from '../../../components/shared/Info';
import {GUTTER_SIZE} from '../../../config';
import {BASE_INSURANCE_PREMIUM_REDUCTION_FACTOR} from '../../../stores/calculator-store';
import {CalculatorPaths} from '../../paths';
import FranchiseSelector from '../../../components/FranchiseSelector';
import BaseInsuranceCardProduct from '../../../components/cards/BaseInsuranceCardProduct';
import './BaseInsuranceSelection.scss';
import InfoBox from '../../../components/shared/InfoBox';

const BaseInsuranceSelection: FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const store = useStore();
  const person = store.calculatorStore.current;
  const [selectedType, setSelectedType] = useState(person.baseInsurance?.type || BaseInsuranceType.STANDARD);
  const [selectedFranchise, setSelectedFranchise] = useState(person.baseInsurance?.franchiseGroup?.id || 1);
  const isFourteenOrYounger = person.age <= 14;
  const isSixteenOrYounger = person.age <= 16;
  const [withAccident, setWithAccident] = useState(person.baseInsurance?.withAccident || isSixteenOrYounger);
  const hasReduction = store.calculatorStore.getPremiumReductionBaseInsurance(person);

  useEffect(() => {
    const baseInsurance = new BaseInsurance();
    baseInsurance.withAccident = isFourteenOrYounger ? true : withAccident;
    baseInsurance.type = selectedType;
    baseInsurance.setFranchiseGroup(store.franchiseGroupStore.getById(selectedFranchise));
    baseInsurance.setRate(
      store.baseInsuranceRateStore.findOne(
        selectedType,
        selectedFranchise,
        person.age,
        store.calculatorStore.city?.regionCode || '',
        person.insuranceStart
      )
    );
    person.setBaseInsurance(baseInsurance);
    store.calculatorStore.saveToSession();
  }, [selectedType, withAccident, selectedFranchise]);

  const onChange = (e: CheckboxChangeEvent) => setWithAccident(e.target.checked);

  const previousStep = () => {
    if (store.editMode) {
      store.setEditMode(false);
      navigate(CalculatorPaths.overview());
    } else {
      navigate(CalculatorPaths.productSelection());
    }
  };

  const nextStep = () => {
    if (store.editMode) {
      store.setEditMode(false);
      navigate(CalculatorPaths.overview());
    } else if (person.addonInsurances !== undefined) {
      navigate(CalculatorPaths.productAddon());
    } else {
      navigate(CalculatorPaths.overview());
    }
  };

  const getPrice = (type: BaseInsuranceType): number => {
    const rate = store.baseInsuranceRateStore.findOne(
      type,
      selectedFranchise,
      person.age,
      store.calculatorStore.city?.regionCode || '',
      person.insuranceStart
    );

    if (rate) {
      const environmentalTax = rate.premiumVersion?.environmentalTax || 0;
      const factor = hasReduction ? BASE_INSURANCE_PREMIUM_REDUCTION_FACTOR : 1;

      if (withAccident) {
        return rate.valueWithAccident * factor - environmentalTax;
      }

      return rate.valueWithoutAccident * factor - environmentalTax;
    }

    return 0;
  };

  return (
    <>
      <Container background={'white'} className={'base-insurance-selection'}>
        <div className={'title-wrapper'}>
          <Text header={1}>{t('baseInsurance.one')}</Text>
          <Text size={'intro'}>{t('baseInsurance.pleaseSelect')}</Text>
        </div>

        <Row gutter={[GUTTER_SIZE, GUTTER_SIZE]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <FranchiseSelector
              isChild={person.isChild}
              selectedFranchiseGroup={selectedFranchise}
              setSelectedFranchiseGroup={setSelectedFranchise}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <InfoBox title={t('baseInsurance.franchiseLegalText.title')}>
              {t('baseInsurance.franchiseLegalText.text')}
            </InfoBox>
          </Col>
        </Row>
      </Container>
      <Container background={'light'} flex={1}>
        <Space fullWidth direction={'vertical'} size={'large'}>
          <div>
            <Text strong>{t('baseInsurance.model')}</Text>
            <br/>
            <Space direction={'horizontal'} size={'large'} align={'baseline'}>
              <Checkbox disabled={isFourteenOrYounger} checked={withAccident} onChange={onChange}>
                {t('baseInsurance.withAccident.label')}
              </Checkbox>
              <Info className={'franchise-info'}>
                {t('baseInsurance.withAccident.info')}
              </Info>
            </Space>
          </div>
          <Row gutter={[GUTTER_SIZE, GUTTER_SIZE]}>
            {Object.keys(BaseInsuranceTypes).map((key) =>
              getPrice(key as BaseInsuranceType) > 0 && (
                <Col key={key} xs={24} sm={24} md={24} lg={12} xl={8}>
                  <BaseInsuranceCardProduct
                    key={key}
                    type={key as BaseInsuranceType}
                    selected={selectedType === key}
                    onSelectionChange={() => setSelectedType(key as BaseInsuranceType)}
                    price={getPrice(key as BaseInsuranceType)}
                  />
                </Col>
              ))}
          </Row>
          <StepsNavigationButtons backHidden={store.editMode} back={() => previousStep()} next={() => nextStep()}/>
        </Space>
      </Container>
    </>
  );
};

export default observer(BaseInsuranceSelection);
