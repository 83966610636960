import {useTranslation} from 'react-i18next';
import {FC} from 'react';
import Container from '../../../components/layout/Container';
import Text from '../../../components/shared/Text';
import TravelInsuranceInitialForm from './_components/TravelInsuranceInitialForm';

const TravelInsurance: FC = () => {
  const {t} = useTranslation();

  return (
    <>
      <Container background={'white'}>
        <Text header={1}>{t('travelInsurance.title')}</Text>
        <Text size={'intro'}>{t('travelInsurance.text')}</Text>
      </Container>
      <Container background={'light'} flex={1}>
        <TravelInsuranceInitialForm/>
      </Container>
    </>
  );
};

export default TravelInsurance;
