import React, {FC} from 'react';
import Space from './Space';
import Info, {InfoProps} from './Info';

interface InfoLabelProps extends InfoProps {
  label: React.ReactNode;
}

const InfoWithLabel:FC<InfoLabelProps> = ({label, children, ...props}) => (
  <Space>
    {label}
    <Info {...props}>
      {children}
    </Info>
  </Space>
);

export default InfoWithLabel;
