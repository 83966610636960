import {FC, HTMLAttributes, ReactNode} from 'react';
import './InfoBox.scss';
import Text from './Text';

export interface InfoBoxProps extends HTMLAttributes<HTMLDivElement> {
  title?: string;
  children: ReactNode;
}

const InfoBox: FC<InfoBoxProps> = ({className = '', title, children}) => (
  <div className={`info-box ${className}`}>
    {title !== undefined && (
      <Text strong block>{title}</Text>
    )}
    {children}
  </div>
);

export default InfoBox;
