/* eslint-disable import/no-cycle */

import {Api} from '../services/api';
import {RootStore} from './root-store';
import {BaseStore} from './base-store';
import {TermOfInsurance} from '../models/term-of-insurance';

export class TermOfInsuranceStore extends BaseStore<TermOfInsurance, number> {
  constructor(rootStore: RootStore) {
    super(rootStore, 'id', Api.getTermsOfInsurance);

    this.loadAll();
  }
}
