import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import {useNavigate} from 'react-router-dom';
import Card from '../../../components/shared/Card';
import Text from '../../../components/shared/Text';
import Checklist from '../../../components/shared/Checklist';
import Icon from '../../../components/shared/Icon';
import {ArrowRightIcon} from '../../../components/shared/icons';
import './CancellationCard.scss';

export interface CancellationCardProps {
  title: string;
  text?: string;
  checkList: string;
  buttonText?: string;
  navigateTo: string;
}

const CancellationCard: FC<CancellationCardProps> = ({title, text, checkList, buttonText, navigateTo}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(navigateTo);
  };

  return (
    <Card className={'cancellation-card'} onClick={handleClick}>
      <div className={'content'}>
        <Text header={4}>{title}</Text>
        {text && (
          <Text size={'small'}>
            <ReactMarkdown>{text}</ReactMarkdown>
          </Text>
        )}
        <Checklist text={checkList}/>
        <div className={'button-wrapper'}>
          <Text size={'regular'}>
            {buttonText || t('cancellation.overview.card.buttonText')}
          </Text>
          <Icon component={ArrowRightIcon} size={30}/>
        </div>
      </div>
    </Card>
  );
};

export default CancellationCard;
