import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import ReactMarkdown from 'react-markdown';
import {useParams} from 'react-router-dom';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import Container from '../../../components/layout/Container';
import Text from '../../../components/shared/Text';
import Button from '../../../components/shared/Button';
import {Api} from '../../../services/api';
import {Box, BoxWrapper} from '../../../components/shared/Box';
import Icon from '../../../components/shared/Icon';
import {PoliceIcon} from '../../../components/shared/icons';
import InfoBox from '../../../components/shared/InfoBox';
import {Config, GUTTER_SIZE} from '../../../config';
import Space from '../../../components/shared/Space';
import {Col, Row} from '../../../components/layout/Grid';

const TravelInsuranceSuccessSubmission = () => {
  const {policyNr} = useParams();
  const {t} = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [isDownloadLoading, setDownloadLoading] = useState(false);
  const [travelInsuranceExists, setTravelInsuranceExists] = useState(false);

  useEffect(() => {
    if (policyNr) {
      setLoading(true);
      Api.getTravelInsuranceByPolicyNrExists(policyNr)
        .then((data) => {
          setTravelInsuranceExists(data.exists);
        })
        .catch(() => {
          // TODO: add error handling
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [policyNr]);

  const toWebsite = () => {
    window.location.href = Config.websiteUrl;
  };

  if (!policyNr || isLoading) {
    return null;
  }

  const downloadRequest = () => {
    setDownloadLoading(true);
    Api.getTravelInsurancePdf(policyNr)
      .then(async (data) => {
        if (data.pdfdata) {
          const dataURI = `data:application/pdf;base64,${encodeURI(data.pdfdata)}`;
          const base64Response = await fetch(dataURI);
          const blob = await base64Response.blob();

          const a = document.createElement('a');
          a.href = window.URL.createObjectURL(blob);
          a.download = 'Reisedokumentation.pdf';
          a.target = '_blank';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          // TODO: add error handling
        }
        setDownloadLoading(false);
      })
      .catch(() => {
        // TODO: add error handling
        setDownloadLoading(false);
      });
  };

  if (!travelInsuranceExists) {
    return (
      <>
        <Container background={'white'}>
          <Text header={1}>{t('travelInsurance.success.notFound.title')}</Text>
        </Container>
        <Container background={'white'} flex={1}>
          <BoxWrapper>
            <Box flex={'0 0 70px'}>
              <ExclamationCircleOutlined style={{fontSize: 50, color: '#D24345'}}/>
            </Box>
            <Box flex={'auto'}>
              <Text medium color={'gray-blue'}>
                <ReactMarkdown>
                  {t('travelInsurance.success.notFound.text', {url: Config.aquilanaContactUrl})}
                </ReactMarkdown>
              </Text>
            </Box>
          </BoxWrapper>
        </Container>
        <Container background={'white'}>
          <Button onClick={() => toWebsite()} skin={'primary-gray-blue'} style={{marginBottom: '2rem'}}>
            {t('action.toWebsite')}
          </Button>
        </Container>
      </>
    );
  }

  return (
    <>
      <Container background={'white'}>
        <Text header={1}>{t('travelInsurance.success.title')}</Text>
      </Container>
      <Container background={'white'} flex={1}>
        <Space direction={'vertical'} size={'large'}>
          <BoxWrapper>
            <Box flex={'0 0 70px'}>
              <Icon component={PoliceIcon} size={50} color={'gray-blue'}/>
            </Box>
            <Box flex={'auto'}>
              <Text medium color={'gray-blue'}>
                <ReactMarkdown linkTarget={'_blank'}>
                  {t('success.travelInsurance.text')}
                </ReactMarkdown>
              </Text>
            </Box>
          </BoxWrapper>
          <InfoBox title={t('label.notice')}>
            <ReactMarkdown linkTarget={'_blank'}>
              {t('success.travelInsurance.info', {url: Config.aquilanaContactUrl})}
            </ReactMarkdown>
          </InfoBox>
        </Space>
      </Container>
      <Container background={'white'}>
        <Row gutter={[GUTTER_SIZE, GUTTER_SIZE]} style={{marginBottom: '2rem'}}>
          <Col>
            <Button skin={'primary-turquoise'} onClick={downloadRequest} loading={isDownloadLoading}>
              {t('travelInsurance.success.downloadRequest')}
            </Button>
          </Col>
          <Col>
            <Button onClick={() => toWebsite()} skin={'primary-gray-blue'}>
              {t('action.toWebsite')}
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default observer(TravelInsuranceSuccessSubmission);
