import * as React from 'react';
import {FC, HTMLAttributes, ReactNode, useState} from 'react';
import {InformationIcon} from './icons';
import Button from './Button';
import Icon from './Icon';
import InfoModal from './InfoModal';
import './Info.scss';

export interface InfoProps extends HTMLAttributes<HTMLDivElement> {
  title?: string;
  children: ReactNode;
  width?: number
}

const Info: FC<InfoProps> = ({title, children, className, width = 800, ...props}) => {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <div className={`aquilana-info ${className}`} {...props}>
      <Button type={'link'} onClick={() => setModalVisible(!modalVisible)}>
        <Icon size={24} component={InformationIcon}/>
      </Button>
      <InfoModal modalVisible={modalVisible} setModalVisible={setModalVisible} title={title} width={width}>
        {children}
      </InfoModal>
    </div>
  );
};

export default Info;
