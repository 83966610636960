import {FC} from 'react';
import {Radio} from 'antd';
import {useTranslation} from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import {TravelInsuranceRate} from '../../../../models/travel-insurance-rate';
import {BaseInsuranceIcon} from '../../../../components/shared/icons';
import Text from '../../../../components/shared/Text';
import Divider from '../../../../components/shared/Divider';
import Price from '../../../../components/shared/Price';
import Info from '../../../../components/shared/Info';
import ExternalLink from '../../../../components/shared/ExternalLink';
import {Config} from '../../../../config';
import Checklist from '../../../../components/shared/Checklist';
import {TravelInsuranceTypes} from '../../../../models/travel-insurance-type';
import InsuranceCard from '../../../../components/cards/InsuranceCard';
import './TravelInsuranceCard.scss';

interface TravelInsuranceCardProps {
  rate: TravelInsuranceRate;
  selected: boolean;
  onSelectionChange: () => void;
}

const TravelInsuranceCard: FC<TravelInsuranceCardProps> = ({
  rate,
  selected,
  onSelectionChange,
}) => {
  const {t} = useTranslation();

  if (!rate.travelInsuranceType) {
    return null;
  }

  return (
    <InsuranceCard
      icon={BaseInsuranceIcon}
      priceText={t('travelInsurance.priceText', {days: rate.termOfInsurance?.days})}
      title={rate.travelInsuranceType?.label ?? ''}
      subTitle={t('travelInsurance.one')}
      onSelectionChange={onSelectionChange}
      price={rate.value}
      selectInput={<Radio checked={selected} onChange={onSelectionChange}/>}
      info={(
        <Info>
          <ReactMarkdown linkTarget={'_blank'} className={'info-text'}>
            {t('travelInsuranceType.general.info')}
          </ReactMarkdown>
          <br/>
          <ReactMarkdown>
            {t(`${TravelInsuranceTypes[rate.travelInsuranceType.identifier].translationPrefix}.info`)}
          </ReactMarkdown>
          <br/>
          <ExternalLink href={Config.travelInsuranceInformationUrl}>{t('label.moreInformation')}</ExternalLink>
        </Info>
      )}
    >
      <Checklist
        text={t(`${TravelInsuranceTypes[rate.travelInsuranceType.identifier].translationPrefix}.description`)}
      />
      <br/>
      <Text size={'small'}>
        {t(`travelInsuranceRate.type.${rate.type}`)}
      </Text>
      <Divider type={'vertical'} orientation={'center'} primary style={{margin: '0.25rem'}}/>
      <Text size={'small'} color={'gray-blue'}>
        <Text>CHF </Text>
        <Price value={rate.insuranceAmount}/>
      </Text>
      <Divider type={'vertical'} orientation={'center'} primary style={{margin: '0.25rem'}}/>
      <Text size={'small'}>
        {rate.termOfInsurance?.days}
        {' '}
        {t('label.days')}
      </Text>
    </InsuranceCard>
  );
};

export default TravelInsuranceCard;
