import {FC} from 'react';
import {Slider, Spin} from 'antd';
import {observer} from 'mobx-react-lite';
import {SliderMarks} from 'antd/lib/slider';
import {useTranslation} from 'react-i18next';
import {useStore} from '../hooks/useStore';
import Price from './shared/Price';
import Space from './shared/Space';
import Info from './shared/Info';
import Text from './shared/Text';
import './FranchiseSelector.scss';

interface FranchiseSelectorProps {
  isChild: boolean;
  selectedFranchiseGroup: number;
  setSelectedFranchiseGroup: (franchiseGroup: number) => void;
}

const FranchiseSelector: FC<FranchiseSelectorProps> = ({
  isChild,
  selectedFranchiseGroup,
  setSelectedFranchiseGroup,
}) => {
  const {t} = useTranslation();
  const store = useStore();

  if (store.franchiseGroupStore.isLoading) {
    return <Spin/>;
  }

  const marks: SliderMarks = {};
  store.franchiseGroupStore.models.forEach((group, index) => {
    marks[index + 1] = selectedFranchiseGroup === group.id
      ? (
        <Text strong color={'gray-blue'} style={{whiteSpace: 'nowrap'}}>
          <Price noZeroDecimals value={isChild ? group.valueChild : group.valueAdult}/>
        </Text>
      )
      : ' ';
  });

  return (
    <div>
      <Text header={2}>{t('baseInsurance.franchise')}</Text>
      <Space fullWidth direction={'horizontal'} align={'center'} size={'large'}>
        <Slider
          className={'franchise-selector'}
          marks={marks}
          min={1}
          max={6}
          value={selectedFranchiseGroup}
          onChange={(value) => setSelectedFranchiseGroup(value)}
          tooltip={{
            open: false,
          }}
        />
        <Info className={'franchise-info'}>
          {t('baseInsurance.franchiseInfo')}
        </Info>
      </Space>
    </div>
  );
};

export default observer(FranchiseSelector);
