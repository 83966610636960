import {useTranslation} from 'react-i18next';
import {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {Navigate, useNavigate} from 'react-router-dom';
import Container from '../../../components/layout/Container';
import {useStore} from '../../../hooks/useStore';
import Text from '../../../components/shared/Text';
import Space from '../../../components/shared/Space';
import {Col, Row} from '../../../components/layout/Grid';
import {GUTTER_SIZE} from '../../../config';
import {CalculatorPaths} from '../../paths';
import ContactForm from './_components/ContactForm';

const Contact: FC = () => {
  const {t} = useTranslation();
  const store = useStore();
  const navigate = useNavigate();

  if (!store.calculatorStore.current.baseInsurance && !store.calculatorStore.current.hasAddonInsurances) {
    return (
      <Navigate replace to={CalculatorPaths.index()}/>
    );
  }

  if (!store.calculatorStore.policyHolder.lastName) {
    return (
      <Navigate replace to={CalculatorPaths.overview()}/>
    );
  }

  const afterSubmit = () => {
    if (store.editMode) {
      store.setEditMode(false);
      navigate(CalculatorPaths.finish());
    } else {
      navigate(CalculatorPaths.payment());
    }
  };

  return (
    <>
      <Container background={'white'}>
        <Text header={1}>{t('stepDetails.contactData')}</Text>
      </Container>
      <Container background={'light'} flex={1}>
        <Row gutter={[GUTTER_SIZE, GUTTER_SIZE]}>
          <Col xs={24} sm={24} md={24} lg={16} xl={16}>
            <Space fullWidth direction={'vertical'} size={'large'}>
              <ContactForm afterSubmit={afterSubmit}/>
            </Space>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default observer(Contact);
