import {computed, makeObservable, observable} from 'mobx';
import dayjs, {Dayjs} from 'dayjs';
import {IBaseModel} from './base-model';

export interface ICity extends IBaseModel {
  id: number;
  number: number;
  canton: string;
  community: string;
  regionCode: string;
  district: string;
  postcode: number;
  city: string;
  validFrom: Dayjs | null;
}

export class City implements ICity {
  id = 0;
  number = 0;
  canton = '';
  community = '';
  regionCode = '';
  district = '';
  postcode = 0;
  city = '';
  validFrom: ICity['validFrom'] = null;

  constructor() {
    makeObservable(this, {
      id: observable,
      number: observable,
      canton: observable,
      community: observable,
      regionCode: observable,
      district: observable,
      postcode: observable,
      city: observable,
      validFrom: observable,

      label: computed,
    });
  }

  public get name(): string {
    const parts = [this.city];
    if (this.community !== this.city) {
      parts.push(`(${this.community})`);
    }

    return parts.join(' ');
  }

  get label(): string {
    return [this.postcode, this.name].join(' ');
  }

  static fromJSON(data: ICity): City {
    const city = new City();
    city.id = data.id;
    city.number = data.number;
    city.canton = data.canton;
    city.community = data.community;
    city.regionCode = data.regionCode;
    city.district = data.district;
    city.postcode = data.postcode;
    city.city = data.city;
    city.validFrom = data.validFrom ? dayjs(data.validFrom) : null;

    return city;
  }
}
