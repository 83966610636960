import {makeObservable, observable} from 'mobx';
import {IBaseModel} from './base-model';

export enum TravelInsuranceTypeIdentifier {
  FRV1 = 'FRV1',
  FRV2 = 'FRV2'
}

export const TravelInsuranceTypes = {
  [TravelInsuranceTypeIdentifier.FRV1]: {
    // t('travelInsuranceType.frv1.info')
    // t('travelInsuranceType.frv1.description')
    translationPrefix: 'travelInsuranceType.frv1',
  },
  [TravelInsuranceTypeIdentifier.FRV2]: {
    // t('travelInsuranceType.frv2.info')
    // t('travelInsuranceType.frv2.description')
    translationPrefix: 'travelInsuranceType.frv2',
  },
};

export interface ITravelInsuranceType extends IBaseModel {
  id: number;
  label: string;
  identifier: TravelInsuranceTypeIdentifier;
}

export class TravelInsuranceType implements ITravelInsuranceType {
  id = 0;
  label = '';
  identifier: TravelInsuranceTypeIdentifier = TravelInsuranceTypeIdentifier.FRV1;

  constructor() {
    makeObservable(this, {
      id: observable,
      label: observable,
      identifier: observable,
    });
  }

  static fromJSON(data: ITravelInsuranceType): TravelInsuranceType {
    const travelInsuranceType = new TravelInsuranceType();
    travelInsuranceType.id = data.id;
    travelInsuranceType.label = data.label;
    travelInsuranceType.identifier = data.identifier;

    return travelInsuranceType;
  }
}
