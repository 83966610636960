import {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Form, Radio} from 'antd';
import {observer} from 'mobx-react-lite';
import ReactMarkdown from 'react-markdown';
import {Validators} from '../../../../utils/validators';
import {Col, Row} from '../../../../components/layout/Grid';
import Checkbox from '../../../../components/shared/Checkbox';
import Button from '../../../../components/shared/Button';
import {Config} from '../../../../config';
import InfoBox from '../../../../components/shared/InfoBox';

interface SubmitApplicationFranchiseChangeFormProps {
  onSubmit: () => void;
  isLoading: boolean;
}
const SubmitApplicationFranchiseChangeForm: FC<SubmitApplicationFranchiseChangeFormProps> = ({onSubmit, isLoading}) => {
  const {t} = useTranslation();
  const [disabled, setDisabled] = useState(true);
  const [form] = Form.useForm();

  return (
    <div className={'submit-application-form'}>
      <Form
        form={form}
        layout={'vertical'}
        colon={false}
        labelAlign={'left'}
        requiredMark={false}
        onFinish={onSubmit}
      >
        <Form.Item>
          <InfoBox title={t('label.notice')}>
            <ReactMarkdown>
              {t('submitFranchiseChange.info')}
            </ReactMarkdown>
          </InfoBox>
        </Form.Item>
        <Form.Item
          name={'question'}
          label={t('submitFranchiseChange.question')}
        >
          <Radio.Group onChange={({target: {value}}) => setDisabled(value !== 'yes')}>
            <Row>
              <Col key={'yes'}>
                <Radio value={'yes'}>{t('label.yes')}</Radio>
              </Col>
              <Col key={'no'}>
                <Radio value={'no'}>{t('label.no')}</Radio>
              </Col>
            </Row>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name={'acceptRegulation'}
          rules={[Validators.checkbox()]}
          valuePropName={'checked'}
        >
          <Checkbox>
            <ReactMarkdown className={'aquilana-text'} linkTarget={'_blank'}>
              {t('submitFranchiseChange.regulation', {url: Config.regulationUrl})}
            </ReactMarkdown>
          </Checkbox>
        </Form.Item>
        <Form.Item
          name={'acceptPrivacyPolicy'}
          rules={[Validators.checkbox()]}
          valuePropName={'checked'}
        >
          <Checkbox>
            <ReactMarkdown className={'aquilana-text'} linkTarget={'_blank'}>
              {t('submitFranchiseChange.privacyPolicy', {url: Config.privacyPolicyUrl})}
            </ReactMarkdown>
          </Checkbox>
        </Form.Item>
        <Button skin={'primary-gray-blue'} onClick={() => form.submit()} disabled={disabled} loading={isLoading}>
          {t('action.submit')}
        </Button>
      </Form>
    </div>
  );
};

export default observer(SubmitApplicationFranchiseChangeForm);
