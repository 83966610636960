/* eslint-disable import/no-cycle */
import {Api} from '../services/api';
import {RootStore} from './root-store';
import {Doctor} from '../models/doctor';
import {BaseStore} from './base-store';

export class DoctorStore extends BaseStore<Doctor, number> {
  constructor(rootStore: RootStore) {
    super(rootStore, 'id', Api.getDoctors);
  }

  getByZsr(zsr: string): Doctor | undefined {
    return this.models.find((doctor) => doctor.zsr === zsr);
  }
}
