import {FC, ReactElement, ReactNode, useEffect} from 'react';
import {useLocation} from 'react-router-dom';

interface ScrollToTopProps {
  children: ReactNode;
}

const ScrollToTop: FC<ScrollToTopProps> = ({children}) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (children as ReactElement);
};

export default ScrollToTop;
