// eslint-disable-next-line import/no-cycle
import {PaymentMethod, PaymentRhythm, Sex} from './person';
import {TravelInsuranceRateType} from './travel-insurance-rate';

export enum AdditionalFileIdentifier {
  HERITAGE_FILE = 'heritageFile',
  AHV_FILE = 'ahvFile',
  LEGAL_OBLIGATION_FILE = 'legalObligationFile'
}

export type IDocumentRequest = IIssue | INewBornRequest | ITravelInsuranceIssue | ICancellationUploadRequest;

export interface ITravelInsuranceIssue {
  issueType: string;
  sex: Sex | null;
  firstName: string;
  lastName: string;
  email: string;
  travelInsurances: IApiTravelInsurance[];
}

export interface IApiTravelInsurance {
  start: string;
  travelInsuranceRate: string;
  people: IApiPerson[];
}

export interface ITravelInsuranceLocalStorage {
  start?: string;
  travelInsuranceRateId?: number;
  type?: TravelInsuranceRateType;
  termOfInsuranceId?: number;
}

export interface IIssue {
  issueType: string;
  sex: Sex | null;
  firstName: string;
  lastName: string;
  email: string;
  insurances: IApiInsurance[];
  comparisId?: string;
  caseId?: string;
  caseCc?: string;
  optInCorrectness?: boolean;
  optInConsentOfOtherPeople?: boolean;
  optInPrivacyPolicy?: boolean;
}

export interface ICancellationUploadRequest {
  issueType: string;
  sex: Sex;
  firstName: string;
  lastName: string;
  birthday?: string;
  address?: string;
  city?: string;
  postcode?: number;
  email: string;
  phone?: string;
  insuranceNo: string;
  file: string;
  additionalFiles?: IIssueAdditionalFile[];
  formData: ICancellationUploadFormData | ICancellationDeathFormData | ICancellationLeaveFormData;
}

export interface IIssueAdditionalFile {
  type: AdditionalFileIdentifier;
  file: string;
}

export interface ICancellationUploadFormData {
  email?: string;
}

export interface ICancellationDeathFormData {
  birthday: string;
  deathDate: string;
  sex: Sex;
  firstName: string;
  lastName: string;
  insuranceNumber: string;
}

export interface ICancellationLeaveFormData {
  dateOfLeaving: string;
  newDomicile: string;
  temporaryResidenceAbroadReason: string;
  noMoreLegalObligationOfInsurance: boolean;
  crossBorderCommuter: boolean;
  swissEmployer: boolean;
  benefitsFromSwitzerland: string;
  workingAbroad: string;
  benefitsFromAbroad: string;
  familyJoining: string;
  sex: Sex;
  firstName: string;
  lastName: string;
  address: string;
  postcode: number;
  city: string;
  phone: string;
  email: string;
  birthday: string;
  insuranceNumber: string;
}

export interface INewBornRequest {
  sex: Sex;
  issueType: string;
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  postcode: number;
  email: string;
  phone: string;
  insuranceNo?: string;
  formData: INewBornFormData;
}

export interface INewBornFormData {
  offer: boolean;
  estimatedDateOfBirth: string;
  message: string;
}

export interface IApiInsurance {
  start?: string;
  paymentRhythm?: PaymentRhythm;
  paymentMethodPremium?: PaymentMethod;
  paymentMethodCostSharing?: PaymentMethod;
  iban?: string;
  people: IApiPerson[];
}

export interface IApiPerson {
  uuid?: string;
  firstName: string;
  lastName: string;
  address1: string;
  address2: string;
  city: string;
  phone: string;
  mobile: string;
  email: string;
  birthday: string;
  country: string;
  sex: Sex;
  language: string;
  insuranceNo?: string;
  doctor?: string;
  type?: PersonType;
  premiumReductionBaseInsurance?: boolean;
  premiumReductionAddonInsurances?: boolean;
  baseInsurance?: IApiBaseInsurance;
  addonInsurances?: IApiAddonInsurance[];
  moveInFromAbroad: boolean;
  dateOfArrival: string | null;
  childPremiumReduction: boolean;
  insurer?: string;
}

export enum PersonType {
  DEFAULT = 'default',
  SUPERVISOR = 'supervisor',
  POLICY_HOLDER = 'policy-holder'
}

export interface IApiBaseInsurance {
  baseInsuranceRate: string;
  oldBaseInsuranceRate?: string;
  start: string;
  accidentCoverage: boolean;
  insurer?: string;
  hasPreviousInsurer?: boolean;
}

export interface IApiAddonInsurance {
  addonInsuranceRate: string;
  start: string;
  insurer?: string;
}
