import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {DatePicker, Form, Input, Radio} from 'antd';
import {observer} from 'mobx-react-lite';
import {useNavigate} from 'react-router-dom';
import dayjs from 'dayjs';
import {Validators} from '../../../../utils/validators';
import {useStore} from '../../../../hooks/useStore';
import {ContactLanguage, IPerson, Sex} from '../../../../models/person';
import InfoBox from '../../../../components/shared/InfoBox';
import Select from '../../../../components/shared/Select';
import {Config} from '../../../../config';
import StepsNavigationButtons from '../../../../components/StepsNavigationButtons';
import {sortAlphabetically} from '../../../../utils/sorters';
import {City} from '../../../../models/city';
import {Api} from '../../../../services/api';
import {CalculatorPaths} from '../../../paths';
import OptionalLabel from '../../../../components/shared/OptionalLabel';
import {formatInsuranceNumber} from '../../../../utils/formatter';

// eslint-disable-next-line max-len
interface FormValues extends Required<Pick<IPerson, 'sex' | 'firstName' | 'lastName' | 'residenceInSwitzerland' | 'moveInFromAbroad' | 'dateOfArrival'>> {
  birthday: Date;
  address: string;
  city: City['id'];
  email: string;
  phone: string;
  insuranceNumber: string;
}

const NewBornForm: FC = () => {
  const {t} = useTranslation();
  const store = useStore();
  const navigate = useNavigate();
  const [form] = Form.useForm<FormValues>();
  const [options, setOptions] = useState<{ label: string, value: number }[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!store.cityStore.isLoading) {
      setOptions(
        store.cityStore.models
          .map((city) => ({label: city.label, value: city.id}))
          .sort((a, b) => sortAlphabetically(a.label, b.label))
      );
    }
  }, [store.cityStore.isLoading]);

  const onFinish = (values: FormValues) => {
    setLoading(true);
    store.calculatorStore.setSex(values.sex);
    store.calculatorStore.setNewbornEstimatedBirthDate(dayjs(values.birthday));
    store.calculatorStore.setFirstName(values.firstName);
    store.calculatorStore.setLastName(values.lastName);
    store.calculatorStore.setAddress(values.address);
    store.calculatorStore.setEmail(values.email);
    store.calculatorStore.setPhone(values.phone);
    store.calculatorStore.setInsuranceNumber(values.insuranceNumber);
    store.calculatorStore.setContactLanguage(ContactLanguage.DE); // TODO: change when multi-language is enabled

    Api.sendRequest(store.calculatorStore.getNewbornRequest())
      .then(() => {
        navigate(CalculatorPaths.successNewBorn());
        setLoading(false);
      })
      .catch(() => {
        // TODO: add error handling
        setLoading(false);
      });
  };

  const previousStep = () => {
    navigate(CalculatorPaths.overview());
  };

  const initialValues: Partial<FormValues> = {
    city: store.calculatorStore.city?.id,
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, []);

  return (
    <div>
      <Form<FormValues>
        form={form}
        layout={'vertical'}
        colon={false}
        labelAlign={'left'}
        requiredMark={false}
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <Form.Item
          name={'birthday'}
          label={t('label.plannedBirthdayQuestion')}
          rules={[Validators.required(t('validator.plannedBirthdayRequired'))]}
        >
          <DatePicker
            format={Config.dateFormat}
            placeholder={t('label.dateFormat')}
            disabledDate={(d) => d.isBefore(dayjs().startOf('day').add(1, 'day'))
              || d.isAfter(dayjs().startOf('day').add(2, 'year'))}
            showToday={false}
          />
        </Form.Item>
        <Form.Item>
          <InfoBox title={t('person.detailsInfo.title')}>
            {t('person.detailsInfo.text')}
          </InfoBox>
        </Form.Item>
        <Form.Item
          name={'salutation'}
          label={t('label.policyholder')}
          rules={[Validators.required(t('validator.policyholderRequired'))]}
        >
          <Radio.Group>
            {Object.values(Sex).map((sex) => (
              <Radio key={sex} value={sex}>{t(`sex.${sex}`)}</Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name={'firstName'}
          label={t('label.firstName')}
          rules={[Validators.required(t('validator.firstNameRequired')), Validators.noSpecialCharacters]}
        >
          <Input placeholder={t('label.firstName')} maxLength={50}/>
        </Form.Item>
        <Form.Item
          name={'lastName'}
          label={t('label.name')}
          rules={[Validators.required(t('validator.nameRequired')), Validators.noSpecialCharacters]}
        >
          <Input placeholder={t('label.name')} maxLength={50}/>
        </Form.Item>
        <Form.Item
          name={'address'}
          label={t('label.address')}
          rules={[Validators.required(t('validator.addressRequired')), Validators.noSpecialCharacters]}
        >
          <Input placeholder={t('label.address')} maxLength={50}/>
        </Form.Item>
        <Form.Item
          name={'city'}
          label={t('label.postcodeCity')}
          rules={[Validators.required(t('validator.postcodeCityRequired'))]}
        >
          <Select
            disabled
            loading={store.cityStore.isLoading}
            options={options}
            placeholder={t('label.postcodeCityPlaceholder')}
          />
        </Form.Item>
        <Form.Item
          name={'email'}
          label={t('label.email')}
          rules={[Validators.required(t('validator.emailRequired')), Validators.email]}
        >
          <Input placeholder={t('label.email')} maxLength={255}/>
        </Form.Item>
        <Form.Item
          name={'phone'}
          label={t('label.phone')}
          rules={[Validators.required(t('validator.phoneRequired')), Validators.phone]}
        >
          <Input placeholder={t('label.phone')} maxLength={50}/>
        </Form.Item>
        <Form.Item
          name={'insuranceNumber'}
          label={<OptionalLabel label={t('label.insuranceNumber')}/>}
          rules={[Validators.insuranceNumber]}
        >
          <Input placeholder={t('label.insuranceNumberPlaceholder')} onInput={formatInsuranceNumber}/>
        </Form.Item>
        <StepsNavigationButtons
          back={() => previousStep()}
          next={() => form.submit()}
          nextLabel={t('action.submit')}
          loading={loading}
        />
      </Form>
    </div>
  );
};

export default observer(NewBornForm);
