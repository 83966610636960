import React from 'react';
import {useTranslation} from 'react-i18next';
import Container from '../../components/layout/Container';
import Text from '../../components/shared/Text';
import CancellationCard, {CancellationCardProps} from './_components/CancellationCard';
import {Col, Row} from '../../components/layout/Grid';
import {GUTTER_SIZE} from '../../config';
import Space from '../../components/shared/Space';
import {
  CancellationDeathPaths, CancellationLeavePaths, CancellationUploadPaths, CancellationWizardPaths
} from '../paths';
import './CancellationOverview.scss';

interface CardContent extends CancellationCardProps {
  key: string;
}

const CancellationOverview = () => {
  const {t} = useTranslation();

  const cardsPrivate: CardContent[] = [
    {
      key: 'wizard',
      title: t('cancellation.overview.card.wizard.title'),
      checkList: t('cancellation.overview.card.wizard.checkList'),
      navigateTo: CancellationWizardPaths.index(),
    },
    {
      key: 'leave',
      title: t('cancellation.overview.card.leave.title'),
      checkList: t('cancellation.overview.card.leave.checkList'),
      navigateTo: CancellationLeavePaths.index(),
    },
    {
      key: 'death',
      title: t('cancellation.overview.card.death.title'),
      checkList: t('cancellation.overview.card.death.checkList'),
      navigateTo: CancellationDeathPaths.index(),
    },
  ];

  const cardsBusiness: CardContent[] = [
    {
      key: 'upload',
      title: t('cancellation.overview.card.upload.title'),
      text: t('cancellation.overview.card.upload.text'),
      checkList: t('cancellation.overview.card.upload.checkList'),
      buttonText: t('cancellation.overview.card.upload.buttonText'),
      navigateTo: CancellationUploadPaths.index(),
    },
  ];

  return (
    <>
      <Container background={'white'} className={'cancellation-overview-container'}>
        <Text header={1}>{t('cancellation.overview.title')}</Text>
        <Text size={'intro'}>{t('cancellation.overview.text')}</Text>
      </Container>
      <Container background={'light'} flex={1} className={'cancellation-overview-container'}>
        <Space direction={'vertical'} size={'large'} fullWidth>
          <Text header={4}>
            {t('cancellation.overview.cards.private.title')}
          </Text>
          <Row gutter={[GUTTER_SIZE, GUTTER_SIZE]} style={{marginBottom: '3rem'}}>
            {cardsPrivate.map((card) => (
              <Col key={card.key} xs={24} sm={24} md={24} lg={12} xl={8}>
                <CancellationCard {...card}/>
              </Col>
            ))}
          </Row>
          <Text header={4}>
            {t('cancellation.overview.cards.business.title')}
          </Text>
          <Row gutter={[GUTTER_SIZE, GUTTER_SIZE]}>
            {cardsBusiness.map((card) => (
              <Col key={card.key} xs={24} sm={24} md={24} lg={12} xl={8}>
                <CancellationCard {...card}/>
              </Col>
            ))}
          </Row>
        </Space>
      </Container>
    </>
  );
};

export default CancellationOverview;
