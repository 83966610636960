import React, {FC, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {DatePicker, Form, Select} from 'antd';
import {RefSelectProps} from 'antd/lib/select/index';
import {observer} from 'mobx-react-lite';
import {useNavigate} from 'react-router-dom';
import dayjs, {Dayjs} from 'dayjs';
import {City} from '../../../../models/city';
import {useStore} from '../../../../hooks/useStore';
import useCitySelection from '../../../../hooks/useCitySelection';
import useCurrentDomainInformation from '../../../../hooks/useCurrentDomainInformation';
import {CalculatorPaths} from '../../../paths';
import {updateInsuranceRates} from '../../../../utils/insurance-rates';
import {BaseInsurance, BaseInsuranceType} from '../../../../models/base-insurance';
import {AddonInsurance, AddonInsuranceType, PatientCareType} from '../../../../models/addon-insurance';
import {Validators} from '../../../../utils/validators';
import {Config} from '../../../../config';
import InfoBox from '../../../../components/shared/InfoBox';
import Button from '../../../../components/shared/Button';
import StepsNavigationButtons from '../../../../components/StepsNavigationButtons';

interface FormValues {
  birthday: Dayjs;
  city: City['id'];
}

const PersonForm: FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const store = useStore();
  const [form] = Form.useForm<FormValues>();
  const citySelector = useRef<RefSelectProps>(null);
  const {options} = useCitySelection();
  const [birthdayRequired, setBirthdayRequired] = useState(true);
  const person = store.calculatorStore.current;
  const {currentStore} = useCurrentDomainInformation();

  const onFinish = (values: FormValues) => {
    if (birthdayRequired) {
      person.setBirthday(dayjs(values.birthday));
    }
    if (!person.uuid.length) {
      person.createId();
    }
    store.calculatorStore.setCity(store.cityStore.getById(values.city));

    if (birthdayRequired) {
      store.calculatorStore.saveToSession();
      if (!store.editMode) {
        navigate(CalculatorPaths.productSelection());
      } else {
        updateInsuranceRates(person, store, currentStore);
        store.setEditMode(false);
        navigate(CalculatorPaths.overview());
      }
    } else {
      const baseInsurance = new BaseInsurance();
      baseInsurance.setFranchiseGroup(store.franchiseGroupStore.getById(1));
      const baseInsuranceRate = store.baseInsuranceRateStore.findOne(
        BaseInsuranceType.STANDARD,
        1,
        person.age,
        store.calculatorStore.city?.regionCode || '',
        person.insuranceStart
      );
      baseInsurance.withAccident = true;
      baseInsurance.setRate(baseInsuranceRate);
      person.setBaseInsurance(baseInsurance);

      person.setAddonInsurances({});
      const patientCarePlus = new AddonInsurance();
      patientCarePlus.type = AddonInsuranceType.PATIENT_CARE_PLUS;
      const patientCarePlusRates = store.addonInsuranceRateStore.findByTypeAndPerson(patientCarePlus.type, person);
      patientCarePlus.setRate(patientCarePlusRates[0]);
      person.addAddonInsurance(patientCarePlus);

      const patientCareTop = new AddonInsurance();
      patientCareTop.type = AddonInsuranceType.PATIENT_CARE_TOP;
      const patientCareTopRates = store.addonInsuranceRateStore.findByTypeAndPerson(patientCareTop.type, person);
      patientCareTop.setRate(patientCareTopRates[0]);
      person.addAddonInsurance(patientCareTop);
      person.addAddonInsurance(patientCarePlus);

      const patientCare = new AddonInsurance();
      patientCare.type = AddonInsuranceType.PATIENT_CARE;
      const patientCareRate = store.addonInsuranceRateStore.findByTypeAndPerson(patientCare.type, person)
        .find((rate) => rate.variant === PatientCareType.NORMAL);
      patientCare.setRate(patientCareRate);
      person.addAddonInsurance(patientCare);

      const accidentDeathDisability = new AddonInsurance();
      accidentDeathDisability.type = AddonInsuranceType.ACCIDENT_DEATH_DISABILITY;
      const accidentDeathDisabilityRate = store.addonInsuranceRateStore
        .findByTypeAndPerson(accidentDeathDisability.type, person)
        .find((rate) => rate.death === 2500 && rate.invalidity === 100000);
      accidentDeathDisability.setRate(accidentDeathDisabilityRate);
      person.addAddonInsurance(accidentDeathDisability);

      store.calculatorStore.saveToSession();
      navigate(CalculatorPaths.overview());
    }
  };

  const focusCitySelector = () => {
    citySelector?.current?.focus();
  };

  const initialValues = {
    birthday: person?.birthday ? dayjs(person.birthday) : undefined,
    city: store.calculatorStore.city?.id,
  };

  const startCalculation = (isBirthdayRequired: boolean) => {
    setBirthdayRequired(isBirthdayRequired);
    form.submit();
  };

  const setBirthday = (value: string) => {
    if (value) {
      try {
        const date = dayjs(value, 'DD.MM.YYYY');
        if (date.isBefore(dayjs().endOf('day'))) {
          form.setFieldsValue({
            birthday: date,
          });
        }
      } catch (_) {
        // ignore
      }
    }
  };

  const back = () => {
    if (!store.calculatorStore.current.birthday) {
      store.calculatorStore.remove(store.calculatorStore.current);
      store.calculatorStore.setCurrentIndex(0);
    }
    navigate(CalculatorPaths.overview());
  };

  return (
    <div>
      <Form<FormValues>
        form={form}
        layout={'vertical'}
        colon={false}
        labelAlign={'left'}
        requiredMark={false}
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <Form.Item
          name={'birthday'}
          label={t('label.birthday')}
          rules={[Validators.requiredIf(birthdayRequired, t('validator.birthdayRequired'))]}
        >
          <DatePicker
            format={Config.dateFormat}
            placeholder={t('label.dateFormat')}
            onChange={() => focusCitySelector()}
            disabledDate={(d) => d.isAfter(dayjs().endOf('day'))
              || d.isBefore(dayjs().startOf('day').subtract(150, 'years'))}
            onBlur={(e) => {
              // @ts-ignore
              setBirthday(e.currentTarget.value);
            }}
          />
        </Form.Item>
        <Form.Item
          name={'city'}
          label={t('label.postcodeCity')}
          rules={[Validators.required(t('validator.postcodeCityRequired'))]}
        >
          <Select
            showSearch
            loading={store.cityStore.isLoading}
            options={options}
            filterOption={(input, option) => option!.label.toLowerCase().includes(input.toLowerCase())}
            placeholder={t('label.postcodeCityPlaceholder')}
            ref={citySelector}
            disabled={store.calculatorStore.getIndex(person) !== 0}
          />
        </Form.Item>
        <Form.Item>
          <InfoBox title={t('person.genderInfo.title')}>
            {t('person.genderInfo.text')}
          </InfoBox>
        </Form.Item>
        {!store.editMode && person.uuid.length ? (
          <>
            <Form.Item>
              <Button type={'submit'} skin={'primary-gray-blue'} onClick={() => startCalculation(true)}>
                {t('action.startRateCalculation')}
              </Button>
            </Form.Item>
            {store.calculatorStore.getIndex(person) === 0 && (
              <Form.Item>
                <Button type={'link'} onClick={() => startCalculation(false)}>
                  {t('action.startCalculationForNewBorn')}
                </Button>
              </Form.Item>
            )}
          </>
        ) : (
          <StepsNavigationButtons
            back={() => back()}
            next={() => form.submit()}
          />
        )}
      </Form>
    </div>
  );
};

export default observer(PersonForm);
