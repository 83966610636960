import React, {useEffect, useState} from 'react';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {Spin} from 'antd';
import {observer} from 'mobx-react-lite';
import StepPerson from './person/StepPerson';
import ProductSelection from './product/ProductSelection';
import BaseInsuranceSelection from './product/BaseInsuranceSelection';
import AddonInsuranceSelection from './product/AddonInsuranceSelection';
import Overview from './overview/Overview';
import Details from './details/Details';
import DoctorSelection from './details/DoctorSelection';
import Contact from './details/Contact';
import Payment from './finish/Payment';
import Finish from './finish/Finish';
import Success from './success/Success';
import {useStore} from '../../hooks/useStore';
import {Person} from '../../models/person';
import {PersonType} from '../../models/document-request';
import {CalculatorPaths} from '../paths';
import RestoredSessionModal from '../../components/RestoredSessionModal';

const CalculatorRoutes = () => {
  const store = useStore();
  const navigate = useNavigate();
  const location = useLocation();
  const [isInitialLoad, setIsInitialLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showRestoredSessionModal, setShowRestoredSessionModal] = useState(false);

  useEffect(() => {
    const isStoreLoading = store.cityStore.isLoading
      || store.franchiseGroupStore.isLoading
      || store.baseInsuranceRateStore.isLoading
      || store.addonInsuranceRateStore.isLoading
      || store.premiumVersionStore.isLoading
      || store.calculatorStore.isLoading
      || store.franchiseChangeStore.isLoading
      || store.travelInsuranceTypeStore.isLoading
      || store.termOfInsuranceStore.isLoading
      || store.travelInsuranceRateStore.isLoading
      || store.travelInsuranceStore.isLoading;

    if (!isStoreLoading) {
      if (isInitialLoad) {
        store.baseInsuranceRateStore.models.forEach((m) => {
          m.setPremiumVersion(store.premiumVersionStore.getById(m.premiumVersionId));
        });
        store.addonInsuranceRateStore.models.forEach((m) => {
          m.setPremiumVersion(store.premiumVersionStore.getById(m.premiumVersionId));
        });
        const params = new URLSearchParams(location.search);
        const birthday = params.get('birthday');
        const cityId = params.get('cityId');
        const postcode = params.get('postcode');
        const gc = params.get('gc');
        const cc = params.get('cc');

        if (birthday) {
          const city = (() => {
            if (postcode) return store.cityStore.findByPostcode(Number(postcode));
            if (cityId) return store.cityStore.getById(Number(cityId));
            return null;
          })();
          const person = Person.create({
            type: PersonType.POLICY_HOLDER,
            birthday: new Date(birthday).toISOString(),
          });
          store.calculatorStore.setModels([person]);

          if (city) {
            store.calculatorStore.setCity(city);
            navigate(CalculatorPaths.productSelection());
          }
          setIsLoading(false);
        } else if (gc && cc) {
          store.calculatorStore.loadByReference(gc, cc).then(() => {
            navigate(CalculatorPaths.overview());
            setIsLoading(false);
          });
        } else if (store.calculatorStore.loadFromSession()) {
          setShowRestoredSessionModal(true);
          navigate(CalculatorPaths.overview());
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }

      setIsInitialLoad(false);
    }
  }, [
    store.cityStore.isLoading,
    store.franchiseGroupStore.isLoading,
    store.baseInsuranceRateStore.isLoading,
    store.addonInsuranceRateStore.isLoading,
    store.premiumVersionStore.isLoading,
    store.franchiseChangeStore.isLoading,
    store.travelInsuranceTypeStore.isLoading,
    store.termOfInsuranceStore.isLoading,
    store.travelInsuranceRateStore.isLoading,
    store.travelInsuranceStore.isLoading,
    isInitialLoad,
  ]);

  useEffect(() => {
    setIsInitialLoad(true);
  }, []);

  if (isLoading) {
    return <Spin style={{marginTop: 30, marginBottom: 30}}/>;
  }

  return (
    <>
      <Routes>
        <Route index element={<StepPerson/>}/>
        <Route path={'product'} element={<ProductSelection/>}/>
        <Route path={'product/base'} element={<BaseInsuranceSelection/>}/>
        <Route path={'product/addon'} element={<AddonInsuranceSelection/>}/>
        <Route path={'overview'} element={<Overview/>}/>
        <Route path={'details'} element={<Details/>}/>
        <Route path={'details/doctor'} element={<DoctorSelection/>}/>
        <Route path={'details/payment'} element={<Payment/>}/>
        <Route path={'details/contact'} element={<Contact/>}/>
        <Route path={'finish/overview'} element={<Finish/>}/>
        <Route path={'finish/success/submission'} element={<Success type={'submission'}/>}/>
        <Route path={'finish/success/request'} element={<Success type={'request'}/>}/>
        <Route path={'finish/success/new-born'} element={<Success type={'newBorn'}/>}/>
      </Routes>
      <RestoredSessionModal showModal={showRestoredSessionModal} setShowModal={setShowRestoredSessionModal}/>
    </>
  );
};

export default observer(CalculatorRoutes);
