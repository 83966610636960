import {useTranslation} from 'react-i18next';
import {FC} from 'react';
import Container from '../../../components/layout/Container';
import Text from '../../../components/shared/Text';
import {Col, Row} from '../../../components/layout/Grid';
import PersonForm from './_components/PersonForm';

const StepPerson: FC = () => {
  const {t} = useTranslation();

  return (
    <>
      <Container background={'white'}>
        <Text header={1}>{t('stepPerson.title')}</Text>
        <Text size={'intro'}>{t('stepPerson.text')}</Text>
      </Container>
      <Container background={'light'} flex={1}>
        <Row>
          <Col xs={24} sm={24} md={24} lg={12} xl={8}>
            <PersonForm/>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default StepPerson;
