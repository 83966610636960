import React from 'react';
import {useTranslation} from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import Container from '../../../components/layout/Container';
import Text from '../../../components/shared/Text';
import Space from '../../../components/shared/Space';
import {Box, BoxWrapper} from '../../../components/shared/Box';
import Icon from '../../../components/shared/Icon';
import {PoliceIcon} from '../../../components/shared/icons';
import {Col, Row} from '../../../components/layout/Grid';
import {Config, GUTTER_SIZE} from '../../../config';
import Button from '../../../components/shared/Button';
import {useStore} from '../../../hooks/useStore';
import InfoBox from '../../../components/shared/InfoBox';

const Success = () => {
  const {t} = useTranslation();
  const store = useStore();

  const toWebsite = () => {
    window.location.href = Config.websiteUrl;
  };

  return (
    <>
      <Container background={'white'}>
        <Text header={1}>{t('cancellation.wizard.success.title')}</Text>
      </Container>
      <Container background={'light'} flex={1}>
        <Space direction={'vertical'} size={'large'}>
          <BoxWrapper>
            <Box flex={'0 0 70px'}>
              <Icon component={PoliceIcon} size={50} color={'gray-blue'}/>
            </Box>
            <Box flex={'auto'}>
              <Text medium color={'gray-blue'}>
                <ReactMarkdown linkTarget={'_blank'}>
                  {t('cancellation.wizard.success.text', {email: store.cancellationWizardStore.email})}
                </ReactMarkdown>
              </Text>
            </Box>
          </BoxWrapper>
          <InfoBox title={t('cancellation.wizard.success.info.title')}>
            <ReactMarkdown linkTarget={'_blank'}>
              {t('cancellation.wizard.success.info.text', {contactUrl: Config.aquilanaContactUrl})}
            </ReactMarkdown>
          </InfoBox>
          <Row gutter={[GUTTER_SIZE, GUTTER_SIZE]} style={{marginBottom: '2rem', marginTop: '3rem'}}>
            <Col>
              <Button onClick={() => toWebsite()} skin={'primary-gray-blue'}>
                {t('action.toWebsite')}
              </Button>
            </Col>
          </Row>
        </Space>
      </Container>
    </>
  );
};

export default Success;
