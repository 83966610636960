import {makeObservable, observable} from 'mobx';
import {IBaseModel} from './base-model';

export interface ITermOfInsurance extends IBaseModel {
  id: number;
  days: number;
}

export class TermOfInsurance implements ITermOfInsurance {
  id = 0;
  days = 0;

  constructor() {
    makeObservable(this, {
      id: observable,
      days: observable,

    });
  }

  static fromJSON(data: ITermOfInsurance): TermOfInsurance {
    const termOfInsurance = new TermOfInsurance();
    termOfInsurance.id = data.id;
    termOfInsurance.days = data.days;

    return termOfInsurance;
  }
}
