import {ReactComponent as ArrowLeft} from '../../assets/icons/arrow-left.svg';
import {ReactComponent as ArrowRight} from '../../assets/icons/arrow-right.svg';
import {ReactComponent as TrashBin} from '../../assets/icons/trash-bin.svg';
import {ReactComponent as Okp} from '../../assets/icons/products/obligatory-patient-care.svg';
import {ReactComponent as Casamed} from '../../assets/icons/products/obligatory-patient-care-casamedi.svg';
import {ReactComponent as Avatar} from '../../assets/icons/avatar.svg';
import {ReactComponent as Pen} from '../../assets/icons/pen.svg';
import {ReactComponent as Price} from '../../assets/icons/price.svg';
import {ReactComponent as PatientCarePlus} from '../../assets/icons/products/patient-care-plus.svg';
import {ReactComponent as PatientCareTop} from '../../assets/icons/products/patient-care-top.svg';
import {ReactComponent as PatientCare} from '../../assets/icons/products/patient-care.svg';
import {ReactComponent as ToothCare1} from '../../assets/icons/products/tooth-care.svg';
import {ReactComponent as ToothCare2} from '../../assets/icons/products/tooth-care-2.svg';
import {ReactComponent as AccidentDeathDisability} from '../../assets/icons/products/accident-death-disability.svg';
import {ReactComponent as Information} from '../../assets/icons/information.svg';
import {ReactComponent as Documents} from '../../assets/icons/documents.svg';
import {ReactComponent as Print} from '../../assets/icons/print.svg';
import {ReactComponent as DisablementHandicap} from '../../assets/icons/products/disablement-handicap.svg';
import {ReactComponent as FormularAssignment} from '../../assets/icons/formular-assignment.svg';
import {ReactComponent as AssignmentRights} from '../../assets/icons/assignment-rights.svg';
import {ReactComponent as Police} from '../../assets/icons/police.svg';
import {ReactComponent as PriceOverview} from '../../assets/icons/price-overview.svg';
import {ReactComponent as SqsGoodPrivacy} from '../../assets/icons/sqs-goodprivacy.svg';
import {ReactComponent as SqsIso1991} from '../../assets/icons/sqs-iso-1991.svg';
import {ReactComponent as SsVdsz} from '../../assets/icons/sqs-vdsz.svg';
import {ReactComponent as AbroadSecure} from '../../assets/icons/abroad-secure.svg';
import {ReactComponent as Suspension} from '../../assets/icons/suspension.svg';

export const CasamedIcon = Casamed;
export const StandardIcon = Okp;
export const SmartmedIcon = PatientCareTop;
export const ArrowLeftIcon = ArrowLeft;
export const ArrowRightIcon = ArrowRight;
export const TrashBinIcon = TrashBin;
export const AvatarIcon = Avatar;
export const PenIcon = Pen;
export const BaseInsuranceIcon = Okp;
export const AddonInsuranceIcon = PatientCarePlus;
export const PriceIcon = Price;
export const InformationIcon = Information;
export const DocumentsIcon = Documents;
export const PrintIcon = Print;
export const SqsGoodPrivacyIcon = SqsGoodPrivacy;
export const SqsIso1991Icon = SqsIso1991;
export const SsVdszIcon = SsVdsz;

export const PatientCarePlusIcon = PatientCarePlus;
export const PatientCareTopIcon = PatientCareTop;
export const PatientCareIcon = PatientCare;
export const ToothCare1Icon = ToothCare1;
export const ToothCare2Icon = ToothCare2;
export const AccidentDeathDisabilityIcon = AccidentDeathDisability;
export const DisablementHandicapIcon = DisablementHandicap;
export const FormularAssignmentIcon = FormularAssignment;
export const AssignmentRightsIcon = AssignmentRights;
export const PoliceIcon = Police;
export const PriceOverviewIcon = PriceOverview;
export const AbroadSecureIcon = AbroadSecure;
export const SuspensionIcon = Suspension;
