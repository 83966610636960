/* eslint-disable import/no-cycle */
import React, {createContext, FC, PropsWithChildren} from 'react';
import {RootStore} from '../stores/root-store';

export const StoreContext = createContext(new RootStore());

export const StoreContextProvider: FC<PropsWithChildren<unknown>> = ({children}) => {
  const [contextStore] = React.useState<RootStore>(new RootStore());

  return (
    <StoreContext.Provider value={contextStore}>
      {children}
    </StoreContext.Provider>
  );
};
