import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Form, Input, Radio} from 'antd';
import {observer} from 'mobx-react-lite';
import {useNavigate} from 'react-router-dom';
import {Validators} from '../../../../utils/validators';
import {useStore} from '../../../../hooks/useStore';
import {Sex} from '../../../../models/person';
import {FranchiseChangePaths} from '../../../paths';
import StepsNavigationButtons from '../../../../components/StepsNavigationButtons';
import { formatInsuranceNumber } from '../../../../utils/formatter';

interface FormValues {
  sex: Sex;
  firstName: string;
  lastName: string;
  address: string;
  insuranceNo: string;
}

interface FranchiseChangePersonDetailsFormProps {
  afterSubmit: () => void;
}

const FranchiseChangePersonDetailsForm: FC<FranchiseChangePersonDetailsFormProps> = ({afterSubmit}) => {
  const {t} = useTranslation();
  const store = useStore();
  const navigate = useNavigate();
  const [form] = Form.useForm<FormValues>();
  const person = store.franchiseChangeStore.current;

  if (!person) {
    return null;
  }

  const onFinish = (values: FormValues) => {
    person.setSex(values.sex);
    person.setFirstName(values.firstName);
    person.setLastName(values.lastName);
    store.franchiseChangeStore.setAddress(values.address);
    person.setInsuranceNo(values.insuranceNo);

    store.franchiseChangeStore.saveToSession();
    afterSubmit();
  };

  const initialValues: Partial<FormValues> = {
    sex: person.sex,
    firstName: person.firstName,
    lastName: person.lastName,
    address: store.franchiseChangeStore.address,
    insuranceNo: person.insuranceNo,
  };

  const previousStep = () => {
    if (store.editMode) {
      store.setEditMode(false);
      navigate(FranchiseChangePaths.overview());
    } else {
      navigate(FranchiseChangePaths.new());
    }
  };

  return (
    <Form<FormValues>
      form={form}
      layout={'vertical'}
      colon={false}
      labelAlign={'left'}
      requiredMark={false}
      onFinish={onFinish}
      initialValues={initialValues}
    >
      <Form.Item
        name={'sex'}
        label={t('label.sex')}
        rules={[Validators.required(t('validator.salutationRequired'))]}
      >
        <Radio.Group>
          {Object.values(Sex).map((sex) => (
            <Radio key={sex} value={sex}>{t(`sex.${sex}`)}</Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name={'firstName'}
        label={t('label.firstName')}
        rules={[Validators.required(t('validator.firstNameRequired')), Validators.noSpecialCharacters]}
      >
        <Input placeholder={t('label.firstName')} maxLength={50}/>
      </Form.Item>
      <Form.Item
        name={'lastName'}
        label={t('label.name')}
        rules={[Validators.required(t('validator.nameRequired')), Validators.noSpecialCharacters]}
      >
        <Input placeholder={t('label.name')} maxLength={50}/>
      </Form.Item>
      <Form.Item
        name={'address'}
        label={t('label.address')}
        rules={[Validators.required(t('validator.addressRequired')), Validators.noSpecialCharacters]}
      >
        <Input
          disabled={store.franchiseChangeStore.currentIndex !== 0}
          placeholder={t('label.address')}
          maxLength={50}
        />
      </Form.Item>
      <Form.Item
        name={'insuranceNo'}
        label={t('label.insuranceNumber')}
        rules={[Validators.insuranceNumber]}
      >
        <Input
          placeholder={t('label.insuranceNumberPlaceholder')}
          onInput={formatInsuranceNumber}
        />
      </Form.Item>
      <StepsNavigationButtons
        backHidden={store.editMode}
        back={() => previousStep()}
        next={() => form.submit()}
      />
    </Form>
  );
};

export default observer(FranchiseChangePersonDetailsForm);
