import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import Container from '../../components/layout/Container';
import Text from '../../components/shared/Text';
import {Col, Row} from '../../components/layout/Grid';
import Button from '../../components/shared/Button';
import Space from '../../components/shared/Space';
import Icon from '../../components/shared/Icon';
import {ArrowRightIcon} from '../../components/shared/icons';
import {Config} from '../../config';
import {
  CalculatorPaths, CancellationOverviewPath, FranchiseChangePaths, TravelInsurancePaths
} from '../paths';
import './NotFound.scss';

const NotFound = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const toWebsite = () => {
    window.location.href = Config.websiteUrl;
  };

  return (
    <>
      <Container background={'light'} className={'main-container'} noPadding>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <Text header={1}>{t('notFound.main.title')}</Text>
            <Text size={'intro'}>{t('notFound.main.subTitle')}</Text>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <img src={'/img/404_page_error.png'} alt={'Not found Logo'}/>
          </Col>
        </Row>
      </Container>
      <Container background={'white'} flex={1}>
        <Space direction={'vertical'} size={'middle'}>
          <Text size={'intro'}>{t('notFound.secondary.introText')}</Text>
          <Space>
            <Icon component={ArrowRightIcon} size={18}/>
            <Button type={'link'} onClick={() => navigate(CalculatorPaths.index())}>{t('context.calculator')}</Button>
          </Space>
          <Space>
            <Icon component={ArrowRightIcon} size={18}/>
            <Button
              type={'link'}
              onClick={() => navigate(FranchiseChangePaths.index())}
            >
              {t('context.franchiseChange')}
            </Button>
          </Space>
          <Space>
            <Icon component={ArrowRightIcon} size={18}/>
            <Button
              type={'link'}
              onClick={() => navigate(TravelInsurancePaths.index())}
            >
              {t('context.travelInsurance')}
            </Button>
          </Space>
          <Space>
            <Icon component={ArrowRightIcon} size={18}/>
            <Button
              type={'link'}
              onClick={() => navigate(CancellationOverviewPath)}
            >
              {t('context.cancellationOverview')}
            </Button>
          </Space>
          <Space>
            <Icon component={ArrowRightIcon} size={18}/>
            <Button type={'link'} onClick={toWebsite}>{t('action.toWebsite')}</Button>
          </Space>
          <Text size={'intro'}>
            <ReactMarkdown>
              {t('notFound.secondary.contactText', {
                email: Config.contact.email, phone: Config.contact.phone, phoneLink: Config.contact.phoneLink,
              })}
            </ReactMarkdown>
          </Text>
        </Space>
      </Container>
    </>
  );
};

export default NotFound;
