import {useTranslation} from 'react-i18next';
import {FC, useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Navigate, useNavigate} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import Container from '../../../components/layout/Container';
import {useStore} from '../../../hooks/useStore';
import Button from '../../../components/shared/Button';
import Text from '../../../components/shared/Text';
import {Api} from '../../../services/api';
import {Col, Row} from '../../../components/layout/Grid';
import {GUTTER_SIZE} from '../../../config';
import {CalculatorPaths} from '../../paths';
import SubmitApplicationForm from '../../../components/SubmitApplicationForm';
import PeopleOverview from '../../../components/PeopleOverview';

const Finish: FC = () => {
  const {t} = useTranslation();
  const store = useStore();
  const navigate = useNavigate();
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const submitApplicationForm = useRef<HTMLDivElement>(null);

  const {canSubmitApplication} = store.calculatorStore;

  const performAction = () => {
    if (canSubmitApplication) {
      if (submitApplicationForm.current) {
        submitApplicationForm.current.scrollIntoView({behavior: 'smooth', block: 'start'});
      }
    } else {
      setIsSending(true);
      Api.sendRequest(store.calculatorStore.getIssueRequest())
        .then(() => {
          navigate(CalculatorPaths.successRequest());
          setIsSending(false);
        })
        .catch(() => {
          // TODO: add error handling
          setIsSending(false);
        });
    }
  };

  const downloadPdf = () => {
    setIsLoading(true);
    Api.getRequestPdf(store.calculatorStore.getIssueRequest())
      .then(async (data) => {
        if (data.pdfdata) {
          const dataURI = `data:application/pdf;base64,${encodeURI(data.pdfdata)}`;
          const base64Response = await fetch(dataURI);
          const blob = await base64Response.blob();

          const a = document.createElement('a');
          a.href = window.URL.createObjectURL(blob);
          a.download = 'Antrag.pdf';
          a.target = '_blank';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          // TODO: add error handling
        }
        setIsLoading(false);
      })
      .catch(() => {
        // TODO: add error handling
        setIsLoading(false);
      });
  };

  if (!store.calculatorStore.policyHolder || !store.calculatorStore.email) {
    return (
      <Navigate replace to={CalculatorPaths.overview()}/>
    );
  }

  return (
    <>
      <Container background={'white'}>
        {canSubmitApplication ? (
          <>
            <Text header={1}>{t('stepFinish.submitApplication.title')}</Text>
            <Text size={'intro'}>
              <ReactMarkdown linkTarget={'_blank'}>
                {t('stepFinish.submitApplication.text')}
              </ReactMarkdown>
            </Text>
          </>
        ) : (
          <>
            <Text header={1}>{t('stepFinish.requestQuote.title')}</Text>
            <Text size={'intro'}>{t('stepFinish.requestQuote.text')}</Text>
          </>
        )}
      </Container>
      <Container background={'white'}>
        <Row gutter={[GUTTER_SIZE, GUTTER_SIZE]}>
          <Col>
            <Button skin={'primary-gray-blue'} onClick={performAction} loading={isSending} disabled={isLoading}>
              {
                canSubmitApplication
                  ? t('stepFinish.submitApplication.action')
                  : t('stepFinish.requestQuote.action')
              }
            </Button>
          </Col>
          {canSubmitApplication && (
            <Col>
              <Button skin={'primary-turquoise'} onClick={downloadPdf} loading={isLoading} disabled={isSending}>
                {t('stepFinish.downloadDocument')}
              </Button>
            </Col>
          )}
        </Row>
      </Container>
      <Container background={'light'} flex={1}>
        <PeopleOverview fullDetails>
          {!canSubmitApplication && (
            <Button skin={'primary-gray-blue'} onClick={performAction} loading={isSending} disabled={isLoading}>
              {t('stepFinish.requestQuote.action')}
            </Button>
          )}
        </PeopleOverview>
      </Container>
      {canSubmitApplication && (
        <Container background={'white'} className={'print-hidden'}>
          <div ref={submitApplicationForm}/>
          <SubmitApplicationForm/>
        </Container>
      )}
    </>
  );
};

export default observer(Finish);
