import {useTranslation} from 'react-i18next';
import React, {FC, useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Navigate, useNavigate} from 'react-router-dom';
import {Form} from 'antd';
import {useStore} from '../../../hooks/useStore';
import useBreakpoint from '../../../hooks/useBreakpoint';
import {FranchiseChangePaths} from '../../paths';
import Container from '../../../components/layout/Container';
import Text from '../../../components/shared/Text';
import Space from '../../../components/shared/Space';
import Button from '../../../components/shared/Button';
import PeopleOverviewFranchiseChange from './_components/PeopleOverviewFranchiseChange';
import ContactFormFranchiseChange, {
  ContactFormValues
} from './_components/ContactFormFranchiseChange';
import SubmitApplicationFranchiseChangeForm
  from './_components/SubmitApplicationFranchiseChangeForm';
import {Person} from '../../../models/person';
import {Api} from '../../../services/api';

const Overview: FC = () => {
  const {t} = useTranslation();
  const store = useStore();
  const {isMobile} = useBreakpoint();
  const navigate = useNavigate();
  const submitApplicationForm = useRef<HTMLDivElement>(null);
  const [contactForm] = Form.useForm<ContactFormValues>();
  const [isLoading, setLoading] = useState(false);

  if (!store.franchiseChangeStore.city
    || !(store.franchiseChangeStore.current.baseInsurance)
  ) {
    return (
      <Navigate replace to={FranchiseChangePaths.index()}/>
    );
  }

  const nextStep = () => {
    if (submitApplicationForm.current) {
      submitApplicationForm.current.scrollIntoView({behavior: 'smooth', block: 'start'});
    }
  };

  const addPerson = () => {
    const person = Person.create();
    store.franchiseChangeStore.add(person);
    store.franchiseChangeStore.setCurrent(person);
    store.setEditMode(false);
    navigate(FranchiseChangePaths.index());
  };

  const handleSubmit = () => {
    contactForm.submit();
  };

  const afterSubmit = () => {
    setLoading(true);
    Api.sendRequest(store.franchiseChangeStore.getIssueRequest())
      .then(() => {
        navigate(FranchiseChangePaths.successSubmission());
        setLoading(false);
      })
      .catch(() => {
        // TODO: add error handling
        setLoading(false);
      });
  };

  const downloadRequest = () => {
    setLoading(true);
    Api.getFranchisePdf(store.franchiseChangeStore.getIssueRequest())
      .then(async (data) => {
        if (data.pdfdata) {
          const dataURI = `data:application/pdf;base64,${encodeURI(data.pdfdata)}`;
          const base64Response = await fetch(dataURI);
          const blob = await base64Response.blob();

          const a = document.createElement('a');
          a.href = window.URL.createObjectURL(blob);
          a.download = 'Franchisenwechsel.pdf';
          a.target = '_blank';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          // TODO: add error handling
        }
        setLoading(false);
      })
      .catch(() => {
        // TODO: add error handling
        setLoading(false);
      });
  };

  return (
    <>
      <Container background={'white'}>
        <Text header={1}>{t('stepOverviewFranchiseChange.title')}</Text>
        <Text size={'intro'}>{t('stepOverviewFranchiseChange.text')}</Text>
      </Container>
      <Container background={'white'}>
        <Space size={'large'} direction={isMobile ? 'vertical' : 'horizontal'}>
          <Button skin={'primary-gray-blue'} onClick={nextStep}>
            {t('stepOverviewFranchiseChange.requestQuote')}
          </Button>
          <Button skin={'primary-turquoise'} onClick={downloadRequest} loading={isLoading}>
            {t('stepOverviewFranchiseChange.downloadRequest')}
          </Button>
        </Space>
      </Container>
      <Container background={'light'} flex={1}>
        <PeopleOverviewFranchiseChange/>
        <div ref={submitApplicationForm}>
          <Button skin={'primary-turquoise'} onClick={addPerson} style={{marginTop: 40}}>
            {t('stepOverviewFranchiseChange.addMorePeople')}
          </Button>
          <ContactFormFranchiseChange form={contactForm} afterSubmit={afterSubmit}/>
        </div>
      </Container>
      <Container background={'white'}>
        <SubmitApplicationFranchiseChangeForm onSubmit={handleSubmit} isLoading={isLoading}/>
      </Container>
    </>
  );
};

export default observer(Overview);
