import React from 'react';
import {useTranslation} from 'react-i18next';
import Container from '../../../components/layout/Container';
import Text from '../../../components/shared/Text';
import VerificationForm from './_components/VerificationForm';

const Verification = () => {
  const {t} = useTranslation();
  return (
    <>
      <Container background={'white'}>
        <Text header={1}>{t('cancellation.wizard.verification.title')}</Text>
        <Text size={'intro'}>{t('cancellation.wizard.verification.header')}</Text>
      </Container>
      <Container background={'light'} flex={1}>
        <VerificationForm/>
      </Container>
    </>
  );
};

export default Verification;
