// eslint-disable-next-line import/no-cycle
import {DomainContextInformation} from '../../contexts/DomainContext';

export const isCancellationWizardContext = (path: string) => {
  if (!path) {
    return false;
  }
  if (!path.match(/^\/cancellation-wizard/)) {
    return false;
  }

  return true;
};

export const CANCELLATION_WIZARD_CONTEXT_NAME = 'cancellation-wizard';

export const CancellationWizardContext: DomainContextInformation = {
  name: CANCELLATION_WIZARD_CONTEXT_NAME,
  matches: isCancellationWizardContext,
};
