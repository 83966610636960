import React from 'react';
import {useTranslation} from 'react-i18next';
import Container from '../../../components/layout/Container';
import Text from '../../../components/shared/Text';
import CancellationDeathForm from './_components/CancellationDeathForm';

const Cancellation = () => {
  const {t} = useTranslation();

  return (
    <>
      <Container background={'white'}>
        <Text header={1}>{t('cancellation.death.initialPage.title')}</Text>
        <Text size={'intro'}>{t('cancellation.death.initialPage.text')}</Text>
      </Container>
      <Container background={'light'} flex={1}>
        <CancellationDeathForm/>
      </Container>
    </>
  );
};

export default Cancellation;
