/* eslint-disable import/no-cycle */
import {action, computed, makeObservable, observable} from 'mobx';
import dayjs, {Dayjs} from 'dayjs';
import {RootStore} from './root-store';
import {ContactLanguage, PaymentMethod, PaymentRhythm, Person, Sex} from '../models/person';
import {IApiPerson, IIssue, PersonType} from '../models/document-request';
import {Config} from '../config';
import {AquilanaFunctionStore} from './aquilana-function-store';

const STORAGE_KEY = 'cancellation-wizard';

export class CancellationWizardStore extends AquilanaFunctionStore {
  cancellationDate: Dayjs = dayjs().endOf('year');
  paymentMethod?: PaymentMethod = undefined;
  paymentRhythm?: PaymentRhythm = undefined;
  optInCorrectness?: boolean = undefined;
  optInConsentOfOtherPeople?: boolean = undefined;
  optInPrivacyPolicy?: boolean = undefined;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      cancellationDate: observable,
      paymentMethod: observable,
      paymentRhythm: observable,
      optInCorrectness: observable,
      optInConsentOfOtherPeople: observable,
      optInPrivacyPolicy: observable,

      setCancellationDate: action,
      setPaymentMethod: action,
      setPaymentRhythm: action,
      setOptInCorrectness: action,
      setOptInConsentOfOtherPeople: action,
      setOptInPrivacyPolicy: action,
      convertPersonToIPersonRequest: action,

      hasBaseInsurance: computed,
    });
  }

  setCancellationDate(cancellationDate: Dayjs) {
    this.cancellationDate = cancellationDate;
  }

  setPaymentMethod(paymentMethod: PaymentMethod) {
    this.paymentMethod = paymentMethod;
  }

  setPaymentRhythm(paymentRhythm: PaymentRhythm) {
    this.paymentRhythm = paymentRhythm;
  }

  setOptInCorrectness(optInCorrectness: boolean) {
    this.optInCorrectness = optInCorrectness;
  }

  setOptInConsentOfOtherPeople(optInConsentOfOtherPeople: boolean) {
    this.optInConsentOfOtherPeople = optInConsentOfOtherPeople;
  }

  setOptInPrivacyPolicy(optInPrivacyPolicy: boolean) {
    this.optInPrivacyPolicy = optInPrivacyPolicy;
  }

  reset(): void {
    this.models = [Person.create({type: PersonType.POLICY_HOLDER})];
    this.currentIndex = 0;
    this.city = undefined;
    this.address = undefined;
    this.email = undefined;
    this.phone = undefined;
    this.paymentMethod = undefined;
    this.paymentRhythm = undefined;
    localStorage.removeItem(STORAGE_KEY);
  }

  convertPersonToIPersonRequest(person: Person): IApiPerson {
    return {
      sex: person.sex || Sex.MALE,
      firstName: person.firstName || '',
      lastName: person.lastName || '',
      address1: this.address || '',
      address2: '',
      city: this.city ? `/api/cities/${this.city.id}` : '',
      phone: this.phone || '',
      mobile: '',
      email: this.email || '',
      birthday: person.birthday ? dayjs(person.birthday).format(Config.apiDateFormat) : '',
      country: 'CH',
      language: ContactLanguage.DE,
      insuranceNo: person.insuranceNo || '',
      type: person.type,
      childPremiumReduction: false,
      dateOfArrival: null,
      moveInFromAbroad: false,
      premiumReductionBaseInsurance: false,
    };
  }

  getIssueRequest(): IIssue {
    return {
      issueType: Config.issueTypeCancellationWizard,
      sex: this.policyHolder?.sex || null,
      firstName: this.policyHolder?.firstName || '',
      lastName: this.policyHolder?.lastName || '',
      email: this.email || '',
      insurances: [{
        start: this.cancellationDate.format(Config.apiDateFormat),
        people: this.models.map((person) => this.convertPersonToIPersonRequest(person)),
        iban: '',
        paymentMethodPremium: this.paymentMethod || undefined,
        paymentRhythm: this.paymentRhythm || undefined,
      }],
      optInCorrectness: this.optInCorrectness,
      optInConsentOfOtherPeople: this.optInConsentOfOtherPeople,
      optInPrivacyPolicy: this.optInPrivacyPolicy,
    };
  }

  get hasBaseInsurance(): boolean {
    return this.models.some((p) => p.hasBaseInsurance);
  }
}
