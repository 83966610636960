import {FC} from 'react';
import {Select as AntSelect, SelectProps as AntSelectProps} from 'antd';
import './Select.scss';

export interface SelectOption<T> {
  label: string;
  value: T;
}

interface SelectProps extends Omit<AntSelectProps, 'filterOption'> {
  fullWidth?: boolean;
}

const Select: FC<SelectProps> = ({fullWidth, showSearch, style, children, ...props}) => {
  const filter = (inputValue: string, option?: any) => {
    if (typeof option?.label === 'string') {
      return option.label.toLowerCase().includes(inputValue.toLowerCase());
    }
    return false;
  };

  return (
    <AntSelect
      showSearch={showSearch}
      style={fullWidth ? {...style, width: '100%'} : style}
      filterOption={showSearch ? filter : undefined}
      {...props}
    >
      {children}
    </AntSelect>
  );
};

export default Select;
