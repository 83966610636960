import React from 'react';
import {useTranslation} from 'react-i18next';
import {ColumnType} from 'antd/es/table';
import ReactMarkdown from 'react-markdown';
import {Table} from '../../../../components/shared/Table';
import Text from '../../../../components/shared/Text';
import './CancellationDatesInfoTable.scss';

interface DataType {
  key: React.Key;
  description: string;
  noticePeriod: string;
  cancellationDate: string;
}

const CancellationDatesInfoTable = () => {
  const {t} = useTranslation();

  const columns: ColumnType<DataType>[] = [
    {
      title: '',
      dataIndex: 'description',
      render: (description: string) => (
        <Text size={'small'}>
          <ReactMarkdown>{description}</ReactMarkdown>
        </Text>
      ),
      width: '70%',
    },
    {
      title: t('cancellation.wizard.person.noticePeriod'),
      dataIndex: 'noticePeriod',
      render: (noticePeriod: string) => (
        <Text size={'small'}>
          <ReactMarkdown className={'notice-period'}>{noticePeriod}</ReactMarkdown>
        </Text>
      ),
      width: '15%',
    },
    {
      title: t('cancellation.wizard.person.cancellationDate.tableTitle'),
      dataIndex: 'cancellationDate',
      render: (cancellationDate: string) => (
        <Text size={'small'}>
          <ReactMarkdown className={'cancellation-date'}>{cancellationDate}</ReactMarkdown>
        </Text>
      ),
      width: '15%',
    },
  ];

  const data: DataType[] = [
    {
      key: 'standard_with_minimal_franchise',
      description: t('cancellation.cancellationDates.standardWithMinimalFranchise.description'),
      noticePeriod: t('cancellation.cancellationDates.standardWithMinimalFranchise.noticePeriod'),
      cancellationDate: t('cancellation.cancellationDates.standardWithMinimalFranchise.cancellationDate'),
    },
    {
      key: 'standard_with_custom_franchise',
      description: t('cancellation.cancellationDates.standardWithCustomFranchise.description'),
      noticePeriod: t('cancellation.cancellationDates.standardWithCustomFranchise.noticePeriod'),
      cancellationDate: t('cancellation.cancellationDates.standardWithCustomFranchise.cancellationDate'),
    },
    {
      key: 'casamed',
      description: t('cancellation.cancellationDates.casamed.description'),
      noticePeriod: t('cancellation.cancellationDates.casamed.noticePeriod'),
      cancellationDate: t('cancellation.cancellationDates.casamed.cancellationDate'),
    },
    {
      key: 'smartmed',
      description: t('cancellation.cancellationDates.smartmed.description'),
      noticePeriod: t('cancellation.cancellationDates.smartmed.noticePeriod'),
      cancellationDate: t('cancellation.cancellationDates.smartmed.cancellationDate'),
    },
    {
      key: 'daily_benefits_insurance',
      description: t('cancellation.cancellationDates.dailyBenefitsInsurance.description'),
      noticePeriod: t('cancellation.cancellationDates.dailyBenefitsInsurance.noticePeriod'),
      cancellationDate: t('cancellation.cancellationDates.dailyBenefitsInsurance.cancellationDate'),
    },
  ];

  return (
    <Table<DataType>
      size={'small'}
      className={'cancellation-dates-table'}
      columns={columns}
      dataSource={data}
      rowKey={(record) => record.key}
      striped
      scroll={{x: 'fit-content'}}
    />
  );
};

export default CancellationDatesInfoTable;
