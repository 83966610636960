import {Col, Row} from 'antd';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import {useStore} from '../../hooks/useStore';
import useCurrentDomainInformation from '../../hooks/useCurrentDomainInformation';
import {CalculatorStore} from '../../stores/calculator-store';
import {FranchiseChangeStore} from '../../stores/franchise-change-store';
import {GUTTER_SIZE} from '../../config';
import Icon from '../shared/Icon';
import {PriceIcon} from '../shared/icons';
import Price from '../shared/Price';
import Text from '../shared/Text';
import './OverviewCard.scss';

const TotalCard: FC = () => {
  const {t} = useTranslation();
  const store = useStore();
  const {isFranchiseChange, currentStore} = useCurrentDomainInformation();

  if (
    !(currentStore instanceof CalculatorStore
      || currentStore instanceof FranchiseChangeStore
    )) {
    return null;
  }

  const total = currentStore.models
    .map((p) => p.total(
      currentStore.getPremiumReductionBaseInsurance(p),
      isFranchiseChange ? false : store.calculatorStore.getPremiumReductionAddonInsurances(p)
    ))
    .reduce((a, b) => a + b);

  return (
    <div className={'overview-card'}>
      <div className={'card-header'}>
        <Row gutter={GUTTER_SIZE} align={'middle'}>
          <Col flex={'50px'}>
            <Icon component={PriceIcon} size={50}/>
          </Col>
          <Col flex={'auto'}>
            <Text>{t('totalCard.title')}</Text>
          </Col>
        </Row>
      </div>
      <div className={'card-body'}>
        <table>
          <tbody>
            {currentStore.models.map((person, index) => (
              <tr className={'pre-total'} key={person.uuid}>
                <td className={'label large'}>
                  <Text size={'small'}>
                    {t('insurance.monthlyRate')}
                    {currentStore.models.length > 1 && (
                    <>
                      {' '}
                      {person.fullName || t('personCard.namePlaceholder', {number: index + 1})}
                    </>
                    )}
                    {currentStore.hasBaseInsurance && ' *'}
                  </Text>
                </td>
                <td className={'currency'}>
                  <Text size={'extra-small'} opacity={0.8}>CHF</Text>
                </td>
                <td className={'value'}>
                  <Text strong size={'small'}>
                    <Price value={person.total(
                      currentStore.getPremiumReductionBaseInsurance(person),
                      isFranchiseChange ? false : store.calculatorStore.getPremiumReductionAddonInsurances(person)
                    )}
                    />
                  </Text>
                </td>
              </tr>
            ))}
            <tr className={'total'}>
              <td className={'label large'}>
                <Text size={'small'}>{t('totalCard.total')}</Text>
              </td>
              <td className={'currency'}>
                <Text size={'extra-small'} opacity={0.8}>CHF</Text>
              </td>
              <td className={'value'}>
                <Text strong size={'small'}>
                  <Price value={total}/>
                </Text>
              </td>
            </tr>
          </tbody>
        </table>
        <Text size={'extra-small'} color={'gray-blue'}>
          {currentStore.hasBaseInsurance && (`* ${t('insurance.priceAfterEnvironmentalTax')}`)}
        </Text>
      </div>
    </div>
  );
};

export default observer(TotalCard);
