import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Form, Input} from 'antd';
import {observer} from 'mobx-react-lite';
import {useNavigate} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import {IPerson, Person} from '../../../../models/person';
import {City} from '../../../../models/city';
import {PreviousInsurer} from '../../../../models/previous-insurer';
import {useStore} from '../../../../hooks/useStore';
import {sortAlphabetically} from '../../../../utils/sorters';
import {BaseInsuranceType} from '../../../../models/base-insurance';
import {CalculatorPaths} from '../../../paths';
import Space from '../../../../components/shared/Space';
import {Validators} from '../../../../utils/validators';
import Select from '../../../../components/shared/Select';
import StepsNavigationButtons from '../../../../components/StepsNavigationButtons';
import InfoWithLabel from '../../../../components/shared/InfoWithLabel';

// eslint-disable-next-line max-len
interface FormValues extends Required<Pick<IPerson, 'sex' | 'firstName' | 'lastName' | 'residenceInSwitzerland' | 'moveInFromAbroad' | 'dateOfArrival'>> {
  address: string;
  city: City['id'];
  email: string;
  phone: string;
  previousInsurer: PreviousInsurer['id'];
  policyholder: Person['uuid'];
  insuranceStart: number;
}

interface ContactFormProps {
  afterSubmit: () => void;
}

const ContactForm: FC<ContactFormProps> = ({afterSubmit}) => {
  const {t} = useTranslation();
  const store = useStore();
  const navigate = useNavigate();
  const [form] = Form.useForm<FormValues>();
  const [options, setOptions] = useState<{ label: string, value: number }[]>([]);
  const policyholderOptions = store.calculatorStore.models.filter((p) => !p.isChild).map((p) => ({
    value: p.uuid,
    label: p.fullName,
    person: p,
  }));

  let policyholderUuid = store.calculatorStore.policyHolder.uuid;
  if (!policyholderOptions.map((o) => o.value).includes(policyholderUuid)) {
    policyholderUuid = policyholderOptions[0].value;
  }

  const [selectedPolicyholder, setSelectedPolicyholder] = useState(store.calculatorStore.getById(policyholderUuid)!);

  useEffect(() => {
    if (!store.cityStore.isLoading) {
      setOptions(
        store.cityStore.models
          .map((city) => ({label: city.label, value: city.id}))
          .sort((a, b) => sortAlphabetically(a.label, b.label))
      );
    }
  }, [store.cityStore.isLoading]);

  const onFinish = (values: FormValues) => {
    store.calculatorStore.setAddress(values.address);
    if (selectedPolicyholder.isEmailRequired) {
      store.calculatorStore.setEmail(selectedPolicyholder.email || values.email);
    } else {
      store.calculatorStore.setEmail(values.email);
    }
    store.calculatorStore.setPhone(values.phone);
    store.calculatorStore.setPolicyholder(values.policyholder);

    store.calculatorStore.saveToSession();
    afterSubmit();
  };

  const onValuesChange = ({policyholder}: Partial<FormValues>) => {
    if (policyholder) {
      const person = store.calculatorStore.getById(policyholder);
      if (person) {
        setSelectedPolicyholder(person);
      }
    }
  };

  const previousStep = () => {
    if (store.calculatorStore.current.baseInsurance?.type === BaseInsuranceType.CASAMED) {
      navigate(CalculatorPaths.doctor());
    } else {
      navigate(CalculatorPaths.details());
    }
  };

  const initialValues: Partial<FormValues> = {
    policyholder: policyholderUuid,
    address: store.calculatorStore.address,
    city: store.calculatorStore.city?.id,
    email: store.calculatorStore.email,
    phone: store.calculatorStore.phone,
  };

  return (
    <div>
      <Form<FormValues>
        form={form}
        layout={'vertical'}
        colon={false}
        labelAlign={'left'}
        requiredMark={false}
        onFinish={onFinish}
        initialValues={initialValues}
        onValuesChange={onValuesChange}
      >
        <Form.Item
          name={'policyholder'}
          label={(
            <InfoWithLabel label={t('label.policyholder')}>
              <ReactMarkdown>{t('contactForm.policyholderInfo')}</ReactMarkdown>
            </InfoWithLabel>
          )}
          rules={[Validators.required(t('validator.policyholderRequired'))]}
          hidden={policyholderOptions.length === 1}
        >
          <Select
            fullWidth
            options={policyholderOptions}
          />
        </Form.Item>
        <Form.Item
          name={'address'}
          label={t('label.address')}
          rules={[Validators.required(t('validator.addressRequired')), Validators.noSpecialCharacters]}
        >
          <Input placeholder={t('label.address')} maxLength={50}/>
        </Form.Item>
        <Form.Item
          name={'city'}
          label={t('label.postcodeCity')}
          rules={[Validators.required(t('validator.postcodeCityRequired'))]}
        >
          <Select
            disabled
            loading={store.cityStore.isLoading}
            options={options}
            placeholder={t('label.postcodeCityPlaceholder')}
          />
        </Form.Item>
        <Form.Item
          name={selectedPolicyholder.isEmailRequired ? undefined : 'email'}
          label={t('label.email')}
          rules={[Validators.required(t('validator.emailRequired')), Validators.email]}
        >
          <Input
            placeholder={t('label.email')}
            disabled={selectedPolicyholder.isEmailRequired}
            value={selectedPolicyholder.isEmailRequired ? selectedPolicyholder.email : undefined}
            maxLength={255}
          />
        </Form.Item>
        {selectedPolicyholder.isMobileRequired && (
          <Form.Item label={t('label.mobile')}>
            <Input disabled value={selectedPolicyholder.mobile}/>
          </Form.Item>
        )}
        <Form.Item
          name={'phone'}
          label={(
            <Space>
              {t('label.phone')}
              {selectedPolicyholder.isMobileRequired && <span className={'optional'}>{t('label.optional')}</span>}
            </Space>
          )}
          rules={[
            Validators.requiredIf(!selectedPolicyholder.isMobileRequired, t('validator.phoneRequired')),
            Validators.phone,
          ]}
        >
          <Input placeholder={t('label.phone')} maxLength={50}/>
        </Form.Item>
        <StepsNavigationButtons backHidden={store.editMode} back={() => previousStep()} next={() => form.submit()}/>
      </Form>
    </div>
  );
};

export default observer(ContactForm);
