import {action, makeObservable, observable} from 'mobx';
import {IBaseModel} from './base-model';
import {TravelInsuranceType} from './travel-insurance-type';
import {TermOfInsurance} from './term-of-insurance';

/**
 * t('travelInsuranceRate.type.single')
 * t('travelInsuranceRate.type.family')
 */
export enum TravelInsuranceRateType {
  SINGLE = 'single',
  FAMILY = 'family'
}

export interface ITravelInsuranceRate extends IBaseModel {
  id: number;
  insuranceAmount: number;
  type: TravelInsuranceRateType;
  travelInsuranceType: string;
  termOfInsurance: string;
  value: number;
}

export class TravelInsuranceRate implements Omit<ITravelInsuranceRate, 'travelInsuranceType' | 'termOfInsurance'> {
  id = 0;
  insuranceAmount = 0;
  type = TravelInsuranceRateType.SINGLE;
  travelInsuranceTypeUrn = '';
  travelInsuranceType?: TravelInsuranceType = undefined;
  termOfInsuranceUrn = '';
  termOfInsurance?: TermOfInsurance = undefined;
  value = 0;

  constructor() {
    makeObservable(this, {
      id: observable,
      insuranceAmount: observable,
      type: observable,
      travelInsuranceTypeUrn: observable,
      travelInsuranceType: observable,
      value: observable,

      setTravelInsuranceType: action,
      setTermOfInsurance: action,
    });
  }

  public setTravelInsuranceType(travelInsuranceType?: TravelInsuranceType) {
    this.travelInsuranceType = travelInsuranceType;
  }

  public setTermOfInsurance(termOfInsurance?: TermOfInsurance) {
    this.termOfInsurance = termOfInsurance;
  }

  static fromJSON(data: ITravelInsuranceRate): TravelInsuranceRate {
    const travelInsuranceRate = new TravelInsuranceRate();
    travelInsuranceRate.id = data.id;
    travelInsuranceRate.insuranceAmount = data.insuranceAmount;
    travelInsuranceRate.type = data.type;
    travelInsuranceRate.travelInsuranceTypeUrn = data.travelInsuranceType;
    travelInsuranceRate.termOfInsuranceUrn = data.termOfInsurance;
    travelInsuranceRate.value = data.value;

    return travelInsuranceRate;
  }
}
