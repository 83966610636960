// eslint-disable-next-line import/no-cycle
import {DomainContextInformation} from '../../contexts/DomainContext';

export const isTravelInsuranceContext = (path: string) => {
  if (!path) {
    return false;
  }
  if (!path.match(/^\/travel-insurance/)) {
    return false;
  }

  return true;
};

export const TRAVEL_INSURANCE_CONTEXT_NAME = 'travel-insurance';

export const TravelInsuranceContext: DomainContextInformation = {
  name: TRAVEL_INSURANCE_CONTEXT_NAME,
  matches: isTravelInsuranceContext,
};
