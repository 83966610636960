import {useTranslation} from 'react-i18next';
import {FC, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Input, Spin} from 'antd';
import {Navigate, useNavigate} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import Container from '../../../components/layout/Container';
import {useStore} from '../../../hooks/useStore';
import Text from '../../../components/shared/Text';
import Space from '../../../components/shared/Space';
import {Col, Row} from '../../../components/layout/Grid';
import {Doctor} from '../../../models/doctor';
import Select from '../../../components/shared/Select';
import StepsNavigationButtons from '../../../components/StepsNavigationButtons';
import InfoBox from '../../../components/shared/InfoBox';
import {Config, GUTTER_SIZE} from '../../../config';
import {sortNumerically} from '../../../utils/sorters';
import './DoctorSelection.scss';
import {CalculatorPaths} from '../../paths';
import DoctorCard from './_components/DoctorCard';

const DoctorSelection: FC = () => {
  const {t} = useTranslation();
  const store = useStore();
  const navigate = useNavigate();
  const person = store.calculatorStore.current;
  const [selectedDoctor, setSelectedDoctor] = useState<Doctor | undefined>(person.doctor);
  const [selectedPostcode, setSelectedPostcode] = useState(
    person.doctor?.postcode || store.calculatorStore.city?.postcode
  );
  const [search, setSearch] = useState('');
  const [doctors, setDoctors] = useState<Doctor[]>([]);

  useEffect(() => {
    if (!store.doctorStore.models.length && !store.doctorStore.isLoading) {
      store.doctorStore.loadAll();
    }
  }, []);

  useEffect(() => {
    person.setDoctor(selectedDoctor);
  }, [selectedDoctor]);

  useEffect(() => {
    setDoctors(store.doctorStore.models.filter(
      (doctor) => {
        if (doctor.postcode !== selectedPostcode) {
          return false;
        }
        return `${doctor.firstName} ${doctor.lastName}`.toLowerCase().includes(search.toLowerCase())
          || `${doctor.lastName} ${doctor.firstName}`.toLowerCase().includes(search.toLowerCase());
      }
    ));
  }, [selectedPostcode, search, store.doctorStore.isLoading]);

  if (!person.baseInsurance && !person.hasAddonInsurances) {
    return (
      <Navigate replace to={CalculatorPaths.index()}/>
    );
  }

  const nextStep = () => {
    if (store.editMode) {
      store.setEditMode(false);
      navigate(CalculatorPaths.finish());
    } else if (store.calculatorStore.hasNext) {
      store.calculatorStore.setNextAsCurrent();
      navigate(CalculatorPaths.details());
    } else {
      navigate(CalculatorPaths.contact());
    }
  };

  const options = (() => {
    const postcodeMap = new Map();

    store.doctorStore.models.forEach((d) => {
      postcodeMap.set(d.postcode, {
        value: d.postcode,
        label: `${d.postcode} ${d.city}`,
      });
    });

    store.cityStore.models.forEach((c) => {
      if (!postcodeMap.has(c.postcode)) {
        postcodeMap.set(c.postcode, {
          value: c.postcode,
          label: `${c.postcode} ${c.city}`,
        });
      }
    });

    return Array.from(postcodeMap.values());
  })();

  return (
    <>
      <Container background={'white'}>
        <Text header={1}>{t('doctorSelection.title')}</Text>
        <Text header={2}>{store.calculatorStore.current.fullName}</Text>
      </Container>
      <Container background={'light'} flex={1} className={'doctor-selection'}>
        <Space direction={'vertical'} fullWidth size={'large'}>
          <InfoBox title={t('label.information')} className={'main-info'}>
            <ReactMarkdown linkTarget={'_blank'}>
              {t('doctorSelection.infoBox', {url: Config.doctorListUrl})}
            </ReactMarkdown>
          </InfoBox>
          <Row gutter={[GUTTER_SIZE, GUTTER_SIZE]}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Text>{t('label.postcodeCityDoctor')}</Text>
              <Select
                fullWidth
                showSearch
                onChange={(value) => setSelectedPostcode(value)}
                defaultValue={person.doctor ? `${person.doctor.postcode} ${person.doctor.city}`
                  : store.calculatorStore.city?.label}
                options={options}
                className={'small'}
                popupClassName={'small'}
                filterSort={(optionA, optionB) => sortNumerically(Number(optionA?.value), Number(optionB?.value))}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Text>{t('label.name')}</Text>
              <Input
                allowClear
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Col>
          </Row>
          {store.doctorStore.isLoading ? (
            <Spin/>
          ) : (
            <Space fullWidth direction={'vertical'} size={'large'}>
              <Row gutter={[GUTTER_SIZE, GUTTER_SIZE]}>
                {doctors.map((doctor) =>
                  (
                    <Col key={doctor.id} xs={24} sm={24} md={24} lg={12} xl={8}>
                      <DoctorCard
                        doctor={doctor}
                        selected={selectedDoctor?.id === doctor.id}
                        onSelectionChange={() => setSelectedDoctor(doctor)}
                      />
                    </Col>
                  ))}
                {doctors.length === 0 && (
                  <Col xs={24}>
                    <InfoBox title={t('label.information')}>
                      <ReactMarkdown linkTarget={'_blank'}>
                        {t('doctorSelection.noResults', {url: Config.doctorListUrl})}
                      </ReactMarkdown>
                    </InfoBox>
                  </Col>
                )}
              </Row>
            </Space>
          )}
          <StepsNavigationButtons
            backHidden={store.editMode}
            back={() => navigate(CalculatorPaths.details())}
            next={() => nextStep()}
            nextDisabled={!person.doctor}
          />
        </Space>
      </Container>
    </>
  );
};

export default observer(DoctorSelection);
