import React from 'react';
import {Alert} from 'antd';
import './EnvironmentInfo.scss';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {useStore} from '../../hooks/useStore';

const EnvironmentInfo = () => {
  const {t} = useTranslation();
  const {configStore} = useStore();

  if (!configStore.environment?.isEnvironmentInfoVisible) {
    return null;
  }

  return (
    <Alert
      className={'environment-info'}
      style={{textAlign: 'center', fontWeight: 'bold', backgroundColor: '#FFFF00'}}
      banner
      message={t('label.environment', {name: configStore.environment.humanName})}
      showIcon={false}
    />
  );
};

export default observer(EnvironmentInfo);
