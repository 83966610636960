/* eslint-disable import/no-cycle */
import {action, makeObservable, observable, runInAction} from 'mobx';
import {RootStore} from './root-store';
import {IBaseModel} from '../models/base-model';

export class BaseStore<M extends IBaseModel, I> {
  isLoading = false;
  rootStore: RootStore;
  models: M[] = [];
  apiEndpoint?: () => Promise<M[]>;
  identifier: keyof M;

  constructor(rootStore: RootStore, identifier: keyof M, apiEndpoint?: () => Promise<M[]>) {
    makeObservable(this, {
      isLoading: observable,
      models: observable,
      loadAll: action,
      add: action,
    });
    this.rootStore = rootStore;
    this.identifier = identifier;
    if (apiEndpoint) {
      this.apiEndpoint = apiEndpoint;
    }
  }

  loadAll(): Promise<void> {
    if (!this.apiEndpoint) {
      throw new Error('not supported');
    }

    this.isLoading = true;
    return this.apiEndpoint().then((models) => {
      runInAction(() => {
        this.models = models;
        this.isLoading = false;
      });
    });
  }

  getById(id: I): M | undefined {
    // @ts-ignore
    return this.models.find((m) => m[this.identifier] === id);
  }

  getByUrn(urn: string): M | undefined {
    const splittedUrn = urn.split('/');
    const id = Number(splittedUrn[splittedUrn.length - 1]) ?? 1;
    return this.getById(id as unknown as I);
  }

  add(model: M): number {
    return this.models.push(model);
  }
}
