import React from 'react';
import {useTranslation} from 'react-i18next';
import Container from '../../../components/layout/Container';
import Text from '../../../components/shared/Text';
import OptInForm from './_components/OptInForm';

const OptIn = () => {
  const {t} = useTranslation();

  return (
    <>
      <Container background={'white'}>
        <Text header={1}>{t('cancellation.wizard.optIn.title')}</Text>
        <Text size={'intro'}>{t('cancellation.wizard.optIn.header')}</Text>
      </Container>
      <Container background={'light'} flex={1}>
        <OptInForm/>
      </Container>
    </>
  );
};

export default OptIn;
