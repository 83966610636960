import React, {FC} from 'react';
import {DatePicker, Form} from 'antd';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import dayjs, {Dayjs} from 'dayjs';
import {useStore} from '../../../../hooks/useStore';
import {TravelInsuranceRateType} from '../../../../models/travel-insurance-rate';
import Select from '../../../../components/shared/Select';
import {Validators} from '../../../../utils/validators';
import Button from '../../../../components/shared/Button';
import {Col, Row} from '../../../../components/layout/Grid';
import {Config} from '../../../../config';
import {TravelInsurancePaths} from '../../../paths';
import InfoWithLabel from '../../../../components/shared/InfoWithLabel';

interface FormValues {
  insuranceStart: Dayjs;
  termOfInsuranceId: number;
  type: TravelInsuranceRateType;
}

const TravelInsuranceInitialForm: FC = () => {
  const store = useStore();
  const {t} = useTranslation();
  const [form] = Form.useForm<FormValues>();
  const navigate = useNavigate();

  const onSubmit = (values: FormValues) => {
    if (store.travelInsuranceStore.type !== values.type
      || store.travelInsuranceStore.travelInsuranceDuration?.id !== values.termOfInsuranceId) {
      store.travelInsuranceStore.reset(true);
    }
    store.travelInsuranceStore.setTravelInsuranceStartDate(values.insuranceStart);

    const termOfInsurance = store.termOfInsuranceStore.getById(values.termOfInsuranceId);
    if (termOfInsurance) {
      store.travelInsuranceStore.setTravelInsuranceDuration(termOfInsurance);
    }

    store.travelInsuranceStore.setType(values.type);
    store.travelInsuranceStore.saveToSession();
    navigate(TravelInsurancePaths.productSelection());
  };

  const initialValues = {
    insuranceStart: store.travelInsuranceStore.travelInsuranceStartDate
      ? dayjs(store.travelInsuranceStore.travelInsuranceStartDate) : undefined,
    termOfInsuranceId: store.travelInsuranceStore.travelInsuranceDuration?.id,
    type: store.travelInsuranceStore.type,
  };

  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
        <Form
          initialValues={initialValues}
          form={form}
          labelAlign={'left'}
          layout={'vertical'}
          colon={false}
          onFinish={onSubmit}
          requiredMark={false}
        >
          <Form.Item name={'insuranceStart'} label={t('person.insuranceStart')} rules={[Validators.required()]}>
            <DatePicker
              format={Config.dateFormat}
              placeholder={t('label.dateFormat')}
              disabledDate={(d) => d.isBefore(dayjs().startOf('day'))
                || d.isAfter(dayjs().endOf('day').add(1, 'year'))}
            />
          </Form.Item>
          <Form.Item
            name={'termOfInsuranceId'}
            label={(
              <InfoWithLabel
                label={t('termOfInsurance.days')}
                title={t('termOfInsurance.info.title')}
              >
                {t('termOfInsurance.info.text')}
              </InfoWithLabel>
            )}
            rules={[Validators.required()]}
          >
            <Select
              options={Object.values(store.termOfInsuranceStore.models).sort((a, b) => a.days - b.days)
                .map((termOfInsurance) => ({
                  value: termOfInsurance.id,
                  label: termOfInsurance.days,
                }))}
            />
          </Form.Item>
          <Form.Item
            name={'type'}
            label={(
              <InfoWithLabel
                label={t('travelInsuranceRate.type.title')}
                title={t('travelInsuranceRate.type.info.title')}
                width={900}
              >
                {t('travelInsuranceRate.type.info.text')}
              </InfoWithLabel>
            )}
            rules={[Validators.required()]}
          >
            <Select
              options={Object.values(TravelInsuranceRateType)
                .map((type) => ({
                  value: type,
                  label: t(`travelInsuranceRate.type.${type}`),
                }))}
            />
          </Form.Item>
          <Form.Item>
            <Button type={'submit'} skin={'primary-gray-blue'}>
              {t('action.next')}
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default observer(TravelInsuranceInitialForm);
