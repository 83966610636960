import { useState, useEffect } from 'react';
import {sortAlphabetically} from '../utils/sorters';
import {useStore} from './useStore';

const useCitySelection = () => {
  const [options, setOptions] = useState<{ label: string, value: number }[]>([]);
  const store = useStore();

  useEffect(() => {
    if (!store.cityStore.isLoading) {
      setOptions(
        store.cityStore.models
          .map((city) => ({label: city.label, value: city.id}))
          .sort((a, b) => sortAlphabetically(a.label, b.label))
      );
    }
  }, [store.cityStore.isLoading]);
  return {options};
};

export default useCitySelection;
