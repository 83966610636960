import React from 'react';

export const formatIban = (iban: string = ''): string => (iban.match(/.{1,4}/g) || [iban]).join(' ');

export const formatInsuranceNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
  const value = e.target.value.replace(/[^\d.]/g, '');
  const template = 'x.xxx.xxx';
  let formattedText = '';
  let i = 0;
  let j = 0;
  while (i < template.length && j < value.length) {
    if (template[i] === 'x') {
      const match = value[j].match(/\d/);
      formattedText += match ? match[0] : '';
      j += match?.length ?? 1;
      i += match?.length ?? 0;
    } else if (template[i] === '.') {
      formattedText += '.';
      if (value[j] === '.') {
        i += 1;
        j += 1;
      } else {
        i += 1;
      }
    }
  }
  e.target.value = formattedText;
};
