import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import {Modal} from 'antd';
import {useNavigate} from 'react-router-dom';
import {useStore} from '../hooks/useStore';
import Space from './shared/Space';
import Button from './shared/Button';
import Text from './shared/Text';
import useBreakpoint from '../hooks/useBreakpoint';
import {CalculatorPaths} from '../pages/paths';

interface RestoredSessionModalProps {
  showModal: boolean;
  setShowModal: (newValue: boolean) => void;
  width?: number;
}

const RestoredSessionModal: FC<RestoredSessionModalProps> = ({
  showModal,
  setShowModal,
  width = 800,
}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const store = useStore();
  const {isMobile} = useBreakpoint();

  const startNewCalculation = () => {
    store.calculatorStore.reset();
    setShowModal(false);
    navigate(CalculatorPaths.index());
  };

  return (
    <Modal
      width={width}
      open={showModal}
      onCancel={() => setShowModal(false)}
      closable={false}
      footer={(
        <Space direction={isMobile ? 'vertical' : 'horizontal'}>
          <Button skin={'primary-turquoise'} onClick={() => startNewCalculation()}>
            {t('restoredSessionModal.startNewCalculation')}
          </Button>
          <Button skin={'primary-gray-blue'} onClick={() => setShowModal(false)}>
            {t('restoredSessionModal.goToOverview')}
          </Button>
        </Space>
      )}
    >
      <div className={'content-wrapper'}>
        <Text header={2} className={'title'}>{t('restoredSessionModal.title')}</Text>
        <br/>
        <Text size={'small'}>
          {t('restoredSessionModal.text')}
        </Text>
      </div>
    </Modal>
  );
};

export default observer(RestoredSessionModal);
