import {useTranslation} from 'react-i18next';
import React, {FC, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import dayjs from 'dayjs';
import Container from '../../../components/layout/Container';
import Text from '../../../components/shared/Text';
import {Config} from '../../../config';
import {useStore} from '../../../hooks/useStore';
import {formatNumber} from '../../../utils/numbers';
import NewFranchiseCostTable from './_components/NewFranchiseCostTable';
import InfoBox from '../../../components/shared/InfoBox';
import StepsNavigationButtons from '../../../components/StepsNavigationButtons';
import {FranchiseChangePaths} from '../../paths';
import {BaseInsuranceType} from '../../../models/base-insurance';

export const NewFranchise: FC = () => {
  const {t} = useTranslation();
  const store = useStore();
  const navigate = useNavigate();
  const currentBaseInsurance = store.franchiseChangeStore.current.baseInsurance;
  const currentEnvironmentalTax = currentBaseInsurance?.oldRate?.premiumVersion?.environmentalTax;
  const [selectedFranchise, setSelectedFranchise] = React.useState<number>(
    store.franchiseChangeStore.current.baseInsurance?.franchiseGroup?.id ?? 1
  );

  useEffect(() => {
    store.franchiseChangeStore.current.baseInsurance?.setFranchiseGroup(
      store.franchiseGroupStore.getById(selectedFranchise)
    );
    const rate = store.baseInsuranceRateStore.findOne(
      store.franchiseChangeStore.current.baseInsurance?.type || BaseInsuranceType.STANDARD,
      selectedFranchise,
      store.franchiseChangeStore.current.age,
      store.franchiseChangeStore.city?.regionCode || '',
      store.franchiseChangeStore.current.insuranceStart
    );
    store.franchiseChangeStore.current.baseInsurance?.setRate(rate);
  }, [selectedFranchise]);

  const previousStep = () => {
    if (store.editMode) {
      store.setEditMode(false);
      navigate(FranchiseChangePaths.overview());
    } else {
      navigate(FranchiseChangePaths.current());
    }
  };

  const nextStep = () => {
    if (selectedFranchise !== 0) {
      const franchiseGroup = store.franchiseGroupStore.getById(selectedFranchise);
      if (franchiseGroup) {
        const rate = store.baseInsuranceRateStore.findOne(
          store.franchiseChangeStore.current.baseInsurance?.type || BaseInsuranceType.STANDARD,
          selectedFranchise,
          store.franchiseChangeStore.current.age,
          store.franchiseChangeStore.city?.regionCode || '',
          store.franchiseChangeStore.current.insuranceStart
        );
        store.franchiseChangeStore.current.baseInsurance?.setFranchiseGroup(franchiseGroup);
        store.franchiseChangeStore.current.baseInsurance?.setRate(rate);
        store.franchiseChangeStore.saveToSession();
        store.setEditMode(false);
        navigate(FranchiseChangePaths.person());
      }
    }
  };

  return (
    <>
      <Container background={'white'}>
        <Text header={1}>
          {t('stepNewFranchise.title', {
            year: Config.franchiseChangePhase === 3 ? dayjs().add(2, 'y').year() : dayjs().add(1, 'y').year(),
          })}
        </Text>
        <ReactMarkdown>
          {t(
            'stepNewFranchise.headerInfo',
            {
              year: Config.franchiseChangePhase !== 3 ? dayjs().year() : dayjs().add(1, 'y').year(),
              environmentalTax: formatNumber(currentEnvironmentalTax ?? 0),
            }
          )}
        </ReactMarkdown>
        {Config.franchiseChangePhase !== 2 && (
          <ReactMarkdown>
            {
              t('stepNewFranchise.headerInfoSecondLine')
            }
          </ReactMarkdown>
        )}
      </Container>
      <Container background={'light'} flex={1}>
        <NewFranchiseCostTable selectedFranchise={selectedFranchise} setSelectedFranchise={setSelectedFranchise}/>
        <InfoBox title={t('franchiseChange.higherFranchiseInfoText.title')}>
          <ReactMarkdown>
            {t('franchiseChange.higherFranchiseInfoText.textTable')}
          </ReactMarkdown>
        </InfoBox>
        <StepsNavigationButtons
          backHidden={store.editMode}
          nextDisabled={!selectedFranchise
            || selectedFranchise === store.franchiseChangeStore.current.baseInsurance?.oldFranchiseGroup?.id}
          back={() => previousStep()}
          next={() => nextStep()}
        />
      </Container>
    </>
  );
};
