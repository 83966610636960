import {makeObservable, observable} from 'mobx';
import {IBaseModel} from './base-model';

export interface IFranchiseGroup extends IBaseModel {
  id: number;
  label: string;
  valueAdult: number;
  valueChild: number;
}

export class FranchiseGroup implements IFranchiseGroup {
  id = 0;
  label = '';
  valueAdult = 0;
  valueChild = 0;

  constructor() {
    makeObservable(this, {
      id: observable,
      label: observable,
      valueAdult: observable,
      valueChild: observable,
    });
  }

  static fromJSON(data?: Partial<IFranchiseGroup>) {
    const franchiseGroup = new FranchiseGroup();
    if (data?.id) {
      franchiseGroup.id = data.id;
    }
    if (data?.label) {
      franchiseGroup.label = data.label;
    }
    if (data?.valueAdult) {
      franchiseGroup.valueAdult = data.valueAdult;
    }
    if (data?.valueChild) {
      franchiseGroup.valueChild = data.valueChild;
    }

    return franchiseGroup;
  }
}
