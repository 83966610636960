/* eslint-disable import/no-cycle */

import {Api} from '../services/api';
import {RootStore} from './root-store';
import {BaseStore} from './base-store';
import {TravelInsuranceType} from '../models/travel-insurance-type';

export class TravelInsuranceTypeStore extends BaseStore<TravelInsuranceType, number> {
  constructor(rootStore: RootStore) {
    super(rootStore, 'id', Api.getTravelInsuranceTypes);

    this.loadAll();
  }
}
