import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Form, Input, Radio} from 'antd';
import {observer} from 'mobx-react-lite';
import {useNavigate} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import {ContactLanguage, PaymentMethod, PaymentRhythm} from '../../../../models/person';
import {useStore} from '../../../../hooks/useStore';
import {CalculatorPaths} from '../../../paths';
import Space from '../../../../components/shared/Space';
import {Config, GUTTER_SIZE} from '../../../../config';
import {Validators} from '../../../../utils/validators';
import {Col, Row} from '../../../../components/layout/Grid';
import InfoBox from '../../../../components/shared/InfoBox';
import Select from '../../../../components/shared/Select';
import StepsNavigationButtons from '../../../../components/StepsNavigationButtons';
import Text from '../../../../components/shared/Text';
import InfoWithLabel from '../../../../components/shared/InfoWithLabel';
import './PaymentForm.scss';

interface FormValues {
  contactLanguage: ContactLanguage;
  paymentMethod: PaymentMethod;
  usePaymentMethodForCostSharing: boolean;
  paymentRhythm: PaymentRhythm;
  iban: string;
}

const PaymentForm: FC = () => {
  const {t} = useTranslation();
  const store = useStore();
  const navigate = useNavigate();
  const [form] = Form.useForm<FormValues>();
  const [showUsePaymentMethodForCostSharingOptions, setShowUsePaymentMethodForCostSharingOptions] = useState(
    store.calculatorStore.paymentMethod
    && [PaymentMethod.DIRECT_DEBIT, PaymentMethod.DIRECT_DEBIT_POST].includes(store.calculatorStore.paymentMethod)
  );
  const [showInvoiceInfo, setShowInvoiceInfo] = useState(
    store.calculatorStore.usePaymentMethodForCostSharing !== undefined
    && !store.calculatorStore.usePaymentMethodForCostSharing
  );

  const onFinish = (values: FormValues) => {
    store.calculatorStore.setContactLanguage(values.contactLanguage);
    store.calculatorStore.setPaymentMethod(values.paymentMethod);
    if ([PaymentMethod.DIRECT_DEBIT, PaymentMethod.DIRECT_DEBIT_POST].includes(values.paymentMethod)) {
      store.calculatorStore.setPaymentUseMethodForCostSharing(values.usePaymentMethodForCostSharing);
    } else {
      store.calculatorStore.setPaymentUseMethodForCostSharing(true);
    }
    store.calculatorStore.setPaymentRhythm(values.paymentRhythm);
    store.calculatorStore.setIban(values.iban);
    if (store.editMode) {
      store.setEditMode(false);
    }
    navigate(CalculatorPaths.finish());
  };

  const navigateBack = () => {
    navigate(CalculatorPaths.contact());
  };

  const initialValues: Partial<FormValues> = {
    contactLanguage: store.calculatorStore.contactLanguage || ContactLanguage.DE,
    paymentMethod: store.calculatorStore.paymentMethod,
    usePaymentMethodForCostSharing: store.calculatorStore.usePaymentMethodForCostSharing,
    paymentRhythm: store.calculatorStore.paymentRhythm || PaymentRhythm.MONTHLY,
    iban: store.calculatorStore.iban,
  };

  const onValuesChange = ({paymentMethod, usePaymentMethodForCostSharing}: Partial<FormValues>, values: FormValues) => {
    if (paymentMethod) {
      const useIt = [PaymentMethod.DIRECT_DEBIT, PaymentMethod.DIRECT_DEBIT_POST].includes(paymentMethod);
      setShowUsePaymentMethodForCostSharingOptions(useIt);

      const showIt = values.usePaymentMethodForCostSharing !== undefined && !values.usePaymentMethodForCostSharing;
      setShowInvoiceInfo(useIt ? showIt : false);
    }

    if (usePaymentMethodForCostSharing !== undefined) {
      setShowInvoiceInfo(!usePaymentMethodForCostSharing);
    }
  };

  return (
    <div className={'payment-form'}>
      <Form<FormValues>
        form={form}
        layout={'vertical'}
        colon={false}
        labelAlign={'left'}
        requiredMark={false}
        onFinish={onFinish}
        initialValues={initialValues}
        onValuesChange={onValuesChange}
      >
        <Form.Item
          name={'contactLanguage'}
          label={(
            <InfoWithLabel label={t('label.selectContactLanguage')}>
              <ReactMarkdown linkTarget={'_blank'}>
                {t('stepFinish.contactLanguageInfo', {url: Config.myAquilanaUrl})}
              </ReactMarkdown>
            </InfoWithLabel>
          )}
          rules={[Validators.required(t('validator.contactLanguageRequired'))]}
        >
          <Radio.Group>
            <Row>
              {Object.values(ContactLanguage).map((language) => (
                <Col xs={24} md={12} key={language}>
                  <Radio value={language}>{t(`lang.${language.toLowerCase()}`)}</Radio>
                </Col>
              ))}
            </Row>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name={'paymentMethod'}
          label={t('label.selectPaymentMethod')}
          rules={[Validators.required(t('validator.paymentMethodRequired'))]}
        >
          <Radio.Group className={'select-card'}>
            <Row gutter={[GUTTER_SIZE, GUTTER_SIZE]}>
              {Object.values(PaymentMethod).map((paymentMethod) => (
                <Col xs={24} md={12} key={paymentMethod}>
                  <Radio value={paymentMethod}>
                    {t(`paymentMethod.${paymentMethod}`)}
                    {[PaymentMethod.DIRECT_DEBIT, PaymentMethod.DIRECT_DEBIT_POST].includes(paymentMethod) && ' *'}
                    {paymentMethod === PaymentMethod.E_BILL && ' **'}
                  </Radio>
                </Col>
              ))}
            </Row>
          </Radio.Group>
        </Form.Item>
        <Form.Item>
          <Text size={'extra-small'}>
            {'* '}
            {t('paymentMethod.debitDirectNotice')}
            <br/>
            {'** '}
            {t('paymentMethod.eBillNotice')}
          </Text>
        </Form.Item>
        <Form.Item
          name={'usePaymentMethodForCostSharing'}
          rules={[Validators.requiredIf(
            showUsePaymentMethodForCostSharingOptions || false,
            t('validator.usePaymentMethodForCostSharingRequired')
          )]}
          hidden={!showUsePaymentMethodForCostSharingOptions}
        >
          <Radio.Group>
            <Space direction={'vertical'}>
              <Radio value>{t('paymentMethod.useForPremiumPaymentAndCostSharing')}</Radio>
              <Radio value={false}>{t('paymentMethod.useForPremiumPaymentOnly')}</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item hidden={!showInvoiceInfo}>
          <InfoBox title={t('label.information')}>
            <ReactMarkdown>
              {t('paymentMethod.invoiceInfo')}
            </ReactMarkdown>
          </InfoBox>
        </Form.Item>
        <Form.Item
          name={'paymentRhythm'}
          label={t('label.paymentRhythm')}
          rules={[Validators.required(t('validator.paymentRhythmRequired'))]}
        >
          <Select
            options={Object.values(PaymentRhythm).map((paymentRhythm) => ({
              value: paymentRhythm,
              label: t(`paymentRhythm.${paymentRhythm}`),
            }))}
          />
        </Form.Item>
        <Form.Item>
          <InfoBox title={t('paymentRhythm.prePaymentInfo.title')}>
            <ReactMarkdown className={'payment-info'}>
              {t('paymentRhythm.prePaymentInfo.text')}
            </ReactMarkdown>
          </InfoBox>
        </Form.Item>
        <Form.Item
          name={'iban'}
          label={(
            <Space>
              {t('label.iban')}
              {!store.calculatorStore.canSubmitApplication && (
                <span className={'optional'}>{t('label.optional')}</span>
              )}
            </Space>
          )}
          rules={[
            Validators.requiredIf(store.calculatorStore.canSubmitApplication, t('validator.ibanRequired')),
            Validators.iban,
          ]}
        >
          <Input placeholder={t('label.ibanPlaceholder')} maxLength={30}/>
        </Form.Item>
        <StepsNavigationButtons
          backHidden={store.editMode}
          back={() => navigateBack()}
          next={() => form.submit()}
          nextLabel={t('action.next')}
        />
      </Form>
    </div>
  );
};

export default observer(PaymentForm);
