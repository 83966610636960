import React, {FC, ReactNode} from 'react';
import './Container.scss';

export interface ContainerProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: ReactNode;
  background?: 'light' | 'dark' | 'white';
  boxed?: boolean;
  noPadding?: boolean;
  flex?: number;
}

const Container: FC<ContainerProps> = ({
  children,
  className,
  background = 'white',
  boxed = true,
  noPadding = false,
  style,
  flex,
}) => {
  const classNames = ['container'];
  if (className) {
    classNames.push(className);
  }
  if (background) {
    classNames.push(background);
  }
  if (noPadding) {
    classNames.push('no-padding');
  }
  if (flex === 1) {
    classNames.push('main-content');
  }
  return (
    <div className={classNames.join(' ')} style={flex ? {...style, flex} : style}>
      <div className={boxed ? 'boxed' : undefined}>
        {children}
      </div>
    </div>
  );
};

export default Container;
