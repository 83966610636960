import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import Space from './Space';

interface OptionalLabelProps {
  label: React.ReactNode;
}
const OptionalLabel: FC<OptionalLabelProps> = ({label}) => {
  const {t} = useTranslation();

  return (
    <Space>
      {label}
      <span className={'optional'}>{t('label.optional')}</span>
    </Space>
  );
};

export default OptionalLabel;
