/* eslint-disable import/no-cycle */
import {runInAction} from 'mobx';
import {BaseStore} from './base-store';
import {RootStore} from './root-store';
import {Api} from '../services/api';
import {AddonInsuranceRate} from '../models/addon-insurance-rate';
import {AddonInsurance, AddonInsuranceType} from '../models/addon-insurance';
import {Person} from '../models/person';

export class AddonInsuranceRateStore extends BaseStore<AddonInsuranceRate, number> {
  constructor(rootStore: RootStore) {
    super(rootStore, 'id', Api.getAddonInsuranceRates);

    this.loadAll();
  }

  loadAll(): Promise<void> {
    if (!this.apiEndpoint) {
      throw new Error('not supported');
    }

    this.isLoading = true;
    return this.apiEndpoint().then((models) => {
      runInAction(() => {
        this.models = models;
        this.isLoading = false;
      });
    });
  }

  findByTypeAndPerson(type: AddonInsuranceType, person: Person): AddonInsuranceRate[] {
    const age = type === AddonInsuranceType.ACCIDENT_DEATH_DISABILITY
      ? person.getAgeForAccidentDeathDisability() : person.age;

    return this.models.filter(
      (rate) => rate.addonInsuranceTypeId === AddonInsurance.getTypeAsNumber(type)
        && (rate.fromAge <= age && age <= rate.toAge)
        && rate.premiumVersion?.isValidAt(person.insuranceStart)
    );
  }

  findBy(
    type: AddonInsuranceType,
    person: Person,
    variant?: string,
    deductible?: number,
    death?: number,
    invalidity?: number
  ): AddonInsuranceRate[] {
    const age = type === AddonInsuranceType.ACCIDENT_DEATH_DISABILITY
      ? person.getAgeForAccidentDeathDisability() : person.age;

    return this.models.filter(
      (rate) => rate.addonInsuranceTypeId === AddonInsurance.getTypeAsNumber(type)
        && (rate.fromAge <= age && age <= rate.toAge)
        && rate.premiumVersion?.isValidAt(person.insuranceStart)
        && (variant === undefined || rate.variant === variant)
        && (deductible === undefined || rate.deductible === deductible)
        && (death === undefined || rate.death === death)
        && (invalidity === undefined || rate.invalidity === invalidity)
    );
  }
}
