import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Form, Radio} from 'antd';
import {observer} from 'mobx-react-lite';
import {useNavigate} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import {useStore} from '../hooks/useStore';
import useCurrentDomainInformation from '../hooks/useCurrentDomainInformation';
import {Api} from '../services/api';
import {CalculatorPaths, TravelInsurancePaths} from '../pages/paths';
import InfoBox from './shared/InfoBox';
import {Col, Row} from './layout/Grid';
import {Validators} from '../utils/validators';
import Checkbox from './shared/Checkbox';
import {Config} from '../config';
import StepsNavigationButtons from './StepsNavigationButtons';
import Button from './shared/Button';

const SubmitApplicationForm: FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const store = useStore();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [form] = Form.useForm();
  const {isTravelInsurance} = useCurrentDomainInformation();

  const onFinish = () => {
    setLoading(true);
    if (!isTravelInsurance) {
      Api.sendRequest(store.calculatorStore.getIssueRequest())
        .then(() => {
          navigate(CalculatorPaths.successSubmission());
          setLoading(false);
        })
        .catch(() => {
          // TODO: add error handling
          setLoading(false);
        });
    } else {
      Api.sendTravelInsuranceRequest(store.travelInsuranceStore.getIssueRequest())
        .then((data) => {
          if (data.redirectUrl) {
            window.location.href = data.redirectUrl;
          }
        })
        .catch(() => {
          // TODO: add error handling
          setLoading(false);
        });
    }
  };

  return (
    <div className={'submit-application-form'}>
      <Form
        form={form}
        layout={'vertical'}
        colon={false}
        labelAlign={'left'}
        requiredMark={false}
        onFinish={onFinish}
      >
        {!isTravelInsurance && (
          <Form.Item>
            <InfoBox title={t('label.notice')}>{t('submitApplication.info')}</InfoBox>
          </Form.Item>
        )}
        <Form.Item
          name={'question'}
          label={t('submitApplication.question')}
        >
          <Radio.Group onChange={({target: {value}}) => setDisabled(value !== 'yes')}>
            <Row>
              <Col key={'yes'}>
                <Radio value={'yes'}>{t('label.yes')}</Radio>
              </Col>
              <Col key={'no'}>
                <Radio value={'no'}>{t('label.no')}</Radio>
              </Col>
            </Row>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name={'acceptRegulation'}
          rules={[Validators.checkbox()]}
          valuePropName={'checked'}
        >
          <Checkbox>
            <ReactMarkdown className={'aquilana-text'} linkTarget={'_blank'}>
              {
                isTravelInsurance
                  ? t('submitApplication.regulationTravelInsurance', {url: Config.regulationUrlTravelInsurance})
                  : t('submitApplication.regulation', {url: Config.regulationUrl})
              }
            </ReactMarkdown>
          </Checkbox>
        </Form.Item>
        <Form.Item
          name={'acceptPrivacyPolicy'}
          rules={[Validators.checkbox()]}
          valuePropName={'checked'}
        >
          <Checkbox>
            <ReactMarkdown className={'aquilana-text'} linkTarget={'_blank'}>
              {t('submitApplication.privacyPolicy', {url: Config.privacyPolicyUrl})}
            </ReactMarkdown>
          </Checkbox>
        </Form.Item>
        {isTravelInsurance
          ? (
            <StepsNavigationButtons
              back={() => navigate(TravelInsurancePaths.person())}
              nextDisabled={disabled}
              loading={loading}
              next={() => form.submit()}
              nextLabel={t('travelInsurance.overview.submit')}
            />
          )
          : (
            <Button skin={'primary-gray-blue'} onClick={() => form.submit()} disabled={disabled} loading={loading}>
              {t('action.submit')}
            </Button>
          )}
      </Form>
    </div>
  );
};

export default observer(SubmitApplicationForm);
