/* eslint-disable import/no-cycle */
import {Api} from '../services/api';
import {RootStore} from './root-store';
import {BaseStore} from './base-store';
import {City} from '../models/city';

export class CityStore extends BaseStore<City, number> {
  constructor(rootStore: RootStore) {
    super(rootStore, 'id', Api.getCities);

    this.loadAll();
  }

  findByPostcode(postcode: number): City | undefined {
    return this.models.find((city) => city.postcode === postcode);
  }
}
