import React from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import ReactMarkdown from 'react-markdown';
import {useNavigate} from 'react-router-dom';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import Container from '../../../components/layout/Container';
import Text from '../../../components/shared/Text';
import Button from '../../../components/shared/Button';
import {Box, BoxWrapper} from '../../../components/shared/Box';
import {Config, GUTTER_SIZE} from '../../../config';
import Space from '../../../components/shared/Space';
import {Col, Row} from '../../../components/layout/Grid';
import {TravelInsurancePaths} from '../../paths';

const TravelInsurancePaymentFailed = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const toWebsite = () => {
    window.location.href = Config.websiteUrl;
  };

  return (
    <>
      <Container background={'white'}>
        <Text header={1}>{t('travelInsurance.paymentFailed.title')}</Text>
      </Container>
      <Container background={'white'} flex={1}>
        <Space direction={'vertical'} size={'large'}>
          <BoxWrapper>
            <Box flex={'0 0 70px'}>
              <ExclamationCircleOutlined style={{fontSize: 50, color: '#D24345'}}/>
            </Box>
            <Box flex={'auto'}>
              <Text medium color={'gray-blue'}>
                <ReactMarkdown linkTarget={'_blank'}>
                  {t('travelInsurance.paymentFailed.info')}
                </ReactMarkdown>
              </Text>
            </Box>
          </BoxWrapper>
        </Space>
      </Container>
      <Container background={'white'}>
        <Row gutter={[GUTTER_SIZE, GUTTER_SIZE]} style={{marginBottom: '2rem'}}>
          <Col>
            <Button skin={'primary-turquoise'} onClick={() => navigate(TravelInsurancePaths.index())}>
              {t('travelInsurance.paymentFailed.startNewRequest')}
            </Button>
          </Col>
          <Col>
            <Button onClick={() => toWebsite()} skin={'primary-gray-blue'}>
              {t('action.toWebsite')}
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default observer(TravelInsurancePaymentFailed);
