import {Col, Row} from 'antd';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import {useNavigate} from 'react-router-dom';
import Text from '../../../../components/shared/Text';
import {AssignmentRightsIcon, PenIcon} from '../../../../components/shared/icons';
import {useStore} from '../../../../hooks/useStore';
import Icon from '../../../../components/shared/Icon';
import {GUTTER_SIZE} from '../../../../config';
import '../../../../components/cards/OverviewCard.scss';
import {Sex} from '../../../../models/person';
import Button from '../../../../components/shared/Button';
import {TravelInsurancePaths} from '../../../paths';

const TravelInsuranceContactCard: FC = () => {
  const {t} = useTranslation();
  const store = useStore();
  const navigate = useNavigate();

  const edit = () => {
    navigate(TravelInsurancePaths.person());
  };

  return (
    <div className={'overview-card'}>
      <div className={'card-header'}>
        <Row gutter={GUTTER_SIZE} align={'middle'}>
          <Col flex={'50px'}>
            <Icon component={AssignmentRightsIcon} size={50}/>
          </Col>
          <Col flex={'auto'}>
            <Text>{t('travelInsurance.overview.contactCard.title')}</Text>
          </Col>
          <Col flex={'50px'}>
            <Button type={'link'} onClick={edit}>
              <Icon component={PenIcon} size={22}/>
            </Button>
          </Col>
        </Row>
      </div>
      <div className={'card-body'}>
        <table>
          <tbody>
            <tr>
              <td className={'label'}>
                <Text size={'small'}>
                  {t('label.sex')}
                  {' / '}
                  {t('label.name')}
                </Text>
              </td>
              <td className={'value'}>
                <Text strong size={'small'}>
                  {t(`sex.${store.travelInsuranceStore.policyHolder.sex ?? Sex.MALE}`)}
                  {' '}
                  {store.travelInsuranceStore.policyHolder.fullName}
                </Text>
              </td>
            </tr>
            <tr>
              <td className={'label'}>
                <Text size={'small'}>
                  {t('label.address')}
                </Text>
              </td>
              <td className={'value'}>
                <Text strong size={'small'}>
                  {store.travelInsuranceStore.address}
                </Text>
              </td>
            </tr>
            <tr>
              <td className={'label'}>
                <Text size={'small'}>
                  {t('label.postcodeCity')}
                </Text>
              </td>
              <td className={'value'}>
                <Text strong size={'small'}>{store.travelInsuranceStore.city?.label}</Text>
              </td>
            </tr>
            <tr>
              <td className={'label'}>
                <Text size={'small'}>
                  {t('label.email')}
                </Text>
              </td>
              <td className={'value'}>
                <Text strong size={'small'}>
                  {store.travelInsuranceStore.email}
                </Text>
              </td>
            </tr>
            <tr>
              <td className={'label'}>
                <Text size={'small'}>
                  {t('label.phone')}
                </Text>
              </td>
              <td className={'value'}>
                <Text strong size={'small'}>
                  {store.travelInsuranceStore.phone}
                </Text>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default observer(TravelInsuranceContactCard);
