// eslint-disable-next-line import/no-cycle
import {DomainContextInformation} from '../../contexts/DomainContext';

export const isCancellationLeaveContext = (path: string) => {
  if (!path) {
    return false;
  }
  if (!path.match(/^\/cancellation-leave/)) {
    return false;
  }

  return true;
};

export const CANCELLATION_LEAVE_CONTEXT_NAME = 'cancellation-leave';

export const CancellationLeaveContext: DomainContextInformation = {
  name: CANCELLATION_LEAVE_CONTEXT_NAME,
  matches: isCancellationLeaveContext,
};
