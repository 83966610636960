export const GUTTER_SIZE = 24;

export const Config = {
  fallbackLng: 'de',
  currentPriceYear: 2024,
  franchiseChangePhase: 1, // Phase 1: now, Phase 2: when new insurance rates come out, Phase 3: > 01.12.
  apiDateFormat: 'YYYY-MM-DD',
  dateFormat: 'DD.MM.YYYY',
  dateFormatInsuranceStart: 'D. MMMM YYYY',
  doctorListUrl: 'https://hausarztverzeichnis.ch/aquilana',
  myAquilanaUrl: 'https://www.myaquilana.ch/',
  aquilanaContactUrl: 'https://www.aquilana.ch/kontakt',
  regulationUrl: 'https://www.aquilana.ch/statuten',
  regulationUrlTravelInsurance: 'https://www.aquilana.ch/reiseversicherung.pdf',
  privacyPolicyUrl: 'https://www.aquilana.ch/datenschutz',
  disablementHandicapUrl: 'https://www.aquilana.ch/kti',
  websiteUrl: 'https://www.aquilana.ch/',
  travelInsuranceInformationUrl: 'https://www.aquilana.ch/versicherungen/weitere-versicherungen/reiseversicherung',
  cancellationDatesInfoPdfUrl: 'https://www.aquilana.ch/kuendigungstermine.pdf',
  contact: {
    phone: '+41 56 203 44 22',
    phoneLink: '0041562034422',
    openingHours: 'Montag bis Freitag, 08.00 – 16.30 Uhr',
    email: 'kundendienst@aquilana.ch',
  },
  issueTypeRequest: 'api/issue-types/2',
  issueTypeContract: 'api/issue-types/3',
  issueTypeComparisRequest: 'api/issue-types/4',
  issueTypeComparisContract: 'api/issue-types/5',
  issueTypeFranchiseChange: 'api/issue-types/6',
  issueTypeTravelInsurance: 'api/issue-types/16',
  issueTypeNewBorn: 'api/issue-types/15',
  issueCancellationUploadTypes: [
    {
      value: 'api/issue-types/17',
      label: 'Sozialdienst',
    },
    {
      value: 'api/issue-types/18',
      label: 'Beistand',
    },
    {
      value: 'api/issue-types/19',
      label: 'Versicherungsberater',
    },
    {
      value: 'api/issue-types/20',
      label: 'Andere',
    },
  ],
  issueTypeCancellationWizard: 'api/issue-types/21',
  issueTypeCancellationDeath: 'api/issue-types/22',
  issueTypeCancellationLeave: 'api/issue-types/23',
  aquilanaInsurer: 'api/insurers/3',
  debug: 1, // 0 = disabled, 1 = hidden, 2 = visible
  franchiseChangeServiceDisabled: process.env.REACT_APP_FRANCHISE_CHANGE_DISABLED === 'true',
};
