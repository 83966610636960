import {Col, Row} from 'antd';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import {useNavigate} from 'react-router-dom';
import dayjs from 'dayjs';
import {useStore} from '../../hooks/useStore';
import {CalculatorPaths, CancellationWizardPaths} from '../../pages/paths';
import {Config, GUTTER_SIZE} from '../../config';
import Icon from '../shared/Icon';
import {AssignmentRightsIcon, PenIcon} from '../shared/icons';
import Button from '../shared/Button';
import Text from '../shared/Text';
import useCurrentDomainInformation from '../../hooks/useCurrentDomainInformation';

const ContactCard: FC = () => {
  const {t} = useTranslation();
  const store = useStore();
  const navigate = useNavigate();
  const {isCancellationWizard, currentStore} = useCurrentDomainInformation();

  const tableClassNameLabel = `label ${isCancellationWizard ? 'medium-large' : ''}`;
  const tableClassNameValue = `value ${isCancellationWizard ? 'medium-large' : ''}`;

  const edit = () => {
    store.setEditMode(true);
    if (isCancellationWizard) {
      currentStore.setCurrent(currentStore.policyHolder);
      navigate(CancellationWizardPaths.index());
    } else {
      navigate(CalculatorPaths.contact());
    }
  };

  return (
    <div className={'overview-card'}>
      <div className={'card-header'}>
        <Row gutter={GUTTER_SIZE} align={'middle'} wrap={false}>
          <Col flex={'50px'}>
            <Icon component={AssignmentRightsIcon} size={50}/>
          </Col>
          <Col flex={'auto'}>
            <Text>{t('label.policyholder')}</Text>
          </Col>
          <Col flex={'50px'}>
            <Button type={'link'} onClick={edit}>
              <Icon component={PenIcon} size={22}/>
            </Button>
          </Col>
        </Row>
      </div>
      <div className={'card-body'}>
        <table>
          <tbody>
            <tr>
              <td className={tableClassNameLabel}>
                <Text size={'small'}>
                  {t('label.policyholder')}
                </Text>
              </td>
              <td className={tableClassNameValue}>
                <Text strong size={'small'}>
                  {
                  currentStore.policyHolder?.fullName
                }
                </Text>
              </td>
            </tr>
            {!isCancellationWizard && (
            <tr>
              <td className={tableClassNameLabel}>
                <Text size={'small'}>
                  {t('label.paymentMethod')}
                </Text>
              </td>
              <td className={tableClassNameValue}>
                <Text strong size={'small'}>
                  {t(`paymentMethod.${store.calculatorStore.paymentMethod}`)}
                </Text>
              </td>
            </tr>
            )}
            <tr>
              <td className={tableClassNameLabel}>
                <Text size={'small'}>
                  {t('label.address')}
                </Text>
              </td>
              <td className={tableClassNameValue}>
                <Text strong size={'small'}>
                  {currentStore.address}
                </Text>
              </td>
            </tr>
            <tr>
              <td className={tableClassNameLabel}>
                <Text size={'small'}>
                  {t('label.postcodeCity')}
                </Text>
              </td>
              <td className={tableClassNameValue}>
                <Text strong size={'small'}>
                  {currentStore.city?.label}
                </Text>
              </td>
            </tr>
            <tr>
              <td className={tableClassNameLabel}>
                <Text size={'small'}>
                  {t('label.email')}
                </Text>
              </td>
              <td className={tableClassNameValue}>
                <Text strong size={'small'}>
                  {currentStore.email}
                </Text>
              </td>
            </tr>
            <tr>
              <td className={tableClassNameLabel}>
                <Text size={'small'}>
                  {t('label.phone')}
                </Text>
              </td>
              <td className={tableClassNameValue}>
                <Text strong size={'small'}>
                  {currentStore.phone ?? '-'}
                </Text>
              </td>
            </tr>
            {isCancellationWizard && (
            <>
              <tr>
                <td className={tableClassNameLabel}>
                  <Text size={'small'}>
                    {t('label.birthday')}
                  </Text>
                </td>
                <td className={tableClassNameValue}>
                  <Text strong size={'small'}>
                    {dayjs(currentStore.policyHolder?.birthday).format(Config.dateFormat) ?? '-'}
                  </Text>
                </td>
              </tr>
              <tr>
                <td className={tableClassNameLabel}>
                  <Text size={'small'}>
                    {t('label.insuranceNumber')}
                  </Text>
                </td>
                <td className={tableClassNameValue}>
                  <Text strong size={'small'}>
                    {currentStore.policyHolder?.insuranceNo ?? '-'}
                  </Text>
                </td>
              </tr>
            </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default observer(ContactCard);
