import React, {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {ColumnType} from 'antd/es/table';
import {useTranslation} from 'react-i18next';
import {Table} from '../../../../components/shared/Table';
import {useStore} from '../../../../hooks/useStore';
import {BaseInsuranceType, BaseInsuranceTypes} from '../../../../models/base-insurance';
import {formatNumber} from '../../../../utils/numbers';
import {BASE_INSURANCE_PREMIUM_REDUCTION_FACTOR} from '../../../../stores/calculator-store';

interface NewFranchiseCostTableProps {
  selectedFranchise: number;
  setSelectedFranchise: React.Dispatch<React.SetStateAction<number>>;
}

interface DataType {
  key: React.Key;
  franchise: number;
  premium: number;
}

const NewFranchiseCostTable: FC<NewFranchiseCostTableProps> = ({selectedFranchise, setSelectedFranchise}) => {
  const store = useStore();
  const {t} = useTranslation();
  const person = store.franchiseChangeStore.current;
  const hasReduced = store.franchiseChangeStore.getPremiumReductionBaseInsurance(person);
  const factor = hasReduced ? BASE_INSURANCE_PREMIUM_REDUCTION_FACTOR : 1;
  const currentBaseInsuranceTitle = t(
    `${BaseInsuranceTypes[store.franchiseChangeStore.current.baseInsurance?.type
    ?? BaseInsuranceType.STANDARD].translationPrefix}.title`
  );

  const isCurrentFranchise = (franchise: number) => {
    if (store.franchiseChangeStore.current.isChild) {
      return franchise === store.franchiseChangeStore.current.baseInsurance?.oldFranchiseGroup?.valueChild;
    }
    return franchise === store.franchiseChangeStore.current.baseInsurance?.oldFranchiseGroup?.valueAdult;
  };

  const columns: ColumnType<DataType>[] = [
    {
      title: t('baseInsurance.franchise'),
      dataIndex: 'franchise',
      render: (franchise: number) => `CHF ${formatNumber(franchise)} ${isCurrentFranchise(franchise)
        ? t('stepNewFranchise.currentFranchise') : ''}`,
    },
    {
      title: store.franchiseChangeStore.current.baseInsurance?.withAccident
        ? t('stepNewFranchise.premiumWithAccident', {type: currentBaseInsuranceTitle})
        : t('stepNewFranchise.premiumWithoutAccident', {type: currentBaseInsuranceTitle}),
      dataIndex: 'premium',
      render: (premium: number) => {
        const rate = store.franchiseChangeStore.current.baseInsurance?.oldRate;
        if (rate) {
          const environmentalTax = rate.premiumVersion?.environmentalTax || 0;
          const diff = store.franchiseChangeStore.current.baseInsurance?.withAccident
            ? (premium * factor - environmentalTax) - (rate.valueWithAccident * factor - environmentalTax)
            : (premium * factor - environmentalTax) - (rate.valueWithoutAccident * factor - environmentalTax);

          return `CHF ${formatNumber(premium * factor - environmentalTax)}
          (${diff > 0 ? '+' : ''}${formatNumber(diff)})`;
        }
        return 'error';
      },
    },
  ];

  const rates = store.franchiseGroupStore.models.map((franchiseGroup) => {
    const rate = store.baseInsuranceRateStore.findOne(
      store.franchiseChangeStore.current.baseInsurance?.type ?? BaseInsuranceType.STANDARD,
      franchiseGroup.id,
      store.franchiseChangeStore.current.age,
      store.franchiseChangeStore.city?.regionCode || '',
      person.insuranceStart
    );
    let premium = 0;
    if (rate) {
      premium = store.franchiseChangeStore.current.baseInsurance?.withAccident
        ? rate.valueWithAccident : rate.valueWithoutAccident;
    }
    return {
      key: franchiseGroup.id ?? 0,
      franchise: store.franchiseChangeStore.current.isChild ? franchiseGroup.valueChild : franchiseGroup.valueAdult,
      premium,
    };
  });

  if (rates === undefined) {
    return null;
  }

  return (
    <Table<DataType>
      style={{marginBottom: 20, cursor: 'pointer'}}
      rowClassName={(record) => (isCurrentFranchise(record.franchise) ? 'disabled' : '')}
      rowSelection={{
        type: 'radio',
        getCheckboxProps: (record) => ({
          disabled: isCurrentFranchise(record.franchise),
        }),
        selectedRowKeys: (selectedFranchise
          && selectedFranchise !== store.franchiseChangeStore.current.baseInsurance?.oldFranchiseGroup?.id)
          ? [selectedFranchise as React.Key] : undefined,
        onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
          setSelectedFranchise(selectedRows[0].key as number);
        },
      }}
      onRow={(record) => ({
        onClick: () => {
          if (!isCurrentFranchise(record.franchise)) {
            setSelectedFranchise(record.key as number);
          }
        },
      })}
      columns={columns}
      dataSource={rates}
      activeClass={'ant-table-row-selected'}
      striped
    />
  );
};

export default observer(NewFranchiseCostTable);
