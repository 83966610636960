import {FC, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import InsuranceCard from '../../../../components/cards/InsuranceCard';
import {
  AddonInsurance, AddonInsuranceType, AddonInsuranceTypes, PatientCareType
} from '../../../../models/addon-insurance';
import {useStore} from '../../../../hooks/useStore';
import {ADDON_INSURANCE_PREMIUM_REDUCTION_FACTOR} from '../../../../stores/calculator-store';
import {formatNumber} from '../../../../utils/numbers';
import Info from '../../../../components/shared/Info';
import ExternalLink from '../../../../components/shared/ExternalLink';
import Checkbox from '../../../../components/shared/Checkbox';
import Checklist from '../../../../components/shared/Checklist';
import Space from '../../../../components/shared/Space';
import Select from '../../../../components/shared/Select';
import Text from '../../../../components/shared/Text';
import PatientCareInfoTable from './PatientCareInfoTable';

interface AddonInsuranceCardProps {
  type: AddonInsuranceType;
  reduction?: boolean;
}

const AddonInsuranceCard: FC<AddonInsuranceCardProps> = ({type, reduction = false}) => {
  const {t} = useTranslation();
  const store = useStore();
  const [checked, setChecked] = useState(store.calculatorStore.current.getAddonInsurance(type) !== undefined);
  const dummyNewAddonInsurance = new AddonInsurance();
  dummyNewAddonInsurance.type = type;
  const [addonInsurance, setAddonInsurance] = useState<AddonInsurance>(
    store.calculatorStore.current.getAddonInsurance(type) || dummyNewAddonInsurance
  );
  const [variant, setVariant] = useState<string>(addonInsurance?.rate?.variant || PatientCareType.NORMAL);
  const [deductible, setDeductible] = useState<number>(addonInsurance?.rate?.deductible || 0);
  const factor = reduction && type !== AddonInsuranceType.ACCIDENT_DEATH_DISABILITY
    ? ADDON_INSURANCE_PREMIUM_REDUCTION_FACTOR
    : 1;

  const variants = Object.values(PatientCareType).map((careType) => ({
    value: careType,
    label: t(`patientCare.${careType}`),
  }));

  const deductibleOptions = [
    {
      value: 0,
      label: t('insurance.noDeductible'),
    },
    {
      value: 2000,
      label: `CHF ${formatNumber(2000, 0)}.-`,
    },
    {
      value: 5000,
      label: `CHF ${formatNumber(5000, 0)}.-`,
    },
  ];

  const rates = store.addonInsuranceRateStore.findByTypeAndPerson(type, store.calculatorStore.current);

  useEffect(() => {
    const insurance = store.calculatorStore.current.getAddonInsurance(type);

    if (insurance) {
      setAddonInsurance(insurance);
    }
  }, [rates]);

  useEffect(() => {
    if (checked) {
      store.calculatorStore.current.addAddonInsurance(addonInsurance);
    } else {
      store.calculatorStore.current.removeAddonInsurance(addonInsurance.type);
    }
  }, [checked]);

  useEffect(() => {
    const rate = rates.find((r) => r.variant === variant && r.deductible === deductible);
    if (!rate && deductible !== 0) {
      setDeductible(0);
    } else if (!rate) {
      addonInsurance.setRate(rates[0]);
    } else {
      addonInsurance.setRate(rate);
    }
  }, [variant, deductible]);

  const onRateChange = (id: number) => {
    const selectedRate = rates.find((r) => r.id === id);
    if (selectedRate) {
      addonInsurance.setRate(selectedRate);
    }
  };

  return (
    <InsuranceCard
      icon={AddonInsuranceTypes[addonInsurance.type].icon}
      title={t(`${AddonInsuranceTypes[addonInsurance.type].translationPrefix}.title`)}
      subTitle={t('addonInsurance.one')}
      onSelectionChange={() => setChecked(!checked)}
      price={addonInsurance.price * factor}
      info={(
        <Info width={addonInsurance.type === AddonInsuranceType.PATIENT_CARE ? 1100 : 800}>
          <ReactMarkdown>
            {t(`${AddonInsuranceTypes[addonInsurance.type].translationPrefix}.info`)}
          </ReactMarkdown>
          <br/>
          {addonInsurance.type === AddonInsuranceType.PATIENT_CARE && (
            <>
              <Text header={2}>
                {t('addonInsurance.patientCare.table.title')}
              </Text>
              <PatientCareInfoTable/>
              <br/>
              <br/>
            </>
          )}
          <ExternalLink href={AddonInsuranceTypes[addonInsurance.type].productLink}>
            {t('label.moreInformation')}
          </ExternalLink>
        </Info>
      )}
      selectInput={<Checkbox checked={checked} onChange={() => setChecked(!checked)}/>}
    >
      <Checklist text={t(`${AddonInsuranceTypes[addonInsurance.type].translationPrefix}.description`)}/>
      {checked && (
        <>
          {addonInsurance.rate !== undefined && addonInsurance.type === AddonInsuranceType.PATIENT_CARE && (
            <Space direction={'vertical'} fullWidth>
              <>
                <Text size={'small'}>{t('insurance.model')}</Text>
                <Select
                  disabled={!checked}
                  style={{width: '100%'}}
                  onChange={(value) => setVariant(value)}
                  defaultValue={variant}
                  options={variants}
                  className={'small'}
                  popupClassName={'small'}
                />
              </>
              {variant !== PatientCareType.NORMAL && (
                <>
                  <Text size={'small'}>{t('insurance.deductible')}</Text>
                  <Select
                    disabled={!checked}
                    style={{width: '100%'}}
                    onChange={(value) => setDeductible(value)}
                    defaultValue={deductible}
                    options={deductibleOptions}
                    className={'small'}
                    popupClassName={'small'}
                  />
                </>
              )}
            </Space>
          )}
          {addonInsurance.rate && addonInsurance.type === AddonInsuranceType.ACCIDENT_DEATH_DISABILITY && (
            <>
              <Text size={'small'}>{t('insurance.insuranceSum')}</Text>
              <Select
                fullWidth
                disabled={!checked}
                onChange={(value) => onRateChange(value)}
                defaultValue={addonInsurance.rate.id}
                options={rates.map((rate) => ({
                  value: rate.id,
                  label: `${t('addonInsurance.death')}: ${formatNumber(rate.death, 0)} / `
                    + `${t('addonInsurance.invalidity')}: ${formatNumber(rate.invalidity, 0)}`,
                }))}
                className={'small'}
                popupClassName={'small'}
              />
            </>
          )}
        </>
      )}
    </InsuranceCard>
  );
};

export default observer(AddonInsuranceCard);
