import React, {FC, useState} from 'react';
import {Form, Input, Radio} from 'antd';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import {useNavigate} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import StepsNavigationButtons from '../../../../components/StepsNavigationButtons';
import {CancellationLeavePaths} from '../../../paths';
import {Sex} from '../../../../models/person';
import {City} from '../../../../models/city';
import Text from '../../../../components/shared/Text';
import {Validators} from '../../../../utils/validators';
import {useStore} from '../../../../hooks/useStore';
import {Config, GUTTER_SIZE} from '../../../../config';
import {Col, Row} from '../../../../components/layout/Grid';
import Select from '../../../../components/shared/Select';
import useCitySelection from '../../../../hooks/useCitySelection';
import OptionalLabel from '../../../../components/shared/OptionalLabel';
import Checkbox from '../../../../components/shared/Checkbox';
import './CancellationForm.scss';
import {Api} from '../../../../services/api';

interface FormValues {
  senderDifferentFromPolicyHolder: boolean;
  sexSender: Sex;
  firstNameSender: string;
  lastNameSender: string;
  addressSender: string;
  citySender: City['id'];
  emailSender: string;
  phoneSender?: string;
  optInA: boolean;
  optInB: boolean;
  optInC: boolean;
}

const CancellationForm: FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const store = useStore();
  const {options} = useCitySelection();
  const [form] = Form.useForm<FormValues>();
  const [loading, setLoading] = useState(false);

  const onBack = () => {
    navigate(CancellationLeavePaths.questions());
  };

  const initialValues = {
    senderDifferentFromPolicyHolder: store.cancellationLeaveStore.senderDifferentFromPolicyHolder,
    sexSender: store.cancellationLeaveStore.sex,
    firstNameSender: store.cancellationLeaveStore.firstName,
    lastNameSender: store.cancellationLeaveStore.lastName,
    addressSender: store.cancellationLeaveStore.address,
    citySender: store.cancellationLeaveStore.city?.id,
    emailSender: store.cancellationLeaveStore.email,
    phoneSender: store.cancellationLeaveStore.phone,
  };

  const handleSubmit = (values: FormValues) => {
    setLoading(true);

    store.cancellationLeaveStore.setSenderDifferentFromPolicyHolder(values.senderDifferentFromPolicyHolder);
    if (values.senderDifferentFromPolicyHolder) {
      store.cancellationLeaveStore.setSex(values.sexSender);
      store.cancellationLeaveStore.setFirstName(values.firstNameSender);
      store.cancellationLeaveStore.setLastName(values.lastNameSender);
      store.cancellationLeaveStore.setAddress(values.addressSender);
      store.cancellationLeaveStore.setCity(store.cityStore.getById(values.citySender || 0));
      store.cancellationLeaveStore.setEmail(values.emailSender);
      store.cancellationLeaveStore.setPhone(values.phoneSender);
      store.cancellationLeaveStore.setInsuranceNumber('');
      store.cancellationLeaveStore.setBirthDate(null);
    } else {
      store.cancellationLeaveStore.setSex(store.cancellationLeaveStore.personSex!);
      store.cancellationLeaveStore.setFirstName(store.cancellationLeaveStore.personFirstName!);
      store.cancellationLeaveStore.setLastName(store.cancellationLeaveStore.personLastName!);
      store.cancellationLeaveStore.setAddress(store.cancellationLeaveStore.personAddress!);
      store.cancellationLeaveStore.setCity(store.cancellationLeaveStore.personCity!);
      store.cancellationLeaveStore.setEmail(store.cancellationLeaveStore.email!);
      store.cancellationLeaveStore.setPhone(store.cancellationLeaveStore.personPhone!);
      store.cancellationLeaveStore.setInsuranceNumber(store.cancellationLeaveStore.personInsuranceNumber!);
      store.cancellationLeaveStore.setBirthDate(store.cancellationLeaveStore.personBirthday!);
    }

    Api.sendRequest(store.cancellationLeaveStore.getIssueRequest())
      .then(() => {
        navigate(CancellationLeavePaths.successSubmission());
      })
      .catch(() => {
        // TODO: add error handling
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Form
      form={form}
      initialValues={initialValues}
      layout={'vertical'}
      colon={false}
      labelAlign={'left'}
      requiredMark={false}
      onFinish={handleSubmit}
      className={'cancellation-form'}
    >
      <Row gutter={[GUTTER_SIZE, GUTTER_SIZE]}>
        <Col xs={24} sm={24} md={24} lg={18} xl={18}>
          <Form.Item>
            <Text header={4}>{t('cancellation.leave.cancellation.sender.title')}</Text>
          </Form.Item>
          <Form.Item
            name={'senderDifferentFromPolicyHolder'}
            rules={[Validators.required()]}
          >
            <Radio.Group>
              <Radio value={false}>{t('cancellation.leave.cancellation.sender.sameAsPolicyHolder')}</Radio>
              <Radio value>{t('cancellation.leave.cancellation.sender.differentFromPolicyHolder')}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[GUTTER_SIZE, GUTTER_SIZE]}>
        <Col xs={24} sm={24} md={24} lg={16} xl={16}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.senderDifferentFromPolicyHolder !== currentValues.senderDifferentFromPolicyHolder}
          >
            {(f) => {
              const senderDifferentFromPolicyHolder = f.getFieldValue('senderDifferentFromPolicyHolder');

              if (!senderDifferentFromPolicyHolder) {
                return null;
              }

              return (
                <>
                  <Form.Item
                    name={'sexSender'}
                    label={t('label.sex')}
                    rules={[Validators.required(t('validator.salutationRequired'))]}
                  >
                    <Radio.Group>
                      {Object.values(Sex).map((sex) => (
                        <Radio key={sex} value={sex}>{t(`sex.${sex}`)}</Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    name={'firstNameSender'}
                    label={t('label.firstName')}
                    rules={[Validators.required(t('validator.firstNameRequired')), Validators.noSpecialCharacters]}
                  >
                    <Input placeholder={t('label.firstName')} maxLength={50}/>
                  </Form.Item>
                  <Form.Item
                    name={'lastNameSender'}
                    label={t('label.name')}
                    rules={[Validators.required(t('validator.nameRequired')), Validators.noSpecialCharacters]}
                  >
                    <Input placeholder={t('label.name')} maxLength={50}/>
                  </Form.Item>
                  <Form.Item
                    name={'addressSender'}
                    label={t('label.address')}
                    rules={[Validators.required(t('validator.addressRequired')), Validators.noSpecialCharacters]}
                  >
                    <Input placeholder={t('label.address')} maxLength={50}/>
                  </Form.Item>
                  <Form.Item
                    name={'citySender'}
                    label={t('label.postcodeCity')}
                    rules={[Validators.required(t('validator.postcodeCityRequired'))]}
                  >
                    <Select
                      showSearch
                      loading={store.cityStore.isLoading}
                      options={options}
                      placeholder={t('label.postcodeCityPlaceholder')}
                    />
                  </Form.Item>
                  <Form.Item
                    name={'emailSender'}
                    label={t('label.email')}
                    rules={[Validators.required(t('validator.emailRequired')), Validators.email]}
                  >
                    <Input placeholder={t('label.email')} maxLength={255}/>
                  </Form.Item>
                  <Form.Item
                    name={'phoneSender'}
                    label={<OptionalLabel label={t('label.phone')}/>}
                    rules={[Validators.phone]}
                  >
                    <Input placeholder={t('label.phone')} maxLength={50}/>
                  </Form.Item>
                </>
              );
            }}
          </Form.Item>
          <Form.Item>
            <Text header={4}>{t('cancellation.leave.cancellation.optIn.title')}</Text>
          </Form.Item>
          <Form.Item
            name={'optInA'}
            rules={[Validators.checkbox(t('validator.optInRequired'))]}
            valuePropName={'checked'}
          >
            <Checkbox>
              <ReactMarkdown>
                {t('cancellation.leave.cancellation.optInA')}
              </ReactMarkdown>
            </Checkbox>
          </Form.Item>

          <Form.Item
            name={'optInB'}
            rules={[Validators.checkbox(t('validator.optInRequired'))]}
            valuePropName={'checked'}
          >
            <Checkbox>
              <ReactMarkdown>
                {t('cancellation.leave.cancellation.optInB')}
              </ReactMarkdown>
            </Checkbox>
          </Form.Item>

          <Form.Item
            name={'optInC'}
            rules={[Validators.checkbox(t('validator.optInRequired'))]}
            valuePropName={'checked'}
          >
            <Checkbox>
              <ReactMarkdown linkTarget={'_blank'}>
                {t('cancellation.leave.cancellation.optInC', {privacyUrl: Config.privacyPolicyUrl})}
              </ReactMarkdown>
            </Checkbox>
          </Form.Item>

          <StepsNavigationButtons
            back={onBack}
            next={form.submit}
            loading={loading}
            nextLabel={t('action.submit')}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default observer(CancellationForm);
