import {IconComponentProps} from '@ant-design/icons/lib/components/Icon';
import AntIcon from '@ant-design/icons';
import {FC} from 'react';
import './Icon.scss';

export interface IconProps extends Pick<IconComponentProps, 'component' | 'className' | 'style'> {
  size?: number;
  color?: 'turquoise' | 'gray-blue';
}

const Icon: FC<IconProps> = ({size, color, className, style, ...props}) => {
  const classNames = ['aquilana-icon'];
  if (className) {
    classNames.push(className);
  }
  if (color) {
    classNames.push(`color-${color}`);
  }
  return (
    <AntIcon className={classNames.join(' ')} style={{...style, fontSize: size}} {...props}/>
  );
};

export default Icon;
