import * as React from 'react';
import {FC} from 'react';

interface ExternalLinkProps extends React.LinkHTMLAttributes<any> {
}

const ExternalLink: FC<ExternalLinkProps> = ({children, ...props}) => (
  <a target={'_blank'} rel={'noreferrer'} {...props}>
    {children}
  </a>
);

export default ExternalLink;
