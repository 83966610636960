/* eslint-disable import/no-cycle */
import React, {FC, useEffect, useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import {AquilanaFunctionStore} from '../stores/aquilana-function-store';
import {CalculatorContext} from '../pages/calculator/Context';
import {useStore} from '../hooks/useStore';
import {DefaultContext} from '../pages/Context';
import {TravelInsuranceContext} from '../pages/travel-insurance/Context';
import {FranchiseChangeContext} from '../pages/franchise-change/Context';
import {CancellationUploadContext} from '../pages/cancellation-upload/Context';
import {CancellationWizardContext} from '../pages/cancellation-wizard/Context';
import {CancellationDeathContext} from '../pages/cancellation-death/Context';
import {CancellationLeaveContext} from '../pages/cancellation-leave/Context';

export type DomainContextInformation = {
  name: string;
  matches: (path: string) => boolean;
  store?: AquilanaFunctionStore;
  setStore?: React.Dispatch<React.SetStateAction<AquilanaFunctionStore>>
};

interface DomainContextProviderProps {
  children: React.ReactNode;
}

const DomainContexts = [
  CalculatorContext,
  FranchiseChangeContext,
  TravelInsuranceContext,
  CancellationUploadContext,
  CancellationWizardContext,
  CancellationDeathContext,
  CancellationLeaveContext,
];

const getDomainContextData = (path: string): DomainContextInformation => {
  const context = DomainContexts.find((domainContext) => domainContext.matches(path));

  if (!context) {
    return DefaultContext;
  }

  return context;
};

export const DomainContext = React.createContext<DomainContextInformation | null>(null);

export const DomainContextProvider: FC<DomainContextProviderProps> = ({children}: DomainContextProviderProps) => {
  const location = useLocation();
  const store = useStore();
  const domainContextData = getDomainContextData(location.pathname);
  const [contextStore, setContextStore] = React.useState<AquilanaFunctionStore>(store.calculatorStore);

  useEffect(() => {
    let newStore: AquilanaFunctionStore | undefined;

    switch (domainContextData.name) {
      case CalculatorContext.name:
        newStore = store.calculatorStore;
        break;
      case FranchiseChangeContext.name:
        newStore = store.franchiseChangeStore;
        break;
      case TravelInsuranceContext.name:
        newStore = store.travelInsuranceStore;
        break;
      case CancellationUploadContext.name:
        newStore = store.cancellationUploadStore;
        break;
      case CancellationWizardContext.name:
        newStore = store.cancellationWizardStore;
        break;
      case CancellationDeathContext.name:
        newStore = store.cancellationDeathStore;
        break;
      case CancellationLeaveContext.name:
        newStore = store.cancellationLeaveStore;
        break;
      default:
        newStore = store.calculatorStore;
        break;
    }

    setContextStore(newStore);
  }, [domainContextData.name]);

  const domainContextValue = useMemo(() => ({
    name: domainContextData.name,
    matches: domainContextData.matches,
    store: contextStore,
    setStore: setContextStore,
  }), [domainContextData.name, contextStore, location.pathname]);

  return (
    <DomainContext.Provider value={domainContextValue}>
      {children}
    </DomainContext.Provider>
  );
};
