import React, {FC} from 'react';
import {Upload as AntUpload, UploadProps as AntUploadProps} from 'antd';
import {UploadChangeParam as AntUploadChangeParam, UploadFile as AntUploadFile} from 'antd/lib/upload/interface';

export type UploadChangeParam = AntUploadChangeParam;
export type UploadFile = AntUploadFile;

interface UploadProps extends AntUploadProps {
}

const Upload: FC<UploadProps> = ({children, ...props}) => (
  <AntUpload {...props}>{children}</AntUpload>
);

export default Upload;
