/* eslint-disable import/no-cycle */
import {computed, makeObservable} from 'mobx';
import {Dayjs} from 'dayjs';
import {Api} from '../services/api';
import {RootStore} from './root-store';
import {BaseStore} from './base-store';
import {PremiumVersion} from '../models/premium-version';
import {sortNumerically} from '../utils/sorters';

export class PremiumVersionStore extends BaseStore<PremiumVersion, number> {
  constructor(rootStore: RootStore) {
    super(rootStore, 'id', Api.getPremiumVersions);

    makeObservable(this, {
      maxDate: computed,
    });

    this.loadAll();
  }

  get maxDate(): Dayjs {
    return this.models.slice().sort((a, b) => sortNumerically(b.validTo.valueOf(), a.validTo.valueOf()))[0].validTo;
  }
}
