import {computed, makeObservable, observable} from 'mobx';
import {IBaseModel} from './base-model';

export interface IDoctor extends IBaseModel {
  id: number;
  title: string;
  lastName: string;
  firstName: string;
  address: string;
  postcode: number;
  city: string;
  phone: string;
  gln: number;
  zsr: string;
  patientAdmissionStop: boolean;
}

export class Doctor implements IDoctor {
  id = 0;
  title = '';
  lastName = '';
  firstName = '';
  address = '';
  postcode = 0;
  city = '';
  phone = '';
  gln = 0;
  zsr = '';
  patientAdmissionStop = false;

  constructor() {
    makeObservable(this, {
      id: observable,
      title: observable,
      lastName: observable,
      firstName: observable,
      address: observable,
      postcode: observable,
      city: observable,
      phone: observable,
      zsr: observable,
      gln: observable,
      patientAdmissionStop: observable,

      name: computed,
    });
  }

  get name(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  static fromJSON(data: Partial<IDoctor>) {
    const doctor = new Doctor();
    if (data?.id !== undefined) {
      doctor.id = data.id;
    }
    if (data?.title !== undefined) {
      doctor.title = data.title;
    }
    if (data?.lastName !== undefined) {
      doctor.lastName = data.lastName;
    }
    if (data?.firstName !== undefined) {
      doctor.firstName = data.firstName;
    }
    if (data?.address !== undefined) {
      doctor.address = data.address;
    }
    if (data?.postcode !== undefined) {
      doctor.postcode = data.postcode;
    }
    if (data?.city !== undefined) {
      doctor.city = data.city;
    }
    if (data?.phone !== undefined) {
      doctor.phone = data.phone;
    }
    if (data?.gln !== undefined) {
      doctor.gln = data.gln;
    }
    if (data?.zsr !== undefined) {
      doctor.zsr = data.zsr;
    }
    if (data?.patientAdmissionStop !== undefined) {
      doctor.patientAdmissionStop = data.patientAdmissionStop;
    }

    return doctor;
  }
}
