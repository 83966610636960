import i18n, {InitOptions} from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector, {DetectorOptions} from 'i18next-browser-languagedetector';
import de from './locales/de.json';
import fr from './locales/fr.json';
import it from './locales/it.json';
import en from './locales/en.json';
import {Config} from '../config';

export const german = 'de';
export const french = 'fr';
export const italian = 'it';
export const english = 'en';
export const availableLanguages = [german, french, italian, english];

const options: InitOptions & DetectorOptions & { whitelist: any } = {
  resources: {
    [german]: {
      translation: de,
    },
    [french]: {
      translation: fr,
    },
    [italian]: {
      translation: it,
    },
    [english]: {
      translation: en,
    },
  },
  detection: {
    order: ['navigator'],
  },
  load: 'all',
  fallbackLng: Config.fallbackLng,
  debug: process.env.NODE_ENV !== 'production',
  whitelist: availableLanguages,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  nsSeparator: '-::-',
  returnObjects: true,
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init(options);

export default i18n;
