import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useStore} from '../../../hooks/useStore';
import Container from '../../../components/layout/Container';
import Text from '../../../components/shared/Text';
import TravelInsuranceCard from './_components/TravelInsuranceCard';
import {Col, Row} from '../../../components/layout/Grid';
import {GUTTER_SIZE} from '../../../config';
import StepsNavigationButtons from '../../../components/StepsNavigationButtons';
import {TravelInsurancePaths} from '../../paths';
import Space from '../../../components/shared/Space';

const TravelInsuranceProductSelection = () => {
  const store = useStore();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const [selectedRate, setSelectedRate] = useState<number | undefined>(
    store.travelInsuranceStore.travelInsuranceRate?.id
  );

  const rates = (() => {
    if (store.travelInsuranceStore.travelInsuranceDuration && store.travelInsuranceStore.type) {
      return store.travelInsuranceRateStore
        .findAll(store.travelInsuranceStore.type, store.travelInsuranceStore.travelInsuranceDuration);
    }
    return undefined;
  })();

  const onContinue = () => {
    if (selectedRate) {
      const rate = store.travelInsuranceRateStore.getById(selectedRate);
      if (rate) {
        store.travelInsuranceStore.setTravelInsuranceRate(rate);
        store.travelInsuranceStore.saveToSession();
        navigate(TravelInsurancePaths.person());
      }
    }
  };

  const onBack = () => {
    navigate(TravelInsurancePaths.index());
  };

  return (
    <>
      <Container background={'white'}>
        <Text header={1}>{t('travelInsurance.product.title')}</Text>
        <Text size={'intro'}>{t('travelInsurance.product.header')}</Text>
      </Container>
      <Container background={'light'} flex={1}>
        <Space fullWidth direction={'vertical'} size={'large'} style={{height: '100%', flex: 1}}>
          <Row gutter={[GUTTER_SIZE, GUTTER_SIZE]}>
            {rates?.map((rate) => (
              <Col key={rate.id} xs={24} sm={24} md={24} lg={12} xl={10}>
                <TravelInsuranceCard
                  rate={rate}
                  selected={selectedRate === rate.id}
                  onSelectionChange={() => setSelectedRate(rate.id)}
                />
              </Col>
            ))}
          </Row>
          <StepsNavigationButtons back={onBack} next={onContinue} nextDisabled={!selectedRate}/>
        </Space>
      </Container>
    </>
  );
};

export default observer(TravelInsuranceProductSelection);
