import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import {useNavigate} from 'react-router-dom';
import {Person} from '../../models/person';
import {useStore} from '../../hooks/useStore';
import useCurrentDomainInformation from '../../hooks/useCurrentDomainInformation';
import {BASE_INSURANCE_PREMIUM_REDUCTION_FACTOR, CalculatorStore} from '../../stores/calculator-store';
import {FranchiseChangeStore} from '../../stores/franchise-change-store';
import {CalculatorPaths} from '../../pages/paths';
import {BaseInsuranceTypes} from '../../models/base-insurance';
import Space from '../shared/Space';
import {GUTTER_SIZE} from '../../config';
import Card from '../shared/Card';
import {Col, Row} from '../layout/Grid';
import Icon from '../shared/Icon';
import Price from '../shared/Price';
import Checklist from '../shared/Checklist';
import Divider from '../shared/Divider';
import Button from '../shared/Button';
import {PenIcon, TrashBinIcon} from '../shared/icons';
import Text from '../shared/Text';

interface BaseInsuranceCardProps {
  person: Person;
  fullDetails: boolean;
}

const BaseInsuranceCardOverview: FC<BaseInsuranceCardProps> = ({person, fullDetails}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const store = useStore();
  const {currentStore} = useCurrentDomainInformation();

  if (
    !(currentStore instanceof CalculatorStore
      || currentStore instanceof FranchiseChangeStore)
  ) {
    return null;
  }

  const hasReduction = currentStore.getPremiumReductionBaseInsurance(person);
  const factor = hasReduction ? BASE_INSURANCE_PREMIUM_REDUCTION_FACTOR : 1;

  const removeBaseInsurance = () => {
    person.setBaseInsurance(undefined);
  };

  const changeModel = () => {
    store.setEditMode(true);
    store.calculatorStore.setCurrent(person);
    navigate(CalculatorPaths.productBase());
  };

  const changeDoctor = () => {
    store.calculatorStore.setCurrent(person);
    store.setEditMode(true);
    navigate(CalculatorPaths.doctor());
  };

  if (!person.baseInsurance) {
    return null;
  }

  const franchiseValue = (() => {
    if (!person.baseInsurance.franchiseGroup) {
      return 0;
    }

    return person.isChild
      ? person.baseInsurance.franchiseGroup.valueChild
      : person.baseInsurance.franchiseGroup.valueAdult;
  })();

  const baseInsuranceType = BaseInsuranceTypes[person.baseInsurance.type];

  return (
    <Space direction={'vertical'} fullWidth size={GUTTER_SIZE}>
      <Space direction={'vertical'} fullWidth size={GUTTER_SIZE}>
        <Card>
          <Row>
            <Col flex={'auto'}>
              <Text header={2}>
                {t(`baseInsurance.${person.baseInsurance.type}.title`)}
              </Text>
              <Text size={'extra-small'} color={'turquoise'} strong opacity={0.6}>
                {t('baseInsurance.one')}
              </Text>
            </Col>
            <Col flex={'50px'} className={'product-icon'}>
              <Icon component={baseInsuranceType.icon} size={40}/>
            </Col>
          </Row>
          <br/>
          <Text size={'small'} color={'gray-blue'}>
            {`${t('baseInsurance.franchise')}: `}
            <Text>CHF </Text>
            <Price noZeroDecimals value={franchiseValue}/>
          </Text>
          {person.baseInsurance.withAccident && (
            <>
              <br/>
              <Text size={'small'} color={'gray-blue'}>
                {t('baseInsurance.withAccident.label')}
              </Text>
            </>
          )}
          <div className={'print-show'}>
            <br/>
            <Checklist text={t(`${baseInsuranceType.translationPrefix}.description`)}/>
          </div>
          <Divider primary/>
          <Row className={'price-row'}>
            <Col flex={'auto'}>
              <Text size={'small'} opacity={0.4}>{t('insurance.monthlyRate')}</Text>
              <br/>
              <Text size={'small'} opacity={0.4}>CHF </Text>
              <Text strong color={'gray-blue'}><Price value={person.baseInsurance.getNetPrice(factor)}/></Text>
            </Col>
            {!fullDetails && !person.isNewBorn && (
              <Col flex={'50px'} className={'delete'}>
                <Button type={'link'} onClick={removeBaseInsurance}>
                  <Icon component={TrashBinIcon}/>
                </Button>
              </Col>
            )}
          </Row>
        </Card>
        {fullDetails && person.doctor !== undefined && (
          <Card>
            <Row>
              <Col flex={'auto'}>
                <Text header={2}>
                  {t('label.doctor')}
                </Text>
              </Col>
              <Col flex={'50px'} className={'product-icon'}>
                <Button type={'link'} onClick={changeDoctor}>
                  <Icon component={PenIcon} size={22}/>
                </Button>
              </Col>
            </Row>
            <br/>
            <Text size={'small'}>
              {person.doctor.title}
              <br/>
            </Text>
            <Text strong size={'small'}>
              {person.doctor.name}
              <br/>
            </Text>
            <Text size={'small'}>
              {person.doctor.address}
              <br/>
              {person.doctor.postcode}
              {' '}
              {person.doctor.city}
            </Text>
          </Card>
        )}
      </Space>
      {!fullDetails && !person.isNewBorn && (
        <Button className={'text-small'} type={'link'} onClick={() => changeModel()}>
          {t('baseInsurance.change')}
        </Button>
      )}
    </Space>
  );
};

export default observer(BaseInsuranceCardOverview);
