import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import {useNavigate} from 'react-router-dom';
import {Form} from 'antd';
import ReactMarkdown from 'react-markdown';
import {useStore} from '../../../../hooks/useStore';
import {CancellationWizardPaths} from '../../../paths';
import StepsNavigationButtons from '../../../../components/StepsNavigationButtons';
import {Col, Row} from '../../../../components/layout/Grid';
import {Config, GUTTER_SIZE} from '../../../../config';
import {Validators} from '../../../../utils/validators';
import Checkbox from '../../../../components/shared/Checkbox';
import Text from '../../../../components/shared/Text';
import InfoBox from '../../../../components/shared/InfoBox';
import {Api} from '../../../../services/api';
import './OptInForm.scss';

interface FormValues {
  optInCorrectness: boolean;
  optInConsentOfOtherPeople: boolean;
  optInPrivacyPolicy: boolean;
}

const OptInForm: FC = () => {
  const {t} = useTranslation();
  const store = useStore();
  const navigate = useNavigate();
  const [form] = Form.useForm<FormValues>();
  const [loading, setLoading] = useState(false);

  const onSubmit = (values: FormValues) => {
    setLoading(true);
    store.cancellationWizardStore.setOptInCorrectness(values.optInCorrectness);
    store.cancellationWizardStore.setOptInConsentOfOtherPeople(values.optInConsentOfOtherPeople);
    store.cancellationWizardStore.setOptInPrivacyPolicy(values.optInPrivacyPolicy);

    Api.sendRequest(store.cancellationWizardStore.getIssueRequest())
      .then(() => {
        navigate(CancellationWizardPaths.success());
      })
      .catch(() => {
        // TODO: add error handling
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onBack = () => {
    navigate(CancellationWizardPaths.verification());
  };

  return (
    <>
      <Row gutter={[GUTTER_SIZE, GUTTER_SIZE]}>
        <Col xs={24} sm={24} md={24} lg={18} xl={18}>
          <Form<FormValues>
            form={form}
            layout={'vertical'}
            colon={false}
            labelAlign={'left'}
            requiredMark={false}
            onFinish={onSubmit}
            className={'opt-in-form'}
          >
            <Form.Item>
              <InfoBox title={t('cancellation.wizard.optIn.info.title')}>
                {t('cancellation.wizard.optIn.info.text')}
              </InfoBox>
            </Form.Item>
            <Form.Item>
              <Text
                size={'intro'}
                color={'gray-blue'}
                medium
                style={{marginBottom: 0}}
              >
                {t('cancellation.wizard.optIn.subtitle')}
              </Text>
            </Form.Item>
            <Form.Item
              name={'optInCorrectness'}
              valuePropName={'checked'}
              rules={[Validators.checkbox(t('validator.optInCorrectnessRequired'))]}
            >
              <Checkbox>
                <ReactMarkdown>
                  {t('cancellation.wizard.optIn.optInCorrectness.label')}
                </ReactMarkdown>
              </Checkbox>
            </Form.Item>
            <Form.Item
              name={'optInConsentOfOtherPeople'}
              valuePropName={'checked'}
              rules={[Validators.checkbox(t('validator.optInConsentOfOtherPeopleRequired'))]}
            >
              <Checkbox>
                <ReactMarkdown>
                  {t('cancellation.wizard.optIn.optInConsentOfOtherPeople.label')}
                </ReactMarkdown>
              </Checkbox>
            </Form.Item>
            <Form.Item
              name={'optInPrivacyPolicy'}
              valuePropName={'checked'}
              rules={[Validators.checkbox(t('validator.optInPrivacyPolicyRequired'))]}
            >
              <Checkbox>
                <ReactMarkdown linkTarget={'_blank'}>
                  {t('cancellation.wizard.optIn.optInPrivacyPolicy.label', {privacyUrl: Config.privacyPolicyUrl})}
                </ReactMarkdown>
              </Checkbox>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <StepsNavigationButtons
        back={onBack}
        next={form.submit}
        nextLabel={t('cancellation.wizard.optIn.submitLabel')}
        loading={loading}
      />
    </>
  );
};

export default observer(OptInForm);
