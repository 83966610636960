/* eslint-disable import/no-cycle */
import {action, computed, makeObservable, observable} from 'mobx';
import {BaseStore} from './base-store';
import {Person} from '../models/person';
import {City} from '../models/city';
import {RootStore} from './root-store';
import {IDocumentRequest, PersonType} from '../models/document-request';
import {sortNumerically} from '../utils/sorters';

export abstract class AquilanaFunctionStore extends BaseStore<Person, String> {
  currentIndex = 0;
  city?: City | null = undefined;
  address?: string = undefined;
  email?: string = undefined;
  phone?: string = undefined;

  protected constructor(rootStore: RootStore) {
    super(rootStore, 'uuid');

    this.add(Person.create({type: PersonType.POLICY_HOLDER}));

    makeObservable(this, {
      address: observable,
      currentIndex: observable,
      city: observable,
      email: observable,
      phone: observable,

      remove: action,
      setPolicyholder: action,
      setAddress: action,
      setCity: action,
      setEmail: action,
      setPhone: action,
      setCurrent: action,
      setCurrentIndex: action,
      setNextAsCurrent: action,
      setModels: action,
      getIssueRequest: action,
      reset: action,

      current: computed,
      policyHolder: computed,
      children: computed,
      hasNext: computed,
    });
  }

  get policyHolder() {
    return this.models.find((p) => p.type === PersonType.POLICY_HOLDER) || this.models[0];
  }

  getIndex(person: Person) {
    return this.models.indexOf(person);
  }

  get current() {
    return this.models[this.currentIndex];
  }

  get hasNext(): boolean {
    return this.currentIndex + 1 < this.models.length;
  }

  get children(): Person[] {
    return this.models.slice().sort((a, b) => sortNumerically(a?.birthday?.valueOf() || 0, b?.birthday?.valueOf() || 0))
      .filter((p) => p.isChild);
  }

  remove(person: Person): boolean {
    const index = this.getIndex(person);
    if (index >= 0) {
      this.models.splice(index, 1);
      if (this.currentIndex > this.models.length - 1) {
        this.setCurrentIndex(0);
      }
      return true;
    }
    return false;
  }

  setCurrent(person: Person) {
    const index = this.models.findIndex((p) => p.uuid === person.uuid);
    if (index !== -1) {
      this.currentIndex = index;
    }
  }

  setCurrentIndex(index: number) {
    this.currentIndex = index;
  }

  setNextAsCurrent() {
    this.currentIndex += 1;
  }

  setPolicyholder(personId: Person['uuid']) {
    this.models.forEach((p) => {
      if (p.uuid === personId) {
        p.type = PersonType.POLICY_HOLDER;
      } else if (p.type === PersonType.POLICY_HOLDER) {
        p.type = PersonType.DEFAULT;
      }
    });
  }

  setAddress(address: string) {
    this.address = address;
  }

  setCity(city?: City) {
    this.city = city;
  }

  setEmail(email: string) {
    this.email = email;
  }

  setPhone(phone?: string) {
    this.phone = phone;
  }

  setModels(models: Person[]) {
    this.models = models;
    this.setCurrentIndex(0);
  }

  abstract reset(): void;

  abstract getIssueRequest(): IDocumentRequest;
}
