import React from 'react';
import {useTranslation} from 'react-i18next';
import Container from '../../../components/layout/Container';
import Text from '../../../components/shared/Text';
import QuestionsForm from './_components/QuestionsForm';

const Questions = () => {
  const {t} = useTranslation();

  return (
    <>
      <Container background={'white'}>
        <Text header={1}>{t('cancellation.leave.questions.title')}</Text>
        <Text size={'intro'}>{t('cancellation.leave.questions.text')}</Text>
      </Container>
      <Container background={'light'} flex={1}>
        <QuestionsForm/>
      </Container>
    </>
  );
};

export default Questions;
