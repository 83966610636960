import {useLayoutEffect, useState} from 'react';

const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState('');

  useLayoutEffect(() => {
    const updateSize = () => {
      if (window.innerWidth >= 1400) {
        setBreakpoint('xxl');
      } else if (window.innerWidth >= 1200) {
        setBreakpoint('xl');
      } else if (window.innerWidth >= 992) {
        setBreakpoint('lg');
      } else if (window.innerWidth >= 768) {
        setBreakpoint('md');
      } else if (window.innerWidth >= 576) {
        setBreakpoint('sm');
      } else {
        setBreakpoint('xs');
      }
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return {
    isMobile: ['xs', 'sm'].includes(breakpoint),
    breakpoint,
  };
};

export default useBreakpoint;
