import {Col, Row} from 'antd';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import {useNavigate} from 'react-router-dom';
import {useStore} from '../../hooks/useStore';
import {CalculatorPaths} from '../../pages/paths';
import {GUTTER_SIZE} from '../../config';
import Icon from '../shared/Icon';
import {FormularAssignmentIcon, PenIcon} from '../shared/icons';
import Button from '../shared/Button';
import {formatIban} from '../../utils/formatter';
import Text from '../shared/Text';
import './OverviewCard.scss';

const PaymentCard: FC = () => {
  const {t} = useTranslation();
  const store = useStore();
  const navigate = useNavigate();

  const edit = () => {
    store.setEditMode(true);
    navigate(CalculatorPaths.payment());
  };

  return (
    <div className={'overview-card'}>
      <div className={'card-header'}>
        <Row gutter={GUTTER_SIZE} align={'middle'} wrap={false}>
          <Col flex={'50px'}>
            <Icon component={FormularAssignmentIcon} size={50}/>
          </Col>
          <Col flex={'auto'}>
            <Text>{t('stepDetails.payment')}</Text>
          </Col>
          <Col flex={'50px'}>
            <Button type={'link'} onClick={edit}>
              <Icon component={PenIcon} size={22}/>
            </Button>
          </Col>
        </Row>
      </div>
      <div className={'card-body'}>
        <table>
          <tbody>
            <tr>
              <td className={'label'}>
                <Text size={'small'}>
                  {t('label.contactLanguage')}
                </Text>
              </td>
              <td className={'value'}>
                <Text strong size={'small'}>{t(`lang.${store.calculatorStore.contactLanguage?.toLowerCase()}`)}</Text>
              </td>
            </tr>
            <tr>
              <td className={'label'}>
                <Text size={'small'}>
                  {t('label.paymentMethod')}
                </Text>
              </td>
              <td className={'value'}>
                <Text strong size={'small'}>{t(`paymentMethod.${store.calculatorStore.paymentMethod}`)}</Text>
              </td>
            </tr>
            <tr>
              <td className={'label'}>
                <Text size={'small'}>
                  {t('label.paymentRhythm')}
                </Text>
              </td>
              <td className={'value'}>
                <Text strong size={'small'}>{t(`paymentRhythm.${store.calculatorStore.paymentRhythm}`)}</Text>
              </td>
            </tr>
            <tr>
              <td className={'label'}>
                <Text size={'small'}>
                  {t('label.iban')}
                </Text>
              </td>
              <td className={'value'}>
                <Text strong size={'small'}>
                  {store.calculatorStore.iban ? formatIban(store.calculatorStore.iban) : '-'}
                </Text>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default observer(PaymentCard);
