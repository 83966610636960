import {Divider as AntDivider, DividerProps as AntDividerProps} from 'antd';
import {FC} from 'react';
import './Divider.scss';

interface DividerProps extends AntDividerProps {
  primary?: boolean;
}

const Divider: FC<DividerProps> = ({primary, className, ...props}) => (
  <AntDivider className={`aquilana-divider ${primary && 'divider-primary'} ${className}`} {...props}/>
);

export default Divider;
