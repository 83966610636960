import React, {useEffect} from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import Person from './person/Person';
import {useStore} from '../../hooks/useStore';
import {CancellationLeavePaths} from '../paths';
import Questions from './questions/Questions';
import Success from './success/Success';
import Cancellation from './cancellation/Cancellation';

const CancellationLeaveRoutes = () => {
  const navigate = useNavigate();
  const store = useStore();

  useEffect(() => {
    store.cancellationLeaveStore.reset();
    navigate(CancellationLeavePaths.index());
  }, []);

  return (
    <Routes>
      <Route index element={<Person/>}/>
      <Route path={'questions'} element={<Questions/>}/>
      <Route path={'cancellation'} element={<Cancellation/>}/>
      <Route path={'success'} element={<Success/>}/>
    </Routes>
  );
};

export default observer(CancellationLeaveRoutes);
