import {FC, ReactNode} from 'react';
import {Checkbox, Col, Row} from 'antd';
import {CheckboxChangeEvent} from 'antd/lib/checkbox/Checkbox';
import {GUTTER_SIZE} from '../../../../config';
import Card from '../../../../components/shared/Card';
import './ProductCard.scss';

interface ProductCardProps {
  icon: ReactNode;
  name: string;
  selected: boolean;
  onSelectionChange: (selected: boolean) => void;
  disabled?: boolean;
}

const ProductCard: FC<ProductCardProps> = ({icon, name, selected, onSelectionChange, disabled}) => {
  const onChange = (e: CheckboxChangeEvent) => onSelectionChange(e.target.checked);

  return (
    <Card className={'product-card'} onClick={() => onSelectionChange(!selected)}>
      <Row align={'middle'} gutter={GUTTER_SIZE}>
        <Col>{icon}</Col>
        <Col flex={'auto'}>{name}</Col>
        <Col className={'checkbox-wrapper'}>
          <Checkbox checked={selected} onChange={onChange} disabled={disabled}/>
        </Col>
      </Row>
    </Card>
  );
};

export default ProductCard;
