import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import {useNavigate} from 'react-router-dom';
import Container from '../../../components/layout/Container';
import Text from '../../../components/shared/Text';
import {useStore} from '../../../hooks/useStore';
import Space from '../../../components/shared/Space';
import {Box, BoxWrapper} from '../../../components/shared/Box';
import Icon from '../../../components/shared/Icon';
import {PoliceIcon} from '../../../components/shared/icons';
import {Config, GUTTER_SIZE} from '../../../config';
import {Col, Row} from '../../../components/layout/Grid';
import Button from '../../../components/shared/Button';
import {CancellationUploadPaths} from '../../paths';
import InfoBox from '../../../components/shared/InfoBox';

const Success = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const store = useStore();

  useEffect(() => {
    if (!store.cancellationUploadStore.file) {
      navigate(CancellationUploadPaths.index());
    }
  }, []);

  const uploadNewCancellation = () => {
    store.cancellationUploadStore.reset();
    navigate(CancellationUploadPaths.index());
  };

  const toWebsite = () => {
    window.location.href = Config.websiteUrl;
  };

  return (
    <>
      <Container background={'white'}>
        <Text header={1}>{t('cancellation.upload.successPage.title')}</Text>
      </Container>
      <Container background={'light'} flex={1}>
        <Space direction={'vertical'} size={'large'}>
          <BoxWrapper>
            <Box flex={'0 0 70px'}>
              <Icon component={PoliceIcon} size={50} color={'gray-blue'}/>
            </Box>
            <Box flex={'auto'}>
              <Text medium color={'gray-blue'}>
                <ReactMarkdown linkTarget={'_blank'}>
                  {t('cancellation.upload.successPage.text', {email: store.cancellationUploadStore.email})}
                </ReactMarkdown>
              </Text>
            </Box>
          </BoxWrapper>
          <InfoBox title={t('cancellation.wizard.success.info.title')}>
            <ReactMarkdown linkTarget={'_blank'}>
              {t('cancellation.wizard.success.info.text', {contactUrl: Config.aquilanaContactUrl})}
            </ReactMarkdown>
          </InfoBox>
          <Row gutter={[GUTTER_SIZE, GUTTER_SIZE]} style={{marginBottom: '2rem', marginTop: '3rem'}}>
            <Col>
              <Button skin={'primary-turquoise'} onClick={uploadNewCancellation}>
                {t('cancellation.upload.successPage.uploadNewCancellation')}
              </Button>
            </Col>
            <Col>
              <Button onClick={() => toWebsite()} skin={'primary-gray-blue'}>
                {t('action.toWebsite')}
              </Button>
            </Col>
          </Row>
        </Space>
      </Container>
    </>
  );
};

export default Success;
