import React, {FC, useState} from 'react';
import {Form, Input, Radio} from 'antd';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {Col, Row} from '../../../../components/layout/Grid';
import {Config, GUTTER_SIZE} from '../../../../config';
import {Sex} from '../../../../models/person';
import {Validators} from '../../../../utils/validators';
import InfoBox from '../../../../components/shared/InfoBox';
import Text from '../../../../components/shared/Text';
import OptionalLabel from '../../../../components/shared/OptionalLabel';
import {formatInsuranceNumber} from '../../../../utils/formatter';
import Upload, {UploadChangeParam, UploadFile} from '../../../../components/shared/Upload';
import Button from '../../../../components/shared/Button';
import Space from '../../../../components/shared/Space';
import Checkbox from '../../../../components/shared/Checkbox';
import {useStore} from '../../../../hooks/useStore';
import {Api} from '../../../../services/api';
import {CancellationUploadPaths} from '../../../paths';
import StepsNavigationButtons from '../../../../components/StepsNavigationButtons';
import Select from '../../../../components/shared/Select';

interface FormValues {
  sex: Sex;
  firstName: string;
  lastName: string;
  emailCustomer?: string;
  phone?: string;
  insuranceNumber: string;
  file: UploadFile[];
  issueType: string;
  email: string;
  optIn: boolean;
}

const CancellationUploadForm: FC = () => {
  const {t} = useTranslation();
  const store = useStore();
  const navigate = useNavigate();
  const [form] = Form.useForm<FormValues>();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (values: FormValues) => {
    setLoading(true);

    const reader = new FileReader();
    reader.readAsDataURL(values.file[0].originFileObj as Blob);
    reader.onload = () => {
      store.cancellationUploadStore.setSex(values.sex);
      store.cancellationUploadStore.setFirstName(values.firstName);
      store.cancellationUploadStore.setLastName(values.lastName);
      store.cancellationUploadStore.setEmail(values.email);
      store.cancellationUploadStore.setInsuranceNumber(values.insuranceNumber);
      store.cancellationUploadStore.setFile((reader.result as string).split(',').pop() || '');
      store.cancellationUploadStore.setIssueType(values.issueType);

      if (values.phone) {
        store.cancellationUploadStore.setPhone(values.phone);
      }

      if (values.emailCustomer) {
        store.cancellationUploadStore.setCustomerEmail(values.emailCustomer);
      }

      Api.sendRequest(store.cancellationUploadStore.getIssueRequest())
        .then(() => {
          navigate(CancellationUploadPaths.successSubmission());
        })
        .finally(() => setLoading(false));
    };
  };

  const normFile = (e: UploadChangeParam) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e.fileList;
  };

  return (
    <Row gutter={[GUTTER_SIZE, GUTTER_SIZE]}>
      <Col xs={24} sm={24} md={24} lg={16} xl={16}>
        <Form
          form={form}
          layout={'vertical'}
          colon={false}
          labelAlign={'left'}
          requiredMark={false}
          onFinish={handleSubmit}
        >
          <Form.Item>
            <InfoBox title={t('cancellation.upload.initialPage.info.title')}>
              {t('cancellation.upload.initialPage.info.text')}
            </InfoBox>
          </Form.Item>
          <Form.Item>
            <Text header={4}>{t('cancellation.upload.initialPage.policyHolder.formTitle')}</Text>
          </Form.Item>
          <Form.Item
            name={'sex'}
            label={t('label.sex')}
            rules={[Validators.required(t('validator.salutationRequired'))]}
          >
            <Radio.Group>
              {Object.values(Sex).map((sex) => (
                <Radio key={sex} value={sex}>{t(`sex.${sex}`)}</Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name={'firstName'}
            label={t('label.firstName')}
            rules={[Validators.required(t('validator.firstNameRequired')), Validators.noSpecialCharacters]}
          >
            <Input placeholder={t('label.firstName')} maxLength={50}/>
          </Form.Item>
          <Form.Item
            name={'lastName'}
            label={t('label.name')}
            rules={[Validators.required(t('validator.nameRequired')), Validators.noSpecialCharacters]}
          >
            <Input placeholder={t('label.name')} maxLength={50}/>
          </Form.Item>
          <Form.Item
            name={'emailCustomer'}
            label={<OptionalLabel label={t('label.email')}/>}
            rules={[Validators.email]}
          >
            <Input placeholder={t('label.email')} maxLength={255}/>
          </Form.Item>
          <Form.Item
            name={'phone'}
            label={<OptionalLabel label={t('label.phone')}/>}
            rules={[Validators.phone]}
          >
            <Input placeholder={t('label.phone')} maxLength={50}/>
          </Form.Item>
          <Form.Item
            name={'insuranceNumber'}
            label={t('label.insuranceNumber')}
            rules={[Validators.insuranceNumber, Validators.required(t('validator.insuranceNumberRequired'))]}
          >
            <Input
              placeholder={t('label.insuranceNumberPlaceholder')}
              onInput={formatInsuranceNumber}
            />
          </Form.Item>
          <Form.Item
            name={'file'}
            label={t('label.upload')}
            getValueFromEvent={normFile}
            valuePropName={'fileList'}
            rules={[
              {
                validator: (_, [file]) => {
                  if (!file) {
                    return Promise.reject(t('validator.cancellationFileRequired'));
                  }

                  if (file.type !== 'application/pdf') {
                    return Promise.reject(t('validator.cancellationFileInvalid'));
                  }

                  const maxFileSize = 20 * 1024 * 1024;
                  if (file.size > maxFileSize) {
                    return Promise.reject(t('validator.cancellationFileTooLarge'));
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Upload maxCount={1} beforeUpload={() => false} accept={'.pdf'}>
              <Space direction={'vertical'} fullWidth size={'large'}>
                <Button skin={'primary-gray-blue'}>{t('action.selectCancellationFile')}</Button>
                <Text size={'small'}>{t('cancellation.upload.initialPage.fileUpload.info')}</Text>
              </Space>
            </Upload>
          </Form.Item>
          <Form.Item style={{marginTop: '3rem'}}>
            <Text header={4}>{t('cancellation.upload.initialPage.confirmationMail.title')}</Text>
          </Form.Item>
          <Form.Item
            label={t('cancellation.upload.initialPage.relationToCustomer.title')}
            name={'issueType'}
            rules={[Validators.required(t('validator.relationToCustomerRequired'))]}
          >
            <Select
              options={Config.issueCancellationUploadTypes}
              placeholder={t('cancellation.upload.initialPage.relationToCustomer.placeholder')}
            />
          </Form.Item>
          <Form.Item
            name={'email'}
            label={t('label.email')}
            rules={[Validators.required(t('validator.emailRequired')), Validators.email]}
          >
            <Input placeholder={t('label.email')} maxLength={255}/>
          </Form.Item>
          <Form.Item
            style={{marginTop: '3rem'}}
            name={'optIn'}
            valuePropName={'checked'}
            label={t('cancellation.upload.initialPage.optIn.title')}
            rules={[Validators.checkbox()]}
          >
            <Checkbox>{t('cancellation.upload.initialPage.optIn.text')}</Checkbox>
          </Form.Item>

          <StepsNavigationButtons
            backHidden
            back={() => ''}
            next={() => form.submit()}
            nextLabel={t('cancellation.upload.initialPage.submitCancellation')}
            loading={loading}
          />
        </Form>
      </Col>
    </Row>
  );
};

export default CancellationUploadForm;
