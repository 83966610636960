// eslint-disable-next-line import/no-cycle
import {DomainContextInformation} from '../../contexts/DomainContext';

export const isCancellationDeathContext = (path: string) => {
  if (!path) {
    return false;
  }
  if (!path.match(/^\/cancellation-death/)) {
    return false;
  }

  return true;
};

export const CANCELLATION_DEATH_CONTEXT_NAME = 'cancellation-death';

export const CancellationDeathContext: DomainContextInformation = {
  name: CANCELLATION_DEATH_CONTEXT_NAME,
  matches: isCancellationDeathContext,
};
