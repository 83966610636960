/* eslint-disable import/no-cycle */
import axios from 'axios';
import {FranchiseGroup} from '../models/franchise-group';
import {PreviousInsurer} from '../models/previous-insurer';
import {Doctor} from '../models/doctor';
import {BaseInsuranceRate} from '../models/base-insurance-rate';
import {City} from '../models/city';
import {AddonInsuranceRate} from '../models/addon-insurance-rate';
import {IDocumentRequest, IIssue, ITravelInsuranceIssue} from '../models/document-request';
import {PremiumVersion} from '../models/premium-version';
import {TermOfInsurance} from '../models/term-of-insurance';
import {TravelInsuranceType} from '../models/travel-insurance-type';
import {TravelInsuranceRate} from '../models/travel-insurance-rate';
import {Environment} from '../models/environment';

export class Api {
  static getEnvironment = (): Promise<Environment> => Api.get('environment', Environment.fromJSON);
  static getCities = (): Promise<City[]> => Api.get('cities', City.fromJSON);

  static getFranchiseGroups = (): Promise<FranchiseGroup[]> => Api.get('franchise-groups', FranchiseGroup.fromJSON);

  static getPreviousInsurers = (): Promise<PreviousInsurer[]> => Api.get('previous-insurers');

  static getDoctors = (): Promise<Doctor[]> => Api.get('doctors', Doctor.fromJSON);

  static getBaseInsuranceRates = (): Promise<BaseInsuranceRate[]> => Api
    .get('base-insurance-rates', BaseInsuranceRate.fromObject);

  static getAddonInsuranceRates = (): Promise<AddonInsuranceRate[]> => Api
    .get('addon-insurance-rates', AddonInsuranceRate.fromObject);

  static getPremiumVersions = (): Promise<PremiumVersion[]> => Api.get('premium-versions', PremiumVersion.fromJSON);

  static getTermsOfInsurance = (): Promise<TermOfInsurance[]> =>
    Api.get('terms-of-insurance', TermOfInsurance.fromJSON);

  static getTravelInsuranceTypes = (): Promise<TravelInsuranceType[]> =>
    Api.get('travel-insurance-types', TravelInsuranceType.fromJSON);

  static getTravelInsuranceRates = (): Promise<TravelInsuranceRate[]> =>
    Api.get('travel-insurance-rates', TravelInsuranceRate.fromJSON);

  static getCase = (gc: string, cc: string): Promise<IIssue> => Api.get(`case?gc=${gc}&cc=${cc}`);

  static sendRequest = async (data: IDocumentRequest): Promise<any> => Api.post('send-request', data);

  static sendTravelInsuranceRequest = async (data: ITravelInsuranceIssue): Promise<any> =>
    Api.post('send-travel-insurance-request', data);

  static getOfferPdf = async (data: IIssue): Promise<any> => Api.post('offer-pdf', data);

  static getFranchisePdf = async (data: IIssue): Promise<any> => Api.post('franchise-change-pdf', data);

  static getTravelInsurancePdf = async (poliyNr: string): Promise<any> =>
    Api.get(`travel-insurance-pdf?policyNr=${poliyNr}`);

  static getTravelInsuranceByPolicyNrExists = async (poliyNr: string): Promise<any> =>
    Api.get(`travel-insurance-exists?policyNr=${poliyNr}`);

  static getRequestPdf = async (data: IIssue): Promise<any> => Api.post('request-pdf', data);

  private static getUrl = (url: string) => `${process.env.REACT_APP_CALCULATOR_API_URL}/${url}`;

  private static get = (url: string, mapper: (data: any) => any = (data) => data) => axios.get(
    Api.getUrl(url),
    process.env.REACT_APP_CALCULATOR_API_AUTH ? {
      headers: {
        Authorization: `Basic ${btoa(process.env.REACT_APP_CALCULATOR_API_AUTH)}`,
      },
    } : undefined
  )
    .then((response) => (
      Array.isArray(response.data) ? response.data.map((data: any) => mapper(data)) : mapper(response.data)
    ));

  private static post = (url: string, data: any) => {
    const headers: { [key: string]: string } = {
      'Content-Type': 'application/json',
    };
    if (process.env.REACT_APP_CALCULATOR_API_AUTH) {
      headers.Authorization = `Basic ${btoa(process.env.REACT_APP_CALCULATOR_API_AUTH)}`;
    }
    return axios.post(Api.getUrl(url), data, {headers}).then((response) => response.data);
  };
}
