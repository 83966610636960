// eslint-disable-next-line import/no-cycle
import {DomainContextInformation} from '../../contexts/DomainContext';

export const isCalculatorContext = (path: string) => {
  if (!path) {
    return false;
  }
  if (!path.match(/^\/offer/)) {
    return false;
  }

  return true;
};

export const CALCULATOR_CONTEXT_NAME = 'calculator';

export const CalculatorContext: DomainContextInformation = {
  name: CALCULATOR_CONTEXT_NAME,
  matches: isCalculatorContext,
};
