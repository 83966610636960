import React from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import {useNavigate} from 'react-router-dom';
import Container from '../../../components/layout/Container';
import Text from '../../../components/shared/Text';
import {Col, Row} from '../../../components/layout/Grid';
import {GUTTER_SIZE} from '../../../config';
import Space from '../../../components/shared/Space';
import PersonBlock from '../../../components/PersonBlock';
import {useStore} from '../../../hooks/useStore';
import StepsNavigationButtons from '../../../components/StepsNavigationButtons';
import {CancellationWizardPaths} from '../../paths';
import ContactCard from '../../../components/cards/ContactCard';
import Button from '../../../components/shared/Button';
import {Person} from '../../../models/person';
import {PersonType} from '../../../models/document-request';

const Overview = () => {
  const store = useStore();
  const {t} = useTranslation();
  const navigate = useNavigate();

  const onAddPerson = () => {
    const person = Person.create();
    store.cancellationWizardStore.add(person);
    store.cancellationWizardStore.setCurrent(person);
    store.setEditMode(false);
    navigate(CancellationWizardPaths.index());
  };

  const onBack = () => {
    navigate(CancellationWizardPaths.index());
  };

  const onNext = () => {
    navigate(CancellationWizardPaths.verification());
  };

  return (
    <>
      <Container background={'white'}>
        <Text header={1}>{t('cancellation.wizard.overview.title')}</Text>
        <Text size={'intro'}>{t('cancellation.wizard.overview.header')}</Text>
      </Container>
      <Container background={'light'} flex={1}>
        <Row gutter={[GUTTER_SIZE, GUTTER_SIZE]}>
          <Col xs={24} sm={24} md={24} lg={16} xl={16}>
            <Space fullWidth direction={'vertical'} size={'large'}>
              <PersonBlock person={store.cancellationWizardStore.policyHolder} fullDetails index={0}/>
              {store.cancellationWizardStore.models.filter((person) => person.type !== PersonType.POLICY_HOLDER)
                .map((person, index) => (
                  <PersonBlock key={person.uuid} person={person} fullDetails index={index + 1}/>
                ))}
              {store.cancellationWizardStore.models.length < 8 && (
                <Button skin={'secondary'} onClick={onAddPerson}>
                  {t('cancellation.wizard.overview.addAnotherPerson')}
                </Button>
              )}
            </Space>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <Space fullWidth direction={'vertical'} size={'large'}>
              <ContactCard/>
            </Space>
          </Col>
        </Row>
        <StepsNavigationButtons back={onBack} next={onNext} backHidden/>
      </Container>
    </>
  );
};

export default observer(Overview);
