import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Form, FormInstance, Input, Radio} from 'antd';
import {observer} from 'mobx-react-lite';
import {Validators} from '../../../../utils/validators';
import {useStore} from '../../../../hooks/useStore';
import {Sex} from '../../../../models/person';
import Text from '../../../../components/shared/Text';
import {Col} from '../../../../components/layout/Grid';

export interface ContactFormValues {
  sex: Sex;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

interface ContactFormFranchiseChangeProps {
  form: FormInstance<ContactFormValues>;
  afterSubmit: () => void;
}

const ContactFormFranchiseChange: FC<ContactFormFranchiseChangeProps> = ({form, afterSubmit}) => {
  const {t} = useTranslation();
  const store = useStore();

  const onFinish = (values: ContactFormValues) => {
    store.franchiseChangeStore.policyHolder.setSex(values.sex);
    store.franchiseChangeStore.policyHolder.setFirstName(values.firstName);
    store.franchiseChangeStore.policyHolder.setLastName(values.lastName);
    store.franchiseChangeStore.setEmail(values.email);
    store.franchiseChangeStore.setPhone(values.phone);
    store.franchiseChangeStore.saveToSession();
    afterSubmit();
  };

  const initialValues: Partial<ContactFormValues> = {
    sex: store.franchiseChangeStore.policyHolder.sex,
    firstName: store.franchiseChangeStore.policyHolder.firstName,
    lastName: store.franchiseChangeStore.policyHolder.lastName,
    email: store.franchiseChangeStore.email,
    phone: store.franchiseChangeStore.phone,
  };

  return (
    <Col xs={24} sm={24} md={24} lg={14} xl={14} style={{marginTop: 140}}>
      <Text header={2}>{t('stepOverviewFranchiseChange.contactTitle')}</Text>
      <Form<ContactFormValues>
        style={{marginTop: 40}}
        form={form}
        layout={'vertical'}
        colon={false}
        labelAlign={'left'}
        requiredMark={false}
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <Form.Item
          name={'sex'}
          label={t('label.sex')}
          rules={[Validators.required(t('validator.salutationRequired'))]}
        >
          <Radio.Group>
            {Object.values(Sex).map((sex) => (
              <Radio key={sex} value={sex}>{t(`sex.${sex}`)}</Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name={'firstName'}
          label={t('label.firstName')}
          rules={[Validators.required(t('validator.firstNameRequired')), Validators.noSpecialCharacters]}
        >
          <Input placeholder={t('label.firstName')} maxLength={50}/>
        </Form.Item>
        <Form.Item
          name={'lastName'}
          label={t('label.name')}
          rules={[Validators.required(t('validator.nameRequired')), Validators.noSpecialCharacters]}
        >
          <Input placeholder={t('label.name')} maxLength={50}/>
        </Form.Item>
        <Form.Item
          name={'email'}
          label={t('label.email')}
          rules={[Validators.required(t('validator.emailRequired')), Validators.email]}
        >
          <Input placeholder={t('label.email')} maxLength={255}/>
        </Form.Item>
        <Form.Item
          name={'phone'}
          label={t('label.phone')}
          rules={[Validators.required(t('validator.phoneRequired')), Validators.phone]}
        >
          <Input placeholder={t('label.phone')} maxLength={50}/>
        </Form.Item>
      </Form>
    </Col>
  );
};

export default observer(ContactFormFranchiseChange);
