import React, {FC, ReactNode} from 'react';
import {RowProps} from 'antd';
import {Col, Row} from './Grid';
import './SplittedContainer.scss';

export interface SplittedContainerProps extends RowProps {
  left: ReactNode;
  right: ReactNode;
  backgroundLeft?: 'light' | 'gray-blue' | 'smoke-blue' | 'white';
  backgroundRight?: 'light' | 'gray-blue' | 'smoke-blue' | 'white';
}

const SplittedContainer: FC<SplittedContainerProps> = ({
  left,
  right,
  backgroundLeft = 'white',
  backgroundRight = 'white',
  className,
  ...props
}) => (
  <Row gutter={0} className={className ? `splitted-container ${className}` : 'splitted-container'} {...props}>
    <Col xs={24} lg={12} className={backgroundLeft}>
      <div className={'col-left'}>
        {left}
      </div>
    </Col>
    <Col xs={24} lg={12} className={backgroundRight}>
      <div className={'col-right'}>
        {right}
      </div>
    </Col>
  </Row>
);

export default SplittedContainer;
