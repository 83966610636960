import React from 'react';
import {useTranslation} from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import Space from '../../../../components/shared/Space';
import Text from '../../../../components/shared/Text';
import Icon from '../../../../components/shared/Icon';
import {DocumentsIcon} from '../../../../components/shared/icons';
import {Config} from '../../../../config';
import CancellationDatesInfoTable from './CancellationDatesInfoTable';

const CancellationDatesInfo = () => {
  const {t} = useTranslation();

  return (
    <Space direction={'vertical'} size={20} fullWidth>
      <Text size={'regular'} strong color={'gray-blue'}>
        {t('cancellation.wizard.person.cancellationDate.info.subtitle')}
      </Text>
      <CancellationDatesInfoTable/>
      <Space>
        <Text>
          {t('cancellation.wizard.person.cancellationDate.info.footer.text')}
        </Text>
        <Icon component={DocumentsIcon} size={24}/>
        <ReactMarkdown linkTarget={'_blank'}>
          {t('cancellation.wizard.person.cancellationDate.info.footer.link', {url: Config.cancellationDatesInfoPdfUrl})}
        </ReactMarkdown>
      </Space>
    </Space>
  );
};

export default CancellationDatesInfo;
