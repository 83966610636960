import {FC} from 'react';
import ReactMarkdown from 'react-markdown';
import Text from './Text';

interface ChecklistProps {
  text: string;
}

const Checklist: FC<ChecklistProps> = ({text}) => {
  const listItems = text.split('\n');
  const createKey = (index: number): string => `item-${index}`;

  return (
    <ul className={'text-small check-list'}>
      {listItems.map((item, index) => (
        <li key={createKey(index)}>
          <Text>
            <ReactMarkdown>
              {item.replace(/<br>/, '\n')}
            </ReactMarkdown>
          </Text>
        </li>
      ))}
    </ul>
  );
};

export default Checklist;
