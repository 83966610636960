import {useTranslation} from 'react-i18next';
import {FC} from 'react';
import {observer} from 'mobx-react-lite';
import ReactMarkdown from 'react-markdown';
import {useNavigate} from 'react-router-dom';
import Container from '../../../components/layout/Container';
import Text from '../../../components/shared/Text';
import Icon from '../../../components/shared/Icon';
import {PoliceIcon} from '../../../components/shared/icons';
import InfoBox from '../../../components/shared/InfoBox';
import Space from '../../../components/shared/Space';
import Button from '../../../components/shared/Button';
import {Config, GUTTER_SIZE} from '../../../config';
import {Box, BoxWrapper} from '../../../components/shared/Box';
import {useStore} from '../../../hooks/useStore';
import {Col, Row} from '../../../components/layout/Grid';
import {CalculatorPaths} from '../../paths';

// t('success.submission.text')
// t('success.submission.info')
// t('success.request.text')
// t('success.request.info')
// t('success.newBorn.text')
interface SuccessPops {
  type: 'submission' | 'request' | 'newBorn';
}

const Success: FC<SuccessPops> = ({type}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const store = useStore();

  const startNewCalculation = () => {
    store.calculatorStore.reset();
    navigate(CalculatorPaths.index());
  };

  const toWebsite = () => {
    window.location.href = Config.websiteUrl;
  };

  return (
    <>
      <Container background={'white'}>
        <Text header={1}>{t(`success.${type}.title`)}</Text>
      </Container>
      <Container background={'white'} flex={1}>
        <Space direction={'vertical'} size={'large'}>
          <BoxWrapper>
            <Box flex={'0 0 70px'}>
              <Icon component={PoliceIcon} size={50} color={'gray-blue'}/>
            </Box>
            <Box flex={'auto'}>
              <Text medium color={'gray-blue'}>
                <ReactMarkdown linkTarget={'_blank'}>
                  {t(`success.${type}.text`, {url: Config.aquilanaContactUrl, email: store.calculatorStore.email})}
                </ReactMarkdown>
              </Text>
            </Box>
          </BoxWrapper>
          {type !== 'newBorn' && (
            <InfoBox title={t('label.notice')}>
              <ReactMarkdown>
                {t(`success.${type}.info`, {url: Config.aquilanaContactUrl})}
              </ReactMarkdown>
            </InfoBox>
          )}
          <br/>
          <Row gutter={[GUTTER_SIZE, GUTTER_SIZE]}>
            <Col>
              <Button onClick={() => toWebsite()} skin={'primary-gray-blue'}>
                {t('action.toWebsite')}
              </Button>
            </Col>
            <Col>
              <Button onClick={() => startNewCalculation()} skin={'primary-turquoise'}>
                {t('action.startNewCalculation')}
              </Button>
            </Col>
          </Row>
        </Space>
      </Container>
    </>
  );
};

export default observer(Success);
