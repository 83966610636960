import React, {FC, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {DatePicker, Form, Input, Radio} from 'antd';
import {useNavigate} from 'react-router-dom';
import dayjs, {Dayjs} from 'dayjs';
import {PreviousInsurer} from '../../../../models/previous-insurer';
import {Validators} from '../../../../utils/validators';
import {useStore} from '../../../../hooks/useStore';
import {sortAlphabetically} from '../../../../utils/sorters';
import {Config} from '../../../../config';
import {TravelInsurancePaths} from '../../../paths';
import {Person, Sex, TravelInsurancePersonType} from '../../../../models/person';
import Select from '../../../../components/shared/Select';
import {TravelInsuranceRateType} from '../../../../models/travel-insurance-rate';
import Space from '../../../../components/shared/Space';
import Button from '../../../../components/shared/Button';

interface PersonData {
  sex: Sex;
  insurer: PreviousInsurer['id'];
  firstName: string;
  lastName: string;
  birthDate: Dayjs;
}

const TravelInsuranceFamilyForm: FC = () => {
  const {t} = useTranslation();
  const store = useStore();
  const navigate = useNavigate();
  const person = store.travelInsuranceStore.current;
  const [form] = Form.useForm<PersonData>();
  const insurerOptions = store.previousInsurerStore.models.filter((insurer) => !insurer.deletedAt)
    .map((insurer) => ({label: insurer.name, value: insurer.id}))
    .sort((a, b) => sortAlphabetically(a.label, b.label));
  const [addNewChildAfterSubmit, setAddNewChildAfterSubmit] = useState(false);

  const initialValues = {
    firstName: person.firstName,
    lastName: person.lastName || store.travelInsuranceStore.policyHolder?.lastName,
    sex: person.sex,
    birthDate: person.birthday ? dayjs(person.birthday) : undefined,
    insurer: person.previousInsurer?.id || store.travelInsuranceStore.policyHolder?.previousInsurer?.id,
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [person.uuid]);

  if (store.travelInsuranceStore.type !== TravelInsuranceRateType.FAMILY) {
    navigate(TravelInsurancePaths.overview());
  }

  const onCancel = () => {
    if (!store.editMode) {
      store.travelInsuranceStore.remove(person);
    }
    navigate(TravelInsurancePaths.overview());
  };

  const onAddAnotherChild = () => {
    setAddNewChildAfterSubmit(true);

    form.submit();
  };

  const onClickSave = () => {
    setAddNewChildAfterSubmit(false);

    form.submit();
  };

  const onSubmit = (values: PersonData) => {
    person.setFirstName(values.firstName);
    person.setLastName(values.lastName);
    person.setSex(values.sex);
    person.setBirthday(dayjs(values.birthDate));
    person.setPreviousInsurer(store.previousInsurerStore.getById(values.insurer));

    store.setEditMode(false);

    if (addNewChildAfterSubmit) {
      const newPerson = Person.create();
      newPerson.setTravelInsurancePersonType(TravelInsurancePersonType.CHILD);
      store.travelInsuranceStore.add(newPerson);
      store.travelInsuranceStore.setCurrent(newPerson);
    } else {
      navigate(TravelInsurancePaths.overview());
    }
  };

  return (
    <Form
      initialValues={initialValues}
      form={form}
      onFinish={onSubmit}
      layout={'vertical'}
      colon={false}
      labelAlign={'left'}
      requiredMark={false}
    >
      <Form.Item
        name={'sex'}
        label={t('label.sex')}
        rules={[Validators.required(t('validator.salutationRequired'))]}
      >
        <Radio.Group>
          {Object.values(Sex).map((sex) => (
            <Radio key={sex} value={sex}>{t(`sex.${sex}`)}</Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name={'insurer'}
        label={t('stepPersonDetails.currentBaseInsurance.insurer')}
        rules={[Validators.required(t('validator.insurerRequired'))]}
      >
        <Select
          showSearch
          loading={store.previousInsurerStore.isLoading}
          options={insurerOptions}
          placeholder={t('person.selectInsurer')}
        />
      </Form.Item>
      <Form.Item
        name={'firstName'}
        label={t('label.firstName')}
        rules={[Validators.required(t('validator.firstNameRequired')), Validators.noSpecialCharacters]}
      >
        <Input placeholder={t('label.firstName')}/>
      </Form.Item>
      <Form.Item
        name={'lastName'}
        label={t('label.name')}
        rules={[Validators.required(t('validator.nameRequired')), Validators.noSpecialCharacters]}
      >
        <Input placeholder={t('label.name')}/>
      </Form.Item>
      <Form.Item
        name={'birthDate'}
        label={t('label.birthday')}
        rules={[Validators.required(t('validator.birthdayRequired')), {
          validator: (_, value) => {
            if (person.travelInsurancePersonType === TravelInsurancePersonType.PARTNER) {
              if (value && dayjs(store.travelInsuranceStore.travelInsuranceStartDate)
                .diff(dayjs(value), 'years') < 18) {
                return Promise.reject(t('travelInsurance.hasToBeAdultAtStartDate'));
              }
              return Promise.resolve();
            }
            if (value && store.travelInsuranceStore.travelInsuranceDuration?.days
                && dayjs(store.travelInsuranceStore.travelInsuranceStartDate)
                  .add(store.travelInsuranceStore.travelInsuranceDuration.days, 'days')
                  .diff(dayjs(value), 'years') >= 25) {
              return Promise.reject(t('travelInsurance.family.childAgeConflict'));
            }
            return Promise.resolve();
          },
        }]}
      >
        <DatePicker
          format={Config.dateFormat}
          placeholder={t('label.dateFormat')}
          disabledDate={(d) => d.isAfter(dayjs().endOf('day'))
            || d.isBefore(dayjs().startOf('day').subtract(150, 'years'))}
        />
      </Form.Item>
      <Space
        direction={'horizontal'}
        fullWidth
        size={'large'}
        style={{justifyContent: 'space-between'}}
        className={'bottom-navigation'}
      >
        <Button skin={'secondary'} onClick={onCancel}>
          {t('action.back')}
        </Button>
        <Space>
          {person.travelInsurancePersonType === TravelInsurancePersonType.CHILD && (
          <Button skin={'secondary'} onClick={onAddAnotherChild}>
            {t('action.addAnotherChild')}
          </Button>
          )}
          <Button skin={'primary-gray-blue'} onClick={onClickSave}>
            {t('action.next')}
          </Button>
        </Space>
      </Space>
    </Form>
  );
};

export default observer(TravelInsuranceFamilyForm);
