import React, {FC, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {DatePicker, Form, Select} from 'antd';
import {RefSelectProps} from 'antd/lib/select/index';
import {observer} from 'mobx-react-lite';
import {useNavigate} from 'react-router-dom';
import dayjs, {Dayjs} from 'dayjs';
import {Validators} from '../../../../utils/validators';
import InfoBox from '../../../../components/shared/InfoBox';
import {useStore} from '../../../../hooks/useStore';
import {City} from '../../../../models/city';
import Button from '../../../../components/shared/Button';
import {Config} from '../../../../config';
import {FranchiseChangePaths} from '../../../paths';
import StepsNavigationButtons from '../../../../components/StepsNavigationButtons';
import {updateInsuranceRates} from '../../../../utils/insurance-rates';
import useCitySelection from '../../../../hooks/useCitySelection';
import useCurrentDomainInformation from '../../../../hooks/useCurrentDomainInformation';

interface FormValues {
  birthday: Dayjs;
  city: City['id'];
}

const FranchisePersonForm: FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const store = useStore();
  const [form] = Form.useForm<FormValues>();
  const citySelector = useRef<RefSelectProps>(null);
  const {options} = useCitySelection();
  const person = store.franchiseChangeStore.current;
  const {currentStore} = useCurrentDomainInformation();

  const onFinish = (values: FormValues) => {
    person.setBirthday(dayjs(values.birthday));

    if (!person.uuid.length) {
      person.createId();
    }
    store.franchiseChangeStore.setCity(store.cityStore.getById(values.city));

    store.franchiseChangeStore.saveToSession();
    if (!store.editMode) {
      navigate(FranchiseChangePaths.current());
    } else {
      updateInsuranceRates(person, store, currentStore);
      store.setEditMode(false);
      navigate(FranchiseChangePaths.overview());
    }
  };

  const focusCitySelector = () => {
    citySelector?.current?.focus();
  };

  const initialValues = {
    birthday: person?.birthday ? dayjs(person.birthday) : undefined,
    city: store.franchiseChangeStore.city?.id,
  };

  const setBirthday = (value: string) => {
    if (value) {
      try {
        const date = dayjs(value, 'DD.MM.YYYY');
        if (date.isBefore(dayjs().endOf('day'))) {
          form.setFieldsValue({
            birthday: date,
          });
        }
      } catch (_) {
        // ignore
      }
    }
  };

  const back = () => {
    if (!store.franchiseChangeStore.current.birthday) {
      store.franchiseChangeStore.remove(store.franchiseChangeStore.current);
      store.franchiseChangeStore.setCurrentIndex(0);
    }
    navigate(FranchiseChangePaths.overview());
  };

  return (
    <div>
      <Form<FormValues>
        form={form}
        layout={'vertical'}
        colon={false}
        labelAlign={'left'}
        requiredMark={false}
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <Form.Item
          name={'birthday'}
          label={t('label.birthday')}
          rules={[Validators.required(t('validator.birthdayRequired'))]}
        >
          <DatePicker
            format={Config.dateFormat}
            placeholder={t('label.dateFormat')}
            onChange={() => focusCitySelector()}
            disabledDate={(d) => d.isAfter(dayjs().endOf('day'))
              || d.isBefore(dayjs().startOf('day').subtract(150, 'years'))}
            onBlur={(e: React.FocusEvent<HTMLElement>) => {
              // @ts-ignore
              setBirthday(e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item
          name={'city'}
          label={t('label.postcodeCity')}
          rules={[Validators.required(t('validator.postcodeCityRequired'))]}
        >
          <Select
            showSearch
            loading={store.cityStore.isLoading}
            options={options}
            filterOption={(input, option) => option!.label.toLowerCase().includes(input.toLowerCase())}
            placeholder={t('label.postcodeCityPlaceholder')}
            ref={citySelector}
            disabled={store.franchiseChangeStore.getIndex(person) !== 0}
          />
        </Form.Item>
        <Form.Item style={{maxWidth: '50vw'}}>
          <InfoBox title={t('franchiseChange.higherFranchiseInfoText.title')}>
            {t('franchiseChange.higherFranchiseInfoText.textInitialPage')}
          </InfoBox>
        </Form.Item>
        {!store.editMode && person.uuid.length ? (
          <Form.Item>
            <Button type={'submit'} skin={'primary-turquoise'}>
              {t('action.changeFranchise')}
            </Button>
          </Form.Item>
        ) : (
          <StepsNavigationButtons
            back={() => back()}
            next={() => form.submit()}
          />
        )}
      </Form>
    </div>
  );
};

export default observer(FranchisePersonForm);
