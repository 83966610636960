import {makeObservable, observable} from 'mobx';
import dayjs, {Dayjs} from 'dayjs';
import {IBaseModel} from './base-model';

export interface IPremiumVersion extends IBaseModel {
  id: number;
  validFrom: Dayjs;
  validTo: Dayjs
  environmentalTax: number;
}

export class PremiumVersion implements IPremiumVersion {
  id = 0;
  validFrom = dayjs();
  validTo = dayjs();
  environmentalTax = 0;

  constructor() {
    makeObservable(this, {
      id: observable,
      validFrom: observable,
      validTo: observable,
      environmentalTax: observable,
    });
  }

  isValidAt(date: Dayjs): boolean {
    return this.validFrom.valueOf() <= date.valueOf() && this.validTo.valueOf() >= date.valueOf();
  }

  static fromJSON(data?: Partial<IPremiumVersion>) {
    const premiumVersion = new PremiumVersion();
    if (data?.id) {
      premiumVersion.id = data.id;
    }
    if (data?.validFrom) {
      premiumVersion.validFrom = dayjs(data.validFrom).startOf('day');
    }
    if (data?.validTo) {
      premiumVersion.validTo = dayjs(data.validTo).endOf('day');
    }
    if (data?.environmentalTax) {
      premiumVersion.environmentalTax = data.environmentalTax;
    }

    return premiumVersion;
  }
}
