import {useTranslation} from 'react-i18next';
import {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {useNavigate} from 'react-router-dom';
import Container from '../../../components/layout/Container';
import {useStore} from '../../../hooks/useStore';
import Text from '../../../components/shared/Text';
import StepsNavigationButtons from '../../../components/StepsNavigationButtons';
import {AddonInsuranceType} from '../../../models/addon-insurance';
import {Col, Row} from '../../../components/layout/Grid';
import Space from '../../../components/shared/Space';
import {DisablementHandicapIcon} from '../../../components/shared/icons';
import Divider from '../../../components/shared/Divider';
import ExternalLink from '../../../components/shared/ExternalLink';
import {Config, GUTTER_SIZE} from '../../../config';
import {CalculatorPaths} from '../../paths';
import InsuranceCard from '../../../components/cards/InsuranceCard';
import AddonInsuranceCard from './_components/AddonInsuranceCard';

const AddonInsuranceSelection: FC = () => {
  const store = useStore();
  const person = store.calculatorStore.current;
  const navigate = useNavigate();
  const {t} = useTranslation();
  const hasReduction = store.calculatorStore.getPremiumReductionAddonInsurances(person);

  if (!person) {
    return null;
  }

  const previousStep = () => {
    if (store.editMode) {
      store.setEditMode(false);
      navigate(CalculatorPaths.overview());
    } else if (person.baseInsurance) {
      navigate(CalculatorPaths.productBase());
    } else {
      navigate(CalculatorPaths.index());
    }
  };

  const nextStep = () => {
    if (store.editMode) {
      store.setEditMode(false);
    }
    store.calculatorStore.saveToSession();
    navigate(CalculatorPaths.overview());
  };

  const createKey = (index: number) => `item-${index}`;
  // TODO: enabled when required (https://semabit.myjetbrains.com/youtrack/issue/AQP-67)
  const showDisablementHandicap = false;

  return (
    <>
      <Container background={'white'}>
        <Text header={1}>{t('addonInsurance.one')}</Text>
        <Text size={'intro'}>{t('addonInsurance.pleaseSelect')}</Text>
      </Container>
      <Container background={'light'} flex={1}>
        <Space direction={'vertical'} size={'large'}>
          <Row gutter={[GUTTER_SIZE, GUTTER_SIZE]}>
            {Object.values(AddonInsuranceType).map((key) => (
              <Col key={key} xs={24} sm={24} md={24} lg={12} xl={8} flex={1}>
                <AddonInsuranceCard
                  key={key}
                  type={key as AddonInsuranceType}
                  reduction={hasReduction}
                />
              </Col>
            ))}
            {showDisablementHandicap && (
              <Col key={'kti'} xs={24} sm={24} md={24} lg={12} xl={8}>
                <InsuranceCard
                  dark
                  icon={DisablementHandicapIcon}
                  title={t('addonInsurance.disablementHandicap.title')}
                  subTitle={t('addonInsurance.one')}
                >
                  <ul className={'text-small check-list'}>
                    {t('addonInsurance.disablementHandicap.description').split('\n').map((text, index) => (
                      <li key={createKey(index)}>
                        <Text>
                          {text}
                        </Text>
                      </li>
                    ))}
                  </ul>
                  <Divider primary/>
                  <Text size={'small'} block align={'right'}>
                    <ExternalLink href={Config.disablementHandicapUrl}>
                      {t('label.moreInformation')}
                    </ExternalLink>
                  </Text>
                </InsuranceCard>
              </Col>
            )}
          </Row>
          <StepsNavigationButtons backHidden={store.editMode} back={() => previousStep()} next={() => nextStep()}/>
        </Space>
      </Container>
    </>
  );
};

export default observer(AddonInsuranceSelection);
