import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import Cancellation from './cancellation/Cancellation';
import Success from './success/Success';

const CancellationUploadRoutes = () => (
  <Routes>
    <Route index element={<Cancellation/>}/>
    <Route path={'success'} element={<Success/>}/>
  </Routes>
);

export default observer(CancellationUploadRoutes);
