import {useTranslation} from 'react-i18next';
import {Col, Row} from 'antd';
import {FC} from 'react';
import ProductCard from './ProductCard';
import {useStore} from '../../../../hooks/useStore';
import {GUTTER_SIZE} from '../../../../config';
import Icon from '../../../../components/shared/Icon';
import {AddonInsuranceIcon, BaseInsuranceIcon} from '../../../../components/shared/icons';

interface StepProductProps {
  baseInsuranceSelected: boolean;
  setBaseInsuranceSelected: (selected: boolean) => void;
  addonInsuranceSelected?: boolean;
  setAddonInsuranceSelected?: (selected: boolean) => void;
}

const InsuranceSelection: FC<StepProductProps> = ({
  baseInsuranceSelected,
  setBaseInsuranceSelected,
  addonInsuranceSelected,
  setAddonInsuranceSelected,
}) => {
  const {t} = useTranslation();
  const store = useStore();
  const person = store.calculatorStore.current;

  return (
    <Row gutter={[GUTTER_SIZE, GUTTER_SIZE]}>
      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
        <ProductCard
          icon={<Icon component={BaseInsuranceIcon} size={44}/>}
          name={t('baseInsurance.many')}
          selected={baseInsuranceSelected}
          onSelectionChange={setBaseInsuranceSelected}
        />
      </Col>
      {!person.isSenior && setAddonInsuranceSelected && addonInsuranceSelected !== undefined && (
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <ProductCard
            icon={<Icon component={AddonInsuranceIcon} size={44}/>}
            name={t('addonInsurance.many')}
            selected={addonInsuranceSelected}
            onSelectionChange={setAddonInsuranceSelected}
          />
        </Col>
      )}
    </Row>
  );
};

export default InsuranceSelection;
